import PropTypes from '+prop-types';

import styled from 'styled-components';

const StyledInput = styled.input`
  position: sticky;
  top: 0;
  z-index: 1;
  width: 100%;
  min-width: 200px;
  padding: 8px 12px;
  border: none;
  border-bottom: 1px solid ${({ theme }) => theme.palette.divider};
  outline: none;
  font-size: 14px;
  background: ${({ theme }) => theme.palette.background.paper};
  color: ${({ theme }) => theme.palette.text.primary};
  margin-top: -8px;
  margin-bottom: 8px;

  &::placeholder {
    color: ${({ theme }) => theme.palette.text.secondary};
  }
`;

const DropdownSearch = ({ value, onChange, placeholder, autoFocus }) => {
  const handleKeyDown = (e) => {
    e.stopPropagation();
  };

  return (
    <StyledInput
      type="text"
      value={value}
      onChange={onChange}
      onKeyDown={handleKeyDown}
      placeholder={placeholder}
      autoFocus={autoFocus}
    />
  );
};

DropdownSearch.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  autoFocus: PropTypes.bool,
};

DropdownSearch.defaultProps = {
  placeholder: 'Search...',
  autoFocus: false,
};

export default DropdownSearch;
