import { useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { actions, selectors } from '@/redux/api/nql-complete';

/**
 * @param {ContextTypes} context
 * @param {string} [id]
 * @return {{
 *  isValidatingNql: boolean,
 *  clearValidationError: (function(): void),
 *  validateNql: (function(nql: string): Promise<unknown>),
 *  validationStatus: string,
 *  validationError: string
 * }}
 */
export const useVerifyNqlBeforeSend = (context, id) => {
  const dispatch = useDispatch();

  const validationError = useSelector(selectors.getValidationError(id));
  const validationStatus = useSelector(selectors.getValidationStatus);

  const clearTimeoutRef = useRef();
  const lastText = useRef();
  const lastError = useRef();
  lastError.current = validationError;

  // Position of this useEffect is important and prevent "Can’t perform a react state update on an unmounted component"
  useEffect(
    () => () => {
      lastText.current = '';
      lastError.current = null;
      clearTimeoutRef.current?.();
      dispatch(actions.cancel(id));
      dispatch(actions.clearValidationError(id));
    },
    [context, id],
  );

  const validateNql = useCallback(
    (text, _, meta) => {
      clearTimeoutRef.current?.();

      // do not run validation if field is active or text is empty
      if (!text || meta?.active) {
        return false;
      }

      if (lastText.current === text && lastError.current) {
        return lastError.current;
      }

      return new Promise((resolve) => {
        // to avoid error: Cannot update a component while rendering a different component.
        const timer = setTimeout(() => {
          clearTimeoutRef.current = null;

          const payload = {
            context,
            text,
            id,
            promise: {
              resolve,
              reject: resolve,
            },
          };

          lastText.current = text;
          dispatch(actions.cancel(id));
          dispatch(actions.clearValidationError(id));
          dispatch(actions.validate(payload, id));
        }, 300);

        clearTimeoutRef.current = () => {
          clearTimeout(timer);
          clearTimeoutRef.current = null;
          resolve();
        };
      });
    },
    [context, id],
  );

  const clearValidationError = useCallback(() => {
    dispatch(actions.clearValidationError(id));
  }, [id]);

  return {
    isValidatingNql: validationStatus === 'fetching',
    validationError,
    validationStatus,
    validateNql,
    clearValidationError,
  };
};
