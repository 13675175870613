import { useCallback, useMemo } from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { mirteHeatmapColors } from '+hooks/mitreHooks';
import { pluralize } from '+utils';

const TacticTitle = styled.div`
  background-color: ${({ theme }) => theme.mitreTacticBackground};

  &.selected {
    background-color: ${({ theme }) =>
      theme.selectedMitreTechniqueBackground} !important;
  }

  &.selector.selected {
    color: ${({ theme }) => theme.selectedMitreTextColor} !important;
  }

  font-weight: 600;
  height: 60px;
  margin-bottom: 1px;
  padding: 5px;
  margin-right: 1px;
  align-content: center;
  line-height: 16px;
  font-size: 13px;
`;

const EventCount = styled.div`
  background-color: ${({ theme }) => theme.mitreTacticBackground};
  margin-right: 1px;
  padding: 5px;
  display: flex;
  height: 20px;
  align-items: center;

  ${({ heatmapindex }) =>
    heatmapindex > 0 &&
    css`
      background-color: ${mirteHeatmapColors[heatmapindex - 1]} !important;
      > span {
        color: #fff !important;
        text-shadow: 0 0 1px #000;
      }
    `}

  > .count {
    font-weight: 700;
  }

  > .text {
    display: none;
    font-size: 12px;
    margin-left: 4px;
  }
`;

const Tactic = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 2px;

  &.selector {
    cursor: pointer;
    margin-bottom: 0px;
  }

  &.hasEvents {
    > ${EventCount} {
      background-color: ${({ theme }) => theme.mitreTacticBackgroundHasEvents};
    }

    .text {
      display: inline-block;
    }
  }
`;

const MITRETactic = (props) => {
  const {
    tactic,
    heatmapIndex,
    selectorMode,
    selectedTechniques,
    onTacticClick,
  } = props;

  const allTechniquesSelected = useMemo(
    () =>
      selectorMode &&
      tactic?.techniques?.every((tech) =>
        selectedTechniques?.includes(tech.technique_id),
      ),
    [tactic, selectedTechniques, selectorMode],
  );

  const eventCount = useMemo(() => {
    if (!tactic.techniques) {
      return 0;
    }
    return tactic.techniques.reduce(
      (sum, technique) => sum + technique.events?.length || 0,
      0,
    );
  }, [tactic, allTechniquesSelected]);

  const onClick = useCallback(() => {
    if (selectorMode) {
      onTacticClick?.(tactic);
    }
  }, [eventCount, onTacticClick, tactic]);

  return (
    <Tactic
      onClick={onClick}
      className={classNames({
        selector: selectorMode,
        hasEvents: eventCount > 0,
      })}
    >
      <TacticTitle
        className={classNames({
          selected: allTechniquesSelected,
          selector: selectorMode,
        })}
      >
        {tactic.tactic_name}
      </TacticTitle>
      {!selectorMode && (
        <EventCount heatmapindex={heatmapIndex}>
          <span className="count">{eventCount > 0 ? eventCount : '-'}</span>
          <span className="text">{pluralize(eventCount, 'Event')}</span>
        </EventCount>
      )}
    </Tactic>
  );
};

MITRETactic.propTypes = {
  tactic: PropTypes.shape({
    tactic_name: PropTypes.string,
    techniques: PropTypes.arrayOf(
      PropTypes.shape({
        events: PropTypes.arrayOf(PropTypes.shape({})),
      }),
    ),
  }),
  heatmapIndex: PropTypes.number.isRequired,
  selectorMode: PropTypes.bool,
  selectedTechniques: PropTypes.arrayOf(PropTypes.string).isRequired,
  onTacticClick: PropTypes.func,
};

MITRETactic.defaultProps = {
  tactic: {
    tactic_name: '',
  },
  selectorMode: false,
  onTacticClick: () => {},
};

export default MITRETactic;
