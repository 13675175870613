import { BooleanColumnFilter } from '+components/Table/Filters';

import { BaseColumnFactory } from './BaseColumnFactory';

/**
 * @param {Object} props
 * @param {string} props.field - field name
 * @param {object} [props.optionsLabels] - options labels for BooleanColumnFilter
 * @param {string} [props.description=''] - field description
 * @return {Object} - column with filter=booleanFilter and Filter=BooleanColumnFilter
 */
export const BooleanColumnFactory = (props) => {
  const { optionsLabels, ...rest } = props;

  return BaseColumnFactory({
    width: 70,
    getCellProps: () => ({ style: { justifyContent: 'center' } }),
    sortType: 'boolean',
    Filter: BooleanColumnFilter(optionsLabels),
    filter: 'booleanFilter',
    // eslint-disable-next-line react/prop-types
    Cell: ({ value }) => {
      if (value == null) {
        return null;
      }
      return value ? 'TRUE' : 'FALSE';
    },
    ...rest,
  });
};
