import { useCallback, useMemo, useState } from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { CustomType } from '@/models/CustomType';
import { TimeDuration, TimePeriods } from '@/models/TimePeriods';

import Button, { ButtonVariants } from '+components/Button';
import ButtonGroup from '+components/ButtonGroup';
import DateTimePicker from '+components/form/DateTimePicker';
import { Group, Label } from '+components/form/FormField';
import InputText from '+components/form/InputText';
import { normalizeDateTimePickerValue } from '+components/form/Normalizers';
import NumberInput from '+components/form/NumberInput';
import Select from '+components/form/Select';
import Toggle from '+components/form/Toggle';
import { Modal, ModalBody, ModalFooter, ModalHeader } from '+components/Modal';
import dayjs from '+utils/dayjs';

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 8px;
`;

const StyledTimeFrame = styled.div`
  display: flex;
  gap: 8px;
`;

const StyledDateRange = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  justify-content: space-between;

  & > div {
    display: block;
    width: 90%;
  }
`;

const StyledDateRangeContainer = styled.div`
  margin-top: 8px;
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 8px;
`;

const directionalityOptions = {
  srcip: { value: 'srcip', label: 'src only' },
  dstip: { value: 'dstip', label: 'dst only' },
  both: { value: 'ip', label: 'both' },
};

const StyledLabel = styled(Label)`
  display: block;
`;

const InvestigateHostModal = ({ isOpen, onClose, onInvestigate }) => {
  const [ip, setIp] = useState('');
  const [dateTime, setDateTime] = useState({
    period: {
      value: 4,
      type: TimeDuration.hour,
    },
    to: null,
    from: null,
  });
  const [directionality, setDirectionality] = useState(
    directionalityOptions.both.value,
  );
  const [internal, setInternal] = useState(false);

  const min = new Date(2019, 0, 1, 0, 0, 0, 0);
  const max = dayjs().millisecond(0).toDate();

  const periodOptions = useMemo(() => {
    const allowedPeriods = [
      TimeDuration.minute,
      TimeDuration.hour,
      TimeDuration.day,
      TimeDuration.week,
      TimeDuration.month,
    ];
    return [
      {
        value: CustomType,
        label: 'Custom Range',
      },
      ...allowedPeriods.map((value) => ({
        value,
        label: TimePeriods[value].name,
      })),
    ];
  }, []);

  const handleClose = useCallback(() => {
    onClose();
  }, [onClose]);

  const handleDateTimeChange = useCallback(
    (value, key) => {
      setDateTime({
        ...dateTime,
        ...{ [key]: normalizeDateTimePickerValue(value.getTime()) },
      });
    },
    [dateTime],
  );

  return (
    <Modal isOpen={isOpen} onClose={handleClose}>
      <ModalHeader>Investigate Host</ModalHeader>
      <ModalBody>
        <Group>
          <Label>IP Address</Label>
          <InputText
            name="ip"
            value={ip}
            onChange={(e) => setIp(e.target.value)}
          />
        </Group>
        <Group>
          <Label>Time Frame</Label>
          <StyledTimeFrame>
            <NumberInput
              name="periodValue"
              value={dateTime.period.value}
              onChange={(value) => {
                setDateTime({
                  ...dateTime,
                  period: { ...dateTime.period, value },
                });
              }}
              disabled={dateTime.period.type === CustomType}
            />
            <Select
              name="periodType"
              value={dateTime.period.type}
              options={periodOptions}
              onChange={(value) => {
                setDateTime({
                  ...dateTime,
                  period: { ...dateTime.period, type: value.value },
                });
              }}
              required
            />
          </StyledTimeFrame>
          {dateTime.period.type === CustomType && (
            <StyledDateRangeContainer>
              <StyledDateRange>
                <Label>From</Label>
                <DateTimePicker
                  name="from"
                  value={dateTime.from}
                  min={min}
                  max={max}
                  onChange={(value) => handleDateTimeChange(value, 'from')}
                />
              </StyledDateRange>
              <StyledDateRange>
                <Label>To</Label>
                <DateTimePicker
                  name="to"
                  value={dateTime.to}
                  min={min}
                  max={max}
                  onChange={(value) => handleDateTimeChange(value, 'to')}
                />
              </StyledDateRange>
            </StyledDateRangeContainer>
          )}
        </Group>
        <Group>
          <Label>Directionality</Label>
          <ButtonGroup>
            <Button
              onClick={() =>
                setDirectionality(directionalityOptions.srcip.value)
              }
              variant={
                directionality === directionalityOptions.srcip.value
                  ? ButtonVariants.contained
                  : ButtonVariants.outlined
              }
            >
              {directionalityOptions.srcip.label}
            </Button>
            <Button
              onClick={() =>
                setDirectionality(directionalityOptions.dstip.value)
              }
              variant={
                directionality === directionalityOptions.dstip.value
                  ? ButtonVariants.contained
                  : ButtonVariants.outlined
              }
            >
              {directionalityOptions.dstip.label}
            </Button>
            <Button
              onClick={() =>
                setDirectionality(directionalityOptions.both.value)
              }
              variant={
                directionality === directionalityOptions.both.value
                  ? ButtonVariants.contained
                  : ButtonVariants.outlined
              }
            >
              {directionalityOptions.both.label}
            </Button>
          </ButtonGroup>
        </Group>
        <Group>
          <StyledLabel>Optional</StyledLabel>
          <Toggle
            checked={internal}
            onChange={(e) => setInternal(e.target.checked)}
            checkedLabel="Internal Traffic Only"
          />
        </Group>
      </ModalBody>
      <ModalFooter>
        <ButtonContainer>
          <Button
            onClick={() =>
              onInvestigate({ ip, dateTime, directionality, internal })
            }
            disabled={!ip}
          >
            Apply
          </Button>
          <Button variant={ButtonVariants.outlined} onClick={handleClose}>
            Cancel
          </Button>
        </ButtonContainer>
      </ModalFooter>
    </Modal>
  );
};

InvestigateHostModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onInvestigate: PropTypes.func.isRequired,
};

export default InvestigateHostModal;
