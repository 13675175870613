import { ContextTypes } from '@/models/ContextTypes';

const dash = '—';

export const padding = '14px';

export const getNqlForContext = (props) => {
  const { flowNql, eventNql, blockNql, dnsNql, context } = props;
  switch (context) {
    case ContextTypes.flow:
      return flowNql;
    case ContextTypes.dns:
      return dnsNql;
    case ContextTypes.alerts:
      return eventNql;
    case ContextTypes.blocks:
      return blockNql;
    default:
      return null;
  }
};

export const getMainTitle = (item, options) => {
  if (!item) {
    return '';
  }

  let { title } = item;

  if (!title) {
    let { field } = item;
    if (field === 'algorithm') {
      field = 'detection model';
    }
    const value = Array.isArray(item.value)
      ? item.value.join(', ')
      : item.value;
    title = [field, value]
      .filter((el) => el != null && el !== '')
      .join(` ${dash} `);
  }

  if (options?.showCustomer && item.customer) {
    return `${title} (${item.customer})`;
  }

  return title;
};
