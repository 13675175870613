import PropTypes from '+prop-types';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDebounce } from 'react-use';

import styled from 'styled-components';

import SigmaIcon from 'mdi-react/SigmaIcon';

import SelectOrigin from '+components/form/Select';
import AggregatorTypes from '+components/Table/Aggregators/AggregatorTypes';

import { Container, Row } from '../Filters/Components';

const Select = styled(SelectOrigin).attrs((props) => ({
  ...props,
  showClearButton:
    props.value?.value != null && props.value.value !== AggregatorTypes.none,
}))`
  width: 100%;
`;

const numericTypes = ['integer', 'float', 'number'];

const _options = [
  {
    value: AggregatorTypes.none,
    label: 'None',
    description: 'No aggregation will be performed',
  },
  // TODO: Implement in the future if needed
  // {
  //   value: AggregatorTypes.unique,
  //   label: 'Unique',
  //   description: 'List of unique values',
  // },
  {
    value: AggregatorTypes.count,
    label: 'Count',
    description: 'Total count of values',
  },
  {
    value: AggregatorTypes.sum,
    label: 'Sum',
    description: 'Total sum of all values',
    availableTypes: numericTypes,
  },
  {
    value: AggregatorTypes.min,
    label: 'Min',
    description: 'Smallest value among all',
    availableTypes: numericTypes,
  },
  {
    value: AggregatorTypes.max,
    label: 'Max',
    description: 'Largest value among all',
    availableTypes: numericTypes,
  },
  {
    value: AggregatorTypes.average,
    label: 'Average',
    description: 'Average of all values',
    availableTypes: numericTypes,
  },
  {
    value: AggregatorTypes.median,
    label: 'Median',
    description: 'Middle value when all are sorted',
    availableTypes: numericTypes,
  },
];

/**
 * @param {Object} [properties]
 * @param {string} [properties.defaultValue] - provides default value for aggregator selector
 * @param {Object} [properties.selectProps] - props for `Select` component
 * @return {function(*): JSX.Element}
 */
export const DefaultColumnAggregator = (properties) => {
  const { defaultValue, selectProps } = properties || {};

  const Component = (props) => {
    const { column } = props;

    const [localValue, setLocalValue] = useState(undefined);

    useEffect(() => {
      if (defaultValue) {
        column.setAggregate(defaultValue);
      }
    }, []);

    const columnType = column.type || column.sortType;

    const options = useMemo(
      () =>
        _options.filter(
          ({ availableTypes }) =>
            !availableTypes || availableTypes.includes(columnType),
        ),
      [columnType],
    );

    const onSelectChange = useCallback((item) => {
      setLocalValue(item?.value ?? AggregatorTypes.none);
    }, []);

    const selectValue = useMemo(() => {
      const _value = localValue ?? AggregatorTypes.none;
      return options.find(({ value }) => value === _value);
    }, [localValue, options]);

    useEffect(() => {
      setLocalValue((prevValue) => {
        const nextValue = column.aggregate ?? undefined;
        return prevValue === nextValue ? prevValue : nextValue;
      });
    }, [column.aggregate]);

    useDebounce(
      () => {
        if (
          localValue == null ||
          localValue === '' ||
          localValue === AggregatorTypes.none
        ) {
          column.setAggregate(undefined);
          return;
        }
        column.setAggregate(localValue);
      },
      300,
      [localValue],
    );

    return (
      <Container>
        <Row>
          <SigmaIcon size={24} />
          <Select
            {...(selectProps || {})}
            options={options}
            value={selectValue}
            onChange={onSelectChange}
          />
        </Row>
      </Container>
    );
  };

  Component.propTypes = {
    column: PropTypes.shape({
      type: PropTypes.string,
      sortType: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
      aggregate: PropTypes.string,
      setAggregate: PropTypes.func,
    }).isRequired,
  };

  return Component;
};
