import { useCallback, useMemo } from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { lang } from '+components/charts/common/utils';
import SubDetails, {
  getRowHeight,
} from '+components/ContextTables/EventTable/components/SubDetails';
import Table from '+components/Table';

import { Columns, getColumns } from './MitreColumns';

const sortBy = [
  { id: 'severity', desc: true },
  { id: 'start', desc: false },
];

const hiddenColumns = ['alerttype', 'categories'];

const StyledTable = styled(Table)`
  margin-top: 32px;
`;

const MitreTable = (props) => {
  const {
    tacticsAndEvents,
    selectedTechnique,
    selectedTechniqueLabel,
    setSelectedTechnique,
  } = props;

  const columns = getColumns(Object.values(Columns));

  const onRemoveFilter = useCallback(
    () => setSelectedTechnique(''),
    [setSelectedTechnique],
  );

  const tableData = useMemo(() => {
    if (selectedTechnique) {
      return tacticsAndEvents.flat();
    }
    return tacticsAndEvents.flatMap((item) =>
      item.techniques.flatMap((technique) => technique.events),
    );
  }, [tacticsAndEvents, selectedTechnique]);

  const externalTableFilters = useMemo(
    () =>
      selectedTechnique
        ? [{ label: selectedTechniqueLabel, onRemoveFilter }]
        : null,
    [selectedTechnique, onRemoveFilter],
  );

  return (
    <StyledTable
      id="MITRE Table"
      columns={columns}
      data={tableData}
      noDataText={tableData ? 'There are no events.' : lang.loading}
      SubComponent={SubDetails}
      rowHeight={getRowHeight}
      fillWithEmptyRows
      sortBy={sortBy}
      hiddenColumns={hiddenColumns}
      externalFilters={externalTableFilters}
      onClearExternalFilters={onRemoveFilter}
    />
  );
};

MitreTable.propTypes = {
  tacticsAndEvents: PropTypes.arrayOf(
    PropTypes.shape({
      tactic_id: PropTypes.string,
      tactic_name: PropTypes.string,
      techniques: PropTypes.arrayOf(
        PropTypes.shape({
          technique_id: PropTypes.string,
          technique_name: PropTypes.string,
          events: PropTypes.arrayOf(PropTypes.shape({})),
        }),
      ),
    }),
  ).isRequired,
  selectedTechnique: PropTypes.string,
  selectedTechniqueLabel: PropTypes.string,
  setSelectedTechnique: PropTypes.func.isRequired,
};

MitreTable.defaultProps = {
  selectedTechnique: '',
  selectedTechniqueLabel: '',
};

export default MitreTable;
