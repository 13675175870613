import { createSelector, createSlice } from '@/redux/util';

const name = 'globalFiltersUI';

const initialState = {
  available: false,
  portalId: false,

  range: true,
  from: true,
  to: true,
  nql: false,
  metric: false,
  customers: true,
  excludeMetrics: [],
  autoRefresh: false,
  displayLabels: true,
  displayAdditionalFilters: true,
  updateEvery: 60e3,
  isPristine: true,
  userChangedContext: false,
  userFlippedIpDirection: '',
};

const slice = createSlice({
  name,
  initialState,

  reducers: {
    change(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },

    disableAll(state) {
      return {
        ...state,

        range: false,
        from: false,
        to: false,
        nql: false,
        metric: false,
        customers: false,
        onlyRealtimeOption: false,
      };
    },

    showTopbar(state) {
      state.available = true;
    },

    hideTopbar(state) {
      state.available = false;
    },

    showAdditionalFilters(state) {
      state.displayAdditionalFilters = true;
    },

    hideAdditionalFilters(state) {
      state.displayAdditionalFilters = false;
    },

    setFormPristine: (state, { payload }) => ({
      ...state,
      isPristine: payload,
    }),

    userChangedContext: (state, { payload }) => {
      return {
        ...state,
        userChangedContext: payload,
      };
    },

    userFlippedIpDirection: (state, { payload }) => {
      return {
        ...state,
        userFlippedIpDirection: payload,
      };
    },
  },

  selectors: (getState) => ({
    getAllState: getState,
    getMetric: createSelector([getState], (state) => state.metric),
    getAvailable: createSelector([getState], (state) => state.available),
    getUpdateEvery: createSelector([getState], (state) => state.updateEvery),
    getOnlyRealtime: createSelector([getState], (state) => state.onlyRealtime),
    getDisplayAdditionalFilters: createSelector(
      [getState],
      (state) => state.displayAdditionalFilters,
    ),
    getFormPristine: (state) => state.globalFiltersUI.isPristine,
    getUserChangedContext: (state) => state.globalFiltersUI.userChangedContext,
    getUserFlippedIpDirection: (state) =>
      state.globalFiltersUI.userFlippedIpDirection,
  }),
});

export const { selectors, actions } = slice;

export default slice;
