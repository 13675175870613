import PropTypes from '+prop-types';
import { Children, useEffect, useMemo, useRef, useState } from 'react';

import styled from 'styled-components';

import useEvent from '+hooks/useEvent';

const Container = styled.div`
  display: flex;
  flex-wrap: nowrap;
  gap: ${(props) => props.$gap}px;
  width: 100%;
  overflow: hidden;
  min-width: 12ch;

  .menu-trigger {
    overflow: unset !important;
  }

  [class^='HeaderSubheader'] {
    width: unset;
  }

  .__chip {
    &-hidden {
      display: none !important;
    }

    &-ellipsis {
      overflow: hidden !important;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
`;

const MultipleItemsWrapper = (props) => {
  const { gap, minRenderLength, maxRenderLength } = props;

  const firstLoad = useRef(true);
  const [containerNode, setContainerNode] = useState(null);

  const children = useMemo(
    () => Children.toArray(props.children),
    [props.children],
  );

  const onResize = useEvent((width) => {
    if (!children?.length || !containerNode) {
      return;
    }

    let _hiddenCount = 0;

    const containerNodeRect = containerNode.getBoundingClientRect();
    const containerNodeRightBound = containerNodeRect.x + width;

    const childrenNodes = Array.from(containerNode.children);

    childrenNodes.forEach((childNode, index) => {
      // hide all child to the right if one before was already hidden (_hiddenCount > 0)
      // or hide if child width more than container's

      childNode.classList.add('__chip');
      childNode.classList.remove('__chip-ellipsis');

      if (minRenderLength && index < minRenderLength) {
        childNode.classList.remove('__chip-hidden');
        return;
      }

      let hide = !!_hiddenCount;

      if (!hide) {
        childNode.classList.remove('__chip-hidden');
        const childNodeRect = childNode.getBoundingClientRect();
        hide = childNodeRect.x > containerNodeRightBound;
      }

      if (hide) {
        childNode.classList.add('__chip-hidden');
        _hiddenCount += 1;
      }
    });

    const lastVisibleChild = Array.from(
      containerNode.querySelectorAll('.__chip:not(.__chip-hidden)'),
    ).at(-1);
    lastVisibleChild?.classList?.add('__chip-ellipsis');
  });

  const observer = useMemo(
    () =>
      new ResizeObserver((elements) => {
        const [{ contentRect }] = elements || [];

        if (!contentRect) {
          return;
        }

        const { width } = contentRect;
        if (firstLoad.current) {
          firstLoad.current = false;
          onResize(width);
        } else {
          // put func inside requestAnimationFrame for performance reasons
          requestAnimationFrame(() => onResize(width));
        }
      }),
    [],
  );

  useEffect(
    () => {
      if (!containerNode) {
        return undefined;
      }

      observer.observe(containerNode);

      return () => {
        observer.disconnect();
      };
    },
    // we need children in deps to re-observe on children change
    [containerNode, children],
  );

  return !children?.length ? null : (
    <Container ref={setContainerNode} $gap={gap}>
      {children.slice(0, maxRenderLength)}
    </Container>
  );
};

MultipleItemsWrapper.propTypes = {
  children: PropTypes.children.isRequired,
  gap: PropTypes.number,
  minRenderLength: PropTypes.number,
  maxRenderLength: PropTypes.number,
};

MultipleItemsWrapper.defaultProps = {
  gap: 10,
  minRenderLength: 1,
  maxRenderLength: 20,
};

export default MultipleItemsWrapper;
