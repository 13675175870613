import classNames from 'classnames';
import styled, { css } from 'styled-components';

import Button, { ButtonVariants } from '+components/Button';
import ScrollBar from '+components/ScrollBar/smooth';
import {
  baseRowHeight,
  staticTableHeaderHeight,
} from '+components/Table/constants';
import { propsSelectors as themeTable } from '+theme/slices/table';

export const headerSettingsMenuWidth = 265;
export const cellHorizontalPadding = 4;
export const cellBorderWidth = 1;

export const TrGroup = styled.div`
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  overflow: hidden;
`;

export const Tr = styled.div`
  flex: 1 0 auto;
  display: flex;
  position: relative;

  background: ${({ $odd, theme }) =>
    $odd ? theme.table.colorTrOddBackground : null};

  &.root-row {
    background: ${({ theme }) => theme.table.colorTrOddBackground};
  }

  &.sub-row {
    background: unset;
  }

  &:hover {
    background: ${({ $padRow, theme }) =>
      $padRow ? null : theme.table.colorTrHoverBackground};
  }
`;

const Cell = styled.div`
  display: flex;
  align-items: center;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 0 calc(${cellHorizontalPadding}px + ${cellBorderWidth}px);
  overflow: hidden;
  min-height: ${baseRowHeight}px;
  width: 100%;

  //:last-child {
  //  max-width: 100% !important;
  //  width: 100% !important;
  //}

  ${({ $hidden }) =>
    $hidden &&
    css`
      width: 0 !important;
      min-width: 0 !important;
      padding: 0 !important;
      border: 0 !important;
      opacity: 0 !important;
    `};
`;

const sortBoxShadow = ({ $sortAsc, $sortDesc, theme }) => {
  const sort = $sortAsc || $sortDesc;
  const sign = $sortAsc ? 1 : -1;
  const offset = sort ? sign * 3 : 0;

  return `inset 0 ${offset}px 0 0 ${
    sort ? theme.table.colorSortBoxShadow : 'transparent'
  }`;
};

export const Th = styled(Cell)`
  box-shadow: ${sortBoxShadow};
`;

export const Td = styled(Cell)`
  ${({ $expander }) =>
    $expander &&
    css`
      cursor: pointer;
      text-overflow: clip;
    `};
  position: relative;
`;

export const TableHeader = styled.div`
  display: flex;
  flex-direction: row;
  margin: 10px;
  align-items: baseline;
`;

export const TableTitle = styled.span`
  margin-right: 20px;
  font-weight: 700;
`;

export const FiltersRow = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: -5px 0;
`;

export const FiltersRowLabel = styled.span`
  margin-right: 5px;
  font-size: 10px;
  font-weight: 600;
  line-height: 20px;
  color: ${(props) => props.theme.colorTextSecondary} !important;
`;

export const ExternalHeaderControlsContainer = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: flex-end;
`;

export const ClearFiltersButton = styled(Button).attrs({
  variant: ButtonVariants.link,
})`
  background-color: transparent;
  text-decoration: none;
  color: ${themeTable.filterButtonColor};
  margin: 5px;

  &.MuiButton-link {
    text-decoration: unset;
    text-transform: unset;
    height: unset;
    font-weight: unset;
  }
`;

export const THead = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: ${baseRowHeight + 2}px;
  background: ${themeTable.colorHeaderBackground};
  border-bottom: 2px solid ${themeTable.colorHeaderBottomBorder};

  ${Tr} {
    text-align: center;
    background: transparent !important;
  }

  ${Cell} {
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 600;
    line-height: normal;
    position: relative;
    transition: box-shadow 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    border-right: ${cellBorderWidth}px solid ${themeTable.colorHeaderBorder};
    //&:last-child {
    //  border-right: 0;
    //}
  }
`;

export const THeadHeader = THead;

export const THeadHeaderGroup = styled(THead)`
  background: ${themeTable.colorHeaderGroupBackground};
  border-bottom: 2px solid ${themeTable.colorHeaderGroupBorder};
`;

export const THeadSettings = styled.div`
  display: flex;
  flex-direction: column;
  width: ${headerSettingsMenuWidth}px;
  padding: 10px;
  gap: 10px;

  input,
  select {
    padding: 5px 7px;
    font-size: inherit;
    border-radius: 4px;
    font-weight: normal;
    outline: none;
    height: 32px;
    transition: border-color 0.3s ease-in-out;
  }
`;

const ignorePropsSet = new Set(['disableFilters']);

export const ResizableHeader = styled(Th).withConfig({
  shouldForwardProp: (prop) => !ignorePropsSet.has(prop),
})`
  &.has-filter {
    background: ${({ theme }) => theme.table.headerCellHasFilterBackground};
  }
  &.sigma {
    background: ${({ theme }) => theme.table.colorTrOddBackground};
  }
  &:not(:last-child) {
    overflow: initial;
  }
`;

export const ResizableHeaderContent = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;
  height: 100%;
  width: 100%;
  gap: 3px;

  ${({ $isDragging }) =>
    $isDragging &&
    css`
      color: rgba(0, 0, 0, 0) !important;
      > * {
        display: none;
      }
    `}

  > .mdi-icon:not(.grouped-icon),
  > .checkbox {
    margin: 0 auto;
  }
`;

export const Ellipsis = styled.div`
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const Resizer = styled.div`
  display: inline-block;
  position: absolute;
  width: 11px;
  top: 0;
  bottom: 0;
  right: -1px;
  cursor: col-resize;
  z-index: 10;
`;

const DropZone = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 11;
`;

export const DropZoneLeft = styled(DropZone)`
  left: 0;
  right: 50%;
  opacity: 0.5;
`;

export const DropZoneRight = styled(DropZone)`
  right: 0;
  left: 50%;
  opacity: 0.5;
`;

export const Borders = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  pointer-events: none;
`;

export const Border = styled(Td)`
  height: 100%;
  border-right: ${cellBorderWidth}px solid ${themeTable.colorTdInBodyBorder};
  //&:last-child {
  //  border-right: 0;
  //}
`;

export const TBody = styled(ScrollBar)`
  ${({ $autoHeight }) =>
    !$autoHeight &&
    css`
      flex: 1 1 0;
    `};

  .scroll-content > div {
    display: flex;
    flex-direction: column;
    position: relative;
  }

  ${TrGroup} {
    border-bottom: 1px solid ${themeTable.colorTrGroupInBodyBorder};
  }

  & ${TrGroup} > ${Tr} {
    align-items: center;
    min-height: 40px;
  }
`;

export const Table = styled(ScrollBar)`
  & > .scroll-content > div {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    border-collapse: collapse;
  }

  ${({ $autoHeight }) =>
    !$autoHeight &&
    css`
      &:not(#fake_id_for_hook) {
        display: flex;
        flex: 1 1 0;
      }

      & > .scroll-content {
        display: flex;
      }
    `}
`;

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  color: ${({ theme }) => theme.colorText};
  background: ${({ theme }) => theme.cardBackgroundColor};
  border-radius: 4px;
  min-height: 69px;
  overflow: hidden;

  * {
    box-sizing: border-box;
  }

  *::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  *::-webkit-scrollbar-track {
    background: transparent;
  }

  *::-webkit-scrollbar-thumb {
    background-clip: padding-box;
    border-radius: 8px;
    border: 2px solid transparent;

    background-color: ${({ theme }) => theme.scrollBackgroundColor};
  }

  &.resizing * {
    transition: none !important;
    cursor: col-resize !important;
    user-select: none !important;
  }

  &.staticTable {
    ${Table} > .scroll-content > div {
      gap: 5px;
      padding-top: 5px;
    }

    ${THead}, ${TrGroup} {
      border-bottom: 0;
      border-radius: 4px;
      box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
    }

    ${THead} {
      margin: 0 5px;
      height: ${staticTableHeaderHeight + 10}px;
      width: unset !important;

      ${Cell} {
        min-height: ${staticTableHeaderHeight + 10}px;
        border-right: 0;
      }
    }

    ${TBody} > .scroll-content > div {
      gap: 10px;
      padding: 5px;
    }

    ${Border} {
      border-right: 0;
    }
  }
`;

export const NoData = styled.div`
  display: block;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: all 0.3s ease;
  z-index: 1;
  pointer-events: none;
  text-align: center;

  background: ${themeTable.colorNoDataBackground};
  color: ${themeTable.colorNoData};
`;

export const Expander = styled.div.attrs(({ $open, className }) => ({
  children: '-',
  className: classNames(className, { open: $open }),
}))`
  display: inline-block;
  position: relative;
  color: transparent !important;
  width: 100%;
  text-align: center;
  user-select: none;

  &:after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    top: 50%;
    left: 50%;
    border-top: 5.04px solid transparent;
    border-bottom: 5.04px solid transparent;
    border-left: 7px solid ${({ theme }) => theme.colorText};
    transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    cursor: pointer;

    transform: translate(-50%, -50%) rotate(0);
  }

  &.open:after {
    transform: translate(-50%, -50%) rotate(90deg);
  }
`;
