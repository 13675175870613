import PropTypes from '+prop-types';
import { useRef } from 'react';
import { useDrag, useDragLayer, useDrop } from 'react-dnd';
import { matchPath, NavLink, useLocation } from 'react-router-dom';

import classNames from 'classnames';
import styled from 'styled-components';

import PinIcon from 'mdi-react/PinIcon';
import PinOffIcon from 'mdi-react/PinOffIcon';

import RoutePaths from '@/models/RoutePaths';

import IconButton from '+components/IconButton';
import { useMenuActions, useMenuState } from '+components/Menu';
import { BetaTag } from '+components/Tag';
import Tooltip from '+components/Tooltip';
import useEvent from '+hooks/useEvent';

import { ItemMenu } from './ItemMenu';

const dragItemType = 'main-nav-item';

const checkMatching = (patterns, pathname) =>
  (Array.isArray(patterns) ? patterns : [patterns])
    .filter(Boolean)
    .some((path) => matchPath(path, pathname));

const StyledTooltip = styled(Tooltip)`
  max-width: 250px !important;
  padding: 15px;
`;

export const Body = (props) => {
  const {
    className,
    icon,
    title,
    beta,
    collapse,
    route,
    exact,
    includeRoutes,
    excludeRoutes,
    disableActive,
    id,
    index,
    onMove,
    onMoveUp,
    onMoveDown,
    onUnpin,
    onOpenInNewTab,
    disabled,
    ...tail
  } = props;

  const { pathname } = useLocation();

  const isContextMenuAvailable =
    !!onOpenInNewTab || !!onMoveUp || !!onMoveDown || !!onUnpin;
  const menuState = useMenuState();
  const menuActions = useMenuActions();

  const hasIncludedRoutes = checkMatching(includeRoutes, pathname);
  const hasExcludedRoutes = checkMatching(excludeRoutes, pathname);
  const canBeActive =
    (includeRoutes && hasIncludedRoutes) || !hasExcludedRoutes;

  const ref = useRef(null);

  const isNavDragging = useDragLayer(
    (monitor) => monitor.isDragging() && monitor.getItemType() === dragItemType,
  );

  const [{ isDragging: isItemDragging }, drag] = useDrag({
    type: dragItemType,
    item: () => ({ id, index }),
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  });

  const [{ handlerId }, drop] = useDrop({
    accept: dragItemType,
    collect: (monitor) => ({
      handlerId: monitor.getHandlerId(),
    }),
    hover: (item) => {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      if (dragIndex === hoverIndex) {
        return;
      }
      // const hoverBoundingRect = ref.current?.getBoundingClientRect();
      // const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // const clientOffset = monitor.getClientOffset();
      // const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      // if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
      //   return;
      // }
      // if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
      //   return;
      // }
      onMove?.(item.id, id);
      item.index = hoverIndex;
    },
  });

  drag(drop(ref));

  const setClassName = useEvent(({ isActive }) =>
    classNames(className, 'sidebar__link', {
      'sidebar__link--collapse': collapse,
      'sidebar__link--active':
        !disableActive && canBeActive && (isActive || hasIncludedRoutes),
      isItemDragging,
      isNavDragging,
      isContextMenuOpen: menuState?.show,
      'sidebar__link--disabled': disabled,
    }),
  );

  const doUnpin = useEvent(
    (e) => {
      // prevent navigation to the route
      e.preventDefault();
      onUnpin?.(id);
    },
    [onUnpin, id],
  );

  const onContextMenu = useEvent((e) => {
    if (!isContextMenuAvailable) {
      return;
    }
    menuActions?.showMenu?.(e);
  });

  const handleNavLinkClick = useEvent((e) => {
    if (disabled) {
      e.preventDefault();
    }
  });

  return (
    <StyledTooltip
      title="Your account role does not have the proper read permissions to view this page."
      disabled={!disabled}
      arrow={false}
    >
      <div>
        {isContextMenuAvailable && (
          <ItemMenu
            id={id}
            route={route}
            onOpenInNewTab={onOpenInNewTab}
            onMoveUp={onMoveUp}
            onMoveDown={onMoveDown}
            onUnpin={onUnpin}
          />
        )}

        <NavLink
          {...tail}
          ref={onMove ? ref : undefined}
          data-handler-id={handlerId}
          className={setClassName}
          to={route}
          end={exact}
          onContextMenu={onContextMenu}
          onClick={handleNavLinkClick}
        >
          {icon && <span className="sidebar__link-icon">{icon}</span>}
          {title && !collapse && (
            <span className="sidebar__link-title">{title}</span>
          )}
          {beta && (
            <span className="sidebar__link-beta">
              <BetaTag>Beta</BetaTag>
            </span>
          )}
          {!!onUnpin && !isNavDragging && (
            <IconButton
              className="sidebar__link-unpin"
              size="small"
              title="Unpin"
              onClick={doUnpin}
            >
              <PinIcon className="pin-icon" size={16} />
              <PinOffIcon className="unpin-icon" size={16} />
            </IconButton>
          )}
        </NavLink>
      </div>
    </StyledTooltip>
  );
};

Body.propTypes = {
  ...ItemMenu.propTypes,
  className: PropTypes.string,
  icon: PropTypes.shape({}),
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.children]).isRequired,
  beta: PropTypes.bool,
  collapse: PropTypes.bool,
  route: PropTypes.string,
  exact: PropTypes.bool,
  includeRoutes: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  excludeRoutes: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  disableActive: PropTypes.bool,
  id: PropTypes.string,
  index: PropTypes.number,
  disabled: PropTypes.bool,
};

Body.defaultProps = {
  ...ItemMenu.defaultProps,
  className: '',
  icon: null,
  beta: false,
  collapse: false,
  route: `${RoutePaths.home}`,
  exact: false,
  includeRoutes: null,
  excludeRoutes: null,
  disableActive: false,
  id: null,
  index: null,
  disabled: false,
};
