import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ContextTypes } from '@/models/ContextTypes';
import StatsRequest from '@/models/StatsRequest';

import {
  actions as mitreActions,
  selectors as mitreSelectors,
} from '@/redux/api/mitre';

import { useGlobalFilters } from '+hooks/useGlobalFilters';
import useRealtimeOrRequest from '+hooks/useRealtimeOrRequest';

import useLoadingIndicator from '../useLoadingIndicator';
import { inUseMitreTechniques } from './inUseMitreTechniques';

const includeFields = [
  'alerttype',
  'categories',
  'srcips',
  'dstips',
  'start',
  'duration',
  'id',
  'severity',
  'timestamp',
  'tdm',
  'traffictype',
  'mitre',
  'summary',
];

/**
 * Returns MITRE tactics, techniques, and associated events.
 *
 * Usage example:
 * ```
 * import { useMitreTactics } from '+hooks/useMitreTactics';
 * const { isFetching: isMitreFetching, tactics, tacticsAndEvents } = useMitreTactics();
 * ```
 * @return {Object}
 */
export const useMitreTactics = (userFilters, showNonMitre) => {
  const dispatch = useDispatch();
  const [filters] = useGlobalFilters(ContextTypes.alerts);

  const isTacticsFetching = useSelector(mitreSelectors.isFetching);
  const tactics = useSelector(mitreSelectors.getTactics);

  // We should only show techniques that we can support within Neto
  // the imported list is from Jeff Nathan
  const filteredTactics = useMemo(() => {
    return tactics.map((tactic) => {
      const filteredTechniques = tactic.techniques.filter((technique) => {
        return inUseMitreTechniques.has(technique.technique_id);
      });
      return { ...tactic, techniques: filteredTechniques };
    });
  }, [tactics]);

  const request = useMemo(
    () => ({
      feed: ContextTypes.alerts,
      start: userFilters?.start,
      end: userFilters?.end,
      size: 1000,
      include: includeFields,
      ...StatsRequest.makeSearch({
        search: filters.nql,
        intersect: filters.intersect,
      }),
      customers: filters.customers,
      sort: {
        field: 'severity',
        order: 'desc',
      },
    }),
    [
      userFilters?.start,
      userFilters?.end,
      userFilters?.algorithm,
      userFilters?.ip,
      JSON.stringify(filters.nql),
      JSON.stringify(filters.intersect),
      JSON.stringify(filters.customers),
      includeFields,
    ],
  );

  const { isFetching: isEventsFetching, records } = useRealtimeOrRequest({
    name: 'mitre-events-search',
    socketOptions: request,
    stopRequest: !includeFields.length,
    refresher: filters.refresher,
  });

  const data = useMemo(() => records?.toArray() || [], [records]);

  const nonMitreEvents = useMemo(() => {
    return data.filter((event) => !event.mitre?.techniques?.ids?.length);
  }, [data]);

  useEffect(() => {
    dispatch(mitreActions.fetchTactics());
  }, []);

  const tacticsAndEvents = useMemo(() => {
    if (!filteredTactics?.length || !data?.length) {
      return [];
    }

    const eventsByTechnique = data.reduce((acc, event) => {
      event.mitre.techniques?.ids.forEach((techniqueId) => {
        if (!acc[techniqueId]) {
          acc[techniqueId] = [];
        }
        acc[techniqueId].push(event);
      });
      return acc;
    }, {});

    const mitreTactics = filteredTactics.map((tactic) => ({
      ...tactic,
      techniques: tactic.techniques.map((technique) => ({
        ...technique,
        events: eventsByTechnique[technique.technique_id] || [],
      })),
    }));

    if (showNonMitre) {
      const nonMitreEventsTactic = {
        techniques: [
          {
            technique_id: 'non-mitre',
            technique_name: 'Non MITRE Events',
            events: nonMitreEvents,
          },
        ],
        tactic_name: 'Non MITRE',
        tactic_id: 'non-mitre',
      };

      mitreTactics.unshift(nonMitreEventsTactic);
    }

    return mitreTactics;
  }, [data, filteredTactics, showNonMitre, nonMitreEvents]);

  useLoadingIndicator(isTacticsFetching || isEventsFetching);

  return [
    isTacticsFetching || isEventsFetching,
    tactics,
    tacticsAndEvents,
    filteredTactics,
  ];
};
