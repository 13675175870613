export const FeatureFlags = {
  showRealTimeFlowMap: 'showRealTimeFlowMap',
  guestAccess: 'guestAccess',
  notificationPopupsManager: 'notificationPopupsManager',
  rolesUiSettings: 'rolesUiSettings',
  resizableTray: 'resizableTray',
  dnsDevices: 'dnsDevices',
};

export default FeatureFlags;
