import PropTypes from '+prop-types';
import { useEffect, useMemo, useState } from 'react';

import styled from 'styled-components';

import AddIcon from 'mdi-react/AddIcon';
import DeleteForeverIcon from 'mdi-react/DeleteForeverIcon';

import ArrayNQLField from '+components/form/ArrayNQLField';
import {
  Field,
  FieldArray,
  useField,
  useFieldArray,
} from '+components/form/FinalForm';
import {
  Description,
  FieldContainer,
  Group,
  Label,
} from '+components/form/FormField';
import { normalizeSelectValue } from '+components/form/Normalizers';
import SelectField from '+components/form/SelectField';
import IconButtonOrigin from '+components/IconButton';
import { Col, LayoutSizes, Row } from '+components/Layout';
import useEvent from '+hooks/useEvent';
import { useSourceTypes } from '+hooks/useSourceTypes';

import { Config } from './utils';

const IconButton = styled(IconButtonOrigin)`
  visibility: ${(props) => (props.$hidden ? 'hidden' : 'visible')};
  pointer-events: ${(props) => (props.$hidden ? 'none' : 'auto')};
  margin-top: 4px;
`;

const FakeButton = styled.div`
  visibility: hidden;
  width: 32px;
  height: 32px;
`;

const groupBy = (option) => option.group;

const SearchByItem = (props) => {
  const {
    name,
    index,
    placeholder,
    options,
    fieldLength,
    maxLength,
    context,
    helperText,
    required,
    addDisabled,
    disabled,
    onAdd,
    onRemove,
  } = props;

  const { input } = useField(name);

  const fixedOptions = useMemo(
    () =>
      options.map((option) => ({
        ...option,
        disabled: option.value === input.value?.type ? false : option.disabled,
      })),
    [options, input.value],
  );

  const doRemove = useEvent(() => {
    onRemove?.(index);
  });

  return (
    <Row gap={LayoutSizes.groupGap} alignItems="flex-start" wrap="nowrap">
      <Col container={false} xs={2.2} item>
        <Field
          name={`${name}.type`}
          component={SelectField}
          options={fixedOptions}
          groupBy={groupBy}
          parse={normalizeSelectValue}
          disabled={disabled}
        />
      </Col>

      {fieldLength > 1 && (
        <IconButton
          color="primary"
          title="Remove Search"
          disabled={disabled}
          onClick={doRemove}
        >
          <DeleteForeverIcon size={16} />
        </IconButton>
      )}

      {maxLength > 1 && (
        <IconButton
          color="primary"
          title="Add Search"
          $hidden={fieldLength !== index + 1}
          disabled={disabled || addDisabled || fieldLength === maxLength}
          onClick={onAdd}
        >
          <AddIcon size={16} />
        </IconButton>
      )}

      <Col
        container={false}
        xs={5}
        md={7}
        lg={9}
        item
        pl={LayoutSizes.groupGap}
        flexGrow={1}
        maxWidth="100%"
      >
        <FieldArray
          name={`${name}.search`}
          component={ArrayNQLField}
          placeholder={placeholder}
          context={context}
          required={required}
          disabled={disabled}
          excludingEverythingField={false}
        />

        {!!fieldLength && helperText && index === fieldLength - 1 && (
          <Description style={{ marginLeft: 'unset' }}>
            {helperText}
          </Description>
        )}
      </Col>
    </Row>
  );
};

SearchByItem.propTypes = {
  name: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  placeholder: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape({})),
  fieldLength: PropTypes.number.isRequired,
  maxLength: PropTypes.number.isRequired,
  context: PropTypes.string.isRequired,
  helperText: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  required: PropTypes.bool.isRequired,
  addDisabled: PropTypes.bool,
  disabled: PropTypes.bool.isRequired,
  onAdd: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
};

SearchByItem.defaultProps = {
  addDisabled: false,
  placeholder: undefined,
  options: [],
  helperText: '',
};

const getFirstAvailableOption = (options, allOptionIsDisabled) => {
  const option = options.find((item) => {
    if (allOptionIsDisabled && item.value === 'all') {
      return false;
    }
    return !item.disabled;
  });
  return option?.value;
};

const RenderSearchBy = (props) => {
  const {
    fields,
    label,
    helperText,
    placeholder,
    context,
    maxLength,
    required,
    disabled,
  } = props;

  const { meta } = useFieldArray(fields.name, {});
  const [fieldInvalid, setFieldInvalid] = useState(false);
  const fieldLength = fields.value?.length || 0;
  const isRequired = required || fieldLength > 1;

  const sourceTypes = useSourceTypes(context);

  const options = useMemo(
    () =>
      sourceTypes.map((option) => {
        const optionDisabled = fields.value?.some((searchByItem) => {
          return option.value === searchByItem.type;
        });
        return {
          ...option,
          disabled: optionDisabled,
        };
      }),
    [sourceTypes, fields.value, fieldLength],
  );

  const onAdd = useEvent(() => {
    fields.push({
      type: getFirstAvailableOption(options, fieldLength >= 1),
      search: [Config.defaultSearchBySearch],
    });
  });

  const onRemove = useEvent((index) => {
    fields.remove(index);
  });

  useEffect(() => {
    if (!label) {
      return;
    }
    const nqlEl = document.getElementById(fields.name);
    const errors = nqlEl?.getElementsByClassName('form__form-group-error');
    setFieldInvalid(!!errors?.length);
  }, [label, fields.name, meta]);

  return (
    <Group
      id={fields.name}
      style={{ width: '150%', display: maxLength ? null : 'none' }}
    >
      {!!fieldLength && label && (
        <Label required={isRequired} invalid={fieldInvalid}>
          {label}
        </Label>
      )}

      <FieldContainer>
        <Row
          gap={LayoutSizes.groupGap}
          alignItems="flex-end"
          wrap="nowrap"
          height="26px"
          fontSize="13px"
          fontWeight="600"
        >
          <Col container={false} xs={2.1} item>
            Search Against
          </Col>
          {fieldLength > 1 && <FakeButton />}
          {maxLength > 1 && <FakeButton />}
          <Col container={false} xs={5} item pl={LayoutSizes.groupGap}>
            NQL Expression
          </Col>
        </Row>

        <Col gap="15px">
          {fields.map((name, index) => (
            <SearchByItem
              key={name}
              name={name}
              index={index}
              placeholder={placeholder}
              fieldLength={fieldLength}
              maxLength={maxLength}
              context={context}
              helperText={helperText}
              required={isRequired}
              disabled={disabled}
              options={options}
              onAdd={onAdd}
              onRemove={onRemove}
            />
          ))}
        </Col>
      </FieldContainer>
    </Group>
  );
};

RenderSearchBy.propTypes = {
  fields: PropTypes.shape().isRequired,
  label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  helperText: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  placeholder: PropTypes.string,
  context: PropTypes.string.isRequired,
  maxLength: PropTypes.number,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
};

RenderSearchBy.defaultProps = {
  label: '',
  helperText: '',
  placeholder: undefined,
  maxLength: Number.MAX_SAFE_INTEGER,
  required: false,
  disabled: false,
};

export default RenderSearchBy;
