import PropTypes from '+prop-types';

import { Stack } from '@mui/material';

import { ActionsContainer, LayoutSizes, Row } from '+components/Layout';
import { BackToButton } from '+components/PageTabs';

import { PageTitle } from './PageTitle';

export const PageHeader = (props) => {
  const { title, children, showBackButton, marginBottom, backUrl, wide } =
    props;

  const shown = showBackButton || title || children;
  const flex = wide ? '1 1 0' : undefined;

  return (
    shown && (
      <Row item marginBottom={marginBottom}>
        <Stack
          direction="row"
          gap={LayoutSizes.groupGap}
          alignItems="center"
          flex={flex}
        >
          {showBackButton && <BackToButton backUrl={backUrl} />}
          {title && <PageTitle>{title}</PageTitle>}
          {children && (
            <ActionsContainer mb={0} flex={flex}>
              {children}
            </ActionsContainer>
          )}
        </Stack>
      </Row>
    )
  );
};

PageHeader.propTypes = {
  title: PropTypes.node,
  children: PropTypes.children,
  showBackButton: PropTypes.bool,
  marginBottom: PropTypes.string,
  backUrl: PropTypes.string,
  wide: PropTypes.bool,
};

PageHeader.defaultProps = {
  title: null,
  children: null,
  showBackButton: false,
  marginBottom: undefined,
  backUrl: undefined,
  wide: false,
};
