import { Route, Routes } from 'react-router-dom';

import { Overrides } from './components/Overrides';
import { UpdateForm } from './components/UpdateForm';

export const ThresholdsOverrides = () => (
  <Routes>
    <Route path="/update" element={<UpdateForm />} />
    <Route path="*" element={<Overrides />} />
  </Routes>
);
