import { useMemo } from 'react';

import Box from '@mui/material/Box';
import { alpha } from '@mui/material/styles';

import CheckCircleIcon from 'mdi-react/CheckCircleIcon';
import CircleIcon from 'mdi-react/CircleIcon';

import PermissionModel from '@/models/Permission';

import { HeaderSubheaderCell } from '+components/Table/Cells';
import {
  AuthorColumnFactory,
  BaseColumnFactory,
  BooleanColumnFactory,
  MenuColumnFactory,
} from '+components/Table/Columns';
import { SelectColumnFilter } from '+components/Table/Filters';
import {
  autoRemoveIfAll,
  withAutoRemove,
} from '+components/Table/FilterTypeFactories';
import { Palette } from '+theme';

const getPermissionValue = (permissions) => {
  let expected = 0;
  let current = 0;

  (permissions || []).forEach(({ resource, ...item }) => {
    const resourceMeta = PermissionModel.Resources[resource];
    const excludeActions = new Set(resourceMeta?.excludeActions || []);

    Object.entries(item).forEach(([key, value]) => {
      if (excludeActions.has(key)) {
        return;
      }

      expected += 1;
      current += value;
    });
  });

  if (current === expected) {
    return expected !== 0 ? 2 : 0;
  }

  return current > 0 ? 1 : 0;
};

const accessorFactory =
  (group, canManageResellerParams) =>
  ({ permissions }) =>
    getPermissionValue(
      permissions.filter(({ resource }) => {
        const resourceMeta = PermissionModel.Resources[resource];

        if (
          !resourceMeta ||
          (resourceMeta.resellersOnly && !canManageResellerParams)
        ) {
          return false;
        }

        return resourceMeta.group === group;
      }),
    );

const checked = <CheckCircleIcon size={22} />;
const unchecked = <CircleIcon size={22} />;
const colors = [alpha(Palette.gray3, 0.2), Palette.gray3, Palette.success];

export const TernaryCell = ({ value }) =>
  useMemo(() => {
    const fixed = typeof value === 'boolean' ? +value * 2 : value;

    return (
      // we use <b> since we have global overriding for div,p,span
      <Box
        color={colors[fixed]}
        component="b"
        display="inline-flex"
        alignItems="center"
        justifyContent="center"
      >
        {fixed > 0 ? checked : unchecked}
      </Box>
    );
  }, [value]);

const labels = ['No Permissions', 'Partial Permissions', 'Full Permissions'];

const permissionGroupColumnFactory = (canManageResellerParams) => (group) =>
  BaseColumnFactory({
    accessor: accessorFactory(group, canManageResellerParams),
    id: `permissions.${group}`,
    Header: group,
    getCellProps: () => ({ style: { justifyContent: 'center' } }),
    Cell: TernaryCell,
    sortType: 'number',
    Filter: SelectColumnFilter({
      fixedOptions: [
        { value: 'all', label: 'All' },
        ...labels
          .map((label, index) => ({
            value: index,
            label,
          }))
          .reverse(),
      ],
      sort: false,
    }),
    filter: withAutoRemove((rows, id, filterValue) => {
      if (autoRemoveIfAll(filterValue)) {
        return rows;
      }

      return rows.filter(({ values }) => values[id] === filterValue.value);
    }, autoRemoveIfAll),
  });

const optionsLabels = {
  false: labels[0],
  true: labels[2],
};

export const getColumns = ({
  canManageResellerParams,
  showSubscription,
  cxActionMenu,
  profileEmail,
}) =>
  [
    BaseColumnFactory({
      accessor: 'name',
      Header: 'Name / Desc',
      width: 300,
      Cell: HeaderSubheaderCell({
        propHeader: 'name',
        propSubheader: 'description',
      }),
      realAccessor: ['name', 'description'],
    }),
    canManageResellerParams &&
      BooleanColumnFactory({
        accessor: 'canMasquerade',
        Header: 'Masquerading',
        Cell: TernaryCell,
        width: 'auto',
        optionsLabels,
      }),
    BooleanColumnFactory({
      accessor: 'canSendFlow',
      Header: 'Send NetoFlow',
      Cell: TernaryCell,
      width: 'auto',
      optionsLabels,
    }),
    BooleanColumnFactory({
      accessor: 'canSendDns',
      Header: 'Send NetoDNS',
      Cell: TernaryCell,
      width: 'auto',
      optionsLabels,
    }),
    BooleanColumnFactory({
      accessor: 'canFetchAuditLog',
      Header: 'View Audit Logs',
      Cell: TernaryCell,
      width: 'auto',
      optionsLabels,
    }),
    showSubscription &&
      BooleanColumnFactory({
        accessor: 'canManageSubscription',
        Header: 'Manage Subscription',
        Cell: TernaryCell,
        width: 'auto',
        optionsLabels,
      }),
    ...Object.values(PermissionModel.ResourceGroups).map(
      permissionGroupColumnFactory(canManageResellerParams),
    ),
    AuthorColumnFactory({
      accessor: 'createdBy',
      profileEmail,
      width: 'auto',
      minWidth: 120,
      useFullNameForOptions: true,
    }),
    MenuColumnFactory({ cxActionMenu }),
  ].filter(Boolean);
