import styled from 'styled-components';

import { VpcIcon } from '@/shared/img/icon';

import { SOURCETYPES } from '../../utils/sourceTypes';

const VpcContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

const VpcIconWrapper = styled.span`
  width: 24px;
  justify-content: center;
`;

export const formatVpc = (vpc, logtype, isDnsContext, size = 18) => {
  // Azure NSG and Vnet both share the azure/blobstorage vpc name
  if (vpc === 'azure/blobstorage') {
    if (logtype === 'azure-vnet-flow-log') {
      vpc = 'azure-vnet/blobstorage';
    } else {
      vpc = 'azure/blobstorage';
    }
  }

  let name = SOURCETYPES[vpc]?.label || vpc || '';
  if (isDnsContext) {
    name = SOURCETYPES[vpc]?.labelDns || name;
  }

  return (
    <VpcContainer>
      <VpcIconWrapper>
        <VpcIcon sourceType={vpc} size={size} />
      </VpcIconWrapper>
      <span>{name}</span>
    </VpcContainer>
  );
};

const providers = Object.keys(SOURCETYPES);

const parseIfInt = (value) =>
  typeof value === 'number' ? value : parseInt(value, 10);

export const vpcParamsToUi = (params) => {
  if (!params) {
    return null;
  }
  const ui = {
    ...params,
    ...params[`${params.flowtype}${params.flowresource}`],
    ...(params.logtype === 'azure-vnet-flow-log' ? params.azurevnet : {}),
    ...(params.logtype === 'aws-tgc-flow-log' ? params.awstgw : {}),
  };
  providers.forEach((provider) => {
    const key = provider.replace('/', '');
    delete ui[key];
  });
  return ui;
};

export const vpcUItoParams = (values) => {
  const samplerate = parseIfInt(values.samplerate, 10);
  const samplepercent = parseIfInt(values.samplepercent, 10);

  const vpcData = {
    ...values,
    samplerate,
    samplepercent,
  };

  // not allowed to specify it
  providers.forEach(
    (provider) => delete (vpcData[provider] || '').replace('/', ''),
  );
  delete vpcData.keypassphrase;
  delete vpcData.publickey;
  delete vpcData.fingerprint;
  delete vpcData.fingerprint_sha256;

  return vpcData;
};
