import PropTypes from '+prop-types';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import MDEditor from '@uiw/react-md-editor';
import styled, { useTheme } from 'styled-components';

import Button, { ButtonVariants } from '+components/Button';
import { ActionsContainer } from '+components/Layout';
import * as toast from '+components/toast';

const MAX_LINES_TO_DISPLAY = 800;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  .wmde-markdown {
    background-color: transparent;
  }

  &[data-color-mode='light'] pre.language-json {
    background-color: #e0dfe2;
  }

  code.language-json {
    font-size: 12px;
  }
`;

const JsonView = ({ rowData, ...rest }) => {
  const { name } = useTheme();

  const jsonString = JSON.stringify(rowData, null, 2);
  const jsonLines = jsonString.split('\n');
  const isTruncated = jsonLines.length > MAX_LINES_TO_DISPLAY;
  const displayedJson = isTruncated
    ? jsonLines.slice(0, MAX_LINES_TO_DISPLAY).join('\n')
    : jsonString;
  const markdown = `\`\`\`json\n${displayedJson}${isTruncated ? `\n... ${jsonLines.length - MAX_LINES_TO_DISPLAY} lines more` : ''}\n\`\`\``;
  const downloadData = `data:application/json;base64,${btoa(
    decodeURIComponent(encodeURIComponent(jsonString)),
  )}`;
  const downloadFileName = `${rowData.id}.json`;

  return (
    <Container data-color-mode={name}>
      <ActionsContainer mb={0}>
        <CopyToClipboard
          text={jsonString}
          onCopy={() => toast.success('Copied!')}
        >
          <Button variant={ButtonVariants.contained} onClick={() => null}>
            Copy to clipboard
          </Button>
        </CopyToClipboard>

        <Button
          style={{ padding: '0px' }}
          variant={ButtonVariants.contained}
          onClick={() => null}
        >
          <a
            style={{ color: 'inherit', padding: '10px' }}
            href={downloadData}
            download={downloadFileName}
            aria-label="Download JSON"
          >
            Download JSON
          </a>
        </Button>
      </ActionsContainer>

      <MDEditor.Markdown {...rest} source={markdown} disableCopy />
    </Container>
  );
};

JsonView.propTypes = {
  rowData: PropTypes.shape().isRequired,
};

export default JsonView;
