import { TimestampColumnFilter } from '+components/Table/Filters';

import { BaseColumnFactory } from './BaseColumnFactory';

/**
 * @param {Object} props
 * @param {string} props.field - field name
 * @param {string} [props.description=''] - field description
 * @param {function} [props.textAlign] - text alignment
 * @return {Object}
 */
export const TimestampColumnFactory = (props) =>
  BaseColumnFactory({
    getCellProps: () => ({
      style: { justifyContent: props.textAlign ?? 'center' },
    }),
    Filter: TimestampColumnFilter,
    filter: 'timestampFilter',
    sortType: 'number',
    ...props,
  });
