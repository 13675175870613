import PropTypes from '+prop-types';
import { Fragment, useEffect } from 'react';
import { useForm } from 'react-final-form';

import { ContextTypes, ContextTypesLabels } from '@/models/ContextTypes';

import ArrayNQLField from '+components/form/ArrayNQLField';
import { FieldArray, useFormState } from '+components/form/FinalForm';
import Field from '+components/form/FinalForm/Field';
import Group from '+components/form/FormField/components/Group';
import Label from '+components/form/FormField/components/Label';
import { normalizeSelectValue } from '+components/form/Normalizers';
import Plaintext from '+components/form/Plaintext';
import SelectField from '+components/form/SelectField';
import TextField from '+components/form/TextField';
import { validateRequired } from '+components/form/Validators';
import FormModal from '+components/FormModal';
import UniversalField from '+components/UniversalField';

const contextOptions = Object.entries(ContextTypesLabels)
  .filter(([key]) => key !== ContextTypes.ip)
  .map(([value, label]) => ({
    value,
    label,
  }));

const commonProps = {
  disabled: PropTypes.bool,
};

const commonDefaultProps = {
  disabled: false,
};

const FormBody = (props) => {
  const { context, disabled } = props;
  const form = useForm();
  const { values } = useFormState({
    subscription: { values: true },
  });

  useEffect(() => {
    form.mutators?.runValidation();
  }, [values.context]);

  return (
    <Fragment>
      {!!context && (
        <Group>
          <Label>Context</Label>
          <Plaintext>
            <UniversalField
              field="context"
              value={values.context}
              options={{ disabled: true }}
            />
          </Plaintext>
        </Group>
      )}

      {!context && (
        <Field
          name="context"
          label="Context"
          component={SelectField}
          options={contextOptions}
          parse={normalizeSelectValue}
          validate={validateRequired}
          disabled={disabled}
          defaultValue={ContextTypes.flow}
          required
        />
      )}

      <Field
        name="title"
        label="Title"
        component={TextField}
        type="text"
        maxLength={96}
        validate={validateRequired}
        disabled={disabled}
        required
      />

      <FieldArray
        name="nql"
        label="NQL Query"
        component={ArrayNQLField}
        context={values.context || ContextTypes.flow}
        maxLength={1}
        showGfButton={false}
        showCopyButton={false}
        showSaveButton={false}
        allowPresets={false}
        required
        generalMenu={false}
      />
    </Fragment>
  );
};

FormBody.propTypes = { ...commonProps, context: PropTypes.string };
FormBody.defaultProps = { ...commonDefaultProps, context: '' };

export const CommonForm = (props) => {
  const {
    initialValues,
    onToggle,
    onSubmit,
    isOpen,
    disabled,
    loading,
    ...tail
  } = props;

  return (
    <FormModal
      {...tail}
      mode={initialValues?.id ? 'edit' : 'add'}
      item="nql preset"
      initialValues={initialValues}
      loading={loading}
      disabled={disabled || loading}
      onSubmit={onSubmit}
      onToggle={onToggle}
      isOpen={isOpen}
      testId="nql-presets-change-modal"
    >
      <FormBody context={initialValues?.context} disabled={disabled} />
    </FormModal>
  );
};

CommonForm.propTypes = {
  ...commonProps,
  initialValues: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    nql: PropTypes.arrayOf(PropTypes.string),
    context: PropTypes.string,
  }),
  loading: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  onToggle: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
};

CommonForm.defaultProps = {
  ...commonDefaultProps,
  isOpen: false,
  initialValues: {
    context: ContextTypes.flow,
  },
  loading: false,
};
