import { useEffect, useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { useSelector } from 'react-redux';
import { useInterval } from 'react-use';

import { ColorTypes } from '@/models/ColorTypes';

import { selectors as customerSelectors } from '@/redux/api/customer';

import authClient from '@/middleware/authClient';

import ConfirmModal from '+components/ConfirmModal';
import useEvent from '+hooks/useEvent';

const IdleTimer = () => {
  const customer = useSelector(customerSelectors.getCurrentCustomer);
  const timeout = customer?.inactivityTimeout * 60 * 1000; // calculate timeout in milliseconds
  const promptBeforeIdle = 1 * 60 * 1000; // 1 minute
  const [remaining, setRemaining] = useState(timeout);
  const [open, setOpen] = useState(false);
  const timeoutEnabled = timeout > 0;

  const onIdle = useEvent(() => {
    setOpen(false);
    authClient.logout();
  });

  const onActive = useEvent(() => {
    setOpen(false);
  });

  const onPrompt = useEvent(() => {
    setOpen(true);
  });

  const { getRemainingTime, activate, start, pause } = useIdleTimer({
    onIdle,
    onActive,
    onPrompt,
    startManually: true,
    timeout: timeout || 120000, // Set a default timeout of 2 minutes
    promptBeforeIdle,
    throttle: 500,
    crossTab: true,
    syncTimers: 200,
  });

  useInterval(
    () => {
      setRemaining(Math.ceil(getRemainingTime() / 1000));
    },
    timeoutEnabled ? 1000 : null,
    [getRemainingTime, timeoutEnabled],
  );

  useEffect(() => {
    if (timeoutEnabled) {
      start();
    } else {
      pause();
    }
  }, [timeoutEnabled, start, pause]);

  return (
    <ConfirmModal
      isOpen={open}
      confirmButtonText="I'm still here"
      confirmButtonColor={ColorTypes.primary}
      secondaryButtonText="Logout"
      cancelButtonText=""
      textTemplate={
        <div>
          <div>
            You&apos;ve been inactive for a while. Please confirm you&apos;re
            still here.
          </div>
          <div>
            Time remaining: 0:{`${remaining < 10 ? '0' : ''}${remaining}`}
          </div>
        </div>
      }
      titleTemplate="Session Timeout"
      onToggle={activate}
      onSecondary={authClient.logout}
      onConfirm={activate}
      hideCloseButton
    />
  );
};

export default IdleTimer;
