import { useMemo } from 'react';

import { scaleLinear } from 'd3-scale';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { makeId } from '+utils';

import MitreTactic from './MitreTactic';
import MitreTechnique from './MitreTechnique';

const TacticChart = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 8px;

  > div:first-of-type > div > div:first-child {
    border-top-left-radius: 4px;
  }

  > div:last-of-type > div > div:first-child {
    border-top-right-radius: 4px;
  }
`;

const MitreColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 0;
`;

const emptyTactics = Array(12).fill({});
const tacticKeys = Array(12)
  .fill()
  .map(() => makeId());

export const MitreChart = (props) => {
  const {
    tactics,
    tacticsAndEvents,
    onTechniqueClick,
    onTacticClick,
    selectedTechniques,
    minEventCount,
    maxEventCount,
    selectorMode,
  } = props;

  const heatmapScale = useMemo(() => {
    return scaleLinear().domain([minEventCount, maxEventCount]).range([1, 5]);
  }, [minEventCount, maxEventCount]);

  const getHeatmapIndex = (eventCount) => {
    if (eventCount > 0) {
      return Math.round(heatmapScale(eventCount));
    }
    return 0;
  };

  const tacticTitlesOnly = useMemo(() => {
    const tempTactics = tactics.length
      ? tactics.map((tactic) => ({
          tactic_name: tactic.tactic_name,
        }))
      : emptyTactics;
    tempTactics.unshift({
      tactic_name: 'Non MITRE',
    });
    return tempTactics;
  }, [tactics]);

  const chartData = useMemo(
    () => (tacticsAndEvents?.length ? tacticsAndEvents : tacticTitlesOnly),
    [tacticsAndEvents, tacticTitlesOnly],
  );

  return (
    <div>
      <TacticChart>
        {chartData?.map((tactic, key) => {
          const eventCount = tactic.techniques?.reduce(
            (sum, technique) => sum + technique.events?.length || 0,
            0,
          );
          const heatmapIndex = selectedTechniques[0]
            ? 0
            : getHeatmapIndex(eventCount);

          return (
            <MitreColumn key={`column-${tacticKeys[key]}`}>
              <MitreTactic
                onTacticClick={onTacticClick}
                tactic={tactic}
                key={`tactic-${tacticKeys[key]}`}
                heatmapIndex={heatmapIndex}
                selectorMode={selectorMode}
                selectedTechniques={selectedTechniques}
              />
              {tactic.techniques?.map((technique) => {
                const techniqueEventCount = technique.events?.length;
                const techniqueHeatmapIndex =
                  getHeatmapIndex(techniqueEventCount);

                return (
                  <MitreTechnique
                    onTechniqueClick={onTechniqueClick}
                    key={technique.technique_id}
                    technique={technique}
                    selectedTechniques={selectedTechniques}
                    heatmapIndex={techniqueHeatmapIndex}
                    selectorMode={selectorMode}
                  />
                );
              })}
            </MitreColumn>
          );
        })}
      </TacticChart>
    </div>
  );
};

MitreChart.propTypes = {
  tactics: PropTypes.arrayOf(
    PropTypes.shape({
      tactic_id: PropTypes.string,
      tactic_name: PropTypes.string,
    }),
  ).isRequired,
  tacticsAndEvents: PropTypes.arrayOf(
    PropTypes.shape({
      tactic_id: PropTypes.string,
      tactic_name: PropTypes.string,
      techniques: PropTypes.arrayOf(
        PropTypes.shape({
          technique_id: PropTypes.string,
        }),
      ),
    }),
  ).isRequired,
  onTechniqueClick: PropTypes.func,
  onTacticClick: PropTypes.func,
  selectedTechniques: PropTypes.arrayOf(PropTypes.string),
  minEventCount: PropTypes.number,
  maxEventCount: PropTypes.number,
  // when in selector mode the component functions as a control to select MITRE techniques, it does not display event counts.
  selectorMode: PropTypes.bool,
};

MitreChart.defaultProps = {
  selectedTechniques: [],
  onTechniqueClick: () => {},
  onTacticClick: () => {},
  minEventCount: Infinity,
  maxEventCount: 0,
  selectorMode: false,
};
