/* eslint-disable react/prop-types, camelcase */
import { Link } from 'react-router-dom';

import RoleModel from '@/models/Role';
import RoutePaths from '@/models/RoutePaths';

import { HeaderSubheaderCell } from '+components/Table/Cells';
import {
  BaseColumnFactory,
  MenuColumnFactory,
} from '+components/Table/Columns';
import { getRowOriginal } from '+components/Table/Columns/utils';
import { SelectColumnFilter } from '+components/Table/Filters';
import Tag from '+components/Tag';
import dayjs, { DateFormat } from '+utils/dayjs';

const roleFormatter =
  (roles, shortname) =>
  ({ row, value = [] }) => {
    const original = getRowOriginal(row);
    const userRole =
      original?.app_metadata?.original &&
      original?.app_metadata?.original === shortname
        ? original?.original_roles?.[0]
        : value?.[0];
    const role = roles?.[userRole];
    if (!role) {
      return userRole;
    }
    return (
      <Link to={`${RoutePaths.roles}/${role.id}`}>
        <Tag outlined={false} color={role.color}>
          {role.label}
        </Tag>
      </Link>
    );
  };

export const getColumns = ({ shortname, roles, cxActionMenu }) => [
  BaseColumnFactory({
    accessor: 'appname',
    Header: 'Name / Desc',
    minWidth: 160,
    Cell: HeaderSubheaderCell({
      propHeader: 'appname',
      propSubheader: 'description',
    }),
    realAccessor: ['appname', 'description'],
  }),
  BaseColumnFactory({
    accessor: 'roles',
    Header: 'Role',
    minWidth: 240,
    getCellProps: () => ({ style: { justifyContent: 'center' } }),
    Cell: roleFormatter(roles, shortname),
    Filter: SelectColumnFilter({
      optionLabel: (key) =>
        key === 'all' ? 'All' : roles?.[key]?.label || key,
      sort: (a, b) => RoleModel.sortRoles(roles?.[a], roles?.[b]),
      selectProps: {
        groupBy: (option) => {
          if (option.value === 'all') {
            return '';
          }
          return roles?.[option.value]?.system ? 'System' : 'Custom';
        },
      },
    }),
    filter: 'selectFilter',
  }),
  BaseColumnFactory({
    accessor: 'lastLogin',
    Header: 'Last Login',
    minWidth: 240,
    getCellProps: () => ({ style: { justifyContent: 'center' } }),
    Cell: (row) =>
      row.value ? dayjs(row.value).format(DateFormat.second) : 'Never',
  }),
  MenuColumnFactory({ cxActionMenu }),
];
