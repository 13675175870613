import styled from 'styled-components';

import Box from '@mui/material/Box';

export const PageTitle = styled(Box).attrs((props) => ({
  display: 'inline',
  fontWeight: 600,
  fontSize: '20px',
  lineHeight: '30px',
  margin: 0,
  marginRight: '10px',
  ...props,
  component: 'h1',
}))``;
