import PropTypes from '+prop-types';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useThrottle, useToggle } from 'react-use';

import { LRUMap } from 'lru_map';

import { ContextTypes } from '@/models/ContextTypes';
import { CustomType } from '@/models/CustomType';
import StatsRequest from '@/models/StatsRequest';

import { lang } from '+components/charts/common/utils';
import ForceDirected, {
  Resolvers,
  SettingItem,
  SettingToggle,
  sumFields,
} from '+components/charts/ForceDirected';
import useEvent from '+hooks/useEvent';
import useGlobalFilters from '+hooks/useGlobalFilters';
import useRealtimeOrRequest from '+hooks/useRealtimeOrRequest';
import { preserveRef } from '+utils';
import nqlLang from '+utils/nqlLang';

const getHash = (isRealtime, filters, params) => {
  const hash = `${params.search}_${params.intersect}`;

  if (isRealtime) {
    return hash;
  }

  const { period, startIsMin, endIsNow } = filters;

  if (period.type !== CustomType) {
    return `${hash}_${params.start}`;
  }

  return `${hash}_${startIsMin ? 0 : params.start}_${
    endIsNow ? 0 : params.end
  }`;
};

const getSearch = (currentIp, currentIpType, dnsHidden, onlyInternal) => {
  let result;
  if (currentIp) {
    result = nqlLang.equal(currentIpType || 'ip', currentIp);
  } else if (onlyInternal) {
    result = nqlLang.and(
      nqlLang.equal('srcinternal', true),
      nqlLang.equal('dstinternal', true),
    );
  }

  return nqlLang.and(result, dnsHidden ? nqlLang.notEqual('port', 53) : '');
};

const ipRegExp =
  /ip\s==\s((:?(:?25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(:?25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?))/gi;

export const includeFields = [
  'id',
  'srcip',
  'dstip',
  'srcport',
  'dstport',
  'flowsrcname',
  'flowsrcip',
  'protocol',
  'label',
  'timestamp',

  // for calculations
  sumFields[0], // 'bits'
  // ...sumFields, // TODO uncomment when we will need to have sumFields
];

const IpExplorer = (props) => {
  const {
    id,
    currentIp,
    currentIpType,
    nodesFunction,
    particlesFunction,
    suffixOfExportFilename,
    onSystemNqlChanged,
    overrideSystemNql,
    refGetSearch,
    transparent,
    onlyInternal,
  } = props;

  const name = id || 'IPExplorer';

  const [data, setData] = useState(null);
  const [particles, setParticles] = useState(null);
  const [dnsHidden, toggleDnsHidden] = useToggle(true);
  const [search, setSearch] = useState(
    getSearch(currentIp, currentIpType, dnsHidden, onlyInternal),
  );

  const onSearchChanged = useEvent((value) => {
    onSystemNqlChanged?.(value);
  });

  const [filters] = useGlobalFilters(ContextTypes.flow);

  const fixedSearch = overrideSystemNql ?? search;

  const additionalSocketOptions = useMemo(
    () =>
      StatsRequest.makeSearch({
        search: filters.nql,
        andSearch: fixedSearch,
        intersect: filters.intersect,
      }),
    [fixedSearch, filters.nql, filters.intersect],
  );

  const selectedIds = useMemo(
    () =>
      !(fixedSearch || filters.nql?.length)
        ? null
        : Array.from(
            nqlLang
              .format([fixedSearch, ...filters.nql.flat()].join(' && '))
              .matchAll(ipRegExp),
          ).map(([, ip]) => ip),
    [fixedSearch, filters.nql],
  );

  const { isFetching, records, isRealtime, socketOptions } =
    useRealtimeOrRequest({
      name,
      additionalSocketOptions,
      includeFields,
      refresher: filters.refresher,
    });

  const lastRecord = useRef(new LRUMap(2000));
  const sendParticles = useRef(false);

  useEffect(() => {
    if (!records?.size) {
      return;
    }

    const items = (records?.toArray() || []).filter((item) => {
      if (!item || lastRecord.current.has(item.id)) {
        return false;
      }

      lastRecord.current.set(item.id, true);

      return true;
    });

    setData(items);
    if (sendParticles.current) {
      setParticles(items);
    }
    sendParticles.current = !(socketOptions.start > 0);
  }, [records]);

  const hash = getHash(isRealtime, filters, socketOptions);
  const lastFunctions = useRef([nodesFunction, particlesFunction]);
  useEffect(() => {
    const newFns = [nodesFunction, particlesFunction];
    const isChanged = lastFunctions.current.some(
      (fn, index) => fn !== newFns[index],
    );

    if (!isChanged) {
      setData(null);
      lastRecord.current.clear();
    } else if (lastFunctions.current[0] !== nodesFunction) {
      setData((prev) => {
        let items = records?.filter(Boolean).toArray();
        if (!items?.length) {
          items = prev ? [...prev] : [];
        }

        return items;
      });
    }

    lastFunctions.current = newFns;

    setParticles(null);
    sendParticles.current = false;
  }, [hash, nodesFunction, particlesFunction]);

  const additionalSettingsActions = useMemo(
    () => [
      {
        key: 'DNS traffic',
        closeMenu: false,
        action: (event) => {
          event.stopPropagation();
          toggleDnsHidden();
        },
        content: (
          <SettingItem>
            <span>DNS traffic</span>
            <SettingToggle checked={!dnsHidden} />
          </SettingItem>
        ),
      },
    ],
    [dnsHidden],
  );

  useEffect(() => {
    const next = getSearch(currentIp, currentIpType, dnsHidden, onlyInternal);
    onSearchChanged(next);
    setSearch(next);
    preserveRef(refGetSearch, () => next);
  }, [currentIp, currentIpType, dnsHidden, onlyInternal]);

  const noData = useThrottle(isFetching ? lang.loading : lang.noData, 2e3);

  return (
    <ForceDirected
      data={data}
      particles={particles}
      selectedIds={selectedIds}
      noData={noData}
      additionalSettingsActions={additionalSettingsActions}
      nodesFunction={nodesFunction}
      particlesFunction={particlesFunction}
      suffixOfExportFilename={suffixOfExportFilename}
      transparent={transparent}
    />
  );
};

IpExplorer.propTypes = {
  currentIp: PropTypes.string,
  currentIpType: PropTypes.string,
  nodesFunction: PropTypes.func,
  particlesFunction: PropTypes.func,
  id: PropTypes.string,
  suffixOfExportFilename: PropTypes.string,
  onSystemNqlChanged: PropTypes.func,
  overrideSystemNql: PropTypes.string,
  refGetSearch: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any }),
  ]),
  transparent: PropTypes.bool,
  onlyInternal: PropTypes.bool,
};

IpExplorer.defaultProps = {
  currentIp: '',
  currentIpType: 'ip',
  nodesFunction: Resolvers.ip.nodes,
  particlesFunction: null,
  id: null,
  suffixOfExportFilename: 'ip_explorer',
  onSystemNqlChanged: null,
  overrideSystemNql: null,
  refGetSearch: null,
  transparent: false,
  onlyInternal: true,
};

export default IpExplorer;
