import PropTypes from '+prop-types';
import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Route, Routes } from 'react-router-dom';
import { useLocation } from 'react-use';

import { useFlag } from '@unleash/proxy-client-react';
import { Settings } from 'luxon';
import { useTheme } from 'styled-components';

import FeatureFlags from '@/models/FeatureFlags';
import RoutePaths from '@/models/RoutePaths';
import SettingCategories from '@/models/SettingCategories';
import { UIProperties } from '@/models/UIProperties';

import {
  actions as customerActions,
  selectors as customerSelectors,
} from '@/redux/api/customer';
import { actions as docsActions } from '@/redux/api/docs';
import { actions as responseIntegrationsActions } from '@/redux/api/integrations/response';
import { actions as nqlCompleteActions } from '@/redux/api/nql-complete';
import { actions as ruleActions } from '@/redux/api/rules';
import {
  actions as profileActions,
  selectors as profileSelectors,
} from '@/redux/api/user/profile';
import { actions as newSocketActions } from '@/redux/newsocket';

import { config } from '@/config';
import authClient from '@/middleware/authClient';
import CveLookup from '@/pages/CveLookup';
import DashboardsManage from '@/pages/Dashboards/Manage';
import HomePage from '@/pages/Home';
import Layout from '@/pages/Layout';
import NotFound404 from '@/pages/NotFound404';
import OpenSourceSoftware from '@/pages/OpenSourceSoftware';
import AdminRoutes from '@/routers/components/AdminRoutes';
import DashboardsRoutes from '@/routers/components/DashboardsRoutes';
import InvestigateRoutes from '@/routers/components/InvestigateRoutes';
import NdrRoutes from '@/routers/components/NdrRoutes';
import ToolsRoutes from '@/routers/components/ToolsRoutes';

import { BreadProvider, CrumbRoute } from '+components/Breadcrumb';
import { TryAgainBoundary } from '+components/ErrorBoundary';
import FeaturesProvider from '+components/FeaturesProvider';
import { GlobalFilterProvider } from '+components/GlobalFilters/Context';
import { PageTabsProvider } from '+components/PageTabs';
import Portal from '+components/Portal';
import { Positions, ToastContainer, Transition } from '+components/toast';
import { useFeatureOverrides } from '+hooks/useFeatureOverrides';
import { useHasFlow } from '+hooks/useHasFlow';
import { usePollCurrentCustomer } from '+hooks/usePollCurrentCustomer';
import usePortalSettingsValue from '+hooks/usePortalSettingsValue';
import { useRemoveLoader } from '+hooks/useRemoveLoader';
import useUIProperty from '+hooks/useUIProperty';
import { getSsoLoginUrl, getSsoLogoutUrl, homeUrl } from '+utils';
import { chameleon } from '+utils/chameleon';
import dayjs from '+utils/dayjs';
import { logRocket } from '+utils/logRocket';

const WrappedRoutes = (props) => {
  const { guest, demo, hideNav } = props;
  const theme = useTheme();
  const dispatch = useDispatch();
  const location = useLocation();
  const customer = useSelector(customerSelectors.getCurrentCustomer);

  const isRolesUiSettingsEnabled = useFlag(FeatureFlags.rolesUiSettings);
  const [isNotificationPopupsEnabled] = usePortalSettingsValue(
    SettingCategories.ui,
    'isNotificationPopupsEnabled',
    true,
  );

  const profile = useSelector(profileSelectors.getProfile);
  const samlProvider = useSelector(customerSelectors.getSamlProvider);

  const [, setVersion] = useUIProperty(UIProperties.version);
  const [, setUnderCover] = useUIProperty(UIProperties.underCover);
  const [, setImpersonating] = useUIProperty(UIProperties.impersonating);
  const [, setGuest] = useUIProperty(UIProperties.guest);
  const [, setDemo] = useUIProperty(UIProperties.demo);
  const [, setHideNav] = useUIProperty(UIProperties.hideNav);
  const [, setWindowFocused] = useUIProperty(UIProperties.windowFocused);
  const hasFlow = useHasFlow();

  const [tz, changeTz] = usePortalSettingsValue(
    SettingCategories.ui,
    'tz',
    'Etc/UTC',
  );
  const [userRoleUiSettings] = usePortalSettingsValue(
    SettingCategories.ui,
    `${profile?.roles?.[0]}:settings`,
    {},
  );

  usePollCurrentCustomer();
  useFeatureOverrides();

  // it is a dirty hack for skipping the fist render for children
  // to run the next useEffect earlier than children useEffects
  const firstRender = useRef(true);

  useEffect(() => {
    firstRender.current = false;

    setGuest(guest);
    setDemo(demo);
    setHideNav(
      hideNav ||
        !hasFlow ||
        (isRolesUiSettingsEnabled && userRoleUiSettings?.hideNav),
    );

    dispatch(newSocketActions.connectServer());

    dispatch(nqlCompleteActions.fetchDocs());
    dispatch(nqlCompleteActions.fetchThresholdTerms());
    dispatch(docsActions.fetchDetectionModelsDocs('fetchDetectionModelsDocs'));
    dispatch(profileActions.requestProfileWithDependencies());
    dispatch(profileActions.requestPermissions());
    dispatch(ruleActions.fetchCategories());
    dispatch(ruleActions.fetchAlgorithms());
    dispatch(ruleActions.fetchCCMs());
    dispatch(responseIntegrationsActions.fetchPlugins());

    if (!guest) {
      dispatch(customerActions.requestSamlProvider()); // we need this information for redirect logout url
    }

    if (!guest) {
      // TODO: Temporary solution for saved global filters
      // Remove me if it's November 2024 or later
      const globalFiltersLocalStorage = localStorage.getItem('globalFilters');
      if (globalFiltersLocalStorage) {
        const globalFilters = JSON.parse(globalFiltersLocalStorage);
        changeTz(globalFilters.tz);
        localStorage.removeItem('globalFilters');
      }
    }

    return () => {
      dispatch(newSocketActions.disconnectServer());
    };
  }, [
    guest,
    hideNav,
    userRoleUiSettings?.hideNav,
    isRolesUiSettingsEnabled,
    hasFlow,
  ]);

  useEffect(() => {
    if (guest) {
      localStorage.setItem('logoutRedirectUri', homeUrl);
      return;
    }

    if (!Object.keys(profile || {}).length) {
      return;
    }

    if (profile?.idp) {
      if (profile?.app_metadata?.original) {
        // If it's masquerading user and logout URL already set - we need to keep it
        // But if logout URL not set - set logout URL to Netography Logout page
        const redirectUri = localStorage.getItem('logoutRedirectUri');
        if (!redirectUri) {
          const logoutRedirectUri = getSsoLogoutUrl(profile?.idp);
          localStorage.setItem('logoutRedirectUri', logoutRedirectUri);
        }
        return;
      }
      // If it's IDP user and company configured SLO set logout URL to company SSO URL
      // If company don't configured SLO - set logout URL to Netography Logout page
      const logoutRedirectUri =
        profile.idp === samlProvider?.alias &&
        samlProvider?.config?.singleLogoutServiceUrl
          ? getSsoLoginUrl(profile?.idp)
          : getSsoLogoutUrl(profile?.idp);
      localStorage.setItem('logoutRedirectUri', logoutRedirectUri);
    } else {
      localStorage.removeItem('logoutRedirectUri');
    }
  }, [guest, profile, samlProvider]);

  useEffect(() => {
    if (profile?.id) {
      const profileName =
        !demo && profile.guest
          ? `${profile.name} (${profile.app_metadata?.shortname})`
          : profile.name;

      const data = {
        email: profile.email,
        name: profileName,
        roles: profile.roles.join(', '),
        shortname: profile.app_metadata?.shortname,
        originalShortname: profile.app_metadata?.original,
        theme: theme.name,
        environment: config.environment,
        customerType: customer?.type,
      };

      logRocket.identify(profile.id, data);

      // Identify user for Chameleon.io, we can utilize more variables here
      // See documentation: https://app.chameleon.io/setup/install
      chameleon.identify(profile.id, data);
    }
  }, [demo, profile?.id, customer?.type]);

  useEffect(() => {
    if (guest) {
      return undefined;
    }

    const onStorageChanged = (event) => {
      if (event.key === 'impersonate') {
        document.location.reload();
      }
    };

    // Reload this tab if user impersonate on another tab
    window.addEventListener('storage', onStorageChanged);

    return () => {
      document.removeEventListener('storage', onStorageChanged, true);
    };
  }, [guest]);

  useEffect(() => {
    setVersion(document.querySelector('meta[name="version"]')?.content);
  }, []);

  useEffect(() => {
    const realUser = authClient.getUser();
    setUnderCover(!!profile?.app_metadata?.original);
    setImpersonating(profile?.email && realUser?.email !== profile?.email);
  }, [profile]);

  useEffect(() => {
    const onVisibilityChange = () => {
      setWindowFocused(document.visibilityState === 'visible');
    };
    document.addEventListener('visibilitychange', onVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', onVisibilityChange);
    };
  }, []);

  useEffect(() => {
    dayjs.locale('en');
    dayjs.tz.setDefault(tz);
    Settings.defaultZone = tz;
    Settings.defaultLocale = 'en-US';
  }, [tz]);

  useRemoveLoader();

  return firstRender.current ? null : (
    <TryAgainBoundary resetKey={location.key}>
      {isNotificationPopupsEnabled && (
        <Portal inBody>
          <ToastContainer
            icon={false}
            position={Positions.BOTTOM_RIGHT}
            transition={Transition.Flip}
            autoClose={6000}
            stacked
          />
        </Portal>
      )}

      <BreadProvider>
        <GlobalFilterProvider>
          <Layout>
            <TryAgainBoundary resetKey={location.key}>
              <Routes>
                <Route
                  index
                  element={<CrumbRoute title="Home" component={HomePage} />}
                />

                <Route
                  path={RoutePaths.dashboardsFavorites.pageName}
                  element={
                    hasFlow || guest ? (
                      <DashboardsManage />
                    ) : (
                      <Navigate to="/" replace />
                    )
                  }
                />

                <Route
                  path={`${RoutePaths.dashboards.pageName}/*`}
                  element={
                    hasFlow ? <DashboardsRoutes /> : <Navigate to="/" replace />
                  }
                />

                <Route
                  path="investigate/*"
                  element={
                    hasFlow ? (
                      <InvestigateRoutes />
                    ) : (
                      <Navigate to="/" replace />
                    )
                  }
                />

                <Route
                  path="ndr/*"
                  element={
                    hasFlow ? <NdrRoutes /> : <Navigate to="/" replace />
                  }
                />

                <Route
                  path="tools/*"
                  element={<CrumbRoute title="Tools" component={ToolsRoutes} />}
                />

                <Route
                  path={RoutePaths.cveLookup.pageName}
                  element={
                    hasFlow ? (
                      <CrumbRoute title="CVE Lookup" component={CveLookup} />
                    ) : (
                      <Navigate to="/" replace />
                    )
                  }
                />

                <Route path="admin/*" element={<AdminRoutes />} />

                <Route
                  path={RoutePaths.openSourceSoftware.pageName}
                  element={
                    <CrumbRoute
                      title="Netography - Open Source Software"
                      component={OpenSourceSoftware}
                    />
                  }
                />

                <Route
                  path="*"
                  element={
                    <CrumbRoute
                      title="Page Not Found"
                      component={NotFound404}
                    />
                  }
                />
              </Routes>
            </TryAgainBoundary>
          </Layout>
        </GlobalFilterProvider>
      </BreadProvider>
    </TryAgainBoundary>
  );
};

WrappedRoutes.propTypes = {
  guest: PropTypes.bool,
  hideNav: PropTypes.bool,
};

WrappedRoutes.defaultProps = {
  guest: false,
  hideNav: false,
};

const WrappedWithFeatures = (props) => (
  <FeaturesProvider>
    <PageTabsProvider>
      <WrappedRoutes {...props} />
    </PageTabsProvider>
  </FeaturesProvider>
);

WrappedWithFeatures.propTypes = WrappedRoutes.propTypes;
WrappedWithFeatures.defaultProps = WrappedRoutes.defaultProps;

export default WrappedWithFeatures;
