/* eslint-disable react/prop-types,react/no-array-index-key */
import { useMemo } from 'react';

import capitalize from 'lodash.capitalize';
import uniqBy from 'lodash.uniqby';

import { ResponseIntegrations } from '@/models/integrations/ResponseIntegrations';

import { PluginIcon } from '@/shared/img/icon';

import AlertTypeLabel from '+components/Labels/AlertTypeLabel';
import {
  CellPluginName,
  HeaderSubheaderCell,
  MultipleItemsWrapper,
  UniversalCell,
} from '+components/Table/Cells';
import {
  BaseColumnFactory,
  RowSelectorColumnFactory,
} from '+components/Table/Columns';
import { SelectColumnFilter } from '+components/Table/Filters';
import {
  autoRemoveIfAll,
  someOfFieldsFilter,
  withAutoRemove,
} from '+components/Table/FilterTypeFactories';
import { makeId } from '+utils/general';
import sortByHelper from '+utils/sortByHelper';

const separator = makeId();

const providerLabelStyle = {
  display: 'flex',
  alignItems: 'center',
  gap: '0.5rem',
};

const getMergedAlgorithms = ({ categories, algorithms } = {}) => {
  const cats = Array.isArray(categories) ? categories : [];
  const algs = Array.isArray(algorithms) ? algorithms : [];

  if (cats.includes('all') && algs.includes('all')) {
    return 'all';
  }

  if (cats.length > 0 && !cats.includes('all')) {
    return String(cats);
  }

  if (algs.length > 0 && !algs.includes('all')) {
    return String(algs);
  }

  return String([...cats, ...algs]);
};

const getMergedAlertTypes = (types) => {
  if (!Array.isArray(types) || types?.length === 0) {
    return '';
  }

  return [
    types.includes('start') ? 1 : 9,
    types.includes('ongoing') ? 2 : 9,
    types.includes('end') ? 3 : 9,
  ].join('');
};

const getMergedPlugins = (plugins) =>
  !Array.isArray(plugins) || plugins?.length === 0
    ? ''
    : [
        ...plugins
          .filter(({ type }) => type === 'notification')
          .map(({ name }) => name),
        ...plugins
          .filter(({ type }) => type === 'webhook')
          .map(({ name }) => name),
        ...plugins
          .filter(({ type }) => type === 'block')
          .map(({ name }) => name),
      ]
        .sort()
        .join(' ');

const columns = ({ algorithms, categories }) => [
  RowSelectorColumnFactory(),
  BaseColumnFactory({
    accessor: 'name',
    Header: 'Policy Name',
    width: 220,
    Cell: HeaderSubheaderCell({
      propHeader: 'name',
      propSubheader: 'description',
    }),
    realAccessor: ['name', 'description'],
    filter: someOfFieldsFilter(['name', 'description']),
  }),
  BaseColumnFactory({
    id: 'caterithms',
    accessor: 'config',
    Header: 'Detection Categories / Models',
    // maxWidth: 80,
    getCellProps: () => ({ style: { whiteSpace: 'unset' } }),
    Cell: UniversalCell('caterithms'),
    sortType: sortByHelper(getMergedAlgorithms),
    Filter: SelectColumnFilter({
      optionValueExtractor: (row, id) => {
        const options = [];
        Object.entries(row.values[id]).forEach(([key, value]) => {
          if (['algorithms', 'categories'].includes(key)) {
            value.forEach((item) => {
              if (item !== 'all') {
                const { description } =
                  (key === 'algorithms'
                    ? algorithms?.[item]
                    : categories?.[item]) || {};
                options.push({
                  value: `${key}${separator}${item}`,
                  label: item,
                  description,
                  group: key,
                });
              }
            });
          }
        });
        return uniqBy(options, 'value');
      },
      sort: (a, b) => (a.value > b.value ? 1 : -1),
      selectProps: {
        groupBy: (option) => option.group,
      },
    }),
    filter: withAutoRemove((rows, [id], filterValue) => {
      if (autoRemoveIfAll(filterValue)) {
        return rows;
      }

      const [filterConfigKey, filterConfigValue] =
        filterValue.value.split(separator);
      return rows.filter(({ values: { [id]: value } }) => {
        return (
          (value.algorithms?.includes('all') &&
            value.categories?.includes('all')) ||
          value[filterConfigKey]?.includes(filterConfigValue)
        );
      });
    }, autoRemoveIfAll),
  }),
  BaseColumnFactory({
    accessor: (original) =>
      (original?.config?.tracks || [])
        .flatMap((track) => track)
        .filter((item) => item !== 'all'),
    Header: 'Tracked Objects',
    width: 120,
    getCellProps: () => ({ style: { justifyContent: 'center' } }),
    Cell: ({ value }) =>
      useMemo(
        () => (
          <MultipleItemsWrapper>
            {value.map((item, index) => (
              <span key={`${item}_${index}`}>{item}</span>
            ))}
          </MultipleItemsWrapper>
        ),
        [value],
      ),
    Filter: SelectColumnFilter({
      optionLabel: (key) => (key === 'all' ? 'All' : key),
    }),
    filter: 'selectFilter',
  }),
  BaseColumnFactory({
    // accessor: 'config',
    accessor: (original) => {
      let severities = Array.isArray(original.config?.severities)
        ? original.config?.severities
        : [];
      if (severities.includes('all')) {
        severities = ['high', 'medium', 'low'];
      }
      return severities;
    },
    Header: 'Severities',
    width: 200,
    Cell: UniversalCell('severity'),
    Filter: SelectColumnFilter({
      fixedOptions: ['all', 'high', 'medium', 'low'],
    }),
    filter: 'selectFilter',
  }),
  BaseColumnFactory({
    // id: 'alertTypes',
    // accessor: 'config',
    accessor: (original) => {
      let alerttypes = Array.isArray(original.config?.alerttypes)
        ? original.config?.alerttypes
        : [];
      if (alerttypes.includes('all')) {
        alerttypes = ['start', 'ongoing', 'end'];
      }
      return alerttypes;
    },
    Header: 'Alert Types',
    width: 160,
    getCellProps: () => ({ style: { justifyContent: 'center' } }),
    Cell: ({ value }) => {
      const start = value.includes('start');
      const ongoing = value.includes('ongoing');
      const end = value.includes('end');
      // const showAll = !row.subRows?.length;
      // return showAll ? (
      //   <MultipleItemsWrapper>
      //     <AlertTypeLabel alerttype="start" active={start} />
      //     <AlertTypeLabel alerttype="ongoing" active={ongoing} />
      //     <AlertTypeLabel alerttype="end" active={end} />
      //   </MultipleItemsWrapper>
      // ) : (
      //   <MultipleItemsWrapper>
      //     {start && <AlertTypeLabel alerttype="start" active={start} />}
      //     {ongoing && <AlertTypeLabel alerttype="ongoing" active={ongoing} />}
      //     {end && <AlertTypeLabel alerttype="end" active={end} />}
      //   </MultipleItemsWrapper>
      // );
      return (
        <MultipleItemsWrapper>
          {start && <AlertTypeLabel alerttype="start" active={start} />}
          {ongoing && <AlertTypeLabel alerttype="ongoing" active={ongoing} />}
          {end && <AlertTypeLabel alerttype="end" active={end} />}
        </MultipleItemsWrapper>
      );
    },
    Filter: SelectColumnFilter({
      fixedOptions: ['all', 'start', 'ongoing', 'end'],
    }),
    filter: 'selectFilter',
    sortType: sortByHelper(getMergedAlertTypes),
  }),
  BaseColumnFactory({
    accessor: 'plugins',
    Header: 'Integrations',
    // maxWidth: 120,
    // getCellProps: () => ({ style: { whiteSpace: 'unset' } }),
    Cell: ({ value }) =>
      !value?.length ? null : (
        <MultipleItemsWrapper>
          {value.map((item, index) => (
            <CellPluginName
              {...item}
              key={`${item.name}-${index}`}
              icon={<PluginIcon name={item.adapter} size={14} />}
              style={providerLabelStyle}
            />
          ))}
        </MultipleItemsWrapper>
      ),
    Filter: SelectColumnFilter({
      optionValueExtractor: (row, id) =>
        (row.values[id] || []).map(({ adapter }) => adapter),
      optionLabel: (value) =>
        ResponseIntegrations[value]?.title || capitalize(value),
    }),
    filter: withAutoRemove((rows, [id], filterValue) => {
      if (autoRemoveIfAll(filterValue)) {
        return rows;
      }

      return rows.filter((row) =>
        row.values[id]?.some(({ adapter }) => adapter === filterValue.value),
      );
    }, autoRemoveIfAll),
    sortType: sortByHelper(getMergedPlugins),
  }),
];

export default columns;
