import PropTypes from '+prop-types';
import { useCallback, useMemo } from 'react';

import styled from 'styled-components';

import DrawerOriginal from '@mui/material/Drawer';
import Stack from '@mui/material/Stack';

import CloseIcon from 'mdi-react/CloseIcon';

import Button, { ButtonVariants } from '+components/Button';
import { ScrollBarMixin } from '+theme/mixins/scrollBarMixin';

const CloseButton = styled(Button).attrs({
  variant: ButtonVariants.link,
})``;

const Body = styled(Stack)`
  overflow-x: hidden;
  overflow-y: auto;
  ${ScrollBarMixin};
`;

const getPeperProps = (minWidth) => ({
  sx: (theme) => ({
    backgroundColor: theme.drawerBackgroundColor,
    minWidth: minWidth || '30vw',
    maxWidth: '70vw',
    boxShadow: '-8px 0px 8px 0px #00000040',
    borderLeft: '1px solid',
    borderColor: theme.drawerBorderColor,
  }),
});

const modalProps = {
  sx: {
    '.MuiModal-backdrop': {
      backgroundColor: 'rgba(0, 0, 0, 0.3)',
      cursor: 'pointer',
    },
  },
};

export const Drawer = (props) => {
  const { title, onClose, children, minWidth, ...rest } = props;

  const peperProps = useMemo(() => getPeperProps(minWidth), [minWidth]);

  const onCloseClick = useCallback(() => {
    onClose?.('closeButton');
  }, [onClose]);

  return (
    <DrawerOriginal
      onClose={onClose}
      anchor="right"
      PaperProps={peperProps}
      ModalProps={modalProps}
      elevation={0}
      {...rest}
    >
      <Stack height="100%" overflow="hidden" position="relative">
        <Stack
          direction="row"
          gap="10px"
          alignItems="center"
          px="15px"
          pt="5px"
        >
          <CloseButton onClick={onCloseClick}>
            <CloseIcon />
          </CloseButton>
          {title && (
            <Stack
              component="main"
              direction="row"
              flex={1}
              justifyContent="center"
              alignItems="center"
              lineHeight="3.2rem"
              fontSize="1.6rem"
              fontWeight="700"
              color="text.primary"
              position="relative"
            >
              {title}
            </Stack>
          )}
        </Stack>
        <Body flex={1} position="relative">
          {children}
        </Body>
      </Stack>
    </DrawerOriginal>
  );
};

Drawer.propTypes = {
  onClose: PropTypes.func,
  title: PropTypes.string,
  children: PropTypes.children,
  minWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Drawer.defaultProps = {
  onClose: undefined,
  title: undefined,
  children: undefined,
  minWidth: undefined,
};
