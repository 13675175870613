import PropTypes from '+prop-types';
import { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import isEqual from 'lodash.isequal';

import { ContextTypes } from '@/models/ContextTypes';
import StatsRequest from '@/models/StatsRequest';

import { selectors as customerSelectors } from '@/redux/api/customer';

import { lang } from '+components/charts/common/utils';
import { Columns, EventTable } from '+components/ContextTables/EventTable';
import useEvent from '+hooks/useEvent';
import useGlobalFilters from '+hooks/useGlobalFilters';
import useLoadingIndicator from '+hooks/useLoadingIndicator';
import useRealtimeOrRequest from '+hooks/useRealtimeOrRequest';
import { makeId } from '+utils';
import nqlLang from '+utils/nqlLang';

const tableIdEventList = 'result_event_list';
const eventSearchId = makeId();

const defaultEventListColumns = [
  Columns.traffictype,
  Columns.summary,
  Columns.categories,
  Columns.srcips,
  Columns.dstips,
  Columns.start,
  Columns.duration,
  Columns.alerttype,
  Columns.severity,
  Columns.menu,
];

/**
 * @param {URLSearchParams} search
 * @param {string[]} fields
 * @returns {boolean}
 */
const removePartsFromSearch = (search, ...fields) => {
  let hasRemoved = false;

  fields.forEach((field) => {
    if (search.has(field)) {
      search.delete(field);
      hasRemoved = true;
    }
  });

  return hasRemoved;
};

const Fields = {
  algorithm: 'algorithm',
  ip: 'ip',
  from: 'from',
  to: 'to',
};
const fieldsList = Object.values(Fields);

const EventListTab = (props) => {
  const { userFilters, refresher, timeFilter } = props;
  const location = useLocation();
  const navigate = useNavigate();

  const [filters] = useGlobalFilters(ContextTypes.alerts);
  const customer = useSelector(customerSelectors.getCurrentCustomer);

  const [includeFields, setIncludeFields] = useState([]);

  const eventListColumns = useMemo(() => {
    if (customer?.multi_account) {
      const indexOfCustomerColumn = defaultEventListColumns.indexOf(
        Columns.customer,
      );
      if (indexOfCustomerColumn === -1) {
        return [...defaultEventListColumns, Columns.customer];
      }
    }
    return defaultEventListColumns;
  }, [defaultEventListColumns, customer]);

  const getRemoveFilter = useCallback(
    (...fields) =>
      () => {
        const search = new URLSearchParams(location.search);
        if (removePartsFromSearch(search, ...fields)) {
          navigate({ search: search.toString() });
        }
      },
    [location.search, navigate],
  );

  const onClearExternalFilters = useEvent(getRemoveFilter(...fieldsList));

  const externalTableFilters = useMemo(() => {
    const arr = [];

    if (userFilters.ip) {
      arr.push({
        label: `${userFilters.ip}${userFilters.score ? ' && score > 0' : ''}`,
        onRemoveFilter: getRemoveFilter(Fields.ip),
      });
    }

    if (userFilters.algorithm) {
      arr.push({
        label: userFilters.algorithm,
        onRemoveFilter: getRemoveFilter(Fields.algorithm),
      });
    }

    if (timeFilter) {
      arr.push({
        label: timeFilter,
        onRemoveFilter: getRemoveFilter(Fields.from, Fields.to),
      });
    }

    return arr.length > 0 ? arr : null;
  }, [userFilters, getRemoveFilter, timeFilter]);

  const request = useMemo(
    () => ({
      feed: ContextTypes.alerts,
      start: userFilters.start,
      end: userFilters.end,
      size: 1000,
      include: includeFields,
      ...StatsRequest.makeSearch({
        search: filters.nql,
        intersect: filters.intersect,
        andSearch: nqlLang.and(
          nqlLang.equal('algorithm', userFilters.algorithm),
          nqlLang.equal('ipinfo.ip', userFilters.ip),
          userFilters.ip && userFilters.score
            ? nqlLang.or(
                nqlLang.greater('ndm_score_threat', '0'),
                nqlLang.greater('ndm_score_confidence', '0'),
              )
            : '',
        ),
      }),
      customers: filters.customers,
      sort: {
        field: 'start',
        order: 'desc',
      },
    }),
    [
      userFilters.start,
      userFilters.end,
      userFilters.algorithm,
      userFilters.ip,
      JSON.stringify(filters.nql),
      JSON.stringify(filters.intersect),
      JSON.stringify(filters.customers),
      includeFields,
      refresher,
    ],
  );

  const { isFetching, records } = useRealtimeOrRequest({
    name: eventSearchId,
    socketOptions: request,
    stopRequest: !includeFields.length,
    refresher: filters.refresher,
  });

  useLoadingIndicator(isFetching);

  const data = useMemo(() => records?.toArray() || [], [records]);

  const onColumnsChange = useEvent(
    (allColumns, hiddenColumns, technicalColumns) => {
      const fieldsSet = new Set(
        allColumns
          .map((item) => {
            if (item.realAccessor) {
              return item.realAccessor;
            }
            return typeof item.accessor === 'string' ? item.accessor : item.id;
          })
          .flat()
          .map((field) => {
            if (field.startsWith('label.ip')) {
              return 'label.ip';
            }
            if (field.startsWith('label.port')) {
              return 'label.port';
            }
            return field;
          }),
      );
      // remove hidden columns
      hiddenColumns.forEach((item) => {
        fieldsSet.delete(item);
      });
      // remove technical columns
      technicalColumns.forEach((item) => {
        fieldsSet.delete(item);
      });
      // add id and timestamp - we need it to fetch full record if we need it
      fieldsSet.add('id');
      fieldsSet.add('timestamp');
      if (customer?.multi_account) {
        fieldsSet.add('customer');
      }
      const nextValue = [...fieldsSet].filter(Boolean);
      setIncludeFields((prevValue) =>
        isEqual(prevValue, nextValue) ? prevValue : nextValue,
      );
    },
  );

  return (
    <EventTable
      id={tableIdEventList}
      data={data}
      columns={eventListColumns}
      noDataText={records ? 'There are no events.' : lang.loading}
      onColumnsChange={onColumnsChange}
      exportingAllFields={false}
      externalFilters={externalTableFilters}
      fillWithEmptyRows
      onClearExternalFilters={onClearExternalFilters}
    />
  );
};

EventListTab.propTypes = {
  userFilters: PropTypes.shape({
    start: PropTypes.number,
    end: PropTypes.number,
    algorithm: PropTypes.string,
    ip: PropTypes.string,
    score: PropTypes.bool,
  }).isRequired,
  refresher: PropTypes.any,
  timeFilter: PropTypes.string,
};

EventListTab.defaultProps = {
  refresher: null,
  timeFilter: null,
};

export default EventListTab;
