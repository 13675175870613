import PropTypes from '+prop-types';
import { memo, useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMeasure } from 'react-use';

import styled from 'styled-components';

import Stack from '@mui/material/Stack';

import { ContextTypes } from '@/models/ContextTypes';
import { MetricSettings } from '@/models/MetricSettings';
import StatsRequest from '@/models/StatsRequest';

import { selectors as customerSelectors } from '@/redux/api/customer';
import {
  actions as thresholdActions,
  selectors as thresholdSelectors,
} from '@/redux/api/thresholder';
import { selectors as globalFiltersSelectors } from '@/redux/globalFilters';

import SeriesTypes from '+components/charts/common/SeriesTypes';
import SparklineChart from '+components/charts/SparklineChart';
import { NqlCodeBlock } from '+components/form/NqlTextField';
import { Col, LayoutTypes, Row } from '+components/Layout';
import { usePageTabs } from '+components/PageTabs';
import UniversalField from '+components/UniversalField';
import useLoadingIndicator from '+hooks/useLoadingIndicator';
import useStatsRequest from '+hooks/useStatsRequest';
import { convertThresholdsValuesToSeries } from '+utils/convertThresholdsValuesToSeries';
import dayjs from '+utils/dayjs';

const ChartContainer = styled(Stack)`
  .highcharts-xaxis {
    display: none;
  }

  .highcharts-yaxis-labels > text:not(#fake-id) {
    font-size: 14px;
    font-weight: 700;
    fill: ${({ theme }) => theme.colorText} !important;
    -webkit-font-smoothing: antialiased;

    & > tspan {
      font-weight: 400;
    }
  }
`;

export const ThresholdFuncs = {
  sum: 'sum',
  max: 'max',
  maxin: 'max',
  min: 'min',
  minin: 'min',
  cnt: 'count',
  count: 'count',
  cntin: 'count',
  countin: 'count',
  avg: 'average',
  avgin: 'average',
  average: 'average',
  averagein: 'average',
  div: 'divide',
  divide: 'divide',
  multiply: 'multiply',
  product: 'multiply',
};

const windowFunctions = new Set([
  'maxin',
  'minin',
  'avgin',
  'averagein',
  'countin',
  'cntin',
]);

const getFlowMetric = (isRate) => (metric) => {
  let value = MetricSettings[ContextTypes.flow].card;
  switch (metric) {
    case MetricSettings[ContextTypes.flow].bits.key:
    case 'bitsxrate':
      value = isRate
        ? MetricSettings[ContextTypes.flow].bitrate
        : MetricSettings[ContextTypes.flow].bits;
      break;
    case MetricSettings[ContextTypes.flow].packets.key:
    case 'packetsxrate':
      value = isRate
        ? MetricSettings[ContextTypes.flow].packetrate
        : MetricSettings[ContextTypes.flow].packets;
      break;
    case 'flow':
    case 'record':
      value = MetricSettings[ContextTypes.flow].flows;
      break;
    case 'count':
      value = isRate
        ? MetricSettings[ContextTypes.flow].flowrate
        : MetricSettings[ContextTypes.flow].flows;
      break;
    default:
      value = MetricSettings[ContextTypes.flow][metric] || value;
      break;
  }

  return value.key || value;
};

const getDnsMetric = (isRate) => (metric) => {
  let value = MetricSettings[ContextTypes.dns].card;
  switch (metric) {
    case MetricSettings[ContextTypes.dns].queries.key:
    case MetricSettings[ContextTypes.dns].queryrate.key:
    case 'count':
      value = isRate
        ? MetricSettings[ContextTypes.dns].queryrate
        : MetricSettings[ContextTypes.dns].queries;
      break;
    case MetricSettings[ContextTypes.dns].answers.key:
    case MetricSettings[ContextTypes.dns].avganswers.key:
      value = isRate
        ? MetricSettings[ContextTypes.dns].avganswers
        : MetricSettings[ContextTypes.dns].answers;
      break;
    case 'record':
      value = MetricSettings[ContextTypes.dns].queries;
      break;
    default:
      value = MetricSettings[ContextTypes.dns][metric] || value;
      break;
  }

  return value.key || value;
};

const getMetricDefault = () => (metric) => {
  return metric;
};

const getMetricFns = {
  [ContextTypes.flow]: getFlowMetric,
  [ContextTypes.dns]: getDnsMetric,
};

const getMetrics = (context, metrics, func) => {
  const fn = ThresholdFuncs[func];
  const isRate = fn === ThresholdFuncs.avg;
  const isWindow = windowFunctions.has(func);
  const getFn = getMetricFns[context] || getMetricDefault;

  return (metrics || []).slice(+isWindow).map(getFn(isRate));
};

const autoThresholdTrack = 'default';

const ThresholdChart = memo((props) => {
  const dispatch = useDispatch();
  const { event, threshold, colorIndex } = props;

  const context = event.traffictype || ContextTypes.flow;

  const seriesId = `threshold-${btoa(threshold?.Expr)}`;
  const [, activePageTab] = usePageTabs();
  const customer = useSelector(customerSelectors.getCurrentCustomer);
  const autoRefresh = useSelector(
    globalFiltersSelectors.getAutoRefresh(activePageTab?.id),
  );

  const isAutoThreshold = event?.tdm?.auto_threshold;

  const [ref, { width }] = useMeasure();
  const [hackMarginRef, { width: marginLeft }] = useMeasure();

  const autoThresholdValues = useSelector(
    thresholdSelectors.getThresholdValues(event.tdm?.name, autoThresholdTrack),
  );

  const period = useMemo(() => {
    const now = Math.floor(Date.now() / 1000);
    const start = event?.start || now;
    const end = event?.end;

    return {
      start: (start - 120) * 1000,
      end: end ? Math.min(end + 120, now) * 1000 : undefined,
    };
  }, [event?.start, event?.end]);

  const request = useMemo(() => {
    const nql = event?.search;
    const isSubAccountRecord =
      event?.customer && event?.customer !== customer?.shortname;

    return {
      seriesId,
      params: {
        ...period,
        series: getMetrics(context, threshold?.Metric, threshold?.Func).map(
          (metric) => ({
            context,
            name: metric,
            metric,
            ...StatsRequest.makeSearch({
              search: nql,
            }),
            ...(metric === MetricSettings[context].card.key
              ? {
                  field: threshold?.Metric?.[0] ? [threshold?.Metric?.[0]] : [],
                }
              : {}),
          }),
        ),
        ...(isSubAccountRecord && { customers: [event?.customer] }),
      },
    };
  }, [seriesId, event, threshold, customer?.shortname, context, period]);

  const { series, isFetching, pollingHeartbeat } = useStatsRequest({
    context,
    requestType: StatsRequest.Types.ts,
    request,
    stopPollingHeartbeat: !!event?.end || !autoRefresh || !threshold,
  });

  const extendedSeries = useMemo(() => {
    if (!series?.length) {
      return series;
    }

    let result = [...series];

    if (result.length > 1) {
      let name;
      switch (ThresholdFuncs[threshold?.Func]) {
        case ThresholdFuncs.divide:
          name = `${result[0].name} / ${result[1].name}`;
          result = [
            {
              ...result[0],
              data: (result[0].data || []).map((d, i) => [
                d[0],
                d[1] / (result[1].data[i][1] || 1),
              ]),
              name,
              label: name,
              series: name,
            },
          ];
          break;
        case ThresholdFuncs.multiply:
          name = `${result[0].name} * ${result[1].name}`;
          result = [
            {
              ...result[0],
              data: (result[0].data || []).map((d, i) => [
                d[0],
                d[1] * result[1].data[i][1],
              ]),
              name,
              label: name,
              series: name,
            },
          ];
          break;
        default:
          break;
      }
    }

    if (colorIndex != null) {
      result[0] = {
        ...result[0],
        colorIndex,
      };
    }

    const thresholdSeries = {
      ...result[0],
      name: 'threshold',
      label: 'Threshold',
      series: 'threshold',
      data: (result[0].data || []).map((d) => [d[0], threshold?.Value || 0]),
      colorIndex: 6,
      seriesType: SeriesTypes.line,
      seriesProps: {
        step: 'left',
      },
    };

    if (
      thresholdSeries.data[0] &&
      event?.tdm?.auto_threshold &&
      autoThresholdValues
    ) {
      const atSeries = convertThresholdsValuesToSeries(
        autoThresholdValues,
        event?.severity,
      );

      if (atSeries?.data?.length) {
        // the min date on ts collection
        const min = thresholdSeries.data[0][0];
        // find the last index of the atSeries that is less than or equal to the min date
        let current = atSeries.data.findLastIndex((d) => d[0] <= min);

        thresholdSeries.data = thresholdSeries.data.flatMap(([ts], idx) => {
          let item = atSeries.data[current];
          const next = atSeries.data[current + 1];

          const newData = [];

          // if the current ts is greater than the next ts, move to the next
          if (next && ts >= next[0]) {
            current += 1;
            next[1] += 100 * (idx + 1);
            if (ts !== next[0]) {
              newData.push([next[0], next[1]]);
            }
            item = next;
          }

          newData.push([ts, item[1]]);

          return newData;
        });
      }
    }

    result.push(thresholdSeries);

    return result;
  }, [
    event?.tdm?.auto_threshold,
    series,
    colorIndex,
    threshold?.Value,
    threshold?.Func,
    autoThresholdValues,
    event?.severity,
  ]);

  const mapExtents = useMemo(() => {
    const peak = (extendedSeries?.[0]?.data || [])?.reduce(
      ([min, max], [, value]) => [Math.min(min, value), Math.max(max, value)],
      [Infinity, -Infinity],
    );

    const expected = (extendedSeries?.[1]?.data || []).reduce(
      ([min, max], [, value]) => [Math.min(min, value), Math.max(max, value)],
      [Infinity, -Infinity],
    );

    const extents = [
      Math.min(peak[1], expected[0]),
      Math.max(peak[1], expected[1]),
    ];

    return {
      min: extents[0] === expected[0] ? 'Threshold' : 'Peak',
      max: extents[1] === expected[1] ? 'Threshold' : 'Peak',
      extents,
    };
  }, [extendedSeries]);

  const tickPositioner = useCallback(
    () => () => mapExtents.extents,
    [mapExtents],
  );

  const tickYLabelFormat = useCallback(
    (item, prepared) => {
      const prop = mapExtents.extents[0] === item.value ? 'min' : 'max';
      return `${mapExtents[prop]}: <span>${prepared}</span>`;
    },
    [mapExtents],
  );

  const overrides = useMemo(
    () => ({
      chart: {
        marginTop: 10,
        marginLeft: Math.max(marginLeft + 4, 50),
      },
    }),
    [marginLeft],
  );

  useEffect(() => {
    const algorithmName = event?.tdm?.name;

    if (algorithmName) {
      dispatch(
        thresholdActions.fetchValuesByTrack({
          modelName: algorithmName,
          track: autoThresholdTrack,
          to: period.end || Date.now(),
          // Max Learning Window is 1 day, so
          from: +dayjs(period.start).subtract(25, 'hours'),
        }),
        algorithmName,
      );
    }

    return () => {
      if (algorithmName) {
        dispatch(thresholdActions.cancel(algorithmName));
      }
    };
  }, [event?.tdm?.name, pollingHeartbeat]);

  useLoadingIndicator(isFetching);

  return (
    <Row>
      <Col $type={LayoutTypes.cardContent}>
        <Row minHeight={152}>
          <Col xs={12} item container={false}>
            <ChartContainer ref={ref} py={2}>
              {!!width && (
                <SparklineChart
                  context={context}
                  // fields={threshold.fields}
                  series={extendedSeries}
                  loading={isFetching}
                  width={width}
                  height={120}
                  showMinMax
                  overrides={overrides}
                  tickPositioner={tickPositioner}
                  tickYLabelFormat={tickYLabelFormat}
                  allowFullscreen={false}
                />
              )}
            </ChartContainer>
          </Col>
        </Row>
        <Row $type={LayoutTypes.field}>
          <Col $type={LayoutTypes.fieldName} xs={4} ref={hackMarginRef}>
            Threshold:
          </Col>
          <Col $type={LayoutTypes.fieldValue} xs={8}>
            <NqlCodeBlock $interactive $transparent $inline>
              {threshold?.Expr}
            </NqlCodeBlock>
          </Col>
        </Row>
        <Row $type={LayoutTypes.field} alignItems="center">
          <Col $type={LayoutTypes.fieldName} xs={4}>
            Auto Thresholding:
          </Col>
          <Col $type={LayoutTypes.fieldValue} xs={8}>
            {isAutoThreshold ? 'Enabled' : 'Disabled'}
          </Col>
        </Row>
        <Row $type={LayoutTypes.field}>
          <Col $type={LayoutTypes.fieldName} xs={4}>
            Track by:
          </Col>
          <Col $type={LayoutTypes.fieldValue} xs={8}>
            <UniversalField
              field="track_by"
              value={event?.track_by}
              original={event}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
});

ThresholdChart.displayName = 'ThresholdChart';

ThresholdChart.propTypes = {
  event: PropTypes.shape().isRequired,
  threshold: PropTypes.shape().isRequired,
  colorIndex: PropTypes.number,
};

ThresholdChart.defaultProps = {
  colorIndex: undefined,
};

export default ThresholdChart;
