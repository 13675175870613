import PropTypes from '+prop-types';
import { Fragment, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import debounce from 'lodash.debounce';

import PermissionModel from '@/models/Permission';

import {
  actions as ipLabelsActions,
  selectors as ipLabelsSelectors,
} from '@/redux/api/labels/ips';
import { actions, selectors } from '@/redux/api/nql-complete';

import { Field, useFormState } from '+components/form/FinalForm';
import { Group, Label } from '+components/form/FormField';
import MultiSelectField from '+components/form/MultiSelectField';
import {
  normalizeMultiSelectValue,
  normalizeSelectValue,
} from '+components/form/Normalizers';
import Plaintext from '+components/form/Plaintext';
import SelectField from '+components/form/SelectField';
import TextField from '+components/form/TextField';
import {
  validateIp,
  validateIps,
  validateLabelContext,
  validateLabelContexts,
  validateLabels,
  validateRequired,
} from '+components/form/Validators';
import FormModal, { defaultProps, propTypes } from '+components/FormModal';
import ContextNameLabel from '+components/Labels/ContextNameLabel';
import usePermissions from '+hooks/usePermissions';
import makeArr from '+utils/makeArr';

const contextValidators = [validateRequired, validateLabelContext];
const contextsValidators = [validateRequired, validateLabelContexts];
const labelsValidators = [validateRequired, validateLabels];
const ipValidators = [validateRequired, validateIp];
const ipsValidators = [validateRequired, validateIps];

const FormBody = (props) => {
  const { canManage, labelContexts } = props;

  const dispatch = useDispatch();

  const { values: formValues } = useFormState({
    subscription: { values: true },
  });

  const suggestionsIds = useMemo(
    () => makeArr(formValues.context).map((item) => `ipLabels_${item}`),
    [formValues.context],
  );

  const suggestionsData = useSelector(
    selectors.getMultipleSuggestionsData(suggestionsIds),
  );

  const labelContextsOptions = useMemo(() => {
    const options = (labelContexts || [])
      .reduce((acc, item) => {
        if (!item.alias && item.value !== 'name') {
          acc.push({ value: item.value, label: item.value });
        }
        return acc;
      }, [])
      .sort((a, b) => a.value.localeCompare(b.value));
    options.unshift({ value: 'name', label: 'name' });
    return options;
  }, [labelContexts]);

  const labelSuggestions = useMemo(
    () =>
      suggestionsData?.reduce(
        (acc, item) => [...acc, ...(item?.suggestions || [])],
        [],
      ),
    [suggestionsData],
  );

  const onLabelInputChange = useMemo(
    () =>
      debounce((_, value) => {
        suggestionsIds.forEach((suggestionsId) => {
          const context = suggestionsId.split('_')[1];
          if (!context) {
            return;
          }
          const text = `label.ip.${context} == ${value}`;
          const caretPos = text.length;
          const fieldType = 'flow';
          dispatch(actions.cancel(suggestionsId));
          dispatch(
            actions.fetchSuggestions(
              {
                id: suggestionsId,
                text,
                caretPos,
                fieldType,
                takeEvery: true,
              },
              suggestionsId,
            ),
          );
        });
      }, 300),
    [suggestionsIds],
  );

  useEffect(
    () => () => {
      dispatch(actions.clearMultipleSuggestions(suggestionsIds));
    },
    [suggestionsIds],
  );

  return (
    <Fragment>
      {formValues.id ? (
        <Fragment>
          <Group>
            <Label>IP Address</Label>
            <Plaintext>{formValues.ip}</Plaintext>
          </Group>

          <Group>
            <Label>Context</Label>
            <Plaintext>
              <ContextNameLabel>{formValues.context}</ContextNameLabel>
            </Plaintext>
          </Group>
        </Fragment>
      ) : (
        <Fragment>
          {Array.isArray(formValues.ip) ? (
            <Field
              name="ip"
              label="IP Addresses"
              placeholder="+ Add IP address"
              component={MultiSelectField}
              parse={normalizeMultiSelectValue}
              validate={ipsValidators}
              disabled={!canManage}
              allowCreate
              required
            />
          ) : (
            <Field
              name="ip"
              label="IP Address"
              component={TextField}
              validate={ipValidators}
              disabled={!canManage}
              required
            />
          )}

          {Array.isArray(formValues.context) ? (
            <Field
              name="context"
              label="Contexts"
              placeholder="+ Add context"
              options={labelContextsOptions}
              component={MultiSelectField}
              parse={normalizeMultiSelectValue}
              validate={contextsValidators}
              helperText="Allowed characters: a-z A-Z 0-9 _ -"
              disabled={!canManage}
              allowCreate
              required
            />
          ) : (
            <Field
              name="context"
              label="Context"
              options={labelContextsOptions}
              component={SelectField}
              parse={normalizeSelectValue}
              validate={contextValidators}
              helperText="Allowed characters: a-z A-Z 0-9 _ -"
              disabled={!canManage}
              freeSolo
              required
            />
          )}
        </Fragment>
      )}

      <Field
        name="labels"
        label="Labels"
        placeholder="+ Add label"
        options={labelSuggestions}
        component={MultiSelectField}
        parse={normalizeMultiSelectValue}
        validate={labelsValidators}
        disabled={!canManage}
        helperText="Maximum length: 80. Allowed characters: a-z A-Z 0-9 . _ - # ~ : ( ) /"
        allowCreate
        required
        optionsLimit={100}
        onInputChange={onLabelInputChange}
      />
    </Fragment>
  );
};

FormBody.propTypes = {
  canManage: PropTypes.bool,
  labelContexts: PropTypes.arrayOf(PropTypes.shape({})),
  ipLabels: PropTypes.shape({}),
};

FormBody.defaultProps = {
  canManage: false,
  labelContexts: [],
  ipLabels: {},
};

const IpLabelForm = (props) => {
  const { initialValues, ...tail } = props;

  const dispatch = useDispatch();

  const labelContexts = useSelector(ipLabelsSelectors.getContexts);

  const permissions = usePermissions(PermissionModel.Resources.label.value);
  const canManage = initialValues.id
    ? permissions?.update
    : permissions?.create;

  useEffect(() => {
    if (!labelContexts?.length) {
      dispatch(ipLabelsActions.fetchContexts());
    }
  }, [labelContexts?.length]);

  return (
    <FormModal
      {...tail}
      mode={initialValues.id ? 'edit' : 'add'}
      item={initialValues.id ? `label for ${initialValues.ip}` : 'new label'}
      initialValues={initialValues}
      disabled={!canManage}
      deleteButtonText="Delete Label"
      deleteButtonHidden={!initialValues.id}
      deleteButtonDisabled={!permissions?.delete}
    >
      <FormBody canManage={canManage} labelContexts={labelContexts} />
    </FormModal>
  );
};

IpLabelForm.propTypes = propTypes;
IpLabelForm.defaultProps = defaultProps;

export default IpLabelForm;
