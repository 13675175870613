import PropTypes from '+prop-types';
import { memo } from 'react';

import styled from 'styled-components';

import Stack from '@mui/material/Stack';

import { CardMixin, LayoutSizes } from '+components/Layout';
import { PageTitle } from '+components/Layout/PageTitle';
import UniversalField from '+components/UniversalField';

const severityColor = ({ theme, $severity }) =>
  theme.severity[$severity] || theme.severity.unknown;

const Severity = styled.div`
  font-weight: 700;
  font-size: 14px;
`;

const TitleContainer = styled.div`
  display: flex;
  gap: 10px;
  justify-content: space-between;
  align-items: center;
  flex: 1 1 0;

  & div:not(${Severity}),
  & h1 {
    color: black;
  }

  ${CardMixin};
  padding: 0 10px;

  [class^='MultipleItemsWrapper__Container'] {
    min-width: unset;
  }

  background: linear-gradient(
    90deg,
    ${severityColor} 20.06%,
    ${({ theme }) => theme.cardBackgroundSecondaryColor} 80%
  );
`;

export const Title = memo((props) => {
  const { severity, children, icon } = props;

  return (
    <TitleContainer $severity={severity}>
      <Stack direction="row" alignItems="center" gap="8px">
        {icon}
        <PageTitle mr={0}>{children}</PageTitle>
      </Stack>
      {severity && (
        <Stack direction="row" gap={LayoutSizes.groupGap} alignItems="center">
          <UniversalField field="severity" value={severity} />
          <Severity>Severity</Severity>
        </Stack>
      )}
    </TitleContainer>
  );
});

Title.displayName = 'Title';

Title.propTypes = {
  children: PropTypes.children.isRequired,
  severity: PropTypes.string,
  icon: PropTypes.node,
};
Title.defaultProps = {
  severity: undefined,
  icon: undefined,
};
