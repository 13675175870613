/* eslint-disable react/prop-types,react/no-array-index-key */
import { useMemo } from 'react';

import capitalize from 'lodash.capitalize';
import styled from 'styled-components';

import ContentCopyIcon from 'mdi-react/ContentCopyIcon';
import GearIcon from 'mdi-react/GearIcon';
import ThumbUpIcon from 'mdi-react/ThumbUpIcon';

import { ContextTypes } from '@/models/ContextTypes';

import {
  CellNqlSearch,
  MultipleItemsWrapper,
  UniversalCell,
} from '+components/Table/Cells';
import {
  BaseColumnFactory,
  EnabledDisabledColumnFactory,
  MenuColumnFactory,
  RowSelectorColumnFactory,
  TimestampColumnFactory,
  ToggleColumnFactory,
  TrafficColumnFactory,
} from '+components/Table/Columns';
import {
  BooleanColumnFilter,
  SelectColumnFilter,
} from '+components/Table/Filters';
import { someOfFieldsFilter } from '+components/Table/FilterTypeFactories';
import Tag from '+components/Tag';
import Tooltip from '+components/Tooltip';
import dayjs from '+utils/dayjs';
import { getColumnsHelper } from '+utils/getColumnsHelper';

const TooltipContent = styled.div`
  display: flex;
  align-items: center;
  .mdi-icon + span {
    margin-left: 4px;
  }
`;

export const Columns = {
  rowSelector: 'rowSelector',
  system: 'system',
  recommended: 'recommended',
  systemdefault: 'systemdefault',
  name: 'name',
  categories: 'categories',
  search_by: 'search_by',
  thresholds: 'thresholds',
  track_by: 'track_by',
  beta: 'beta',
  bypassrule: 'bypassrule',
  created: 'created',
  updated: 'updated',
  algoType: 'algo_type',
  algoRecordType: 'algo_record_type',
  enabled: 'enabled',
  menu: 'menu',
  autoThresholds: 'autoThresholds',
};

const allTypes = 'all_types';

const columnsCollection = ({
  categories,
  permissions,
  cxActionMenu,
  onToggleEnabled,
  onToggleBypassRule,
}) => ({
  [Columns.rowSelector]: RowSelectorColumnFactory(),
  [Columns.system]: BaseColumnFactory({
    accessor: 'system',
    Header: <GearIcon size={14} />,
    Description: 'System',
    minWidth: 60,
    maxWidth: 60,
    getCellProps: () => ({ style: { justifyContent: 'center' } }),
    disableResizing: true,
    sortType: 'boolean',
    Cell: ({ value }) =>
      useMemo(() => {
        if (!value) {
          return null;
        }
        const cellContent = <GearIcon size={14} />;
        const tooltipContent = (
          <TooltipContent>
            {cellContent}
            <span>System Model</span>
          </TooltipContent>
        );
        return (
          <Tooltip title={tooltipContent}>
            <span
              style={{
                display: 'flex',
                justifyContent: 'center',
                cursor: 'pointer',
              }}
            >
              {cellContent}
            </span>
          </Tooltip>
        );
      }, [value]),
    Filter: BooleanColumnFilter({
      true: 'System',
      false: 'Custom',
    }),
    filter: 'booleanFilter',
  }),
  [Columns.recommended]: BaseColumnFactory({
    accessor: (original) => original.system && original.recommended,
    Header: <ThumbUpIcon size={14} />,
    Description: 'Recommended',
    minWidth: 60,
    maxWidth: 60,
    getCellProps: () => ({ style: { justifyContent: 'center' } }),

    disableResizing: true,
    sortType: 'boolean',

    Cell: ({ value }) =>
      useMemo(() => {
        if (!value) {
          return null;
        }
        const cellContent = <ThumbUpIcon size={14} />;
        const tooltipContent = (
          <TooltipContent>
            {cellContent}
            <span>Recommended</span>
          </TooltipContent>
        );
        return (
          <Tooltip title={tooltipContent}>
            <span
              style={{
                display: 'flex',
                justifyContent: 'center',
                cursor: 'pointer',
              }}
            >
              {cellContent}
            </span>
          </Tooltip>
        );
      }, [value]),
    Filter: BooleanColumnFilter({
      true: 'Recommended',
      false: 'Default',
    }),
    filter: 'booleanFilter',
  }),
  [Columns.systemdefault]: BaseColumnFactory({
    accessor: (original) => (original.system ? !original.systemdefault : false),
    Header: <ContentCopyIcon size={14} />,
    Description: 'Customized',
    minWidth: 60,
    maxWidth: 60,
    getCellProps: () => ({ style: { justifyContent: 'center' } }),

    disableResizing: true,
    sortType: 'boolean',

    Cell: ({ value }) =>
      useMemo(() => {
        if (!value) {
          return null;
        }
        const cellContent = <ContentCopyIcon size={14} />;
        const tooltipContent = (
          <TooltipContent>
            {cellContent}
            <span>Customized</span>
          </TooltipContent>
        );
        return (
          <Tooltip title={tooltipContent}>
            <span
              style={{
                display: 'flex',
                justifyContent: 'center',
                cursor: 'pointer',
              }}
            >
              {cellContent}
            </span>
          </Tooltip>
        );
      }, [value]),
    Filter: BooleanColumnFilter({
      true: 'Customized',
      false: 'Default',
    }),
    filter: 'booleanFilter',
  }),
  [Columns.name]: BaseColumnFactory({
    accessor: 'name',
    Header: 'Name / Desc',
    width: 220,
    getCellProps: () => ({ style: { whiteSpace: 'unset' } }),
    Cell: ({ row }) =>
      UniversalCell('tdm')({
        value: {
          id: row?.original?.id,
          name: row?.original?.name,
          description: row?.original?.description,
        },
        options: {
          isNew:
            row?.original &&
            dayjs
              .unix(row.original.created)
              .isAfter(dayjs().subtract(row.original.system + 1, 'week')),
        },
      }),
    realAccessor: ['name', 'description'],
    filter: someOfFieldsFilter(['name', 'description']),
  }),
  [Columns.categories]: BaseColumnFactory({
    accessor: Columns.categories,
    Header: 'Categories',
    width: 250,
    getCellProps: () => ({ style: { whiteSpace: 'unset' } }),
    Cell: UniversalCell(Columns.categories),
    Filter: SelectColumnFilter({
      optionValueExtractor: (row, id) => {
        const options = row.values[id].reduce((acc, item) => {
          if (acc[item]) {
            return acc;
          }
          const { description } = categories?.[item] || {};
          return {
            ...acc,
            [item]: {
              value: item,
              label: item,
              description,
            },
          };
        }, {});
        return Object.values(options);
      },
      sort: ({ label: a }, { label: b }) => (a || '').localeCompare(b || ''),
    }),
    filter: 'selectFilter',
  }),
  [Columns.search_by]: BaseColumnFactory({
    accessor: (original) =>
      original?.search_by?.map(({ type }) =>
        type === 'all' ? allTypes : type,
      ),
    Header: 'NQL Search',
    Cell: ({ value, row: { original } }) =>
      useMemo(
        () => (
          <MultipleItemsWrapper gap={5}>
            {(value || []).map((val, idx) => (
              <CellNqlSearch
                key={`search_by-${idx}`}
                type={val === allTypes ? 'all' : val}
                context={original?.algo_record_type}
              />
            ))}
          </MultipleItemsWrapper>
        ),
        [value],
      ),
    Filter: SelectColumnFilter({
      optionLabel: (key) => {
        if (key === 'all') {
          return 'All';
        }
        if (key === allTypes) {
          return 'all types';
        }
        return key;
      },
    }),
    filter: 'selectFilter',
  }),
  [Columns.thresholds]: BaseColumnFactory({
    accessor: (original) => [
      ...new Set(
        (original?.thresholds || [])
          .map((track) => track?.severity)
          .filter(Boolean),
      ),
    ],
    Header: 'Thresholds',
    Cell: UniversalCell('severity'),
    Filter: SelectColumnFilter({
      fixedOptions: ['all', 'high', 'medium', 'low'],
    }),
    filter: 'selectFilter',
  }),
  [Columns.track_by]: BaseColumnFactory({
    accessor: (original) =>
      (original?.track_by || []).flatMap((track) => track),
    Header: 'Track',
    width: 120,
    getCellProps: () => ({ style: { justifyContent: 'center' } }),
    Cell: ({ value }) =>
      useMemo(
        () => (
          <MultipleItemsWrapper>
            {value.map((item, index) => (
              <span key={`${item}_${index}`}>{item}</span>
            ))}
          </MultipleItemsWrapper>
        ),
        [value],
      ),
    Filter: SelectColumnFilter({
      optionLabel: (key) => (key === 'all' ? 'All' : key),
    }),
    filter: 'selectFilter',
  }),
  [Columns.beta]: BaseColumnFactory({
    accessor: Columns.beta,
    Header: 'Beta',
    minWidth: 80,
    maxWidth: 80,
    getCellProps: () => ({ style: { justifyContent: 'center' } }),

    disableResizing: true,
    sortType: 'boolean',

    Cell: ({ value }) =>
      useMemo(() => {
        if (!value) {
          return null;
        }
        return (
          <Tag outlined={false} color="warning">
            Beta
          </Tag>
        );
      }, [value]),
    Filter: BooleanColumnFilter({
      true: 'Beta',
      false: 'Non Beta',
    }),
    filter: 'booleanFilter',
  }),
  [Columns.created]: TimestampColumnFactory({
    accessor: 'created',
    Header: 'Created Date',
    width: 160,
  }),
  [Columns.updated]: TimestampColumnFactory({
    accessor: 'updated',
    Header: 'Updated Date',
    width: 160,
  }),
  [Columns.algoType]: BaseColumnFactory({
    accessor: (original) => {
      if (original.algo_type === 'CDM') {
        return 'context';
      }

      return 'detection';
    },
    Header: 'Type',
    width: 100,
    Cell: ({ value }) => capitalize(value),
    Filter: SelectColumnFilter({
      fixedOptions: ['all', 'context', 'detection'],
    }),
    filter: 'selectFilter',
  }),
  [Columns.algoRecordType]: TrafficColumnFactory({
    // eslint-disable-next-line camelcase
    accessor: ({ algo_record_type }) => algo_record_type || ContextTypes.flow,
    Header: 'Traffic',
  }),
  [Columns.enabled]: ToggleColumnFactory({
    getDisabled: () => !permissions?.update,
    onToggle: onToggleEnabled,
  }),
  [Columns.bypassrule]: ToggleColumnFactory({
    Header: 'Bypass Policies',
    accessor: 'bypassrule',
    getDisabled: (original) =>
      original.algo_type !== 'TDM' || !permissions?.update,
    onToggle: onToggleBypassRule,
  }),
  [Columns.autoThresholds]: EnabledDisabledColumnFactory({
    accessor: 'autothreshold',
    Header: 'Auto Thresholding',
  }),
  [Columns.menu]: MenuColumnFactory({ cxActionMenu }),
});

export const getColumns = getColumnsHelper(columnsCollection);
