/* eslint-disable camelcase */
import PropTypes from '+prop-types';
import { Fragment, useCallback, useContext, useMemo } from 'react';
import { Link } from 'react-router-dom';

import EyeCircleIcon from 'mdi-react/EyeCircleIcon';
import EyeCircleOutlineIcon from 'mdi-react/EyeCircleOutlineIcon';

import RoutePaths from '@/models/RoutePaths';

import Alert from '+components/Alert';
import Avatar from '+components/Avatar';
import Button, { ButtonVariants } from '+components/Button';
import FinalForm from '+components/form/FinalForm';
import Tag from '+components/Tag';
import Tooltip from '+components/Tooltip';
import dayjs, { DateFormat } from '+utils/dayjs';

import Context from '../Context';
import ActivityContainer from './components/ActivityContainer';
import AvatarContainer from './components/AvatarContainer';
import Col from './components/Col';
import Container from './components/Container';
import Email from './components/Email';
import EmailContainer from './components/EmailContainer';
import FormBody from './components/FormBody';
import FormContainer from './components/FormContainer';
import Name from './components/Name';
import Value from './components/Value';

const unknown = 'N/A';

const ProfileSection = (props) => {
  const { onSendVerificationEmail, onUpdate, isFetching } = props;

  const {
    customer = {},
    profile = {},
    user,
    roles,
    isSelf,
    canManage,
    canManageRole,
    canRemove,
    onDelete,
  } = useContext(Context);

  const {
    email,
    email_verified,
    user_metadata = {},
    app_metadata = {},
    name,
    idp,
  } = user || {};

  const roleItem = useMemo(
    () => roles?.[user?.roles?.[0]],
    [roles, user?.roles],
  );

  const onProfileUpdate = useCallback(
    (values) => {
      if (onUpdate) {
        onUpdate(values);
      }
    },
    [onUpdate],
  );

  const isCustomerUserInMasquerade =
    app_metadata.original && app_metadata.original === customer.shortname;
  const isSupportUserInMasquerade =
    app_metadata.original && app_metadata.original !== customer.shortname;
  const isControlledByIdp = idp || app_metadata.useResellerSso;

  const { created_at: createdAt, lastLogin, lastIp } = user;

  const profileCreatedDate = dayjs(createdAt).format(DateFormat.day);
  const profileLastLoginDate = lastLogin
    ? dayjs(lastLogin).format(DateFormat.second)
    : 'Never';

  return (
    <Container>
      {isSupportUserInMasquerade && (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <Fragment>
          {app_metadata.original === profile.app_metadata?.original ? (
            <Alert>
              Not allowed to manage profile while masquerading, return to the
              original organization ({app_metadata.original}) to edit.
            </Alert>
          ) : (
            <Alert>This user is outside of your organization.</Alert>
          )}
        </Fragment>
      )}

      {!isSupportUserInMasquerade && isControlledByIdp && (
        <Alert>
          User profile controlled by{' '}
          {app_metadata.useResellerSso ? 'reseller' : 'custom'} Identity
          Provider.
        </Alert>
      )}

      {email_verified === false && (
        <Alert severity="warning">
          <div style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
            <span>Email unverified</span>
            <Button
              style={{ height: 'unset' }}
              variant={ButtonVariants.link}
              onClick={onSendVerificationEmail}
              disabled={!canManage}
            >
              Resend Email Verification
            </Button>
          </div>
        </Alert>
      )}

      <Alert>
        <ActivityContainer>
          <Col>
            <Name>Member Since:</Name>
            <Value>{profileCreatedDate || unknown}</Value>
          </Col>
          <Col>
            <Name>Last Login IP:</Name>
            <Value>{lastIp || unknown}</Value>
          </Col>
          <Col>
            <Name>Last Login:</Name>
            <Value>{profileLastLoginDate || unknown}</Value>
          </Col>
        </ActivityContainer>
      </Alert>

      <FormContainer>
        <AvatarContainer>
          <Avatar name={name} src={user_metadata.picture} $size="100px" />
          {roleItem && (
            <Link to={`${RoutePaths.roles}/${roleItem.id}`}>
              <Tag color={roleItem.color} outlined={false}>
                {roleItem.label}
              </Tag>
            </Link>
          )}
          <EmailContainer className="profile__email">
            {isCustomerUserInMasquerade && (
              // Add icon to masquerading users in their parent company
              // @see: https://netography.atlassian.net/browse/PORTAL-1386
              <Tooltip title={`Logged in to: ${app_metadata.shortname}`}>
                <span className="icon">
                  <EyeCircleIcon size={16} />
                </span>
              </Tooltip>
            )}
            {isSupportUserInMasquerade && (
              // Add icon to masquerading users in child company
              <Tooltip title="Support User">
                <span className="icon">
                  <EyeCircleOutlineIcon size={16} />
                </span>
              </Tooltip>
            )}
            <Email href={`mailto:${email}`}>{email}</Email>
          </EmailContainer>
          {user.blocked && (
            <Tag className="profile__blocked-label" color="danger">
              Blocked
            </Tag>
          )}
        </AvatarContainer>

        <FinalForm
          component={FormBody}
          roles={roles}
          initialValues={user}
          isSelf={isSelf}
          canManage={canManage}
          canManageRole={canManageRole}
          canRemove={canRemove}
          onSubmit={onProfileUpdate}
          onDelete={onDelete}
          isFetching={isFetching}
        />
      </FormContainer>
    </Container>
  );
};

ProfileSection.propTypes = {
  onSendVerificationEmail: PropTypes.func,
  onUpdate: PropTypes.func,
  isFetching: PropTypes.bool.isRequired,
};

ProfileSection.defaultProps = {
  onSendVerificationEmail: null,
  onUpdate: null,
};

export default ProfileSection;
