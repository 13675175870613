import { css } from 'styled-components';

import {
  alpha,
  darken,
  decomposeColor,
  emphasize,
  getContrastRatio,
  getLuminance,
  hexToRgb,
  hslToRgb,
  lighten,
  recomposeColor,
  rgbToHex,
} from '@mui/material/styles';

import contextTypes from './slices/contextTypes';
import globalFiltersTheme from './slices/globalFilters';
import inputTheme from './slices/input';
import mapTheme from './slices/map';
import tableTheme from './slices/table';
import Palette from './palette';
import { Theme } from './util';

const colorTool = {
  alpha: (value, rate) =>
    value === 'transparent' ? value : alpha(value, rate),
  darken: (value, rate) =>
    value === 'transparent' ? value : darken(value, rate),
  lighten: (value, rate) =>
    value === 'transparent' ? value : lighten(value, rate),
  getContrastRatio,
  getLuminance,
  emphasize,
  hexToRgb,
  rgbToHex,
  hslToRgb,
  negate: (value) => {
    if (value === 'transparent') {
      return value;
    }

    let colorObject = decomposeColor(value);

    if (colorObject.type === 'hsl' || colorObject.type === 'hsla') {
      colorObject = decomposeColor(hslToRgb(value));
    }

    colorObject.values = colorObject.values.map((rate, index) =>
      index < 3 ? 255 - rate : rate,
    );

    return recomposeColor(colorObject);
  },
};

const sizes = {
  mobileS: 320,
  mobileM: 375,
  mobileL: 425,
  tablet: 768,
  laptop: 1024,
  laptopL: 1440,
  desktop: 2560,
};

const devices = Object.keys(sizes).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (max-width: ${sizes[label]}px) {
      ${css(...args)};
    }
  `;
  return acc;
}, {});

/**
 * Make a theme context root to pass to styled-component ThemeProvider.
 * @param theme Theme enum type to use to create theme context
 */
const makeThemeContext = (theme) => {
  const choose = (choiceObj) => choiceObj[theme];

  return {
    Palette,
    className: choose({
      dark: 'theme-dark',
      light: 'theme-light',
    }),
    name: theme,
    material: {
      palette: {
        mode: theme,
        primary: {
          main: choose({
            light: Palette.primaryLight,
            dark: Palette.primaryDark,
          }),
          contrastText: choose({
            light: '#FFFFFF',
            dark: '#2a2a2f',
          }),
        },
        info: {
          main: choose({
            light: Palette.primaryLight,
            dark: Palette.primaryDark,
          }),
          contrastText: choose({
            light: '#FFFFFF',
            dark: '#2a2a2f',
          }),
        },
        success: {
          main: choose({
            light: Palette.success,
            dark: Palette.success,
          }),
          contrastText: choose({
            light: '#FFFFFF',
            dark: '#2a2a2f',
          }),
        },
        error: {
          main: choose({
            light: Palette.danger,
            dark: Palette.danger,
          }),
        },
        danger: {
          main: choose({
            light: Palette.danger,
            dark: Palette.danger,
          }),
          light: Palette.danger,
        },
        background: {
          paper: choose({
            light: '#f2f4f7',
            dark: '#38373F',
          }),
        },
      },
      typography: {
        fontFamily: "'Source Sans Pro', sans-serif",
        button: {
          letterSpacing: 'normal',
        },
      },
    },
    primary: choose({
      light: Palette.primaryLight,
      dark: Palette.primaryDark,
    }),
    info: choose({
      light: Palette.primaryLight,
      dark: Palette.primaryDark,
    }),
    danger: choose({
      light: Palette.danger,
      dark: Palette.danger,
    }),
    warning: choose({
      light: Palette.warning,
      dark: Palette.warning,
    }),
    medium: choose({
      light: Palette.medium,
      dark: Palette.medium,
    }),
    success: choose({
      light: Palette.success,
      dark: Palette.success,
    }),

    // Severity
    severity: {
      critical: choose({
        light: Palette.danger,
        dark: Palette.danger,
      }),
      high: choose({
        light: Palette.danger,
        dark: Palette.danger,
      }),
      medium: choose({
        light: Palette.medium,
        dark: Palette.medium,
      }),
      low: choose({
        light: Palette.warning,
        dark: Palette.warning,
      }),
      info: choose({
        light: Palette.primaryLight,
        dark: Palette.primaryDark,
      }),
      unknown: choose({
        light: '#878787',
        dark: '#878787',
      }),
    },

    /** * LINK ** */

    linkColor: choose({
      light: Palette.primaryLight,
      dark: Palette.primaryDark,
    }),
    linkHoverColor: choose({
      light: '#469BB8',
      dark: '#8BCDE3',
    }),

    /** * BUTTONS ** */
    // Deprecated
    buttonPrimary: choose({
      light: Palette.primaryLight,
      dark: Palette.primaryDark,
    }),
    // Primary
    buttonPrimaryBorder: choose({
      light: Palette.primaryLight,
      dark: Palette.primaryDark,
    }),
    buttonPrimaryBackground: choose({
      light: Palette.primaryLight,
      dark: Palette.primaryDark,
    }),
    buttonPrimaryText: choose({
      light: '#FCFDFD',
      dark: '#16171A',
    }),
    // Primary - disabled
    buttonPrimaryBorderDisabled: choose({
      light: '#A1A0A7',
      dark: Palette.gray4,
    }),
    buttonPrimaryBackgroundDisabled: choose({
      light: '#A1A0A7',
      dark: Palette.gray4,
    }),
    buttonPrimaryTextDisabled: choose({
      light: '#FCFDFD',
      dark: '#16171A',
    }),
    // Secondary
    buttonSecondaryBorder: choose({
      light: Palette.primaryLight,
      dark: Palette.primaryDark,
    }),
    buttonSecondaryBackground: choose({
      light: 'transparent',
      dark: 'transparent',
    }),
    buttonSecondaryText: choose({
      light: Palette.primaryLight,
      dark: Palette.primaryDark,
    }),
    // Secondary - disabled
    buttonSecondaryBorderDisabled: choose({
      light: '#A1A0A7',
      dark: Palette.gray4,
    }),
    buttonSecondaryTextDisabled: choose({
      light: '#A1A0A7',
      dark: Palette.gray4,
    }),
    buttonSecondaryBackgroundDisabled: choose({
      light: 'transparent',
      dark: 'transparent',
    }),
    // Tertiary
    buttonTertiaryBorder: choose({
      light: Palette.primaryLight,
      dark: '#35353B',
    }),
    buttonTertiaryBackground: choose({
      light: Palette.primaryLight,
      dark: '#35353B',
    }),
    buttonTertiaryText: choose({
      light: '#FFFFFF',
      dark: Palette.primaryDark,
    }),
    // Tertiary - disabled
    buttonTertiaryBorderDisabled: choose({
      light: '#A1A0A7',
      dark: '#35353B',
    }),
    buttonTertiaryBackgroundDisabled: choose({
      light: '#A1A0A7',
      dark: '#35353B',
    }),
    buttonTertiaryTextDisabled: choose({
      light: '#82808A',
      dark: '#16171A',
    }),
    // Danger
    buttonDangerBorder: choose({
      light: Palette.danger,
      dark: Palette.danger,
    }),
    buttonDangerBackground: choose({
      light: Palette.danger,
      dark: Palette.danger,
    }),
    buttonDangerText: choose({
      light: '#FFFFFF',
      dark: '#2a2a2f',
    }),
    // Danger - disabled
    buttonDangerBorderDisabled: choose({
      light: '#A1A0A7',
      dark: Palette.gray4,
    }),
    buttonDangerBackgroundDisabled: choose({
      light: '#A1A0A7',
      dark: Palette.gray4,
    }),
    buttonDangerTextDisabled: choose({
      light: '#FFFFFF',
      dark: '#2a2a2f',
    }),
    // Icon Button
    iconButtonBackground: choose({
      light: Palette.primaryLight,
      dark: Palette.primaryDark,
    }),
    iconButtonText: choose({
      light: '#FCFDFD',
      dark: '#16171A',
    }),
    iconButtonInsideComponentsBackground: choose({
      light: '#A1A0A7',
      dark: '#54545A',
    }),
    iconButtonInsideComponentsText: choose({
      light: '#FFFFFF',
      dark: '#FFFFFF',
    }),
    // Icon Button - disabled
    iconButtonBackgroundDisabled: choose({
      light: '#E0DFE2',
      dark: '#54545A',
    }),
    iconButtonTextDisabled: choose({
      light: '#19181B',
      dark: '#16171A',
    }),
    // Button Group
    buttonGroupHoveredBackground: choose({
      light: '#EFF1F4',
      dark: '#35353B',
    }),
    // Show more Button
    showMoreButtonBackground: choose({
      light: '#E0DFE2',
      dark: '#35353A',
    }),
    showMoreButtonBackgroundHover: choose({
      light: '#C6C4CA',
      dark: '#54545A',
    }),
    showMoreButtonText: choose({
      light: '#35353B',
      dark: Palette.primaryDark,
    }),
    // Icon Button - disabled
    showMoreButtonBackgroundDisabled: choose({
      light: '#E0DFE2',
      dark: '#35353A',
    }),
    showMoreButtonTextDisabled: choose({
      light: '#919191',
      dark: '#707070',
    }),

    /* AttackSurface */
    ctStroke: choose({
      light: '#54545A',
      dark: '#54545A',
    }),
    ctText: choose({
      light: '#646777',
      dark: '#DFDFDF',
    }),
    ctLabelBackground: choose({
      light: '#EFF1F4',
      dark: '#16171A',
    }),

    /** * CONTEXT MENU ** */
    contextMenuBackground: choose({
      light: '#F0F1F4',
      dark: '#2A2A2F',
    }),
    contextMenuColor: choose({
      light: '#313036',
      dark: '#DFDFDF',
    }),
    contextMenuBorderColor: choose({
      light: '#C0BFC5',
      dark: '#54545A',
    }),
    contextMenuSeparatorColor: choose({
      light: '#C0BFC5',
      dark: '#16171A',
    }),
    contextMenuItemHoverBorder: choose({
      light: Palette.primaryLight,
      dark: Palette.primaryDark,
    }),
    contextMenuItemHoverBackground: choose({
      light: '#E0DFE2',
      dark: '#35353B',
    }),
    contextMenuItemDisabledColor: choose({
      light: '#82808A',
      dark: '#797979',
    }),

    /* PLUGIN CARD */
    pluginCardBackground: choose({
      light: '#FFFFFF',
      dark: '#2A2A2F',
    }),

    pluginCardHoverBackground: choose({
      light: '#C0BFC5',
      dark: '#35353B',
    }),

    pluginCardIconColor: choose({
      light: '#54545A',
      dark: Palette.primaryDark,
    }),

    secondaryPluginCardBackground: choose({
      light: '#E0DFE2',
      dark: '#35353A',
    }),

    secondaryPluginCardHoverBackground: choose({
      light: '#EFF1F4',
      dark: '#54545A',
    }),

    /* TAB */
    // Tab - Common
    tabsBorderColor: choose({
      light: '#C0BFC5',
      dark: '#54545A',
    }),
    // Tab - Active
    tabActiveTextColor: choose({
      light: '#19181B',
      dark: '#FFFFFF',
    }),
    tabActiveBackground: choose({
      light: '#E0DFE2',
      dark: '#35353B',
    }),
    tabActiveBorderColor: choose({
      light: Palette.primaryLight,
      dark: Palette.primaryDark,
    }),
    // Tab - Hover
    tabHoverTextColor: choose({
      light: '#19181B',
      dark: '#FFFFFF',
    }),
    tabHoverBackground: choose({
      light: '#E0DFE2',
      dark: '#2A2A2F',
    }),
    tabHoverBorderColor: choose({
      light: '#8C8C8C',
      dark: '#8C8C8C',
    }),
    // Tab - Default
    tabDefaultTextColor: choose({
      light: '#505056',
      dark: '#E0DFE2',
    }),
    tabDefaultBackgroundColor: choose({
      light: 'transparent',
      dark: 'transparent',
    }),
    tabDefaultBorderColor: choose({
      light: '#A1A0A7',
      dark: '#707070',
    }),

    /* SETTINGS TABS */
    settingsTabsContainerBackground: choose({
      light: '#FFFFFF',
      dark: '#202124',
    }),
    settingsTabGroupTitle: choose({
      light: '#A2A2A2',
      dark: '#A2A2A2',
    }),

    /* Drawer */
    drawerBackgroundColor: choose({
      light: '#FFFFFF',
      dark: '#202124',
    }),
    drawerBorderColor: choose({
      light: '#F0F1F4',
      dark: '#1A1B1D',
    }),

    /* CARD */
    cardBackgroundColor: choose({
      light: '#FFFFFF',
      dark: '#202124',
    }),

    cardBackgroundSecondaryColor: choose({
      light: '#FFFFFF',
      dark: '#323237',
    }),

    cardTitleBorderColor: choose({
      light: '#C0BFC5',
      dark: '#323237',
    }),

    /** * GenericLabel ** */
    genericLabelBorderColor: choose({
      light: '#CDCCD1',
      dark: '#4E4E55',
    }),

    genericLabelBorderColorHover: choose({
      light: '#C0BFC5',
      dark: '#6D6D71',
    }),

    genericLabelIconColor: choose({
      light: '#FFFFFF',
      dark: '#1E1E1E',
    }),

    genericLabelIconBackground: choose({
      light: '#DAD8DD',
      dark: '#606066',
    }),

    genericLabelIconBackgroundHover: choose({
      light: '#C0BFC5',
      dark: '#89898F',
    }),

    genericLabelContextBackground: choose({
      light: '#CDCCD1',
      dark: '#4E4E55',
    }),

    genericLabelContextBackgroundHover: choose({
      light: '#C0BFC5',
      dark: '#6D6D71',
    }),

    genericLabelBodyBackground: choose({
      light: '#EAEBED',
      dark: '#26272A',
    }),

    genericLabelBodyBackgroundHover: choose({
      light: '#E0DCE1',
      dark: '#353639',
    }),

    /** * ContextNameLabel ** */
    // ContextNameLabel - custom
    contextNameLabelCustomIconBackground: choose({
      light: '#7EDEA5',
      dark: '#7EDEA5',
    }),

    // ContextNameLabel - system
    contextNameLabelSystemIconBackground: choose({
      light: '#5C6BC0',
      dark: '#5C6BC0',
    }),

    // ContextNameLabel - customized
    contextNameLabelCustomizedIconBackground: choose({
      light: '#9747FF',
      dark: '#9747FF',
    }),

    /** * TOPBAR ** */
    topbarBackground: choose({
      light: '#FFFFFF',
      dark: '#26272A',
    }),
    topbarMenuButtonColor: choose({
      light: '#505056',
      dark: '#DFDFDF',
    }),
    topbarMenuButtonColorHover: choose({
      light: '#19181B',
      dark: '#FFFFFF',
    }),
    colorTopbarBannerText: choose({
      light: '#F2F4F7',
      dark: '#202124',
    }),
    colorTopbarBannerBackground: choose({
      light: Palette.primaryLight,
      dark: Palette.primaryDark,
    }),

    // Topbar - Tabs - Normal
    pageTabBackground: choose({
      light: 'transparent',
      dark: 'transparent',
    }),
    pageTabColor: choose({
      light: '#505056',
      dark: '#A2A2A2',
    }),
    // Topbar - Tabs - Hover
    pageTabBackgroundHover: choose({
      light: '#E0DFE2',
      dark: '#35353A',
    }),
    pageTabColorHover: choose({
      light: '#505056',
      dark: '#A2A2A2',
    }),
    // Topbar - Tabs - Active
    pageTabBackgroundActive: choose({
      light: Palette.primaryLight,
      dark: Palette.primaryDark,
    }),
    pageTabColorActive: choose({
      light: '#FCFDFD',
      dark: '#16171A',
    }),
    // Topbar - Tabs - New
    pageTabBackgroundNew: choose({
      light: Palette.primaryLight,
      dark: Palette.primaryDark,
    }),
    pageTabColorNew: choose({
      light: '#F2F4F7',
      dark: '#202124',
    }),
    // Topbar - Tabs - New Tab Button
    pageTabNewTabButtonBackground: choose({
      light: 'transparent',
      dark: 'transparent',
    }),
    pageTabNewTabButtonBackgroundHover: choose({
      light: '#E0DFE2',
      dark: '#35353A',
    }),
    pageTabNewTabButtonColor: choose({
      light: '#505056',
      dark: '#A2A2A2',
    }),
    pageTabNewTabButtonColorHover: choose({
      light: '#505056',
      dark: '#A2A2A2',
    }),

    /** * SideBarLeft ** */
    sideBarGroupItemBackground: choose({
      light: '#F0F1F4',
      dark: '#35353B',
    }),
    sideBarGroupItemHoverBackground: choose({
      light: '#C0BFC5',
      dark: '#54545A',
    }),
    sideBarGroupItemActiveBackground: choose({
      light: '#B9C5CB',
      dark: '#3A4E51',
    }),
    sideBarItemHoverBackground: choose({
      light: '#E0DFE2',
      dark: '#35353B',
    }),
    sideBarItemActiveColor: choose({
      light: Palette.primaryLight,
      dark: Palette.primaryDark,
    }),
    sideBarItemActiveBackground: choose({
      light: '#B9C5CB',
      dark: '#3A4E51',
    }),
    colorSideBarLeftGroupIcon: choose({
      light: '#19181B',
      dark: '#A2A2A2',
    }),
    colorSideBarLeftGroupTitle: choose({
      light: '#19181B',
      dark: '#A2A2A2',
    }),
    // SideBarLeft - Profile
    profileBackground: choose({
      light: '#FFFFFF',
      dark: '#35353A',
    }),
    // SideBarLeft - Other
    topbarMenuBackground: choose({
      light: '#FFFFFF',
      dark: '#35353B',
    }),
    topbarMenuBackgroundHover: choose({
      light: '#E0DFE2',
      dark: '#414146',
    }),
    topbarMenuTextColor: choose({
      light: '#19181B',
      dark: '#FFFFFF',
    }),
    topbarMenuTextColorSecondary: choose({
      light: '#82808A',
      dark: '#707070',
    }),

    /** * SideBarRight ** */
    propertiesTrayActiveBackground: choose({
      light: '#FFFFFF',
      dark: '#35353A',
    }),
    propertiesTrayMenuItemBorder: choose({
      light: '#A1A0A7',
      dark: '#4E4E55',
    }),
    propertiesTrayMenuItemBackground: choose({
      light: '#FFFFFF',
      dark: '#35353A',
    }),
    rightSidebarEventTitleTextColor: choose({
      light: '#646777',
      dark: '#FFFFFF',
    }),
    rightSideAlertBorderColor: choose({
      light: '#bdbfc3',
      dark: '#787878',
    }),

    /** * TOGGLE ** */
    toggleUncheckedBackground: choose({
      light: '#54545A',
      dark: '#919191',
    }),
    toggleCheckedBackground: choose({
      light: Palette.primaryLight,
      dark: Palette.primaryDark,
    }),
    toggleFocusBorderColor: choose({
      light: Palette.primaryLight,
      dark: Palette.primaryDark,
    }),
    toggleUncheckedThumbColor: choose({
      light: '#C0BFC5',
      dark: '#FFFFFF',
    }),
    toggleCheckedThumbColor: choose({
      light: '#52BBB7',
      dark: '#FFFFFF',
    }),

    /** * CHECKBOX ** */
    checkboxBorderColor: choose({
      light: '#82808A',
      dark: '#605F7B',
    }),
    checkboxFocusBorderColor: choose({
      light: Palette.primaryLight,
      dark: Palette.primaryDark,
    }),
    checkboxColor: choose({
      light: Palette.primaryLight,
      dark: Palette.primaryDark,
    }),

    /** * TAG ** */
    // tag - primary
    tagPrimaryBackground: choose({
      light: Palette.primaryLight,
      dark: Palette.primaryDark,
    }),
    tagPrimaryBorder: choose({
      light: Palette.primaryLight,
      dark: Palette.primaryDark,
    }),
    tagPrimaryColor: choose({
      light: 'black',
      dark: 'black',
    }),

    // tag - primary - outlined
    tagPrimaryOutlinedBackground: choose({
      light: 'transparent',
      dark: 'transparent',
    }),
    tagPrimaryOutlinedBorder: choose({
      light: Palette.primaryLight,
      dark: Palette.primaryDark,
    }),
    tagPrimaryOutlinedColor: choose({
      light: Palette.primaryLight,
      dark: Palette.primaryDark,
    }),

    // tag - secondary
    tagSecondaryBackground: choose({
      light: '#E0DFE2',
      dark: '#35353b',
    }),
    tagSecondaryBorder: choose({
      light: '#E0DFE2',
      dark: '#35353b',
    }),
    tagSecondaryColor: choose({
      light: '#19181B',
      dark: '#FFFFFF',
    }),

    // tag - secondary - outlined
    tagSecondaryOutlinedBackground: choose({
      light: 'transparent',
      dark: 'transparent',
    }),
    tagSecondaryOutlinedBorder: choose({
      light: '#E7E2E2',
      dark: '#E7E2E2',
    }),
    tagSecondaryOutlinedColor: choose({
      light: '#19181B',
      dark: '#E7E2E2',
    }),

    // tag - info
    tagInfoBackground: choose({
      light: '#E7E2E2',
      dark: '#35353a',
    }),
    tagInfoBorder: choose({
      light: '#E7E2E2',
      dark: '#35353a',
    }),
    tagInfoColor: choose({
      light: '#19181B',
      dark: '#E7E2E2',
    }),

    // tag - info - outlined
    tagInfoOutlinedBackground: choose({
      light: 'transparent',
      dark: 'transparent',
    }),
    tagInfoOutlinedBorder: choose({
      light: '#E7E2E2',
      dark: '#E7E2E2',
    }),
    tagInfoOutlinedColor: choose({
      light: '#19181B',
      dark: '#E7E2E2',
    }),

    // tag - success
    tagSuccessBackground: choose({
      light: '#53e0b7',
      dark: '#53e0b7',
    }),
    tagSuccessBorder: choose({
      light: '#53e0b7',
      dark: '#53e0b7',
    }),
    tagSuccessColor: choose({
      light: '#19181B',
      dark: '#35353B',
    }),

    // tag - success - outlined
    tagSuccessOutlinedBackground: choose({
      light: 'transparent',
      dark: 'transparent',
    }),
    tagSuccessOutlinedBorder: choose({
      light: '#53e0b7',
      dark: '#53e0b7',
    }),
    tagSuccessOutlinedColor: choose({
      light: '#19181B',
      dark: '#53e0b7',
    }),

    // tag - danger
    tagDangerBackground: choose({
      light: '#fd4862',
      dark: '#fd4862',
    }),
    tagDangerBorder: choose({
      light: '#fd4862',
      dark: '#fd4862',
    }),
    tagDangerColor: choose({
      light: '#19181B',
      dark: '#35353B',
    }),

    // tag - danger - outlined
    tagDangerOutlinedBackground: choose({
      light: 'transparent',
      dark: 'transparent',
    }),
    tagDangerOutlinedBorder: choose({
      light: '#fd4862',
      dark: '#fd4862',
    }),
    tagDangerOutlinedColor: choose({
      light: '#19181B',
      dark: '#fd4862',
    }),

    // tag - srcip
    tagSrcipBackground: choose({
      light: '#29B6F6',
      dark: '#29B6F6',
    }),
    tagSrcipBorder: choose({
      light: '#29B6F6',
      dark: '#29B6F6',
    }),
    tagSrcipColor: choose({
      light: '#19181B',
      dark: '#35353B',
    }),

    // tag - dstip
    tagDstipBackground: choose({
      light: '#F6D874',
      dark: '#F6D874',
    }),
    tagDstipBorder: choose({
      light: '#F6D874',
      dark: '#F6D874',
    }),
    tagDstipColor: choose({
      light: '#19181B',
      dark: '#35353B',
    }),

    /** SubAccount Tag * */
    tagSubAccountColor: choose({
      light: Palette.danger,
      dark: Palette.danger,
    }),
    tagSubAccountBackground: choose({
      light: '#E0DFE2',
      dark: '#35353B',
    }),

    /** Emphasized Code Tag */

    empCodeTagBackground: choose({
      light: '#EAEBED',
      dark: '#2A2A2F',
    }),

    empCodeTagBorder: choose({
      light: '#C0BFC5',
      dark: '#35353B',
    }),

    /** * TCP Flag Label ** */
    tcpFlagLabelDefaultBorder: choose({
      light: '#DFDFDF',
      dark: '#35353B',
    }),
    tcpFlagLabelDefaultColor: choose({
      light: '#DFDFDF',
      dark: '#35353B',
    }),

    /** * DETAILS ** */
    auditDetailsCardBorder: choose({
      light: '#C0BFC5',
      dark: '#54545A',
    }),
    auditDetailsCardBackground: choose({
      light: '#F0F1F4',
      dark: '#2A2A2F',
    }),
    auditDetailsCardName: choose({
      light: '#505056',
      dark: '#A2A2A2',
    }),

    /** * TOOLTIP ** */
    tooltipBorderColor: choose({
      light: '#CDCCD1',
      dark: '#4E4E55',
    }),
    tooltipBackground: choose({
      light: '#FFFFFF',
      dark: '#2A2A2F',
    }),
    tooltipColor: choose({
      light: '#313036',
      dark: '#DFDFDF',
    }),

    /** * DATETIMEPICKER ** */
    dateTimePickerCalendarBackground: choose({
      light: '#F0F1F4',
      dark: '#38373F',
    }),
    dateTimePickerCalendarToolbarBackground: choose({
      light: '#E0DFE2',
      dark: 'rgba(0, 0, 0, 0.2)',
    }),

    /** * COLOR PICKER ** */
    colorPickerBorder: choose({
      light: '#FFFFFF',
      dark: '#FFFFFF',
    }),
    colorPickerBackground: choose({
      light: '#F0F1F4',
      dark: '#38373F',
    }),

    /** * CHARTS, WIDGETS, DASHBOARDS ** */
    chartBackground: choose({
      light: '#FFFFFF',
      dark: Palette.gray7,
    }),
    widgetHighlightedBorder: choose({
      light: '#82808A',
      dark: '#54545A',
    }),

    /** * FORM WIZARD ** */
    formWizardStepTitleBackground: choose({
      light: '#82808A',
      dark: '#35353B',
    }),

    /** * TOAST / NOTIFICATIONS ** */
    toastMessageColor: choose({
      light: 'black',
      dark: '#DFDFDF',
    }),
    toastBodyBackground: choose({
      light: '#E0DFE2',
      dark: '#35353B',
    }),

    /** * MITRE ** */
    mitreTechniqueBackground: choose({
      light: '#E0DFE2',
      dark: '#2A2A2F',
    }),
    mitreTechniqueBackgroundHasEvents: choose({
      light: '#A1A0A7',
      dark: '#45454B',
    }),
    mitreTacticBackground: choose({
      light: '#C0BFC5',
      dark: '#35353B',
    }),
    selectedMitreTechniqueBackground: choose({
      light: '#08616D',
      dark: '#52bbb7',
    }),

    selectedMitreTextColor: choose({
      light: '#fff',
      dark: '#2a2a2f',
    }),

    /** * OTHER ** */
    colorAccent: choose({
      light: Palette.primaryLight,
      dark: Palette.primaryDark,
    }),
    cardBackground: choose({
      light: '#B4BFD0',
      dark: '#333246',
    }),
    colorBackground: choose({
      light: '#FFFFFF',
      dark: '#26272A',
    }),
    colorBackgroundBody: choose({
      light: '#F0F1F4',
      dark: '#16171A',
    }),
    colorBackgroundBodySecondary: choose({
      light: '#F0F1F4',
      dark: '#2A2A2F',
    }),
    colorBackgroundSeparator: choose({
      light: '#E0DFE2',
      dark: '#16171A',
    }),

    colorText: choose({
      light: '#19181B',
      dark: '#FFFFFF',
    }),
    colorTextSecondary: choose({
      light: '#505056',
      dark: '#A2A2A2',
    }),
    colorSeparator: choose({
      light: '#E0DFE2',
      dark: '#54545A',
    }),
    colorBackgroundModalFooter: choose({
      light: '#F0F1F4',
      dark: '#35353B',
    }),
    colorBackgroundModalBody: choose({
      light: '#FFFFFF',
      dark: '#2A2A2F',
    }),
    colorBackgroundModalPlaintextFieldBackground: choose({
      light: '#F0F1F4',
      dark: Palette.gray7,
    }),
    colorDisabledText: choose({
      light: '#b9b9b9',
      dark: '#797979',
    }),
    colorMenuDisabledText: choose({
      light: '#E0DFE2',
      dark: '#54545A',
    }),
    colorHover: choose({
      light: '#E0DFE2',
      dark: '#35353B',
    }),

    colorFieldBackground: choose({
      light: '#FFFFFF',
      dark: Palette.gray7,
    }),
    colorFieldsBorder: choose({
      light: '#33333a',
      dark: '#555657',
    }),
    colorFieldBackgroundInvalid: choose({
      light: '#FFFFFF',
      dark: Palette.gray7,
    }),
    colorFieldsBorderInvalid: choose({
      light: Palette.danger,
      dark: Palette.danger,
    }),
    scrollBackgroundColor: choose({
      light: '#B4BFD0',
      dark: '#54545A',
    }),
    fieldsGroupLabelColor: choose({
      light: '#B4BFD0',
      dark: '#54545A',
    }),
    noDevicesTitle: choose({
      light: 'black',
      dark: '#fff',
    }),
    noDevicesText: choose({
      light: '#6C6C6C',
      dark: '#dfdfdf',
    }),
    noDevicesWrapper: choose({
      light: 'rgba(255, 255, 255, 0.5)',
      dark: 'rgba(0, 0, 0, 0.5)',
    }),
    noDataPageImageBackground: choose({
      light: '#F2F4F7',
      dark: '#202124',
    }),
    noDataPageTextBackground: choose({
      light: '#FFFFFF',
      dark: '#2A2A2F',
    }),
    searchFormBackgroundColor: choose({
      light: '#FFFFFF',
      dark: '#2A2A2F',
    }),
    colorHighlighted: choose({
      light: '#00a0d4',
      dark: '#98e6ff',
    }),
    fdcLegendShape: choose({
      light: Palette.primaryLight,
      dark: Palette.primaryDark,
    }),
    fdcLegendBackground: choose({
      light: '#e0dfe2',
      dark: '#1c1d1f',
    }),
    textAreaScrollbar: choose({
      light: '#D9DFE7',
      dark: '#35353a',
    }),
    logoSvgUrl: choose({
      light: '/images/logos/logo_light_v20250121.svg',
      dark: '/images/logos/logo_dark_v20250121.svg',
    }),
    iconSvgUrl: choose({
      light: '/images/logos/icon_light_v20250121.svg',
      dark: '/images/logos/icon_dark_v20250121.svg',
    }),
    // SLIDER
    sliderTrack: choose({
      light: Palette.primaryLight,
      dark: Palette.primaryDark,
    }),
    sliderRail: choose({
      light: '#61BBD950',
      dark: '#61BBD950',
    }),
    sliderValueColor: choose({
      light: '#FCFDFD',
      dark: '#16171A',
    }),
    // Spinner
    spinnerOneColor: choose({
      light: '#08616D',
      dark: '#52BBB7',
    }),
    spinnerTwoColor: choose({
      light: '#FD9F43',
      dark: '#FD9F43',
    }),
    spinnerThreeColor: choose({
      light: '#C14838',
      dark: '#C14838',
    }),
    // Pivot Clickable
    pivotClickableBackground: choose({
      light: '#09616d',
      dark: '#53bbb7',
    }),
    pivotClickableText: choose({
      light: '#FFFFFF',
      dark: '#16171A',
    }),
    pivotClickableBorder: choose({
      light: '#e0dfe2',
      dark: '#1a1b1d',
    }),

    ...contextTypes.process(choose),
    ...globalFiltersTheme.process(choose),
    ...mapTheme.process(choose),
    ...inputTheme.process(choose),
    ...tableTheme.process(choose),
    ...devices,
    colorTool,
  };
};

export const darkTheme = makeThemeContext(Theme.dark);
export const lightTheme = makeThemeContext(Theme.light);
