/* eslint-disable react/prop-types */
/* eslint-disable react/no-array-index-key */
import { UniversalCell } from '+components/Table/Cells';
import {
  BaseColumnFactory,
  MenuColumnFactory,
  NumberColumnFactory,
  TimestampColumnFactory,
  TrafficColumnFactory,
} from '+components/Table/Columns';
import { getRowOriginal } from '+components/Table/Columns/utils';
import { SelectColumnFilter } from '+components/Table/Filters';
import {
  autoRemoveIfAll,
  someOfFieldsFilter,
  withAutoRemove,
} from '+components/Table/FilterTypeFactories';
import { autoRemoveByOperator } from '+components/Table/ReactTable/utils';

import getEventTableFilterValues from '../getEventTableFilterValues';

export const Columns = {
  traffictype: 'traffictype',
  algorithm: 'algorithm',
  alerttype: 'alerttype',
  categories: 'categories',
  dstips: 'dstips',
  dstipnames: 'dstipnames',
  dstports: 'dstports',
  dstportnames: 'dstportnames',
  duration: 'duration',
  end: 'end',
  flowsrcnames: 'flowsrcnames',
  id: 'id',
  menu: 'menu',
  search: 'search',
  severity: 'severity',
  sites: 'sites',
  srcips: 'srcips',
  srcipnames: 'srcipnames',
  srcports: 'srcports',
  srcportnames: 'srcportnames',
  start: 'start',
  summary: 'summary',
  tags: 'tags',
  timestamp: 'timestamp',
  rules: 'rules',
  tdm: 'tdm',
  customer: 'customer',
};

export const columnsCollection = ({
  algorithms,
  categories,
  cxActionMenu,
} = {}) => {
  const collection = {
    [Columns.traffictype]: TrafficColumnFactory(),
    [Columns.algorithm]: BaseColumnFactory({
      accessor: Columns.algorithm,
      Header: 'algorithm (DM)',
      Cell: UniversalCell(Columns.algorithm),
      ...getEventTableFilterValues(Columns.algorithm, { algorithms }),
    }),
    [Columns.alerttype]: BaseColumnFactory({
      width: 80,
      ...getEventTableFilterValues(Columns.alerttype),
    }),
    [Columns.categories]: BaseColumnFactory({
      accessor: Columns.categories,
      getCellProps: () => ({ style: { whiteSpace: 'unset' } }),
      Cell: UniversalCell(Columns.categories),
      ...getEventTableFilterValues(Columns.categories, { categories }),
    }),
    [Columns.dstips]: BaseColumnFactory({
      Cell: UniversalCell(Columns.dstips),
      sortType: 'ip',
    }),
    [Columns.dstipnames]: BaseColumnFactory({
      Cell: UniversalCell(Columns.dstipnames),
    }),
    [Columns.dstports]: BaseColumnFactory({
      Cell: UniversalCell(Columns.dstports),
    }),
    [Columns.dstportnames]: BaseColumnFactory({
      Cell: UniversalCell(Columns.dstportnames),
    }),
    [Columns.duration]: NumberColumnFactory({
      id: Columns.duration,
      accessor: (original) =>
        original[Columns.duration] ||
        Math.floor(Date.now() / 1000) - original.timestamp ||
        null,
      maxWidth: 80,
      Cell: ({ value }) => {
        if (value == null) {
          return '-';
        }

        return UniversalCell(Columns.duration)({
          value,
          options: { unit: 'seconds' },
        });
      },
    }),
    [Columns.end]: TimestampColumnFactory({
      accessor: Columns.end,
      width: 160,
    }),
    [Columns.flowsrcnames]: BaseColumnFactory({
      width: 160,
    }),
    [Columns.search]: BaseColumnFactory({
      width: 260,
    }),
    [Columns.severity]: BaseColumnFactory({
      width: 80,
      ...getEventTableFilterValues(Columns.severity),
    }),
    [Columns.sites]: BaseColumnFactory({
      Cell: UniversalCell(Columns.sites),
    }),
    [Columns.srcips]: BaseColumnFactory({
      Cell: UniversalCell(Columns.srcips),
      sortType: 'ip',
    }),
    [Columns.srcipnames]: BaseColumnFactory({
      Cell: UniversalCell(Columns.srcipnames),
    }),
    [Columns.srcports]: BaseColumnFactory({
      Cell: UniversalCell(Columns.srcports),
    }),
    [Columns.srcportnames]: BaseColumnFactory({
      Cell: UniversalCell(Columns.srcportnames),
    }),
    [Columns.start]: TimestampColumnFactory({
      accessor: Columns.start,
      width: 160,
    }),
    [Columns.summary]: BaseColumnFactory({
      getCellProps: () => ({ style: { whiteSpace: 'unset' } }),
      Cell: ({ row }) => {
        const original = getRowOriginal(row);
        return UniversalCell(Columns.tdm)({
          value: {
            id: original?.tdm?.id,
            name: original?.tdm?.name,
            description: original?.summary,
          },
        });
      },
      realAccessor: ['tdm.name', 'summary'],
      filter: someOfFieldsFilter(
        ['description', 'summary'],
        autoRemoveByOperator,
      ),
    }),
    [Columns.tags]: BaseColumnFactory({
      getCellProps: () => ({ style: { whiteSpace: 'unset' } }),
    }),
    [Columns.timestamp]: TimestampColumnFactory({ width: 160 }),
    [Columns.rules]: BaseColumnFactory(),
    [Columns.tdm]: BaseColumnFactory({
      Cell: UniversalCell(Columns.tdm),
      realAccessor: ['tdm.name', 'tdm.description'],
      ...getEventTableFilterValues(Columns.algorithm, {
        algorithms,
        getAlgorithmsName: (row, id) => row?.values?.[id]?.name,
      }),
      filter: withAutoRemove((rows, [id], filterValue) => {
        if (autoRemoveIfAll(filterValue)) {
          return rows;
        }

        return rows.filter(
          ({ values }) => values[id]?.name === filterValue.value,
        );
      }, autoRemoveIfAll),
    }),
    [Columns.customer]: BaseColumnFactory({
      accessor: 'customer',
      Header: 'Account',
      width: 160,
      Filter: SelectColumnFilter({
        optionLabel: (key) => (key === 'all' ? 'All' : key),
      }),
      filter: 'selectFilter',
    }),
    [Columns.menu]: MenuColumnFactory({ cxActionMenu }),
  };

  Object.keys(collection).forEach((key) => {
    if (!collection[key].accessor) {
      collection[key].accessor = key;
    }
  });

  return collection;
};
