import PropTypes from '+prop-types';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import ArrowBackIcon from 'mdi-react/ArrowBackIcon';

import IconButton from '+components/IconButton';

import { usePageTabs } from './usePageTabs';

export const BackToButton = ({ backUrl }) => {
  const [, active, { back }] = usePageTabs();
  const navigate = useNavigate();

  const onClick = useCallback(() => {
    if (backUrl) {
      navigate(backUrl);
      return;
    }

    back();
  }, [back, backUrl]);

  return (
    (!!active.previous || backUrl) && (
      <IconButton onClick={onClick}>
        <ArrowBackIcon />
      </IconButton>
    )
  );
};

BackToButton.propTypes = {
  backUrl: PropTypes.string,
};

BackToButton.defaultProps = {
  backUrl: null,
};
