import isFunction from 'lodash.isfunction';

import { FilterOperator } from '+components/Table/FilterTypeFactories/utils';

export const tooltipDelay = 600;

export const getHeaderActionMenuPosition = (ref, menuWidth) => {
  if (!ref?.current) {
    return { x: 0, y: 0 };
  }
  const table = ref.current.closest('section[role="table"]');
  const tableRect = table.getBoundingClientRect();
  const filterRect = ref.current.getBoundingClientRect();
  let menuX = filterRect.left + filterRect.width / 2 - menuWidth / 2;
  if (menuX < tableRect.left) {
    menuX = tableRect.left;
  }
  if (menuX + menuWidth > tableRect.right) {
    menuX = tableRect.right - menuWidth - 2; // 1 + 1 - border;
  }
  const menuY = filterRect.bottom + 4.5;
  return { x: menuX, y: menuY };
};

/**
 * @param {*[]} hooks
 * @return {function(*, *=): *[]}
 */
export const getProps = (hooks) => (props, meta) => [
  props,
  ...(hooks || []).map((hook) => {
    if (!hook) {
      return {};
    }

    return isFunction(hook) ? hook(meta, props) : hook;
  }),
];

/**
 * @param {*} prev - prev props
 * @param {*} next - next props
 * @return {boolean}
 */
export const cellPropsCompare = (prev, next) => {
  return !Object.keys(prev).some((key) => {
    if (key === 'style') {
      return JSON.stringify(prev[key]) !== JSON.stringify(next[key]);
    }

    return prev[key] !== next[key];
  });
};

export const canBeExpanded = (subRows, expandSingleSubRow) =>
  subRows?.length > (expandSingleSubRow ? 0 : 1) ||
  subRows?.[0]?.subRows?.length > 0;

const getValue = (filterValue) =>
  typeof filterValue === 'object' && !Array.isArray(filterValue)
    ? filterValue?.value
    : filterValue;

export const defaultAutoRemove = (filterValue) =>
  [null, ''].includes(getValue(filterValue));

export const autoRemoveIfAll = (filterValue) =>
  (getValue(filterValue) ?? 'all') === 'all';

export const autoRemoveIfEmpty = (filterValue) =>
  (getValue(filterValue) ?? '') === '';

export const autoRemoveByOperator = (filterValue) => {
  if (filterValue?.operator === FilterOperator.equal) {
    return autoRemoveIfAll(filterValue);
  }
  return autoRemoveIfEmpty(filterValue);
};

const isEmptyValue = (value) => {
  if (value === null || value === undefined || value === '') {
    return true;
  }
  if (Array.isArray(value) && value.length === 0) {
    return true;
  }
  if (typeof value === 'object') {
    return Object.values(value).every(isEmptyValue);
  }
  return false;
};

export const emptyGroupKey = '';

/**
 * This is react-table default groupBy function from useGroupBy hook
 * with only one change: it uses JSON.stringify for keys and emptyGroupKey for empty values.
 * This is needed for correct grouping by objects (like GEO and AS data).
 * @param rows
 * @param columnId
 * @returns {*}
 */
export const defaultGroupByFn = (rows, columnId) => {
  return rows.reduce((prev, row) => {
    const value = row.values[columnId];
    // eslint-disable-next-line no-nested-ternary
    const resKey = isEmptyValue(value)
      ? emptyGroupKey
      : typeof value === 'object'
        ? JSON.stringify(value)
        : `${value}`;
    prev[resKey] = Array.isArray(prev[resKey]) ? prev[resKey] : [];
    prev[resKey].push(row);
    return prev;
  }, {});
};
