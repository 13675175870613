import PropTypes from '+prop-types';
import { Fragment, useMemo, useState } from 'react';

import isEqual from 'lodash.isequal';

import { ContextTypes, ContextTypesLabels } from '@/models/ContextTypes';

import { Breadcrumb } from '+components/Breadcrumb';
import { lang } from '+components/charts/common/utils';
import { trafficDefaultColumns, TrafficTable } from '+components/ContextTables';
import BlockTable, {
  defaultColumns as blockTableDefaultColumns,
} from '+components/ContextTables/BlockTable';
import DnsTable, {
  defaultColumns as dnsTableDefaultColumns,
} from '+components/ContextTables/DnsTable';
import EventTable, {
  defaultColumns as eventTableDefaultColumns,
} from '+components/ContextTables/EventTable';
import FlowTable, {
  defaultColumns as flowTableDefaultColumns,
} from '+components/ContextTables/FlowTable';
import GlobalFiltersSetting from '+components/GlobalFilters/Setting';
import { usePageTabs } from '+components/PageTabs';
import RealtimeManager from '+components/RealtimeManager';
import useEvent from '+hooks/useEvent';
import useGlobalFilters from '+hooks/useGlobalFilters';
import useLastAllowedContext from '+hooks/useLastAllowedContext';
import useRealtimeOrRequest from '+hooks/useRealtimeOrRequest';

const defaultTableValues = {
  throttle: {
    wait: 300,
    props: ['tableData', 'noDataText'],
  },
};

const FlowTableRealtime = RealtimeManager(FlowTable, defaultTableValues);
const DnsTableRealTime = RealtimeManager(DnsTable, defaultTableValues);
const TrafficTableRealTime = RealtimeManager(TrafficTable, defaultTableValues);
const EventTableRealTime = RealtimeManager(EventTable, defaultTableValues);
const BlockTableRealTime = RealtimeManager(BlockTable, defaultTableValues);

const TableComponents = {
  [ContextTypes.flow]: FlowTableRealtime,
  [ContextTypes.dns]: DnsTableRealTime,
  [ContextTypes.traffic]: TrafficTableRealTime,
  [ContextTypes.alerts]: EventTableRealTime,
  [ContextTypes.blocks]: BlockTableRealTime,
};

const tableColumns = {
  [ContextTypes.flow]: flowTableDefaultColumns,
  [ContextTypes.dns]: dnsTableDefaultColumns,
  [ContextTypes.traffic]: trafficDefaultColumns,
  [ContextTypes.alerts]: eventTableDefaultColumns,
  [ContextTypes.blocks]: blockTableDefaultColumns,
};

const tableSortBy = {
  [ContextTypes.flow]: [{ id: 'timestamp', desc: true }],
  [ContextTypes.dns]: [{ id: 'timestamp', desc: true }],
  [ContextTypes.traffic]: [{ id: 'timestamp', desc: true }],
  [ContextTypes.alerts]: [{ id: 'timestamp', desc: true }],
  [ContextTypes.blocks]: [{ id: 'start', desc: true }],
};

const RealTimeTraffic = (props) => {
  const {
    title,
    excludeContexts,
    allowedContexts,
    defaultContext,
    globalFiltersDisabled,
    pageTitleDisabled,
    tableId,
    fillWithEmptyRows,
    minRows,
    defaultPageSize,
    tableHeaderButton,
  } = props;
  const [, activePageTab] = usePageTabs();
  const [flowIncludeFields, setFlowIncludeFields] = useState([]);
  const [dnsIncludeFields, setDnsIncludeFields] = useState([]);
  const [trafficIncludeFields, setTrafficIncludeFields] = useState([]);
  const [eventIncludeFields, setEventIncludeFields] = useState([]);
  const [blockIncludeFields, setBlockIncludeFields] = useState([]);

  const excludeContextsArr = useMemo(
    () => Array.from(excludeContexts || []),
    [excludeContexts],
  );

  const context = useLastAllowedContext({
    allowedContexts,
    excludeContexts,
    defaultContext,
  });

  const [filters] = useGlobalFilters(context);

  const TableComponent = useMemo(() => TableComponents[context], [context]);

  const includeFields = {
    [ContextTypes.flow]: flowIncludeFields,
    [ContextTypes.dns]: dnsIncludeFields,
    [ContextTypes.traffic]: trafficIncludeFields,
    [ContextTypes.alerts]: eventIncludeFields,
    [ContextTypes.blocks]: blockIncludeFields,
  };

  const includeFieldsSetters = {
    [ContextTypes.flow]: setFlowIncludeFields,
    [ContextTypes.dns]: setDnsIncludeFields,
    [ContextTypes.traffic]: setTrafficIncludeFields,
    [ContextTypes.alerts]: setEventIncludeFields,
    [ContextTypes.blocks]: setBlockIncludeFields,
  };

  const { records } = useRealtimeOrRequest({
    name: `${activePageTab?.id}_${context}`,
    includeFields: includeFields[context],
    stopRequest: !activePageTab?.id || !includeFields[context].length,
    context,
    refresher: filters.refresher,
  });

  const data = useMemo(() => records?.toArray() || [], [records]);

  const columnsChange = useEvent(
    (allColumns, hiddenColumns, technicalColumns, setter) => {
      const fieldsSet = new Set(
        allColumns
          .map((item) => {
            if (item.realAccessor) {
              return item.realAccessor;
            }
            return typeof item.accessor === 'string' ? item.accessor : item.id;
          })
          .flat()
          .map((field) => {
            if (field.startsWith('label.ip')) {
              return 'label.ip';
            }
            if (field.startsWith('label.port')) {
              return 'label.port';
            }
            return field;
          }),
      );
      // remove hidden columns
      hiddenColumns.forEach((item) => {
        fieldsSet.delete(item);
      });
      // remove technical columns
      technicalColumns.forEach((item) => {
        fieldsSet.delete(item);
      });
      // add id and timestamp - we need it to fetch full record if we need it
      fieldsSet.add('id');
      fieldsSet.add(context === ContextTypes.blocks ? 'start' : 'timestamp');
      const nextValue = [...fieldsSet].filter(Boolean);
      setter((prevValue) =>
        isEqual(prevValue, nextValue) ? prevValue : nextValue,
      );
    },
  );

  const onColumnsChange = useEvent(
    (allColumns, hiddenColumns, technicalColumns) => {
      columnsChange(
        allColumns,
        hiddenColumns,
        technicalColumns,
        includeFieldsSetters[context],
      );
    },
  );

  const pageTitle = `Search ${ContextTypesLabels[context]}`;

  return (
    <Fragment>
      {!pageTitleDisabled && <Breadcrumb title={pageTitle} />}

      {!globalFiltersDisabled && (
        <GlobalFiltersSetting
          nql
          context={context}
          excludeContexts={excludeContextsArr}
          customers
          socketControl
        />
      )}

      <TableComponent
        title={title}
        id={tableId || `Reports_${context}_Tap`}
        columns={tableColumns[context]}
        tableData={data}
        minRows={minRows}
        noDataText={records ? undefined : lang.loading}
        onColumnsChange={onColumnsChange}
        exportingAllFields={false}
        sortBy={tableSortBy[context]}
        fillWithEmptyRows={fillWithEmptyRows}
        pageSize={defaultPageSize}
        ExternalHeaderControls={tableHeaderButton}
      />
    </Fragment>
  );
};

RealTimeTraffic.propTypes = {
  title: PropTypes.string,
  excludeContexts: PropTypes.instanceOf(Set),
  allowedContexts: PropTypes.instanceOf(Set),
  defaultContext: PropTypes.oneOf(Object.values(ContextTypes)),
  globalFiltersDisabled: PropTypes.bool,
  pageTitleDisabled: PropTypes.bool,
  tableId: PropTypes.string,
  fillWithEmptyRows: PropTypes.bool,
  minRows: PropTypes.number,
  defaultPageSize: PropTypes.number,
  tableHeaderButton: PropTypes.elementType,
};

RealTimeTraffic.defaultProps = {
  title: undefined,
  excludeContexts: undefined,
  allowedContexts: undefined,
  defaultContext: ContextTypes.flow,
  globalFiltersDisabled: false,
  pageTitleDisabled: false,
  tableId: undefined,
  fillWithEmptyRows: true,
  minRows: 20,
  defaultPageSize: undefined,
  tableHeaderButton: undefined,
};

export default RealTimeTraffic;
