import PropTypes from '+prop-types';
import { Fragment } from 'react';

import get from 'lodash.get';

import { Col } from '+components/Layout';
import UniversalField from '+components/UniversalField';
import useGlobalFilters from '+hooks/useGlobalFilters';

import {
  BodyGroupCol,
  BodyGroupTitleRow,
  BodyItemRow,
  BodyNameCol,
  BodySeparator,
  BodyValueCol,
} from '../../BodyComponents';

const DnsOverview = ({ padding, record }) => {
  const [filters] = useGlobalFilters();

  const srcIpLabelFieldName = `label.ip.${filters.labelContext.ip}.src`;
  const srcPortLabelFieldName = `label.port.${filters.labelContext.port}.src`;

  return (
    <Col gap="14px">
      <BodyGroupCol paddingLeft={padding} paddingRight={padding}>
        <BodyItemRow>
          <BodyNameCol>Type:</BodyNameCol>
          <BodyValueCol>
            <UniversalField field="type" value={record.type} disabled />
          </BodyValueCol>
        </BodyItemRow>

        <BodyItemRow>
          <BodyNameCol>Protocol:</BodyNameCol>
          <BodyValueCol>
            <UniversalField field="protocol" value={record.protocol} disabled />
          </BodyValueCol>
        </BodyItemRow>

        <BodyItemRow>
          <BodyNameCol>Version:</BodyNameCol>
          <BodyValueCol>
            <UniversalField field="version" value={record.version} disabled />
          </BodyValueCol>
        </BodyItemRow>

        <BodyItemRow>
          <BodyNameCol>Data Src:</BodyNameCol>
          <BodyValueCol>
            <UniversalField field="datasrc" value={record.datasrc} disabled />
          </BodyValueCol>
        </BodyItemRow>

        {record.provider && (
          <BodyItemRow>
            <BodyNameCol>Provider:</BodyNameCol>
            <BodyValueCol>
              <UniversalField
                field="providername"
                value={record.providername}
                disabled
              />
            </BodyValueCol>
          </BodyItemRow>
        )}

        <BodyItemRow>
          <BodyNameCol>RCode:</BodyNameCol>
          <BodyValueCol>
            <UniversalField field="rcode" value={record.rcode} disabled />
          </BodyValueCol>
        </BodyItemRow>
      </BodyGroupCol>

      <BodySeparator />

      <BodyGroupCol paddingLeft={padding} paddingRight={padding}>
        <BodyGroupTitleRow>Time</BodyGroupTitleRow>

        <BodyItemRow>
          <BodyNameCol>Timestamp:</BodyNameCol>
          <BodyValueCol>
            <UniversalField
              field="timestamp"
              value={record.timestamp}
              disabled
            />
          </BodyValueCol>
        </BodyItemRow>

        <BodyItemRow>
          <BodyNameCol>RTime:</BodyNameCol>
          <BodyValueCol>
            <UniversalField field="rtime" value={record.rtime} disabled />
          </BodyValueCol>
        </BodyItemRow>
      </BodyGroupCol>

      <BodySeparator />

      <BodyGroupCol paddingLeft={padding} paddingRight={padding}>
        <BodyGroupTitleRow>Traffic</BodyGroupTitleRow>

        <BodyItemRow>
          <BodyNameCol>Source:</BodyNameCol>
          <BodyValueCol>
            {!filters.labelContext.show ||
            !get(record, srcIpLabelFieldName)?.length ? (
              <UniversalField
                field="srcip"
                value={record.srcip}
                disabled
                original={record}
              />
            ) : (
              <UniversalField
                field={srcIpLabelFieldName}
                value={get(record, srcIpLabelFieldName)}
                options={{
                  useDataValueInPropertiesTray: true,
                  disabled: true,
                }}
                original={record}
              />
            )}
          </BodyValueCol>
        </BodyItemRow>

        <BodyItemRow>
          <BodyNameCol>SRC Port:</BodyNameCol>
          <BodyValueCol>
            {!filters.labelContext.show ||
            !get(record, srcPortLabelFieldName)?.length ? (
              <UniversalField
                field="srcport"
                value={record.srcport}
                disabled
                original={record}
              />
            ) : (
              <UniversalField
                field={srcPortLabelFieldName}
                value={get(record, srcPortLabelFieldName)}
                options={{
                  useDataValueInPropertiesTray: true,
                  disabled: true,
                }}
                original={record}
              />
            )}
          </BodyValueCol>
        </BodyItemRow>

        <BodyItemRow>
          <BodyNameCol>Site:</BodyNameCol>
          <BodyValueCol>
            <UniversalField field="site" value={record.site} disabled />
          </BodyValueCol>
        </BodyItemRow>
      </BodyGroupCol>

      <BodySeparator />

      <BodyGroupCol paddingLeft={padding} paddingRight={padding}>
        <BodyGroupTitleRow>Query</BodyGroupTitleRow>

        <BodyItemRow>
          <BodyNameCol>Name:</BodyNameCol>
          <BodyValueCol $whiteSpace="normal">
            <UniversalField
              field="query.name"
              value={record.query?.name}
              disabled
            />
          </BodyValueCol>
        </BodyItemRow>

        <BodyItemRow>
          <BodyNameCol>Type:</BodyNameCol>
          <BodyValueCol>
            <UniversalField
              field="query.type"
              value={record.query?.type}
              disabled
            />
          </BodyValueCol>
        </BodyItemRow>

        <BodyItemRow>
          <BodyNameCol>Host:</BodyNameCol>
          <BodyValueCol>
            <UniversalField
              field="query.host"
              value={record.query?.host}
              disabled
            />
          </BodyValueCol>
        </BodyItemRow>

        <BodyItemRow>
          <BodyNameCol>Domain:</BodyNameCol>
          <BodyValueCol $whiteSpace="normal">
            <UniversalField
              field="query.domain"
              value={record.query?.domain}
              disabled
            />
          </BodyValueCol>
        </BodyItemRow>

        <BodyItemRow>
          <BodyNameCol>Depth:</BodyNameCol>
          <BodyValueCol>
            <UniversalField
              field="query.depth"
              value={record.query?.depth}
              disabled
            />
          </BodyValueCol>
        </BodyItemRow>

        <BodyItemRow>
          <BodyNameCol>Class:</BodyNameCol>
          <BodyValueCol>
            <UniversalField
              field="query.class"
              value={record.query?.class}
              disabled
            />
          </BodyValueCol>
        </BodyItemRow>
      </BodyGroupCol>

      <BodySeparator />

      <BodyGroupCol paddingLeft={padding} paddingRight={padding}>
        <BodyGroupTitleRow>Answers</BodyGroupTitleRow>

        <BodyItemRow>
          <BodyNameCol>Answer Count:</BodyNameCol>
          <BodyValueCol>
            <UniversalField
              field="answercount"
              value={record.answercount}
              disabled
            />
          </BodyValueCol>
        </BodyItemRow>

        {record.answers?.map((answer, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <Fragment key={index}>
            <BodySeparator />

            <BodyItemRow>
              <BodyNameCol>Type:</BodyNameCol>
              <BodyValueCol>
                <UniversalField
                  field="answers.type"
                  value={answer.type}
                  disabled
                />
              </BodyValueCol>
            </BodyItemRow>

            <BodyItemRow>
              <BodyNameCol>Class:</BodyNameCol>
              <BodyValueCol>
                <UniversalField
                  field="answers.class"
                  value={answer.class}
                  disabled
                />
              </BodyValueCol>
            </BodyItemRow>

            <BodyItemRow>
              <BodyNameCol>RData:</BodyNameCol>
              <BodyValueCol>
                <UniversalField
                  field="answers.rdata"
                  value={answer.rdata}
                  disabled
                  original={record}
                />
              </BodyValueCol>
            </BodyItemRow>
          </Fragment>
        ))}
      </BodyGroupCol>
    </Col>
  );
};

DnsOverview.propTypes = {
  padding: PropTypes.string.isRequired,
  record: PropTypes.shape().isRequired,
};

export default DnsOverview;
