import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
  useResolvedPath,
} from 'react-router-dom';
import { useMeasure } from 'react-use';

import { ContextTypes } from '@/models/ContextTypes';
import RoutePaths from '@/models/RoutePaths';

import { selectors as rulesSelectors } from '@/redux/api/rules';
import { selectors as searchSelectors } from '@/redux/api/search';

import getColorScale from '@/pages/Events/getColorScale';
import { useIpRateData } from '@/pages/Events/hooks/useIpRateData';
import * as timeInterval from '@/shared/misc/timeIntervals';

import { Breadcrumb } from '+components/Breadcrumb';
import BubbleChart from '+components/charts/BubbleChart';
import { lang } from '+components/charts/common/utils';
import HeatmapChart from '+components/charts/HeatmapChart';
import GlobalFiltersSetting from '+components/GlobalFilters/Setting';
import { Col, Row } from '+components/Layout';
import { TabContent /* , Tabs, Tab */, TabsContainer } from '+components/Tabs';
import useGlobalFilters from '+hooks/useGlobalFilters';
import useLastAllowedContext from '+hooks/useLastAllowedContext';
import useLoadingIndicator from '+hooks/useLoadingIndicator';
import { formatNumber } from '+utils';
import dayjs from '+utils/dayjs';
import { timeBounds } from '+utils/timeBounds';

import AssetSummaryTab from './components/AssetSummaryTab';
import EventListTab from './components/EventListTab';
import EventSummaryTable from './components/EventSummaryTable';
import HeatmapContainer from './components/HeatmapContainer';
import EventsMitre from './components/Mitre';
import useAlertsHeatmap from './hooks/useAlertsHeatmap';
import useAlertsTableData from './hooks/useAlertsTableData';

const excludeContexts = new Set([
  ContextTypes.blocks,
  ContextTypes.traffic,
  ContextTypes.dns,
  ContextTypes.flow,
]);

const tableIdEventSummary = 'result_event_summary';
const tabs = {
  asset: { value: 'asset', label: 'Events by Asset' },
  detection: { value: 'detection', label: 'Events by Detection' },
  list: { value: 'list', label: 'Event List' },
  mitre: { value: 'mitre', label: 'Events by MITRE' },
};
const defaultTab = tabs.asset;

const getDateDiff = (start, end) =>
  start > 0 ? end - start : Math.abs(start) * 1000;

const getInterval = (diff) => {
  if (diff < timeInterval.durationHour) {
    return '1s';
  }
  if (diff < timeInterval.durationDay) {
    return '1m';
  }
  if (diff < timeInterval.durationDays30) {
    return '1h';
  }
  return '1d';
};

const formatterTooltip = function () {
  const { series, point } = this;
  return `
    <table style="width: 100%">
      <tr>
        <td>
          ${
            (series.yAxis?.axisTitle?.textStr || '').split('<br>').slice(-1)[0]
          }:
        </td>
        <td style="text-align:right">
          <b>${series.yAxis.categories[point.y]}</b>
        </td>
      </tr>
      <tr>
        <td>
          ${series.xAxis?.axisTitle?.textStr}:
        </td>
        <td style="text-align:right">
          <b>${series.xAxis.categories[point.x]}</b>
        </td>
      </tr>
      <tr>
        <td>
          Event Count:
        </td>
        <td style="text-align:right">
          <b>${point.value ?? 0}</b>
        </td>
      </tr>
    </table>
 `;
};

const totalScoreSpan = (value, color) => `
<span style="
  margin-top: 1px;
  margin-bottom: 1px;
  padding: 1px 4px;
  border-radius: 3px;
  line-height: 1;
  color: #16171a;
  font-weight: bold;
  background-color: ${color};
">
  ${formatNumber(value, 2)}
</span>
`;

const ipsTable = (ips, colorScale) => {
  const rows = ips
    .map(
      (item) => `
      <tr>
        <td>
          ${item.ip}
        </td>
        <td style="text-align:right">
          <b>${totalScoreSpan(item.totalScore, colorScale(item.totalScore))}</b>
        </td>
      </tr>
    `,
    )
    .join('\n');

  return `
    <div 
      style="
        width: 100%;
        max-height: 150px;
        overflow: hidden;
        overflow-y: auto;
        padding-right: 8px
      " 
      class="withStyledScrollbar"
    >
      <table style="width: 100%">
        ${rows}
      </table>
    </div
  `;
};

const bubblesTooltip = function (that, colorScale) {
  const { series, point } = that;
  return `
    <table style="width: 100%">
      <tr>
        <td>
          ${series.xAxis?.axisTitle?.textStr}:
        </td>
        <td style="text-align:right">
          <b>${formatNumber(point.x, 2)}</b>
        </td>
      </tr>
      <tr>
        <td>
          ${series.yAxis?.axisTitle?.textStr}:
        </td>
        <td style="text-align:right">
          <b>${formatNumber(point.y, 2)}</b>
        </td>
      </tr>
      <tr>
        <td colspan="2">
          IPs (${point.ips.length})
        </td>
      </tr>
      <tr>
        <td colspan="2">
          ${ipsTable(point.ips, colorScale)}
        </td>
      </tr>
    </table>
 `;
};

const tzOrUtc = (date) => (date?.$u ? dayjs.utc : dayjs)(date);

const Events = () => {
  const { pathname: parentPath } = useResolvedPath('.');
  const location = useLocation();
  const navigate = useNavigate();

  const context = useLastAllowedContext({
    excludeContexts,
    defaultContext: ContextTypes.alerts,
  });

  const [chartMeasureRef, { width: chartWidth }] = useMeasure();

  const isFetchingAlerts = useSelector(searchSelectors.isFetching);
  const algorithms = useSelector(rulesSelectors.getAlgorithms);

  const [filters] = useGlobalFilters(context);
  const { start: startGf, end: endGf } = timeBounds(filters);
  const [filterTitle, setFilterTitle] = useState(null);
  const [filteredChartData, setFilteredChartData] = useState([]);

  useLoadingIndicator(isFetchingAlerts);

  const userFilters = useMemo(() => {
    const search = new URLSearchParams(location.search);
    const algorithm = search.get('algorithm') || null;
    const ip = search.get('ip') || null;
    let score = search.get('score') || null;
    score = score !== 'all';
    const start = +(search.get('from') || startGf);
    const end = +(search.get('to') || endGf);
    const isCustomPeriod = start !== startGf || end !== endGf;
    return {
      algorithm,
      ip,
      score,
      start,
      end,
      diff: getDateDiff(start, end),
      isCustomPeriod,
    };
  }, [location.search, startGf, endGf]);

  const interval = useMemo(
    () => getInterval(userFilters.diff),
    [userFilters.diff],
  );

  const tabId = useMemo(() => {
    switch (true) {
      case location.pathname.startsWith(
        `${parentPath}/${RoutePaths.eventsList.pageName}`,
      ):
        return tabs.list.value;
      case location.pathname.startsWith(
        `${parentPath}/${RoutePaths.eventsDetection.pageName}`,
      ):
        return tabs.detection.value;
      case location.pathname.startsWith(
        `${parentPath}/${RoutePaths.eventsMitre.pageName}`,
      ):
        return tabs.mitre.value;
      default:
        return defaultTab.value;
    }
  }, [location.pathname]);

  const currentTab = tabs[tabId] || defaultTab;

  const eventSummaryTableData = useAlertsTableData(
    filters,
    userFilters,
    algorithms,
  );

  const [isTsFetching, heatmapData, heatmapPollingHeartbeat] = useAlertsHeatmap(
    filters,
    userFilters,
    interval,
  );

  const [assetTableData, assetChartData, scoreMinMax] = useIpRateData(
    filters,
    userFilters,
    algorithms,
  );

  useEffect(() => {
    if (isTsFetching) {
      return;
    }

    setFilterTitle(
      userFilters.isCustomPeriod ? heatmapData?.filterTitle : null,
    );
  }, [isTsFetching, userFilters.isCustomPeriod, heatmapData?.filterTitle]);

  const yAxis = useMemo(
    () => (heatmapData?.yAxisCategories || []).map((item) => item.title),
    [heatmapData],
  );

  const handlePlotClick = useCallback(
    (event) => {
      if (event?.point?.value == null) {
        return;
      }

      let from;
      let to;

      const { x, y } = event.point;

      switch (heatmapData.interval) {
        case '1d':
          from = tzOrUtc(heatmapData.yAxisCategories[y].date).set(
            'date',
            x + 1,
          );
          to = from.add(1, 'day');
          break;
        case '1h':
          from = tzOrUtc(heatmapData.yAxisCategories[y].date).set('hour', x);
          to = from.add(1, 'hour');
          break;
        case '1m':
          from = tzOrUtc(heatmapData.yAxisCategories[y].date).set('minute', x);
          to = from.add(1, 'minute');
          break;
        case '1s':
          from = tzOrUtc(heatmapData.yAxisCategories[y].date).set('second', x);
          to = from.add(1, 'second');
          break;
        default:
          break;
      }

      if (from && to) {
        const search = new URLSearchParams(location.search);
        search.set('from', +from);
        search.set('to', +to);
        navigate({ search: search.toString() });
      }
    },
    [
      heatmapData?.yAxisCategories,
      heatmapData?.interval,
      location.search,
      navigate,
    ],
  );

  const calculateFilter = (a) => (str) => (b) => {
    switch (str) {
      case '>':
        return a > b;
      case '<':
        return a < b;
      case '==':
        return a === b;
      default:
        return false;
    }
  };

  const onFilterUpdate = useCallback(
    (tableFilters) => {
      let updatedFilters = Object.assign([], assetChartData);

      tableFilters.forEach((filter) => {
        updatedFilters = updatedFilters.filter((data) => {
          if (filter.id === 'ip' && data.value.startsWith(filter.value.value)) {
            return true;
          }

          if (
            filter.id === 'categories' &&
            data.categories.has(filter.value.value)
          ) {
            return true;
          }

          if (
            filter.id === 'algorithms' &&
            data.algorithms.has(filter.value.value)
          ) {
            return true;
          }

          return calculateFilter(data[filter.id])(filter.value.operator)(
            filter.value.value,
          );
        });
      });

      setFilteredChartData(updatedFilters);
    },
    [assetChartData],
  );

  const colorScale = useMemo(() => getColorScale(scoreMinMax), [scoreMinMax]);

  const bubblesFormatterTooltip = useCallback(
    function () {
      return bubblesTooltip(this, colorScale);
    },
    [colorScale],
  );

  const excludeContextsArr = useMemo(
    () => Array.from(excludeContexts),
    [excludeContexts],
  );

  return (
    <Fragment>
      <GlobalFiltersSetting
        nql={currentTab.value !== tabs.asset.value}
        context={context}
        excludeContexts={excludeContextsArr}
        customers
      />

      <Breadcrumb title={`Events — ${currentTab.label}`} />

      <Row spacing={1}>
        {currentTab !== tabs.mitre && (
          <Col xs={12} item container={false}>
            <TabsContainer>
              <TabContent>
                <Col ref={chartMeasureRef} xs={12} item container={false}>
                  {currentTab !== tabs.asset ? (
                    <HeatmapContainer $clickable={userFilters.diff > 1000}>
                      <HeatmapChart
                        title={heatmapData?.title}
                        data={heatmapData?.data}
                        yAxis={yAxis}
                        xAxis={heatmapData?.xAxisCategories}
                        onPlotClick={handlePlotClick}
                        xfield={heatmapData?.field?.x}
                        yfield={heatmapData?.field?.y}
                        minColor="#3c40c6"
                        maxColor="#2afadc"
                        metric="Event"
                        marginBottom={60}
                        xAxisLabelRotation={0}
                        width={chartWidth}
                        height={Math.max(heatmapData?.height || 0, 300)}
                        alternateGridColor="transparent"
                        formatterTooltip={formatterTooltip}
                        yAxisReversed={false}
                        xAxisReversed={false}
                        loading={!heatmapData?.data?.length && isTsFetching}
                      />
                    </HeatmapContainer>
                  ) : (
                    <div>
                      <BubbleChart
                        xfield="Threat Score"
                        yfield="Confidence Score"
                        width={chartWidth}
                        data={
                          filteredChartData?.length
                            ? filteredChartData
                            : assetChartData
                        }
                        colorVariety={10}
                        colorIndexMin={0}
                        colorIndexMax={9}
                        formatterTooltip={bubblesFormatterTooltip}
                        tooltipStickOnContact
                      />
                    </div>
                  )}
                </Col>
              </TabContent>
            </TabsContainer>
          </Col>
        )}
        <Col xs={12} item container={false}>
          <Routes>
            <Route
              index
              element={
                <AssetSummaryTab
                  data={assetTableData}
                  scoreMinMax={scoreMinMax}
                  userFilters={userFilters}
                  onTableFilterUpdate={onFilterUpdate}
                />
              }
            />

            <Route
              path="summary/*"
              element={
                <Navigate
                  to={{
                    pathname: `../${RoutePaths.eventsDetection.pageName}`,
                    search: location?.search,
                  }}
                  replace
                />
              }
            />

            <Route
              path={`${RoutePaths.eventsDetection.pageName}/*`}
              element={
                <EventSummaryTable
                  id={tableIdEventSummary}
                  data={eventSummaryTableData}
                  userFilters={userFilters}
                  noDataText={
                    eventSummaryTableData
                      ? 'There are no events.'
                      : lang.loading
                  }
                  fillWithEmptyRows
                />
              }
            />

            <Route
              path={`${RoutePaths.eventsList.pageName}/*`}
              element={
                <EventListTab
                  userFilters={userFilters}
                  refresher={heatmapPollingHeartbeat}
                  timeFilter={filterTitle}
                />
              }
            />

            <Route
              path={`${RoutePaths.eventsMitre.pageName}/*`}
              element={<EventsMitre userFilters={userFilters} />}
            />

            <Route path="*" element={<Navigate to={parentPath} replace />} />
          </Routes>
        </Col>
      </Row>
    </Fragment>
  );
};

export default Events;
