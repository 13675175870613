import PropTypes from '+prop-types';
import { useMemo } from 'react';

import get from 'lodash.get';

import TCPFlagLabel from '+components/Labels/TCPFlagLabel';
import { Col, Row } from '+components/Layout';
import { CellTag, UniversalCell } from '+components/Table/Cells';
import useGlobalFilters from '+hooks/useGlobalFilters';

import {
  BodyGroupCol,
  BodyGroupTitleRow,
  BodyItemRow,
  BodyNameCol,
  BodySeparator,
  BodyValueCol,
} from '../../BodyComponents';

const FlowOverview = ({ padding, record }) => {
  const [filters] = useGlobalFilters();

  const row = useMemo(() => ({ original: record }), [record]);

  const srcIpLabelFieldName = `label.ip.${filters.labelContext.ip}.src`;
  const srcPortLabelFieldName = `label.port.${filters.labelContext.port}.src`;
  const dstIpLabelFieldName = `label.ip.${filters.labelContext.ip}.dst`;
  const dstPortLabelFieldName = `label.port.${filters.labelContext.port}.dst`;

  return (
    <Col gap="14px">
      <BodyGroupCol paddingLeft={padding} paddingRight={padding}>
        <BodyItemRow>
          <BodyNameCol>Flow Type:</BodyNameCol>
          <BodyValueCol>
            {UniversalCell('flowtype')({
              row,
              value: record.flowtype,
              options: { disabled: true },
            })}
          </BodyValueCol>
        </BodyItemRow>

        <BodyItemRow>
          <BodyNameCol>Protocol:</BodyNameCol>
          <BodyValueCol>
            {UniversalCell('protocol')({
              row,
              value: record.protocol,
              options: { disabled: true },
            })}
          </BodyValueCol>
        </BodyItemRow>

        <BodyItemRow>
          <BodyNameCol>Flow Version:</BodyNameCol>
          <BodyValueCol>
            {UniversalCell('flowversion')({
              row,
              value: record.flowversion,
              options: { disabled: true },
            })}
          </BodyValueCol>
        </BodyItemRow>

        <BodyItemRow>
          <BodyNameCol>IP Version:</BodyNameCol>
          <BodyValueCol>
            {UniversalCell('ipversion')({
              row,
              value: record.ipversion,
              options: { disabled: true },
            })}
          </BodyValueCol>
        </BodyItemRow>

        <BodyItemRow>
          <BodyNameCol>Packets:</BodyNameCol>
          <BodyValueCol>
            {UniversalCell('packets')({
              row,
              value: record.packets,
              options: { disabled: true },
            })}
          </BodyValueCol>
        </BodyItemRow>

        <BodyItemRow>
          <BodyNameCol>Sample Rate:</BodyNameCol>
          <BodyValueCol>
            {UniversalCell('samplerate')({
              row,
              value: record.samplerate,
              options: { disabled: true },
            })}
          </BodyValueCol>
        </BodyItemRow>
      </BodyGroupCol>

      <BodySeparator />

      <BodyGroupCol paddingLeft={padding} paddingRight={padding}>
        <BodyGroupTitleRow>Time</BodyGroupTitleRow>

        <BodyItemRow>
          <BodyNameCol>Start:</BodyNameCol>
          <BodyValueCol>
            {UniversalCell('start')({
              row,
              value: record.start,
              options: { disabled: true },
            })}
          </BodyValueCol>
        </BodyItemRow>

        <BodyItemRow>
          <BodyNameCol>End:</BodyNameCol>
          <BodyValueCol>
            {UniversalCell('end')({
              row,
              value: record.end,
              options: { disabled: true },
            })}
          </BodyValueCol>
        </BodyItemRow>

        <BodyItemRow>
          <BodyNameCol>Duration:</BodyNameCol>
          <BodyValueCol>
            {UniversalCell('duration')({
              row,
              value: record.duration,
              options: { unit: 'milliseconds', disabled: true },
            })}
          </BodyValueCol>
        </BodyItemRow>

        <BodyItemRow>
          <BodyNameCol>Flowtime:</BodyNameCol>
          <BodyValueCol>
            {UniversalCell('flowrtime')({
              row,
              value: record.flowrtime,
              options: { disabled: true },
            })}
          </BodyValueCol>
        </BodyItemRow>

        <BodyItemRow>
          <BodyNameCol>Timestamp:</BodyNameCol>
          <BodyValueCol>
            {UniversalCell('timestamp')({
              row,
              value: record.timestamp,
              options: { disabled: true },
            })}
          </BodyValueCol>
        </BodyItemRow>
      </BodyGroupCol>

      <BodySeparator />

      <BodyGroupCol paddingLeft={padding} paddingRight={padding}>
        <BodyGroupTitleRow>Traffic</BodyGroupTitleRow>

        <BodyItemRow>
          <BodyNameCol>Source:</BodyNameCol>
          <BodyValueCol>
            {!filters.labelContext.show ||
            !get(record, srcIpLabelFieldName)?.length
              ? UniversalCell('srcip')({
                  row,
                  value: record.srcip,
                  options: { disabled: true },
                })
              : UniversalCell(srcIpLabelFieldName)({
                  row,
                  value: get(record, srcIpLabelFieldName),
                  options: {
                    useDataValueInPropertiesTray: true,
                    disabled: true,
                  },
                })}
          </BodyValueCol>
        </BodyItemRow>

        <BodyItemRow>
          <BodyNameCol>SRC Port:</BodyNameCol>
          <BodyValueCol>
            {!filters.labelContext.show ||
            !get(record, srcPortLabelFieldName)?.length
              ? UniversalCell('srcport')({
                  row,
                  value: record.srcport,
                  options: { disabled: true },
                })
              : UniversalCell(srcPortLabelFieldName)({
                  row,
                  value: get(record, srcPortLabelFieldName),
                  options: {
                    useDataValueInPropertiesTray: true,
                    disabled: true,
                  },
                })}
          </BodyValueCol>
        </BodyItemRow>

        <BodyItemRow>
          <BodyNameCol>Destination:</BodyNameCol>
          <BodyValueCol>
            {!filters.labelContext.show ||
            !get(record, dstIpLabelFieldName)?.length
              ? UniversalCell('dstip')({
                  row,
                  value: record.dstip,
                  options: { disabled: true },
                })
              : UniversalCell(dstIpLabelFieldName)({
                  row,
                  value: get(record, dstIpLabelFieldName),
                  options: {
                    useDataValueInPropertiesTray: true,
                    disabled: true,
                  },
                })}
          </BodyValueCol>
        </BodyItemRow>

        <BodyItemRow>
          <BodyNameCol>DST Port:</BodyNameCol>
          <BodyValueCol>
            {!filters.labelContext.show ||
            !get(record, dstPortLabelFieldName)?.length
              ? UniversalCell('dstport')({
                  row,
                  value: record.dstport,
                  options: { disabled: true },
                })
              : UniversalCell(dstPortLabelFieldName)({
                  row,
                  value: get(record, dstPortLabelFieldName),
                  options: {
                    useDataValueInPropertiesTray: true,
                    disabled: true,
                  },
                })}
          </BodyValueCol>
        </BodyItemRow>

        <BodyItemRow>
          <BodyNameCol>Site:</BodyNameCol>
          <BodyValueCol>
            {UniversalCell('site')({
              row,
              value: record.site,
              options: { disabled: true },
            })}
          </BodyValueCol>
        </BodyItemRow>
      </BodyGroupCol>

      <BodySeparator />

      <BodyGroupCol paddingLeft={padding} paddingRight={padding}>
        <BodyGroupTitleRow>Metrics</BodyGroupTitleRow>

        <BodyItemRow>
          <BodyNameCol>Bits:</BodyNameCol>
          <BodyValueCol>
            {UniversalCell('bits')({
              row,
              value: record.bits,
              options: { disabled: true },
            })}
          </BodyValueCol>
        </BodyItemRow>

        <BodyItemRow>
          <BodyNameCol>Bits x Rate:</BodyNameCol>
          <BodyValueCol>
            {UniversalCell('bitsxrate')({
              row,
              value: record.bitsxrate,
              options: { disabled: true },
            })}
          </BodyValueCol>
        </BodyItemRow>

        <BodyItemRow>
          <BodyNameCol>Flow Bit Rate:</BodyNameCol>
          <BodyValueCol>
            {UniversalCell('flowbrate')({
              row,
              value: record.flowbrate,
              options: { disabled: true },
            })}
          </BodyValueCol>
        </BodyItemRow>

        <BodyItemRow>
          <BodyNameCol>Flow Packet Rate:</BodyNameCol>
          <BodyValueCol>
            {UniversalCell('flowprate')({
              row,
              value: record.flowprate,
              options: { disabled: true },
            })}
          </BodyValueCol>
        </BodyItemRow>

        <BodyItemRow>
          <BodyNameCol>Packets x Rate:</BodyNameCol>
          <BodyValueCol>
            {UniversalCell('packetsxrate')({
              row,
              value: record.packetsxrate,
              options: { disabled: true },
            })}
          </BodyValueCol>
        </BodyItemRow>
      </BodyGroupCol>

      <BodySeparator />

      <BodyGroupCol paddingLeft={padding} paddingRight={padding}>
        <BodyGroupTitleRow>Tags</BodyGroupTitleRow>

        <Row gap="5px">
          {record.tags?.map((tag) => (
            <CellTag key={tag} field="tags" value={tag} disabled />
          ))}
        </Row>
      </BodyGroupCol>

      <BodySeparator />

      <BodyGroupCol paddingLeft={padding} paddingRight={padding}>
        <BodyGroupTitleRow>TCP Flags</BodyGroupTitleRow>

        <Row gap="5px">
          {Object.entries(record.tcpflags || {}).map(([flag, flagValue]) => (
            <TCPFlagLabel key={flag} $flag={flagValue && flag.toLowerCase()}>
              {flag.toUpperCase()}
            </TCPFlagLabel>
          ))}
        </Row>
      </BodyGroupCol>
    </Col>
  );
};

FlowOverview.propTypes = {
  padding: PropTypes.string.isRequired,
  record: PropTypes.shape().isRequired,
};

export default FlowOverview;
