import PropTypes from '+prop-types';
import { memo, useCallback } from 'react';

import { ContextTypes, ContextTypesLabels } from '@/models/ContextTypes';
import { CustomType } from '@/models/CustomType';

import RealTimeTraffic from '@/pages/RealTimeTraffic';

import Button, { ButtonVariants } from '+components/Button';
import { Col, LayoutTypes, Row } from '+components/Layout';
import { usePageTabs } from '+components/PageTabs';
import { getFlowDataFromRecord } from '+utils/getFlowDataFromRecord';
import { getSearchUrl } from '+utils/getSearchUrl';
import makeArr from '+utils/makeArr';

import { TableContainer } from './TableContainer';

const allowedOnlyFlow = new Set([ContextTypes.flow]);
const allowedOnlyDns = new Set([ContextTypes.dns]);

const ContextLabels = {
  ...ContextTypesLabels,
  [ContextTypes.flow]: 'Flows',
};

const HeaderButton = (onSearch) => (
  <Button variant={ButtonVariants.outlined} onClick={onSearch} size="medium">
    Full Search
  </Button>
);

export const TrafficTable = memo((props) => {
  const { event, isSubAccountRecord } = props;

  const [, , pageTabMethods] = usePageTabs();

  const eventContext = event?.traffictype || ContextTypes.flow;

  const onSearch = useCallback(() => {
    const { nql, from, to } = getFlowDataFromRecord({
      record: event,
      type: ContextTypes.alerts,
    });

    const nqlAsArr = makeArr(nql);

    const url = getSearchUrl({
      context: eventContext,
      period: {
        type: CustomType,
      },
      from,
      to,
      autoRefresh: false,
      nql: eventContext === ContextTypes.dns ? nqlAsArr[0] : nqlAsArr,
      customer: isSubAccountRecord ? event?.customer : undefined,
    });
    pageTabMethods.add(url);
  }, [event, eventContext, isSubAccountRecord]);

  const headerButtonProp = useCallback(
    () => HeaderButton(onSearch),
    [onSearch],
  );

  return (
    <Row $type={LayoutTypes.card}>
      <Col>
        <TableContainer item container={false}>
          <RealTimeTraffic
            title={ContextLabels[eventContext]}
            globalFiltersDisabled
            pageTitleDisabled
            minRows={1}
            defaultContext={eventContext}
            allowedContexts={
              eventContext === ContextTypes.dns
                ? allowedOnlyDns
                : allowedOnlyFlow
            }
            defaultPageSize={10}
            tableId={`EventDetailsTrafficTable-${eventContext}`}
            tableHeaderButton={headerButtonProp}
          />
        </TableContainer>
      </Col>
    </Row>
  );
});

TrafficTable.displayName = 'TrafficTable';

TrafficTable.propTypes = {
  event: PropTypes.object,
  isSubAccountRecord: PropTypes.bool,
};

TrafficTable.defaultProps = {
  event: undefined,
  isSubAccountRecord: false,
};
