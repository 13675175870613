import PropTypes from '+prop-types';
import { forwardRef, useCallback, useEffect, useMemo, useRef } from 'react';
import { useDragLayer } from 'react-dnd';
import {
  makePropGetter,
  useColumnOrder,
  useExpanded,
  useFilters,
  useGroupBy,
  usePagination,
  useSortBy,
  useTable,
} from 'react-table';
import { useMeasure } from 'react-use';

import classNames from 'classnames';
import isEqual from 'lodash.isequal';
import isFunction from 'lodash.isfunction';

import { useGroupByColumnMethodsExtender } from '+components/Table/ReactTable/hooks/useGroupByColumnMethodsExtender';
import { preserveRef } from '+utils/react';

import { defaultRowHeight, technicalColumns } from '../constants';
import Body from './components/Body';
import { Container, Table } from './components/Components';
import DefaultColumn from './components/DefaultColumn';
import Header from './components/Header';
import { useDefaultColumnOrder } from './hooks/useDefaultColumnOrder';
import { useDuplicates } from './hooks/useDuplicates';
import { useExpanderColumn } from './hooks/useExpanderColumn';
import { useFiltersColumnMethodsExtender } from './hooks/useFiltersColumnMethodsExtender';
import { useFixedFlexLayout } from './hooks/useFixedFlexLayout';
import { useGroupByAggregators } from './hooks/useGroupByAggregators';
import { useGroupByConsolidateOneItemsGroups } from './hooks/useGroupByConsolidateOneItemsGroups';
import { useGroupByExpandArrayValues } from './hooks/useGroupByExpandArrayValues';
import { useGroupByExpandEmptyGroups } from './hooks/useGroupByExpandEmptyGroups';
import { useGroupByHideEmptyGroups } from './hooks/useGroupByHideEmptyGroups';
import { useMenuColumn } from './hooks/useMenuColumn';
import { useMoveColumn } from './hooks/useMoveColumn';
import { useOnChangeActionsFactory } from './hooks/useOnChangeActionsFactory';
import { useResizeColumns } from './hooks/useResizeColumns';
import { useRowSelect } from './hooks/useRowSelect';
import { useRowSelectorColumn } from './hooks/useRowSelectorColumn';
import { useTotalColumn } from './hooks/useTotalColumn';
import * as filterTypes from './filterTypes';
import * as sortTypes from './sortTypes';
import { defaultGroupByFn, getProps } from './utils';

// uncomment the next line for using Columns SubComponents
// import { useExpandForColumns } from './hooks/useExpandForColumns';

const getToggleExpandOnlyOneProps = (props, meta) => [
  props,
  {
    onClick: () => {
      const {
        row,
        instance: {
          state: { expanded },
          toggleRowExpanded,
        },
      } = meta;

      Object.keys(expanded || {}).forEach((id) => {
        if (row?.id !== id) {
          toggleRowExpanded(id, false);
        }
      });

      row?.toggleRowExpanded?.();
    },
  },
];

const defaultColumn = DefaultColumn();

const ReactTable = forwardRef((props, ref) => {
  const {
    title,
    columns,
    data,
    style,
    className,
    minRows,
    fillWithEmptyRows,
    noDataText,
    autoHeight,
    zebra,

    // sorting
    sortBy,
    disableSortBy,
    autoResetSortBy: initialAutoResetSortBy,
    onSortByChange,

    onColumnsChange,

    // hidden columns
    onHiddenColumnsChange,
    hiddenColumns: initialHiddenColumns,

    // column order
    onColumnOrderChange,
    disableDragAndDrop,
    columnOrder: initialColumnOrder,

    // column widths
    onColumnWidthsChange,
    columnWidths,

    // expander
    SubComponent,
    expanded,
    onExpandedChange,
    expanderInHeader,
    expandOnlyOne,
    expandSingleSubRow,
    paginateExpandedRows: initialPaginateExpandedRows,
    autoResetExpanded: initialAutoResetExpanded,

    // pagination
    PaginationComponent,
    onPageSizeChange,
    onPageIndexChange,
    pageIndex,
    pageSize,
    boundaryCount,
    pageSizeOptions,
    showPageSizeOptions,
    autoResetPage: initialAutoResetPage,
    resetPageIndex,
    serverPagination,
    pageCount: initialPageCount,
    rowsCount: initialRowsCount,

    // getting global props
    getTableProps: initialGetTableProps,
    getHeaderGroupProps,
    getHeaderProps,
    getFilterProps,
    getRowProps,
    getCellProps,
    getRowId: initialGetRowId,

    // filters
    filters,
    externalFilters,
    disableFilters,
    autoResetFilters: initialAutoResetFilters,
    onFiltersChange,
    onClearExternalFilters,

    // header
    ExternalHeaderControls,

    // grouping
    groupBy,
    groupByFn,
    disableGroupBy,
    autoResetGroupBy: initialAutoResetGroupBy,
    onGroupByChange,

    // aggregators
    aggregates,
    disableAggregators,
    onAggregatesChange,

    // duplicate
    duplicates,
    disableDuplicateBy,
    onDuplicatesChange,

    focusOnRowId,

    // virtualization
    virtualized,
    rowHeight,

    // rowSelector
    getIsRowSelectorDisabled,
    selectByPage,
    onSelectedRowsChange,
    autoResetSelectedRows: initialAutoResetSelectedRows,
    onColumnsDraggingChange,

    // other
    testId,
    showMenu,
    isStaticTable,
  } = props;

  const [refForResize, { width }] = useMeasure();

  const getRowId = useCallback(
    (row, relativeIndex, parent) => {
      if (isFunction(initialGetRowId)) {
        return initialGetRowId(row, relativeIndex, parent);
      }

      return parent
        ? [parent.id, row?.id ?? relativeIndex].join('.')
        : row?.id ?? relativeIndex;
    },
    [initialGetRowId],
  );

  const useExpanderOnlyOne = useCallback(
    (hooks) => {
      // TODO: Move to the hook
      if (!expandOnlyOne) {
        return;
      }

      hooks.getToggleRowExpandedProps.push(getToggleExpandOnlyOneProps);
    },
    [expandOnlyOne],
  );

  const fixedColumns = useMemo(() => columns || [], [columns]);

  const refTable = useRef();

  const refTableScroll = useCallback((scroll) => {
    // because Table is styled smooth-scrollbar
    refTable.current = scroll?.containerEl;
  }, []);

  const fixedExpanded = useMemo(() => expanded || {}, [expanded]);

  const isColumnDragging = useDragLayer(
    (monitor) => monitor.isDragging() && monitor.getItemType() === 'column',
  );

  const instance = useTable(
    {
      columns: fixedColumns,
      data,
      initialState: {
        sortBy: sortBy || [],
        groupBy: groupBy || [],
        filters: filters || [],
        aggregates: aggregates || [],
        duplicates: duplicates || [],
        pageSize: pageSize || 10,
        pageIndex: pageIndex || 0,
        expanded: fixedExpanded,
        columnWidths: columnWidths || {},
        selectedRowIds: {},
        tableMargin: isStaticTable ? 10 : 0,
      },
      defaultColumn,
      filterTypes,
      sortTypes,
      disableFilters,
      disableGroupBy,
      disableAggregators,
      disableDuplicateBy,
      disableSortBy,
      disableDragAndDrop,

      getRowId,

      getIsRowSelectorDisabled,
      selectByPage,

      paginateExpandedRows: initialPaginateExpandedRows ?? false,
      autoResetExpanded: initialAutoResetExpanded ?? false,
      autoResetFilters: initialAutoResetFilters ?? false,
      autoResetGroupBy: initialAutoResetGroupBy ?? false,
      autoResetSortBy: initialAutoResetSortBy ?? false,
      autoResetPage: initialAutoResetPage ?? false,
      autoResetResize: false,
      autoResetHiddenColumns: false,
      autoResetSelectedRows: initialAutoResetSelectedRows ?? false,
      groupByFn: groupByFn || defaultGroupByFn,
      manualPagination: serverPagination,
      ...(serverPagination && { pageCount: initialPageCount }),
      showMenu,
    },

    useColumnOrder,

    useMenuColumn(showMenu),
    useTotalColumn,

    useGroupByExpandArrayValues,
    useFilters,
    useFiltersColumnMethodsExtender,

    useGroupByAggregators,
    useGroupBy,
    useGroupByColumnMethodsExtender,

    useSortBy,

    useGroupByExpandEmptyGroups,
    useGroupByConsolidateOneItemsGroups,
    useExpanded,
    useExpanderOnlyOne,
    // useExpanderColumn,
    useExpanderColumn({
      SubComponent,
      expanderInHeader,
      expandSingleSubRow,
    }),
    useGroupByHideEmptyGroups,

    usePagination,
    useRowSelect,
    useRowSelectorColumn,

    useResizeColumns,
    useFixedFlexLayout(refTable),
    useMoveColumn,
    useDefaultColumnOrder,
    useDuplicates,
    // uncomment the next line for using Columns SubComponents
    // useExpandForColumns,
  );

  useEffect(() => {
    if (!ref) {
      return;
    }
    preserveRef(ref, instance);
  }, [ref, instance]);

  const {
    getTableProps,
    headerGroups,
    getTableBodyProps,
    prepareRow,
    page,
    rows,
    state,
    allColumns,

    // column order
    setColumnOrder,

    // column widths
    setColumnWidths,

    // hidden columns
    setHiddenColumns,

    // filters
    setAllFilters,

    // sorting
    setSortBy,

    // groupBy
    setGroupBy,

    // aggregators
    setAllAggregates,

    // duplicate
    setAllDuplicates,

    // expander
    getToggleAllRowsExpandedProps,

    // paginator
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
  } = instance;

  const rowsCount = serverPagination ? initialRowsCount : rows?.length;

  const fakeRows = useMemo(() => {
    if (serverPagination) {
      // if data is same length as pageSize no fake rows needed
      if (data.length === state.pageSize) {
        return [];
      }

      // if paging with server side pagination, maintain table height with empty rows
      if (!data.length) {
        return Array.from({ length: state.pageSize }, (_, i) => i);
      }
    }

    if (
      !state.pageIndex &&
      (state.pageSize <= page.length || (!fillWithEmptyRows && minRows < 1))
    ) {
      return [];
    }

    // if the first page then show the minRows
    let length = state.pageIndex && page.length ? 0 : minRows - page.length;

    // if need to fill with empty rows
    if (fillWithEmptyRows && state.pageIndex) {
      length = state.pageSize - page.length;
    }

    length = Math.max(length, 0);

    return Array.from({ length }, (_, i) => i);
  }, [
    data,
    serverPagination,
    minRows,
    page.length,
    rowsCount,
    fillWithEmptyRows,
    state.pageSize,
    state.pageIndex,
  ]);

  const onPageReset = useRef();

  const doSortByChange = useCallback(
    (value) => {
      // Don't reset page or adjust sort if sort hasn't changed
      if (isEqual(value, sortBy)) {
        return;
      }
      onPageReset.current?.();
      onSortByChange?.(value);
    },
    [sortBy, onSortByChange],
  );

  const doFiltersChange = useCallback(
    (value) => {
      const localValue = value.map((item) => ({
        id: item.id,
        value: { value: item.value.value, operator: item.value.operator },
      }));

      const localFilters = filters.map((item) => ({
        id: item.id,
        value: { value: item.value.value, operator: item.value.operator },
      }));

      // Don't reset page or adjust filters if filter hasn't changed
      if (isEqual(localValue, localFilters)) {
        return;
      }
      onPageReset.current?.();
      onFiltersChange?.(value);
    },
    [filters, onFiltersChange],
  );

  const doGroupByChange = useCallback(
    (value) => {
      if (isEqual(value, groupBy)) {
        return;
      }

      onPageReset.current?.();

      // If groupBy is empty, clear aggregates
      if (!value?.length) {
        setAllAggregates?.([]);
        onGroupByChange?.(value);
        return;
      }

      // Filter out aggregates that are included in groupBy
      const filteredAggregates = aggregates?.filter(
        (item) => !value.includes(item.id),
      );
      if (aggregates?.length !== filteredAggregates?.length) {
        setAllAggregates?.(filteredAggregates);
      }
      onGroupByChange?.(value);
    },
    [groupBy, aggregates, setAllAggregates, onGroupByChange],
  );

  useEffect(() => {
    if (pageSize < 1 || !isFunction(setPageSize)) {
      return;
    }
    setPageSize(
      PaginationComponent ? pageSize : Math.max(rows?.length, pageSize),
    );
  }, [PaginationComponent, setPageSize, pageSize, rows?.length]);

  const pageResetTimeout = useRef();
  useEffect(() => {
    onPageReset.current = () => {
      if (pageResetTimeout.current) {
        clearTimeout(pageResetTimeout.current);
      }

      pageResetTimeout.current = setTimeout(() => {
        gotoPage(0);
      }, 10);
    };

    return () => {
      if (pageResetTimeout.current) {
        clearTimeout(pageResetTimeout.current);
      }
    };
  }, [gotoPage]);

  // While viewing a table with serverPagination, if page size is changed, set page to page 0.
  useEffect(() => {
    if (serverPagination) {
      gotoPage(0);
    }
  }, [pageSize, serverPagination]);

  useEffect(() => {
    if (state.pageIndex !== pageIndex) {
      gotoPage(pageIndex || 0);
    }
  }, [pageIndex]);

  useEffect(() => {
    if (resetPageIndex != null) {
      gotoPage(0);
    }
  }, [resetPageIndex]);

  const fixedColumnOrder = useMemo(
    () => initialColumnOrder || [],
    [initialColumnOrder],
  );

  const fixedHiddenColumns = useMemo(
    () => initialHiddenColumns || [],
    [initialHiddenColumns],
  );

  useEffect(() => {
    onColumnsDraggingChange?.(isColumnDragging);
  }, [onColumnsDraggingChange, isColumnDragging]);

  useEffect(() => {
    onColumnsChange?.(allColumns, fixedHiddenColumns, technicalColumns);
  }, [allColumns, onColumnsChange, fixedHiddenColumns]);

  useEffect(() => {
    if (isColumnDragging) {
      return;
    }
    setColumnOrder?.((prev) =>
      isEqual(prev, fixedColumnOrder) ? prev : fixedColumnOrder || [],
    );
  }, [isColumnDragging, setColumnOrder, fixedColumnOrder]);

  const isResizing = instance.state.columnResizing?.isResizingColumn != null;

  useEffect(() => {
    if (isResizing) {
      return;
    }
    setColumnWidths?.(columnWidths);
  }, [setColumnWidths, columnWidths]);

  useEffect(() => {
    setHiddenColumns((prev) =>
      isEqual(prev, fixedHiddenColumns) ? prev : fixedHiddenColumns || [],
    );
  }, [setHiddenColumns, fixedHiddenColumns]);

  useEffect(() => {
    if (state.filters !== filters) {
      setAllFilters?.(filters || []);
    }
  }, [filters]);

  useEffect(() => {
    if (state.sortBy !== sortBy) {
      setSortBy?.(sortBy || []);
    }
  }, [sortBy]);

  useEffect(() => {
    if (state.groupBy !== groupBy) {
      setGroupBy?.(groupBy || []);
    }
  }, [groupBy]);

  useEffect(() => {
    if (state.aggregates !== aggregates) {
      setAllAggregates?.(aggregates || []);
    }
  }, [aggregates]);

  useEffect(() => {
    if (state.duplicates !== duplicates) {
      setAllDuplicates?.(duplicates || []);
    }
  }, [duplicates]);

  useOnChangeActionsFactory(state, {
    onExpandedChange,
    onColumnOrderChange,
    onHiddenColumnsChange,
    onColumnWidthsChange,
    onPageSizeChange,
    onPageIndexChange,
    onSortByChange: doSortByChange,
    onFiltersChange: doFiltersChange,
    onGroupByChange: doGroupByChange,
    onAggregatesChange,
    onSelectedRowsChange,
    onDuplicatesChange,
  });

  const lastFocusRowId = useRef();
  useEffect(() => {
    if (
      !focusOnRowId ||
      !rows?.length ||
      lastFocusRowId.current === focusOnRowId
    ) {
      return;
    }

    const index = rows.findIndex((row) => row.id === focusOnRowId);
    if (index < 0) {
      return;
    }

    gotoPage(Math.floor(index / state.pageSize));
    lastFocusRowId.current = focusOnRowId;
  }, [focusOnRowId, rows, state.pageSize]);

  useEffect(() => {
    if (serverPagination) {
      return;
    }

    if (!rows?.length) {
      return;
    }

    if (state.pageSize * state.pageIndex > rows.length) {
      gotoPage(Math.floor((rows.length - 1) / state.pageSize));
    }
  }, [state.pageIndex, state.pageSize, rows?.length, serverPagination]);

  const containerStyle = useMemo(() => style || {}, [style]);

  const tableWidth = useMemo(
    () => ({
      style: {
        width: Math.max((width ?? 0) - (state.tableMargin || 0), 0) || '100%',
      },
    }),
    [state.tableMargin, width],
  );

  let tableProps = getTableProps(tableWidth);

  const headerProps = {
    title,
    style: tableProps.style,
    headerGroups,
    getToggleAllRowsExpandedProps,
    expanderInHeader,
    getHeaderProps,
    setAllFilters,
    getFilterProps,
    getHeaderGroupProps,
    disableDragAndDrop,
    isResizing,
    disableFilters: instance.disableFilters,
    disableGroupBy: instance.disableGroupBy,
    disableAggregators: instance.disableAggregators,
    isGrouped: !!groupBy?.length,
    filters,
    columns,
    externalFilters,
    ExternalHeaderControls,
    onClearExternalFilters,
  };

  const bodyStyle = useMemo(
    () => ({
      ...tableProps.style,
      width: width || '100%',
    }),
    [tableProps.style, width],
  );

  const bodyProps = {
    style: bodyStyle,
    allRows: rows,
    rows: serverPagination ? rows : page,
    rowsCount,
    noDataText,
    headerGroups,
    fakeRows,
    getTableBodyProps,
    getRowProps,
    getCellProps,
    prepareRow,
    SubComponent,
    expandSingleSubRow,
    isResizing,
    isGrouped: !!groupBy?.length,
    virtualized,
    rowHeight,
    autoHeight,
    zebra,
  };

  delete tableProps.style;

  const paginatorProps = {
    canPreviousPage,
    canNextPage,
    pageSizeOptions,
    pageCount: serverPagination ? initialPageCount : pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    showPageSizeOptions,
    rowsCount,
    pageIndex: state.pageIndex,
    pageSize: state.pageSize,
    boundaryCount,
  };

  tableProps = makePropGetter([tableProps], { instance })(
    getProps([
      initialGetTableProps,
      {
        ref: refTableScroll,
        $autoHeight: autoHeight,
      },
    ]),
  );

  return (
    <Container
      className={classNames(className || '', {
        resizing: isResizing,
        staticTable: isStaticTable,
      })}
      style={containerStyle}
      ref={refForResize}
      data-testid={testId}
    >
      <Table {...tableProps}>
        <Header {...headerProps} />
        <Body {...bodyProps} />
      </Table>
      {PaginationComponent && <PaginationComponent {...paginatorProps} />}
    </Container>
  );
});

ReactTable.displayName = 'ReactTable';

ReactTable.propTypes = {
  title: PropTypes.string,
  columns: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  data: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  style: PropTypes.shape(),
  className: PropTypes.string,
  minRows: PropTypes.number,
  fillWithEmptyRows: PropTypes.bool,
  noDataText: PropTypes.string,
  autoHeight: PropTypes.bool,
  zebra: PropTypes.bool,

  // sorting
  sortBy: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      desc: PropTypes.bool,
    }),
  ),
  disableSortBy: PropTypes.bool,
  autoResetSortBy: PropTypes.bool,
  onSortByChange: PropTypes.func,

  onColumnsChange: PropTypes.func,

  // hidden columns
  onHiddenColumnsChange: PropTypes.func,
  hiddenColumns: PropTypes.arrayOf(PropTypes.string),

  // column order
  onColumnOrderChange: PropTypes.func,
  disableDragAndDrop: PropTypes.bool,
  columnOrder: PropTypes.arrayOf(PropTypes.string),
  onColumnsDraggingChange: PropTypes.func,

  // column widths
  onColumnWidthsChange: PropTypes.func,
  columnWidths: PropTypes.shape(),

  // expander
  SubComponent: PropTypes.children,
  expanded: PropTypes.shape(),
  onExpandedChange: PropTypes.func,
  expanderInHeader: PropTypes.bool,
  expandOnlyOne: PropTypes.bool,
  expandSingleSubRow: PropTypes.bool,
  paginateExpandedRows: PropTypes.bool,
  autoResetExpanded: PropTypes.bool,

  // pagination
  PaginationComponent: PropTypes.elementType,
  onPageSizeChange: PropTypes.func,
  onPageIndexChange: PropTypes.func,
  pageIndex: PropTypes.number,
  pageSize: PropTypes.number,
  boundaryCount: PropTypes.number,
  pageSizeOptions: PropTypes.arrayOf(PropTypes.number),
  showPageSizeOptions: PropTypes.bool,
  autoResetPage: PropTypes.bool,
  resetPageIndex: PropTypes.number,
  serverPagination: PropTypes.bool,
  pageCount: PropTypes.number,
  rowsCount: PropTypes.number,

  // getting global props
  getTableProps: PropTypes.func,
  getHeaderGroupProps: PropTypes.func,
  getHeaderProps: PropTypes.func,
  getFilterProps: PropTypes.func,
  getRowProps: PropTypes.func,
  getCellProps: PropTypes.func,
  getRowId: PropTypes.func,

  // filters
  filters: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      value: PropTypes.any.isRequired, // eslint-disable-line react/forbid-prop-types
    }),
  ),
  externalFilters: PropTypes.arrayOf(PropTypes.shape()),
  disableFilters: PropTypes.bool,
  autoResetFilters: PropTypes.bool,
  onFiltersChange: PropTypes.func,
  onClearExternalFilters: PropTypes.func,

  // header
  ExternalHeaderControls: PropTypes.elementType,

  // grouping
  groupBy: PropTypes.arrayOf(PropTypes.string),
  groupByFn: PropTypes.func,
  disableGroupBy: PropTypes.bool,
  autoResetGroupBy: PropTypes.bool,
  onGroupByChange: PropTypes.func,

  // aggregators
  aggregates: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      value: PropTypes.any.isRequired, // eslint-disable-line react/forbid-prop-types
    }),
  ),
  disableAggregators: PropTypes.bool,
  onAggregatesChange: PropTypes.func,

  // duplicate
  duplicates: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      duplicateOf: PropTypes.string.isRequired,
    }),
  ),
  disableDuplicateBy: PropTypes.bool,
  onDuplicatesChange: PropTypes.func,

  focusOnRowId: PropTypes.string,

  // virtualization
  virtualized: PropTypes.bool,
  rowHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.func]),

  // rowSelector
  getIsRowSelectorDisabled: PropTypes.func,
  selectByPage: PropTypes.bool,
  onSelectedRowsChange: PropTypes.func,
  autoResetSelectedRows: PropTypes.bool,

  // other
  testId: PropTypes.string,
  showMenu: PropTypes.bool,
  // static table - new design for ENG-969 - columns cannot be changed or resized, styling changes to reflect this.
  isStaticTable: PropTypes.bool,
};

ReactTable.defaultProps = {
  title: null,
  style: null,
  className: null,
  minRows: 0,
  fillWithEmptyRows: false,
  noDataText: 'No rows found',
  autoHeight: true,
  zebra: undefined,

  // sorting
  sortBy: [],
  disableSortBy: false,
  autoResetSortBy: null,
  onSortByChange: null,

  onColumnsChange: null,

  // hidden columns
  onHiddenColumnsChange: null,
  hiddenColumns: [],

  // column order
  onColumnOrderChange: null,
  disableDragAndDrop: false,
  columnOrder: [],
  onColumnsDraggingChange: null,

  // column widths
  onColumnWidthsChange: null,
  columnWidths: {},

  // expander
  SubComponent: null,
  expanded: null,
  onExpandedChange: null,
  expanderInHeader: true,
  expandOnlyOne: null,
  expandSingleSubRow: true,
  paginateExpandedRows: null,
  autoResetExpanded: null,

  // pagination
  PaginationComponent: null,
  onPageSizeChange: null,
  onPageIndexChange: null,
  pageIndex: null,
  pageSize: 10,
  boundaryCount: 1,
  pageSizeOptions: [10, 20, 50, 100],
  showPageSizeOptions: true,
  autoResetPage: null,
  resetPageIndex: null,
  serverPagination: false,
  pageCount: 1,
  rowsCount: 1,

  // getting global props
  getTableProps: null,
  getHeaderGroupProps: null,
  getHeaderProps: null,
  getFilterProps: null,
  getRowProps: null,
  getCellProps: null,
  getRowId: null,

  // filters
  filters: [],
  externalFilters: null,
  disableFilters: null,
  autoResetFilters: null,
  onFiltersChange: null,
  onClearExternalFilters: null,

  // header
  ExternalHeaderControls: null,

  // grouping
  groupBy: [],
  groupByFn: undefined,
  disableGroupBy: undefined,
  autoResetGroupBy: null,
  onGroupByChange: null,

  // aggregators
  aggregates: [],
  disableAggregators: undefined,
  onAggregatesChange: null,

  // duplicate
  duplicates: [],
  disableDuplicateBy: false,
  onDuplicatesChange: null,

  focusOnRowId: null,

  // virtualization
  virtualized: false,
  rowHeight: defaultRowHeight,

  // rowSelector
  getIsRowSelectorDisabled: null,
  selectByPage: true,
  onSelectedRowsChange: null,
  autoResetSelectedRows: null,

  // other
  testId: null,
  showMenu: true,
  isStaticTable: false,
};

export default ReactTable;
