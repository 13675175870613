import { Fragment, useCallback, useContext, useState } from 'react';
import { useDispatch } from 'react-redux';

import { ColorTypes } from '@/models/ColorTypes';

import { actions as customerActions } from '@/redux/api/customer';

import ConfirmModal from '+components/ConfirmModal';
import {
  Description,
  FieldContainer,
  Group,
  Label,
} from '+components/form/FormField';
import Toggle from '+components/form/Toggle';
import { Col } from '+components/Layout';

import Context from '../Context';

const TwoFactorAuthSection = () => {
  const dispatch = useDispatch();

  const { layout, permissions, isFetching, customer } = useContext(Context);

  const [showOtpModal, setShowOtpModal] = useState(false);

  const onOtpChange = useCallback(() => {
    setShowOtpModal(true);
  }, []);

  const onOtpChangeCancel = useCallback(() => {
    setShowOtpModal(false);
  }, []);

  const onOtpChangeConfirm = useCallback(() => {
    const { id, shortname, isOtpRequired } = customer;
    const data = { id, isOtpRequired: !isOtpRequired };
    dispatch(customerActions.update({ shortname, data, silent: true }));
    setShowOtpModal(false);
  }, [customer]);

  return (
    <Fragment>
      <Col
        className="form form--horizontal"
        width={`calc(${layout.marginLeft} + ${layout.width})`}
        item
        container={false}
      >
        <Group>
          <Label disabled={isFetching || !permissions?.update}>MFA</Label>
          <FieldContainer>
            <Toggle
              name="isOtpRequired"
              checked={customer.isOtpRequired}
              type="checkbox"
              checkedLabel="Required"
              disabled={isFetching || !permissions?.update}
              onChange={onOtpChange}
            />
          </FieldContainer>
          <Description>
            Multi-Factor Authentication mandatory for all users.
          </Description>
        </Group>
      </Col>

      {showOtpModal && (
        <ConfirmModal
          item="multi-factor authentication for all users"
          confirmButtonText={customer.isOtpRequired ? 'Disable' : 'Enable'}
          confirmButtonColor={ColorTypes.primary}
          whyAsking="Users will be required to configure multi-factor authentication upon their next login."
          onToggle={onOtpChangeCancel}
          onConfirm={onOtpChangeConfirm}
          isOpen
        />
      )}
    </Fragment>
  );
};

export default TwoFactorAuthSection;
