import PropTypes from '+prop-types';
import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useMatch, useNavigate } from 'react-router-dom';

import styled from 'styled-components';

import PermissionModel from '@/models/Permission';
import RoutePaths from '@/models/RoutePaths';
import { UIProperties } from '@/models/UIProperties';

import { selectors as customerSelectors } from '@/redux/api/customer';
import { selectors as profileSelectors } from '@/redux/api/user/profile';

import Tooltip from '+components/Tooltip';
import { useHasFlow } from '+hooks/useHasFlow';
import { useHasSources } from '+hooks/useHasSources';
import useRoles from '+hooks/useRoles';
import useUIProperty from '+hooks/useUIProperty';
import { getDefaultSearchDateFrom, makeId } from '+utils';

import Tab from './components/Tab';
import TabContent from './components/TabContent';
import TabGroup from './components/TabGroup';
import TabGroupTitle from './components/TabGroupTitle';
import Tabs from './components/Tabs';
import TabsContainer from './components/TabsContainer';

const tabGroups = {
  account: 'Account',
  profile: 'My Profile',
  userManagement: 'User Management',
  dataManagement: 'Data Management',
  detectionResponse: 'Detect and Respond',
};

const StyledTooltip = styled(Tooltip)`
  max-width: 250px !important;
  padding: 15px;
`;

const rootPath = '/admin';

const Settings = ({ children }) => {
  const navigate = useNavigate();

  const { params: { id: tabId } = {} } = useMatch(`${rootPath}/:id/*`) || {};
  const customer = useSelector(customerSelectors.getCurrentCustomer);
  const profile = useSelector(profileSelectors.getProfile);
  const [guest] = useUIProperty(UIProperties.guest);
  const hasFlow = useHasFlow();
  const hasSources = useHasSources();
  const { roles } = useRoles();
  const canManageSubscription =
    !!roles?.[profile?.roles?.[0]]?.canManageSubscription;

  const isProfileSecurityAvailable = !(
    profile?.idp ||
    profile?.app_metadata?.useResellerSso ||
    profile?.app_metadata?.original
  );
  const showSubscription = customer?.type === 'plg' && canManageSubscription;

  const permissions = useSelector(profileSelectors.getPermissions());

  const tabs = useMemo(() => {
    const result = {
      // General
      [RoutePaths.settings.pageName]: {
        label: 'Overview',
        group: tabGroups.account,
        disabled:
          !permissions?.[PermissionModel.Resources.account.value]?.fetch,
      },
      ...(showSubscription && {
        [RoutePaths.billing.pageName]: {
          label: 'Billing',
          group: tabGroups.account,
        },
      }),
      ...(hasFlow && {
        [RoutePaths.searchAuditLogs.pageName]: {
          label: 'Audit Logs',
          group: tabGroups.account,
          disabled:
            !permissions?.[PermissionModel.Resources.account.value]?.fetch,
        },
        ...(customer?.multi_account && {
          [RoutePaths.customers.pageName]: {
            label: 'Customers',
            group: tabGroups.account,
            disabled:
              !permissions?.[PermissionModel.Resources.customer.value]?.fetch,
          },
        }),
      }),
      // My Profile
      ...(!guest && {
        [RoutePaths.profile.pageName]: {
          label: 'Details',
          group: tabGroups.profile,
        },
        ...(hasFlow && {
          [RoutePaths.profilePersonalization.pageName]: {
            label: 'Personalization',
            group: tabGroups.profile,
          },
          ...(isProfileSecurityAvailable && {
            'profile-security': {
              value: RoutePaths.profileSecurity.pageName,
              label: 'Security',
              group: tabGroups.profile,
            },
          }),
          [RoutePaths.profileActivity.pageName]: {
            label: 'Activity',
            group: tabGroups.profile,
            disabled:
              !permissions?.[PermissionModel.Resources.account.value]?.fetch,
          },
        }),
      }),
      // User Management
      [RoutePaths.apiKeys.pageName]: {
        label: 'API Keys',
        group: tabGroups.userManagement,
        disabled:
          !permissions?.[PermissionModel.Resources.customer.value]?.fetch,
      },
      [RoutePaths.users.pageName]: {
        label: 'Users',
        group: tabGroups.userManagement,
        disabled: !permissions?.[PermissionModel.Resources.user.value]?.fetch,
      },
      [RoutePaths.roles.pageName]: {
        label: 'Roles',
        group: tabGroups.userManagement,
        disabled: !permissions?.[PermissionModel.Resources.role.value]?.fetch,
      },
      [RoutePaths.security.pageName]: {
        label: 'Password Policy',
        group: tabGroups.userManagement,
        disabled: !permissions?.[PermissionModel.Resources.user.value]?.fetch,
      },
      [RoutePaths.sso.pageName]: {
        label: 'SSO',
        group: tabGroups.userManagement,
        disabled: !permissions?.[PermissionModel.Resources.user.value]?.fetch,
      },
      // Data Management
      ...(!hasSources && {
        [`${RoutePaths.sources.pageName}`]: {
          value: 'sources/add',
          label: 'Traffic Sources',
          group: tabGroups.dataManagement,
          disabled:
            !permissions?.[PermissionModel.Resources.device.value]?.fetch &&
            !permissions?.[PermissionModel.Resources.cloud_provider.value]
              ?.fetch,
        },
      }),
      ...(hasSources && {
        [RoutePaths.sources.pageName]: {
          label: 'Traffic Sources',
          group: tabGroups.dataManagement,
          disabled:
            !permissions?.[PermissionModel.Resources.device.value]?.fetch &&
            !permissions?.[PermissionModel.Resources.cloud_provider.value]
              ?.fetch,
        },
      }),
      ...(hasFlow && {
        [RoutePaths.integrationsContext.pageName]: {
          label: 'Context Integrations',
          group: tabGroups.dataManagement,
          disabled:
            !permissions?.[PermissionModel.Resources.integration.value]?.fetch,
        },
        labels: {
          value: 'labels/ip',
          label: 'Context Labels',
          group: tabGroups.dataManagement,
          disabled:
            !permissions?.[PermissionModel.Resources.label.value]?.fetch,
        },
        [RoutePaths.flowTags.pageName]: {
          label: 'Flow Tags',
          group: tabGroups.dataManagement,
          disabled:
            !permissions?.[PermissionModel.Resources.flow_tag.value]?.fetch,
        },

        [RoutePaths.trafficClassification.pageName]: {
          label: 'Traffic Classification',
          group: tabGroups.dataManagement,
          disabled:
            !permissions?.[
              PermissionModel.Resources.network_classification.value
            ]?.fetch,
        },

        // Detect and Respond
        [RoutePaths.detectionCategories.pageName]: {
          label: 'Detection Categories',
          group: tabGroups.detectionResponse,
          disabled:
            !permissions?.[PermissionModel.Resources.category.value]?.fetch,
        },
        [RoutePaths.responsePolicies.pageName]: {
          label: 'Response Policies',
          group: tabGroups.detectionResponse,
          disabled:
            !permissions?.[PermissionModel.Resources.response_policy.value]
              ?.fetch,
        },
        [RoutePaths.integrationsResponse.pageName]: {
          label: 'Response Integrations',
          group: tabGroups.detectionResponse,
          disabled:
            !permissions?.[PermissionModel.Resources.integration.value]?.fetch,
        },
        [RoutePaths.nqlPresets.pageName]: {
          label: 'NQL Presets',
          group: tabGroups.detectionResponse,
        },
      }),
    };

    Object.entries(result).forEach(([key, value]) => {
      value.value = value.value || key;
    });

    return result;
  }, [
    customer?.multi_account,
    guest,
    isProfileSecurityAvailable,
    hasFlow,
    hasSources,
    showSubscription,
    permissions,
  ]);

  const groupedTabs = useMemo(() => {
    const groups = {};
    Object.values(tabs).forEach((tab) => {
      if (tab.group) {
        if (!groups[tab.group]) {
          groups[tab.group] = [];
        }
        groups[tab.group].push(tab);
      }
    });
    return groups;
  }, [tabs]);

  const defaultTab = useMemo(() => tabs.overview, [tabs.overview]);

  const currentTab = useMemo(
    () => tabs[tabId] || defaultTab,
    [tabs, tabId, defaultTab],
  );

  const onTabChange = useCallback(
    (_, value) => {
      const search = new URLSearchParams();
      if (value === RoutePaths.searchAuditLogs.pageName) {
        search.set('from', getDefaultSearchDateFrom(24));
        search.set('to', 'now');
        search.set('reqid', makeId());
      }

      navigate({
        pathname: `${rootPath}/${value}`,
        search: search.toString(),
      });
    },
    [tabs.overview.value, navigate],
  );

  return (
    <TabsContainer>
      <Tabs>
        {Object.entries(groupedTabs).map(([tabsGroup, tabsInGroup]) => (
          <TabGroup key={tabsGroup}>
            <TabGroupTitle>{tabsGroup}</TabGroupTitle>
            {tabsInGroup.map((tab) => (
              <StyledTooltip
                key={tab.value}
                title="Your account role does not have the proper read permissions to view this page."
                arrow={false}
                disabled={!tab.disabled}
              >
                <div>
                  <Tab
                    $active={currentTab.value === tab.value}
                    onClick={() =>
                      tab.disabled ? null : onTabChange(null, tab.value)
                    }
                    tabIndex={0}
                    disabled={tab.disabled}
                  >
                    {tab.label}
                  </Tab>
                </div>
              </StyledTooltip>
            ))}
          </TabGroup>
        ))}
      </Tabs>

      <TabContent>{children}</TabContent>
    </TabsContainer>
  );
};

Settings.propTypes = {
  children: PropTypes.children.isRequired,
};

export default Settings;
