import LabelTypes from '@/models/LabelTypes';

const asnFields = new Set([
  'srcas.number',
  'srcowneras.number',
  'dstas.number',
  'dstowneras.number',
]);
export const isAsnField = (field) => asnFields.has(field);

const orgFields = new Set([
  'srcas.org',
  'srcowneras.org',
  'dstas.org',
  'dstowneras.org',
]);
export const isOrgField = (field) => orgFields.has(field);

const asnWithOrgFields = new Set([
  'srcas',
  'dstas',
  'dstowneras',
  'srcowneras',
]);
export const isAsnWithOrgField = (field) => asnWithOrgFields.has(field);

const countryCodeFields = new Set(['srcgeo.countrycode', 'dstgeo.countrycode']);
export const isCountryCodeField = (field) => countryCodeFields.has(field);

const geoFields = new Set(['srcgeo', 'dstgeo']);
export const isGeoField = (field) => geoFields.has(field);

const ipFields = new Set([
  'srcip',
  'srcips',
  'flowsrcip',
  'dstip',
  'dstips',
  'nexthop',
  'ipinfo.ip',
  'ip',
  'ipintell',
  'addresslocal', // BGP table
  'addressremote', // BGP table
  'answers.rdata', // DNS table
]);
export const isIpField = (field) => ipFields.has(field);

const labelFields = new Set([
  'dstname', // dstname - deprecated field
  'dstipname',
  'srcname', // srcname - deprecated field
  'srcipname',
  'ipinfo.ipname',
  // 'nexthopname',
  // 'flowsrcname',
  'dstportname',
  'srcportname',
  'dstportnames',
  'srcportnames',
  'addresslocalname', // BGP table
  'addressremotename', // BGP table
  'ipname',
]);
export const isLabelField = (field) =>
  field.startsWith('label.') || labelFields.has(field);

const portFields = new Set([
  'srcport',
  'srcports',
  'dstport',
  'dstports',
  'port',
]);
export const isPortField = (field) => portFields.has(field);

export const getFieldType = (field) => {
  if (isIpField(field)) {
    return LabelTypes.ip;
  }
  if (isPortField(field)) {
    return LabelTypes.port;
  }
  if (isAsnWithOrgField(field)) {
    return LabelTypes.asn;
  }
  if (isGeoField(field)) {
    return LabelTypes.geo;
  }
  return 'other';
};

export const getIpFieldType = (field) => {
  switch (field) {
    case 'srcip':
    case 'srcips':
      return 'srcip';
    case 'dstip':
    case 'dstips':
      return 'dstip';
    default:
      return null;
  }
};
