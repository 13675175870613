import PropTypes from '+prop-types';
import { Fragment } from 'react';

import styled from 'styled-components';

import { UnixTimestampFields } from '@/models/UnixTimestampFields';

import Button, { ButtonVariants } from '+components/Button';
import { timestampFormatter } from '+components/Table/Cells/formatters';
import useEvent from '+hooks/useEvent';
import { propsSelectors as themeTable } from '+theme/slices/table';

const parseOperator = (operator) => {
  if (operator === 'like') {
    return 'includes';
  }
  return operator;
};

const parseValue = (filter) => {
  if (!filter?.value?.value) {
    return '';
  }
  if (UnixTimestampFields.includes(filter.id)) {
    return timestampFormatter(new Date(filter.value.value));
  }
  return filter.value.value;
};

const Container = styled.div`
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;

  color: ${themeTable.filterLabelTextColor} !important;
  background-color: ${themeTable.filterButtonColor};
  border-radius: 10px;
  margin: 5px;
  padding: 2px 8px;

  font-size: 13px;
  min-height: 20px;
  user-select: none;
`;

const ColumnLabel = styled.span`
  color: ${themeTable.filterLabelTextColor} !important;
  text-transform: uppercase;
`;

const FilterTextContainer = styled.span`
  max-width: 300px;
  text-wrap: nowrap;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: inherit !important;
  line-height: 1.2;

  > span {
    color: inherit !important;
  }
`;

const RemoveButton = styled(Button)`
  margin-left: 7px;
  color: ${themeTable.filterLabelTextColor} !important;
  text-decoration: none !important;
  text-transform: lowercase;
  font-weight: 700;
  &:not(#fake-id) {
    font-size: 16px;
  }
`;

const FilterPill = (props) => {
  const { filter, label, onRemoveFilter } = props;
  const onRemoveClick = useEvent(() => {
    onRemoveFilter(filter?.id);
  });

  return (
    <Container>
      <FilterTextContainer>
        {typeof filter === 'object' && (
          <Fragment>
            <ColumnLabel>{label}</ColumnLabel>
            &nbsp;
            {parseOperator(filter?.value?.operator)}
            &nbsp;
            {parseValue(filter)}
          </Fragment>
        )}
        {typeof filter === 'string' && <span>{filter}</span>}
      </FilterTextContainer>
      {onRemoveFilter && (
        <RemoveButton variant={ButtonVariants.link} onClick={onRemoveClick}>
          ×
        </RemoveButton>
      )}
    </Container>
  );
};

FilterPill.propTypes = {
  filter: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      id: PropTypes.string,
      value: PropTypes.shape({
        value: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.number,
          PropTypes.shape(),
        ]),
        operator: PropTypes.string,
      }),
    }),
  ]).isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.shape()]),
  onRemoveFilter: PropTypes.func,
};

FilterPill.defaultProps = {
  label: null,
  onRemoveFilter: null,
};

export default FilterPill;
