import { useMemo } from 'react';
import { Link } from 'react-router-dom';

import get from 'lodash.get';

import RoutePaths from '@/models/RoutePaths';

import { SelectColumnFilter } from '../Filters';
import { FilterOperator, withAutoRemove } from '../FilterTypeFactories';
import { autoRemoveByOperator } from '../ReactTable/utils';
import { BaseColumnFactory } from './BaseColumnFactory';
import { getRowOriginal } from './utils';

const sortAuthorColumn = (accessor) => (rowA, rowB) => {
  const originalA = getRowOriginal(rowA);
  const originalB = getRowOriginal(rowB);
  const valueA = originalA?.user?.name || get(originalA, accessor) || '';
  const valueB = originalB?.user?.name || get(originalB, accessor) || '';
  return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
};

const optionValueExtractor = (row, id) => {
  const original = getRowOriginal(row);
  const value = get(original, id);

  return original?.user?.name || value;
};

/**
 * Author column factory.
 * @param {object} props
 * @param {string} [props.emailProp] - Email property name.
 * @param {string} [props.profileEmail] - Profile email.
 * @returns {*}
 * @constructor
 */
export const AuthorColumnFactory = (props) => {
  const { accessor, profileEmail, useFullNameForOptions, ...rest } = props;

  const fixedAccessor = accessor || 'email';

  return BaseColumnFactory({
    width: 220,
    accessor: fixedAccessor,
    Header: 'Author',
    Cell: ({ row, value }) =>
      useMemo(() => {
        const original = getRowOriginal(row);
        return !original?.user ? (
          value
        ) : (
          <Link to={`${RoutePaths.users}/${original?.user?.id}`}>
            {original?.user?.name}
          </Link>
        );
      }, [getRowOriginal(row)]),
    Filter: SelectColumnFilter({
      ...(useFullNameForOptions
        ? {
            optionValueExtractor,
            optionLabel: (key) => (key === 'all' ? 'All' : key),
          }
        : {
            fixedOptions: ['all', 'Mine', 'Not Mine'],
          }),
      enableLikeFilter: true,
    }),
    filter: withAutoRemove((rows, _, filterValue) => {
      if (autoRemoveByOperator(filterValue)) {
        return rows;
      }
      const normalizedFilterValue = String(filterValue.value).toLowerCase();
      if (filterValue.operator === FilterOperator.equal) {
        if (useFullNameForOptions) {
          return rows.filter(({ original }) =>
            [
              original.user?.name?.toLowerCase(),
              get(original, fixedAccessor)?.toLowerCase() || '',
            ].some((item) => item === normalizedFilterValue),
          );
        }

        if (filterValue.value === 'Mine') {
          return rows.filter(
            ({ original }) =>
              !original.system && get(original, fixedAccessor) === profileEmail,
          );
        }

        if (filterValue.value === 'Not Mine') {
          return rows.filter(
            ({ original }) =>
              original.system || get(original, fixedAccessor) !== profileEmail,
          );
        }
      }

      return rows.filter(
        ({ original }) =>
          !original.system &&
          [
            get(original, fixedAccessor)?.toLowerCase(),
            original.user?.name?.toLowerCase(),
          ].some((item) => (item || '').includes(normalizedFilterValue)),
      );
    }, autoRemoveByOperator),
    sortType: sortAuthorColumn(fixedAccessor),

    ...rest,
  });
};
