import { useCallback, useContext, useState } from 'react';
import { useDispatch } from 'react-redux';

import styled from 'styled-components';

import { actions as customerActions } from '@/redux/api/customer';

import {
  Description,
  FieldContainer,
  Group,
  Label,
} from '+components/form/FormField';
import Slider from '+components/form/Slider';
import Toggle from '+components/form/Toggle';
import { Col } from '+components/Layout';

import Context from '../Context';

const marks = [
  { value: 0, label: 'Disabled' },
  { value: 10, label: '10 minutes' },
  { value: 20, label: '20 minutes' },
  { value: 30, label: '30 minutes' },
  { value: 40, label: '40 minutes' },
  { value: 50, label: '50 minutes' },
  { value: 60, label: '60 minutes' },
];

const StyledFieldContainer = styled(FieldContainer)`
  margin: 18px 0 25px 0 !important;
`;

const UserInactivitySection = () => {
  const { layout, permissions, isFetching, customer } = useContext(Context);
  const dispatch = useDispatch();
  const [inactivityTimeout, setInactivityTimeout] = useState(
    customer?.inactivityTimeout,
  );

  const onChange = useCallback((_, value) => setInactivityTimeout(value), []);

  const onChangeCommitted = useCallback(
    (_, value) => {
      const { id, shortname } = customer;
      const data = { id, inactivityTimeout: value };
      dispatch(customerActions.update({ shortname, data }));
    },
    [customer, dispatch],
  );

  const updateInactivityPrompt = useCallback(() => {
    const { id, shortname, inactivityPrompt } = customer;
    const data = { id, inactivityPrompt: !inactivityPrompt };
    dispatch(customerActions.update({ shortname, data }));
  }, [customer, dispatch]);

  return (
    <Col
      className="form form--horizontal"
      width={`calc(${layout.marginLeft} + ${layout.width})`}
      item
      container={false}
    >
      <Group>
        <Label disabled={isFetching || !permissions?.update}>
          User Inactivity
        </Label>
        <StyledFieldContainer>
          <Slider
            aria-label="User Incactive Timeout Select"
            disabled={isFetching || !permissions?.update}
            step={10}
            value={inactivityTimeout}
            onChange={onChange}
            onChangeCommitted={onChangeCommitted}
            min={0}
            max={60}
            marks={marks}
          />
        </StyledFieldContainer>
        <Description>
          {inactivityTimeout > 0
            ? `When a user is idle for ${inactivityTimeout} minutes, automatically log them out.`
            : 'Never log a user out due to inactivity.'}
        </Description>
      </Group>
      {customer?.inactivityTimeout > 0 && (
        <Group>
          <FieldContainer>
            <Toggle
              checked={customer.inactivityPrompt}
              type="checkbox"
              checkedLabel="Show Prompt To User"
              disabled={isFetching || !permissions?.update}
              onChange={updateInactivityPrompt}
            />
          </FieldContainer>
          <Description>
            Should a prompt be shown to the user when they are idle to keep them
            from being logged out?
          </Description>
        </Group>
      )}
    </Col>
  );
};

export default UserInactivitySection;
