import PropTypes from '+prop-types';
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { selectors as customerSelectors } from '@/redux/api/customer';
import {
  actions as ruleActions,
  selectors as rulesSelectors,
} from '@/redux/api/rules';

import { lang } from '+components/charts/common/utils';
import Table from '+components/Table';
import useGlobalFilters from '+hooks/useGlobalFilters';
import useIpLabels from '+hooks/useIpLabels';
import makeArr from '+utils/makeArr';

import { Columns, getColumns } from './AssetSummaryColumns';

const defaultColumns = [
  Columns.ip,
  Columns.categories,
  Columns.algorithms,
  Columns.events,
  Columns.threat,
  Columns.confidence,
  Columns.total,
  Columns.menu,
];

const sortBy = [
  {
    id: Columns.total,
    desc: true,
  },
  // {
  //   id: Columns.events,
  //   desc: true,
  // },
];

const AssetSummaryTab = (props) => {
  const { data, scoreMinMax, userFilters, onTableFilterUpdate } = props;
  const dispatch = useDispatch();

  const [filters] = useGlobalFilters();

  const { ipLabelsHash } = useIpLabels();

  const customer = useSelector(customerSelectors.getCurrentCustomer);
  const algorithms = useSelector(rulesSelectors.getAlgorithms);
  const categories = useSelector(rulesSelectors.getCategories);

  const algorithmsHash = useMemo(
    () =>
      Object.values(algorithms || {}).reduce(
        (acc, item) => ({ ...acc, [item.name]: item }),
        {},
      ),
    [algorithms],
  );

  const categoriesHash = useMemo(
    () =>
      categories?.reduce((acc, item) => ({ ...acc, [item.name]: item }), {}),
    [categories],
  );

  const columns = useMemo(() => {
    if (customer?.multi_account) {
      const indexOfCustomerColumn = defaultColumns.indexOf(Columns.customer);
      if (indexOfCustomerColumn === -1) {
        return [...defaultColumns, Columns.customer];
      }
    }
    return defaultColumns;
  }, [defaultColumns, customer]);

  const tableColumns = useMemo(() => {
    const cols = getColumns(columns, {
      algorithms: algorithmsHash,
      categories: categoriesHash,
      scoreMinMax,
      userFilters,
      labelContext: filters.labelContext,
    });

    cols.sort((a, b) => a.order - b.order);
    return cols;
  }, [
    algorithmsHash,
    categoriesHash,
    scoreMinMax,
    userFilters,
    filters.labelContext,
  ]);

  const tableData = useMemo(
    () =>
      (data || []).map((item) => {
        const newItem = { ...item };

        if (filters.labelContext.show) {
          const arr = makeArr(item.customer).filter(Boolean);
          if (!arr.length) {
            arr.push(''); // we need to fetch labels for the current customer
          }
          arr.forEach((originalCustomer) => {
            const isSubAccountRequest =
              originalCustomer && originalCustomer !== customer?.shortname;
            const contextKey = `${filters.labelContext.ip}${
              isSubAccountRequest ? `/${originalCustomer}` : ''
            }`;
            const ipLabels = ipLabelsHash[item.ip]?.[contextKey];
            newItem.ipname = ipLabels
              ? [...(newItem.ipname || []), ...ipLabels].sort((a, b) =>
                  a.localeCompare(b),
                )
              : null;
          });
        }

        return newItem;
      }),
    [data, filters.labelContext, ipLabelsHash],
  );

  const algorithmsLength = Object.keys(algorithms || {}).length;
  useEffect(() => {
    if (!algorithmsLength && data?.length) {
      dispatch(ruleActions.fetchAlgorithms());
    }
  }, [algorithmsLength, data?.length]);

  useEffect(() => {
    if (!categories?.length && data?.length) {
      dispatch(ruleActions.fetchCategories());
    }
  }, [categories?.length, data?.length]);

  return (
    <Table
      id="Asset Summary"
      columns={tableColumns}
      data={tableData}
      sortBy={sortBy}
      noDataText={data ? 'There are no ips.' : lang.loading}
      onFiltersChange={onTableFilterUpdate}
      fillWithEmptyRows
    />
  );
};

AssetSummaryTab.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape()),
  userFilters: PropTypes.shape(),
  scoreMinMax: PropTypes.arrayOf(PropTypes.number),
  onTableFilterUpdate: PropTypes.func.isRequired,
};
AssetSummaryTab.defaultProps = {
  data: [],
  userFilters: null,
  scoreMinMax: [0, 0],
};

export default AssetSummaryTab;
