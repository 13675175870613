import { all, call, put } from 'redux-saga/effects';

import RoleModel from '@/models/Role';
import RoutePaths from '@/models/RoutePaths';

import { actions as customerActions } from '@/redux/api/customer';
import { actions as portalSettingsActions } from '@/redux/api/portalSettings';
import { actions as tableSettingsActions } from '@/redux/api/tableSettings';
import { actions as toastActions } from '@/redux/toast';
import {
  awaiters,
  createSelector,
  createSlice,
  defaultReducers,
  startFetching,
  stopFetching,
  takeLeading,
} from '@/redux/util';

import backendClient from '@/middleware/backendClient';
import { removeAllSavedFilters } from '@/shared/utils';

import loader from '+utils/loader';

const initialState = {
  isFetching: false,
  error: '',
  user: {},
  sessions: null, // []
  otpDevices: [],
  permissions: null, // {},
};

let api;
const initApi = () => {
  if (!api) {
    api = backendClient();
  }
};

const apiPath = '/user/profile';

export const slice = createSlice({
  name: 'profile',
  initialState,

  reducers: {
    ...defaultReducers,
    requestProfile: startFetching,
    requestProfileWithDependencies: startFetching,
    requestProfileUpdate: startFetching,

    receiveProfile(state, { payload: user }) {
      stopFetching(state);
      state.user = user;
    },

    requestSessions: startFetching,
    receiveSessions(state, { payload }) {
      stopFetching(state);
      state.sessions = payload;
    },
    clearSessions(state) {
      state.sessions = [];
    },

    enableOtp: startFetching,
    disableOtp: startFetching,
    toggleOtpEmail: startFetching,
    manageOtpSuccess(state, { payload }) {
      stopFetching(state);
      state.user = payload;
      state.otpDevices = [];
    },

    requestOtpDeviceConfiguration: startFetching,
    cancelOtpDeviceConfigurationRequest: startFetching,
    manageOtpDeviceConfigurationSuccess(state, { payload }) {
      stopFetching(state);
      state.user = payload;
    },

    requestOtpDevices: startFetching,
    requestOtpDevicesSuccess(state, { payload }) {
      stopFetching(state);
      state.otpDevices = payload;
    },

    deleteOtpDevice: startFetching,
    deleteOtpDeviceSuccess(state, { payload: deviceId }) {
      stopFetching(state);
      state.otpDevices = state.otpDevices.filter((el) => el.id !== deviceId);
    },

    requestPermissions: startFetching,
    requestPermissionsSuccess(state, { payload: { data } }) {
      stopFetching(state);
      if (!state.permissions) {
        state.permissions = {};
      }

      const items = Array.isArray(data) ? data : [data];

      items.forEach((permission) => {
        const { resource, ...values } = permission;
        state.permissions[resource] = values;
      });
    },

    clearPermissions(state) {
      state.permissions = null;
    },

    unimpersonate: startFetching,
    unimpersonateSuccess(state) {
      stopFetching(state);
      localStorage.removeItem('impersonate');
      removeAllSavedFilters();
      setTimeout(() => {
        document.location = `${RoutePaths.customers}`;
      }, 10);
    },

    loginToCustomer: startFetching,
    loginToCustomerSuccess(state, { payload: { masqueradeUrl } }) {
      stopFetching(state);
      loader.setMessage('Login to customer... 80%');
      removeAllSavedFilters();
      setTimeout(() => {
        document.location = masqueradeUrl || `${RoutePaths.home}`;
      }, 10);
    },

    logoutFromCustomer: startFetching,
    logoutFromCustomerSuccess(state, { payload: { masqueradeUrl } }) {
      stopFetching(state);
      loader.setMessage('Logout from customer... 80%');
      removeAllSavedFilters();
      setTimeout(() => {
        document.location = masqueradeUrl || `${RoutePaths.customers}`;
      }, 10);
    },

    skip: stopFetching,
  },

  sagas: (actions) => ({
    [actions.clear]: {
      *saga() {
        yield put(customerActions.clear());
      },
    },

    [actions.requestProfile]: {
      taker: takeLeading(actions.skip),
      *saga() {
        initApi();

        try {
          const { data } = yield call(api.get, apiPath);
          yield put(actions.receiveProfile(data.data));
        } catch (error) {
          yield put(actions.fail(error));
        }
      },
    },

    [actions.requestProfileWithDependencies]: {
      *saga() {
        initApi();

        try {
          const [{ data }] = yield all([
            call(api.get, apiPath),
            put(portalSettingsActions.fetchPortalSettings()),
            put(tableSettingsActions.fetch()),
            put(customerActions.fetchCurrentCustomer()),
          ]);

          yield put(actions.receiveProfile(data.data));
        } catch (error) {
          yield put(actions.fail(error));
        }
      },
    },

    [actions.requestProfileUpdate]: {
      *saga({ payload }) {
        initApi();
        // Omit email and requiredActions
        const { email, requiredActions, ...params } = payload;

        try {
          const response = yield call(api.put, apiPath, params);
          yield put(actions.receiveProfile(response.data.data));
          yield put(
            toastActions.successWithAuditLogVerification({
              message: 'Profile is updated',
              response,
            }),
          );
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: 'Error updating profile',
              details: error.message,
            }),
          );
        }
      },
    },

    [actions.requestSessions]: {
      taker: takeLeading(actions.skip),
      *saga() {
        initApi();

        try {
          const { data } = yield call(api.get, `${apiPath}/sessions`);
          yield put(actions.receiveSessions(data.data));
        } catch (error) {
          yield put(actions.fail(error));
        }
      },
    },

    [actions.enableOtp]: {
      taker: takeLeading(actions.skip),
      *saga() {
        initApi();

        try {
          const response = yield call(api.post, `${apiPath}/enable-otp`);
          yield put(actions.manageOtpSuccess(response.data.data));
          yield put(
            toastActions.successWithAuditLogVerification({
              message: 'Multi-Factor Authentication enabled',
              response,
              showWarningOnly: true,
            }),
          );
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: 'Error enabling Multi-Factor Authentication',
              details: error.message,
            }),
          );
        }
      },
    },

    [actions.disableOtp]: {
      taker: takeLeading(actions.skip),
      *saga() {
        initApi();

        try {
          const response = yield call(api.post, `${apiPath}/disable-otp`);
          yield put(actions.manageOtpSuccess(response.data.data));
          yield put(
            toastActions.successWithAuditLogVerification({
              message: 'Multi-Factor Authentication disabled',
              response,
              showWarningOnly: true,
            }),
          );
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: 'Error disabling Multi-Factor Authentication',
              details: error.message,
            }),
          );
        }
      },
    },

    [actions.toggleOtpEmail]: {
      *saga({ payload }) {
        initApi();

        try {
          const on = payload ? 'enable' : 'disable';
          const response = yield call(api.post, `${apiPath}/${on}-otp-email`);
          yield put(actions.manageOtpSuccess(response.data.data));
          yield put(
            toastActions.successWithAuditLogVerification({
              message: `Multi-Factor Authentication by Email ${
                on ? 'enabled' : 'disabled'
              }`,
              response,
              showWarningOnly: true,
            }),
          );
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: `Error ${
                payload ? 'enabling' : 'disabling'
              } Multi-Factor Authentication by Email`,
              details: error.message,
            }),
          );
        }
      },
    },

    [actions.requestOtpDeviceConfiguration]: {
      taker: takeLeading(actions.skip),
      *saga() {
        initApi();

        try {
          const response = yield call(
            api.post,
            `${apiPath}/request-otp-device-configuration`,
          );
          yield put(
            actions.manageOtpDeviceConfigurationSuccess(response.data.data),
          );
          yield put(
            toastActions.successWithAuditLogVerification({
              message: 'OTP device configuration requested',
              response,
              // showWarningOnly: true,
            }),
          );
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: 'Error requesting OTP device configuration',
              details: error.message,
            }),
          );
        }
      },
    },

    [actions.cancelOtpDeviceConfigurationRequest]: {
      taker: takeLeading(actions.skip),
      *saga() {
        initApi();

        try {
          const response = yield call(
            api.post,
            `${apiPath}/cancel-otp-device-configuration-request`,
          );
          yield put(
            actions.manageOtpDeviceConfigurationSuccess(response.data.data),
          );
          yield put(
            toastActions.successWithAuditLogVerification({
              message: 'OTP device configuration request canceled',
              response,
              // showWarningOnly: true,
            }),
          );
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: 'Error canceling OTP device configuration request',
              details: error.message,
            }),
          );
        }
      },
    },

    [actions.requestOtpDevices]: {
      taker: takeLeading(actions.skip),
      *saga() {
        initApi();

        try {
          const { data } = yield call(api.get, `${apiPath}/otp-devices`);
          yield put(actions.requestOtpDevicesSuccess(data.data));
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: 'Error fetching OTP devices',
              details: error.message,
            }),
          );
        }
      },
    },

    [actions.deleteOtpDevice]: {
      *saga({ payload: id }) {
        initApi();

        try {
          const response = yield call(
            api.delete,
            `${apiPath}/otp-devices/${id}`,
          );
          yield put(actions.deleteOtpDeviceSuccess(id));
          yield put(
            toastActions.successWithAuditLogVerification({
              message: 'OTP device removed',
              response,
              showWarningOnly: true,
            }),
          );
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: 'Error deleting OTP devices',
              details: error.message,
            }),
          );
        }
      },
    },

    [actions.requestPermissions]: {
      *saga({ payload: resource }) {
        initApi();

        const path = `${apiPath}/permissions${resource ? `/${resource}` : ''}`;

        const skip = awaiters.has(path);
        if (skip) {
          yield put(actions.skip());
          return;
        }

        awaiters.add(path);

        try {
          const response = yield call(api.get, path);
          yield put(actions.requestPermissionsSuccess(response.data));
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: 'Error fetching user permissions',
              details: error.message,
            }),
          );
        }

        awaiters.delete(path);
      },
    },

    [actions.unimpersonate]: {
      *saga() {
        initApi();
        try {
          const { data } = yield call(api.post, `${apiPath}/unimpersonate`, {
            generateToken: false,
          });
          yield put(actions.unimpersonateSuccess(data.data));
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: 'Error unimpersonating',
              details: error.message,
            }),
          );
        }
      },
    },

    [actions.loginToCustomer]: {
      *saga({ payload: { shortname, masqueradeUrl } }) {
        initApi();
        try {
          loader.setMessage('Login to customer... 40%').show();
          yield call(api.post, `${apiPath}/login-to-customer/${shortname}`, {
            generateToken: false,
          });
          loader.setMessage('Login to customer... 60%');
          yield put(actions.loginToCustomerSuccess({ masqueradeUrl }));
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: 'Error logging in to customer',
              details: error.message,
            }),
          );
        }
      },
    },

    [actions.logoutFromCustomer]: {
      *saga({ payload: { masqueradeUrl } }) {
        initApi();
        try {
          loader.setMessage('Logout from customer... 40%').show();
          yield call(api.post, `${apiPath}/logout-from-customer`, {
            generateToken: false,
          });
          loader.setMessage('Logout from customer... 60%');
          yield put(actions.logoutFromCustomerSuccess({ masqueradeUrl }));
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: 'Error logging out from customer',
              details: error.message,
            }),
          );
        }
      },
    },
  }),

  selectors: (getState) => ({
    isFetching: createSelector(getState, (state) => state.isFetching),

    getProfile: createSelector(getState, (state) => state.user),

    getSessions: createSelector(getState, (state) => state.sessions),

    getOtpDevices: createSelector(getState, (state) => state.otpDevices),

    isAdminUser: createSelector(getState, (state) =>
      state.user?.roles?.includes(RoleModel.Roles.app_admin),
    ),

    isDefaultCustomer: createSelector(
      getState,
      (state) => state.user?.app_metadata?.shortname === 'default',
    ),

    getPermissions: (resource) =>
      createSelector(getState, (state) =>
        resource ? state.permissions?.[resource] : state.permissions,
      ),
  }),
});

export const { actions, selectors } = slice;

export default slice;
