import { useMemo } from 'react';

import {
  LabelOrIpColumnFactory,
  LabelOrPortColumnFactory,
} from '+components/Table/Columns';
import { getRowOriginal } from '+components/Table/Columns/utils';

import { PivotClickable } from './PivotClickable';

const Columns = {
  srcip: 'srcip',
  srcport: 'srcport',
};

export const getWrappedLabelOrIpColumn = ({
  accessor,
  labelFieldName,
  showLabel,
  onPivotClick,
  factory,
  ...rest
}) => ({
  ...rest,
  accessor,
  Cell: (options) => {
    const original = getRowOriginal(options.row);

    const prepared = {
      dataFieldName: accessor,
      labelFieldName,
      showLabel,
    };

    const { Cell } = useMemo(
      () => (factory || LabelOrIpColumnFactory)(prepared),
      [Object.values(prepared), factory],
    );

    return (
      <PivotClickable
        onPivotClick={onPivotClick}
        row={original}
        field={accessor}
      >
        <Cell {...options} />
      </PivotClickable>
    );
  },
});

export const commonColumns = ({ labelContext, onPivotClick }) => [
  getWrappedLabelOrIpColumn({
    accessor: Columns.srcip,
    Header: 'Source',
    labelFieldName: `label.ip.${labelContext.ip}.src`,
    showLabel: labelContext.show,
    onPivotClick,
  }),
  getWrappedLabelOrIpColumn({
    accessor: Columns.srcport,
    Header: 'SRC Port',
    labelFieldName: `label.port.${labelContext.port}.src`,
    showLabel: labelContext.show,
    onPivotClick,
    factory: LabelOrPortColumnFactory,
  }),
];
