import PropTypes from '+prop-types';
import { Fragment, memo, useCallback, useEffect } from 'react';

import useEvent from '+hooks/useEvent';

import { cellPropsCompare, getProps } from '../utils';
import {
  ClearFiltersButton,
  ExternalHeaderControlsContainer,
  FiltersRow,
  FiltersRowLabel,
  TableHeader,
  TableTitle,
  THeadHeaderGroup as THeadHeaderGroupOrigin,
  THeadHeader as THeadHeaderOrigin,
  Tr as TrOrigin,
} from './Components';
import FilterPill from './FilterPill';
import HeaderCell, { defaultProps, propTypes } from './HeaderCell';

const THeadHeader = memo(THeadHeaderOrigin, cellPropsCompare);
const THeadHeaderGroup = memo(THeadHeaderGroupOrigin, cellPropsCompare);
const Tr = memo(TrOrigin, cellPropsCompare);

const Header = (props) => {
  const {
    title,
    headerGroups,
    getHeaderProps,
    getHeaderGroupProps,
    getFilterProps,
    isResizing,
    isGrouped,
    filters,
    externalFilters,
    setAllFilters,
    ExternalHeaderControls,
    onClearExternalFilters,
    ...tail
  } = props;

  const [lastHeaderGroup] = headerGroups.slice(-1);

  const clearFilters = useEvent(() => {
    setAllFilters([]);
    onClearExternalFilters?.(externalFilters);
  });

  const showFilters = filters?.length || externalFilters?.length;

  const getColumnHeader = useCallback(
    (id) => {
      const col = (lastHeaderGroup?.headers || []).find(
        (column) => column.id === id,
      );
      return col?.render?.('Header') ?? id;
    },
    [lastHeaderGroup?.headers],
  );

  const onRemoveFilter = useEvent((id) => {
    setAllFilters(filters.filter((filter) => filter.id !== id));
  });

  useEffect(() => {
    if (isResizing) {
      document.documentElement.classList.add('resizingOverrideCursor');
    } else {
      document.documentElement.classList.remove('resizingOverrideCursor');
    }
  }, [isResizing]);

  return (
    <Fragment>
      {(title || showFilters || ExternalHeaderControls) && (
        <TableHeader>
          {title && <TableTitle>{title}</TableTitle>}
          {showFilters && (
            <Fragment>
              <FiltersRowLabel>FILTERS:</FiltersRowLabel>

              <FiltersRow>
                {externalFilters?.map((externalFilter) => (
                  <FilterPill
                    key={externalFilter.label}
                    filter={externalFilter.label}
                    onRemoveFilter={externalFilter.onRemoveFilter}
                  />
                ))}
                {filters?.map((filter) => (
                  <FilterPill
                    key={filter.id}
                    filter={filter}
                    label={getColumnHeader(filter.id)}
                    onRemoveFilter={onRemoveFilter}
                  />
                ))}

                {(filters?.length > 0 ||
                  externalFilters?.some((filter) => filter.onRemoveFilter)) && (
                  <ClearFiltersButton onClick={clearFilters}>
                    Clear All
                  </ClearFiltersButton>
                )}
              </FiltersRow>
            </Fragment>
          )}
          {ExternalHeaderControls && (
            <ExternalHeaderControlsContainer>
              <ExternalHeaderControls />
            </ExternalHeaderControlsContainer>
          )}
        </TableHeader>
      )}
      {headerGroups.map((headerGroup) => {
        const { key, ...headerGroupProps } = headerGroup.getHeaderGroupProps(
          getProps([getHeaderGroupProps]),
        );
        const Component =
          headerGroup === lastHeaderGroup ? THeadHeader : THeadHeaderGroup;
        return (
          <Component key={key} style={tail.style}>
            <Tr {...headerGroupProps}>
              {headerGroup.headers.map((column, index) => {
                const draggable =
                  !isResizing && headerGroup === lastHeaderGroup;
                const FilterRenderer =
                  lastHeaderGroup?.headers[index]?.render('Filter');
                const AggregatorRenderer =
                  lastHeaderGroup?.headers[index]?.render('Aggregator');
                return (
                  <HeaderCell
                    key={column.id}
                    index={index}
                    count={headerGroup.headers.length}
                    column={column}
                    draggable={draggable}
                    getHeaderProps={getHeaderProps}
                    FilterRenderer={FilterRenderer}
                    AggregatorRenderer={AggregatorRenderer}
                    isGrouped={isGrouped}
                    {...tail}
                  />
                );
              })}
            </Tr>
          </Component>
        );
      })}
    </Fragment>
  );
};

Header.propTypes = {
  ...propTypes,
  headerGroups: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  getHeaderProps: PropTypes.func,
  getHeaderGroupProps: PropTypes.func,
  getFilterProps: PropTypes.func,
  isResizing: PropTypes.bool,
  isGrouped: PropTypes.bool,
  title: PropTypes.string,
  filters: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  externalFilters: PropTypes.arrayOf(PropTypes.shape()),
  onClearExternalFilters: PropTypes.func,
  setAllFilters: PropTypes.func.isRequired,
};

Header.defaultProps = {
  ...defaultProps,
  getHeaderProps: null,
  getHeaderGroupProps: null,
  getFilterProps: null,
  isResizing: false,
  isGrouped: false,
  title: null,
  externalFilters: null,
  onClearExternalFilters: null,
};

export default Header;
