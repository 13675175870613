import PropTypes from '+prop-types';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import LeadPencilIcon from 'mdi-react/LeadPencilIcon';

import RoutePaths from '@/models/RoutePaths';
import { UIProperties } from '@/models/UIProperties';

import { selectors as rulesSelectors } from '@/redux/api/rules';

import { usePageTabs } from '+components/PageTabs';
import useEvent from '+hooks/useEvent';
import useUIProperty from '+hooks/useUIProperty';

import CellWrapper from './CellWrapper';

const CellCaterithm = (props) => {
  const {
    field,
    value,
    original,
    renderer: Renderer,
    disabled,
    withoutDescription,
  } = props;
  const [, , pageTabMethods] = usePageTabs();

  const category = useSelector(rulesSelectors.getCategoryByName(value));
  const algorithm = useSelector(rulesSelectors.getAlgorithmByName(value));
  const [, setDMDrawerId] = useUIProperty(UIProperties.dmDrawerId);

  const item = useMemo(() => {
    if (category) {
      return {
        name: category.name,
        description: category.description,
      };
    }

    if (algorithm) {
      const path = RoutePaths.modelsDetection;
      return {
        id: algorithm.id,
        name: algorithm.name,
        description: algorithm.description,
        type: 'algorithm',
        model:
          algorithm.algo_type === 'CDM'
            ? RoutePaths.modelsContext.pageName
            : path.pageName,
      };
    }
    // this is possible when category or algorithm was removed but it still in policy config
    return { name: value };
  }, [value, category, algorithm]);

  const additionalMenuOptions = useMemo(
    () =>
      item.id && item.type === 'algorithm'
        ? [
            {
              icon: <LeadPencilIcon />,
              text: 'Edit',
              onClick: () => {
                pageTabMethods.add(
                  `${RoutePaths.models}/${item.model}/${item.id}`,
                );
              },
            },
          ]
        : [],
    [item.id, item.type, item.model],
  );

  const onMoreInfoClick = useEvent(() => {
    if (item.id) {
      setDMDrawerId(item.id);
    }
  });

  return value === 'all' ? null : (
    <Renderer
      header={
        <CellWrapper
          customer={original?.customer}
          field={field}
          value={value}
          additionalMenuOptions={additionalMenuOptions}
          disabled={disabled}
          onMoreInfoClick={
            item.id && item.type === 'algorithm' ? onMoreInfoClick : null
          }
        >
          <span>{item.name}</span>
        </CellWrapper>
      }
      subHeader={withoutDescription ? null : item.description}
    />
  );
};

CellCaterithm.propTypes = {
  field: PropTypes.string,
  value: PropTypes.string,
  original: PropTypes.shape(),
  renderer: PropTypes.children.isRequired,
  disabled: PropTypes.bool,
  withoutDescription: PropTypes.bool,
};
CellCaterithm.defaultProps = {
  field: null,
  value: null,
  original: null,
  disabled: false,
  withoutDescription: false,
};

export default CellCaterithm;
