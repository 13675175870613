import PropTypes from '+prop-types';
import { memo, useMemo } from 'react';

import styled from 'styled-components';

import { ContextTypes } from '@/models/ContextTypes';

import { Col, LayoutTypes, Row } from '+components/Layout';
import Table from '+components/Table';
import useGlobalFilters from '+hooks/useGlobalFilters';

import { getColumns } from './getColumns';
import { useIpRateData } from './useIpRateData';

const TableContainer = styled(Row)`
  width: 100%;

  .pagination-container {
    background: ${({ theme }) => theme.cardBackgroundColor};
  }
`;

export const IpSummary = memo((props) => {
  const {
    ipScorePeriodStart,
    ipScorePeriodEnd,
    isSubAccountRecord,
    data,
    context,
  } = props;

  const [filters] = useGlobalFilters(ContextTypes.alerts);

  const ipsRates = useIpRateData({
    fromDate: ipScorePeriodStart,
    toDate: ipScorePeriodEnd,
    polling: !ipScorePeriodEnd,
  });

  const scoreMinMax = useMemo(
    () =>
      Object.values(ipsRates).reduce(
        ([min, max], rate) => [
          Math.min(min, rate || 0),
          Math.max(max, rate || 0),
        ],
        [Infinity, -Infinity],
      ),
    [ipsRates],
  );

  const columns = useMemo(
    () =>
      getColumns({
        labelContext: filters.labelContext,
        scoreMinMax,
        isSubAccountRecord,
        context: context || ContextTypes.flow,
      }),
    [filters.labelContext, ...scoreMinMax, isSubAccountRecord, context],
  );

  const extended = useMemo(
    () =>
      (data || []).map((item) => ({
        ...item,
        totalScore: ipsRates[item.ip] || null,
      })),
    [data, ipsRates],
  );

  return (
    <Row $type={LayoutTypes.card}>
      <Col item>
        <TableContainer item container={false} px="5px" pb="5px">
          <Table
            title="IP Summary"
            data={extended}
            columns={columns}
            minRows={1}
            showMenu={false}
            disableDuplicateBy
            isStaticTable
            pageSize={10}
          />
        </TableContainer>
      </Col>
    </Row>
  );
});

IpSummary.displayName = 'IpSummary';

IpSummary.propTypes = {
  ipScorePeriodStart: PropTypes.number,
  ipScorePeriodEnd: PropTypes.number,
  isSubAccountRecord: PropTypes.bool,
  data: PropTypes.arrayOf(PropTypes.object),
  context: PropTypes.string,
};

IpSummary.defaultProps = {
  ipScorePeriodStart: undefined,
  ipScorePeriodEnd: undefined,
  isSubAccountRecord: false,
  data: undefined,
  context: ContextTypes.flow,
};
