import { useMemo } from 'react';
import { NavLink } from 'react-router-dom';

import RoutePaths from '@/models/RoutePaths';

import getEventTableFilterValues from '+components/ContextTables/EventTable/getEventTableFilterValues';
import { UniversalCell } from '+components/Table/Cells';
import {
  BaseColumnFactory,
  EnabledDisabledColumnFactory,
  MenuColumnFactory,
  MenuColumnLink,
  NumberColumnFactory,
  TrafficColumnFactory,
} from '+components/Table/Columns';
import { getRowOriginal } from '+components/Table/Columns/utils';
import { SelectColumnFilter } from '+components/Table/Filters';
import { getColumnsHelper } from '+utils/getColumnsHelper';

export const Columns = {
  count: 'count',
  autoThresholds: 'autoThresholds',
  algorithm: 'algorithm',
  traffictype: 'traffictype',
  categories: 'categories',
  severity: 'severity',
  menu: 'menu',
};

export const columnsCollection = ({ algorithms, categories, userFilters }) => ({
  [Columns.menu]: MenuColumnFactory({
    cxActionMenu: (_, original) => {
      const search = new URLSearchParams();
      search.set('algorithm', original.algorithm);
      if (userFilters.isCustomPeriod) {
        search.set('from', userFilters.start);
        search.set('to', userFilters.end);
      }
      const url = `${RoutePaths.events}/list?${search.toString()}`;
      return (
        <MenuColumnLink
          to={url}
          title={`Filter by ${original.algorithm} algorithm`}
        />
      );
    },
    order: 1,
  }),
  [Columns.count]: NumberColumnFactory({
    accessor: Columns.count,
    Header: 'Events Count',
    Cell: ({ row }) =>
      useMemo(() => {
        const original = getRowOriginal(row);
        const search = new URLSearchParams();
        search.set('algorithm', original.algorithm);
        if (userFilters.isCustomPeriod) {
          search.set('from', userFilters.start);
          search.set('to', userFilters.end);
        }
        const url = `${RoutePaths.events}/list?${search.toString()}`;
        return (
          <NavLink to={url} title={`Filter by ${original.algorithm} algorithm`}>
            {original.count}
          </NavLink>
        );
      }, [row, userFilters.start, userFilters.end]),
    width: 100,
    order: 2,
  }),
  [Columns.algorithm]: BaseColumnFactory({
    accessor: Columns.algorithm,
    Header: 'Detection Model',
    Cell: UniversalCell(Columns.algorithm),
    ...getEventTableFilterValues(Columns.algorithm, { algorithms }),
    order: 3,
  }),
  [Columns.categories]: BaseColumnFactory({
    accessor: Columns.categories,
    Header: 'Categories',
    getCellProps: () => ({ style: { whiteSpace: 'unset' } }),
    Cell: UniversalCell(Columns.categories),
    ...getEventTableFilterValues(Columns.categories, { categories }),
    order: 4,
  }),
  [Columns.autoThresholds]: EnabledDisabledColumnFactory({
    accessor: (original) => !!algorithms[original.algorithm]?.autothreshold,
    Header: 'Auto Thresholding',
    order: 5,
  }),

  [Columns.severity]: BaseColumnFactory({
    accessor: Columns.severity,
    Header: 'Severity',
    width: 220,
    Cell: UniversalCell(Columns.severity),
    Filter: SelectColumnFilter({
      fixedOptions: ['all', 'high', 'medium', 'low'],
    }),
    filter: 'selectFilter',
    order: 6,
  }),
  [Columns.traffictype]: TrafficColumnFactory({
    Header: 'Traffic',
    order: 7,
  }),
});

export const getColumns = getColumnsHelper(columnsCollection);
