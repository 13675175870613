import { css } from 'styled-components';

export const WordBreakMixin = css`
  --code-block-line-height: 1.6em;
  white-space: pre-wrap !important;
  word-spacing: normal !important;
  word-break: break-word !important;
  overflow-wrap: break-word !important;

  line-height: var(--code-block-line-height) !important;

  -webkit-font-smoothing: antialiased;
`;
