import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import styled from 'styled-components';

import { Stack } from '@mui/material';

import { ContextTypes } from '@/models/ContextTypes';
import StatsRequest from '@/models/StatsRequest';
import { TimeDuration } from '@/models/TimePeriods';

import { selectors as trafficTopSelectors } from '@/redux/api/trafficTop';
import { actions as globalFiltersActions } from '@/redux/globalFilters';
import {
  actions as globalFiltersUiActions,
  selectors as globalFiltersUiSelectors,
} from '@/redux/globalFilters/ui';

import { useTrafficTopData } from '@/pages/TrafficTop/useTrafficTopData';
import { useTrafficTopFields } from '@/pages/TrafficTop/useTrafficTopFields';

import Button, { ButtonVariants } from '+components/Button';
import { lang } from '+components/charts/common/utils';
import { Field, useFormState } from '+components/form/FinalForm';
import { normalizeSelectValue } from '+components/form/Normalizers';
import Toggle from '+components/form/Toggle';
import AdditionalFiltersDropdownField, {
  AdditionalFiltersDropdownCaptureContainer,
  AdditionalFiltersDropdownCaptureLabel,
  AdditionalFiltersDropdownCaptureValue,
} from '+components/GlobalFilters/Panel/components/AdditionalFiltersDropdownField';
import AdditionalFiltersRowItem from '+components/GlobalFilters/Panel/components/AdditionalFiltersRowItem';
import AdditionalFiltersSeparator from '+components/GlobalFilters/Panel/components/AdditionalFiltersSeparator';
import GlobalFiltersPortal from '+components/GlobalFilters/Portal';
import GlobalFiltersSetting from '+components/GlobalFilters/Setting';
import { PageHeader } from '+components/Layout/PageHeader';
import useEvent from '+hooks/useEvent';
import useGlobalFilters from '+hooks/useGlobalFilters';
import useGlobalFiltersProperty from '+hooks/useGlobalFiltersProperty';
import useLastAllowedContext from '+hooks/useLastAllowedContext';
import useLoadingIndicator from '+hooks/useLoadingIndicator';
import { getNqlFieldName, makeArr, nqlLang, timeBounds } from '+utils';

import InvestigateHostModal from './components/InvestigateHostModal';
import TrafficTopDNSTable from './components/TrafficTopDNSTable';
import TrafficTopFlowTable from './components/TrafficTopFlowTable';
import TrafficTopTrafficTable from './components/TrafficTopTrafficTable';

const excludeContexts = new Set([ContextTypes.alerts, ContextTypes.blocks]);

const fieldLabel = 'Aggregate by';

const defaultFieldValue = {
  [ContextTypes.flow]: 'dstip',
  [ContextTypes.dns]: 'query.tld',
  [ContextTypes.traffic]: 'srcip',
};

const TableComponents = {
  [ContextTypes.flow]: TrafficTopFlowTable,
  [ContextTypes.dns]: TrafficTopDNSTable,
  [ContextTypes.traffic]: TrafficTopTrafficTable,
};

const defaultPeriod = {
  value: 4,
  type: TimeDuration.hour,
};

const getFieldKey = (context) => `traffic_top_field_${context}`;

const StyledToggle = styled(Toggle)`
  margin-left: 15px;
`;

const StyledButton = styled(Button)`
  &.selected {
    background-color: #007bff !important;
    color: #fff;
  }
`;

const flipFields = {
  src: 'srcip',
  dst: 'dstip',
};

const showIpDirectionForFields = new Set([flipFields.src, flipFields.dst]);

const GlobalFiltersFields = () => {
  const context = useLastAllowedContext({
    excludeContexts,
    defaultContext: ContextTypes.flow,
  });

  const dispatch = useDispatch();

  const fieldKey = getFieldKey(context);
  const { values } = useFormState({ subscription: { values: true } });

  const { [fieldKey]: field = defaultFieldValue[context] } = values;

  const showIpDirection =
    showIpDirectionForFields.has(field) && context === ContextTypes.flow;
  const defaultIpDirection = field !== flipFields.src;

  const fields = useTrafficTopFields(context);

  const [ipDirection, setIpDirection] = useState(defaultIpDirection);

  const handleIpDirectionChange = useCallback(
    (event) => {
      const newValue = event?.target?.checked;
      const flipToField = newValue ? flipFields.dst : flipFields.src;
      dispatch(
        globalFiltersActions.changeFilter({
          [fieldKey]: flipToField,
          nqlFlow: values.nqlFlow.map((flow) =>
            flow.replace(/\b(srcip|dstip)\b/g, (match) =>
              match === flipFields.src ? flipFields.dst : flipFields.src,
            ),
          ),
        }),
      );
      dispatch(globalFiltersUiActions.userFlippedIpDirection(flipToField));
      setIpDirection(newValue);
    },
    [dispatch, fieldKey, values.nqlFlow],
  );

  const fieldsOptions = useMemo(() => {
    const options = (fields || []).reduce((acc, item) => {
      acc.push({
        value: item.field,
        label: item.field,
        description: item.description,
      });

      return acc;
    }, []);

    options.unshift({
      value: fieldLabel,
      label: fieldLabel,
      header: true,
    });
    return options;
  }, [fields, context]);

  useEffect(() => {
    if (showIpDirectionForFields.has(field)) {
      setIpDirection(field !== flipFields.src);
    }
  }, [field]);

  return (
    <Fragment>
      <AdditionalFiltersRowItem>
        <Field
          component={AdditionalFiltersDropdownField}
          name={fieldKey}
          options={fieldsOptions}
          parse={normalizeSelectValue}
          caption={
            <AdditionalFiltersDropdownCaptureContainer>
              <AdditionalFiltersDropdownCaptureLabel>
                {fieldLabel}
              </AdditionalFiltersDropdownCaptureLabel>
              <AdditionalFiltersDropdownCaptureValue>
                {field}
              </AdditionalFiltersDropdownCaptureValue>
            </AdditionalFiltersDropdownCaptureContainer>
          }
          data-tracking="filter-row-aggregate-by"
          showSearch
        />
      </AdditionalFiltersRowItem>

      <AdditionalFiltersSeparator />
      {showIpDirection && (
        <AdditionalFiltersRowItem>
          <StyledToggle
            name="ipDirection"
            type="checkbox"
            checked={ipDirection}
            uncheckedLabel="SRC"
            checkedLabel="DST"
            data-tracking="filter-row-ip-direction"
            twoOptionToggle
            onChange={handleIpDirectionChange}
          />
        </AdditionalFiltersRowItem>
      )}
    </Fragment>
  );
};

const getNewNql = (currentNql, newNql) =>
  makeArr(currentNql).map((nql) => {
    if (!nql || nql === newNql) {
      return '';
    }

    const parts = nql.split(/ (?:and|&&|AND) /);

    const filteredParts = parts.filter((part) => part.trim() !== newNql.trim());

    if (filteredParts.length === parts.length) {
      return nql;
    }

    return filteredParts.join(' && ');
  });

const externallyExposedNql = nqlLang.and(
  nqlLang.equal('srcinternal', true),
  nqlLang.equal('dstinternal', false),
  nqlLang.less('srcport', 'dstport'),
  nqlLang.greater('packets', 1),
  nqlLang.or(
    nqlLang.equal('protocol', 'udp'),
    nqlLang.and(
      nqlLang.equal('protocol', 'tcp'),
      nqlLang.equal('tcpflags.syn', true),
      nqlLang.equal('tcpflags.ack', true),
    ),
  ),
);

export default () => {
  const [{ context: selectedContext }] = useGlobalFilters();

  const dispatch = useDispatch();

  const [sortBy, setSortBy] = useState([]);
  const [selectedButton, setSelectedButton] = useState(null);
  const [searchParams] = useSearchParams();
  const fieldParam = searchParams.get('field');

  const userFlippedIpDirection = useSelector(
    globalFiltersUiSelectors.getUserFlippedIpDirection,
  );

  const userChangedContext = useSelector(
    globalFiltersUiSelectors.getUserChangedContext,
  );

  const isGlobalFiltersPristine = useSelector(
    globalFiltersUiSelectors.getFormPristine,
  );

  const context = useLastAllowedContext({
    excludeContexts,
    defaultContext: ContextTypes.flow,
  });

  const [filters] = useGlobalFilters(context);

  const fieldKey = getFieldKey(context);

  const [field] = useGlobalFiltersProperty(
    fieldKey,
    fieldParam || defaultFieldValue[context],
  );

  const [externalFilters, setExternalFilters] = useState([
    { label: `${fieldLabel}: ${defaultFieldValue[context]}` },
  ]);

  const isFiltered = field !== defaultFieldValue[context];
  const portalInitialValues = useMemo(() => ({ [fieldKey]: field }), [field]);

  const isFetching = useSelector(trafficTopSelectors.isFetching);

  useLoadingIndicator(isFetching);

  const { start, end } = timeBounds(filters);

  const data = useTrafficTopData(
    context,
    field,
    {
      size: 1000,
      start,
      end,
      ...StatsRequest.makeSearch({
        search: filters.nql,
        intersect: filters.intersect,
      }),
      customers: filters.customers,
    },
    [
      start,
      end,
      JSON.stringify(filters.nql),
      JSON.stringify(filters.intersect),
      JSON.stringify(filters.customers),
      filters.refresher,
    ],
  );

  const TableComponent = useMemo(() => TableComponents[context], [context]);

  const excludeContextsArr = useMemo(
    () => Array.from(excludeContexts),
    [excludeContexts],
  );

  const [isInvestigateModalOpen, setIsInvestigateModalOpen] = useState(false);

  const investigateHost = useCallback(() => {
    setIsInvestigateModalOpen(true);
  }, []);

  useEffect(() => {
    if (isGlobalFiltersPristine) {
      const newFilter = [{ label: `${fieldLabel}: ${field}` }];
      setExternalFilters((prev) => [newFilter[0], ...prev.slice(1)]);
    }
  }, [isGlobalFiltersPristine]);

  useEffect(() => {
    if (userFlippedIpDirection) {
      const newFilters = externalFilters.map((filter) =>
        filter.label.includes(fieldLabel)
          ? { ...filter, label: `${fieldLabel}: ${userFlippedIpDirection}` }
          : filter,
      );
      setExternalFilters(newFilters);
    }
  }, [userFlippedIpDirection]);

  const handleFilterChange = useCallback(
    ({
      context: newContext,
      field: newField,
      nqlFlow,
      nqlDns,
      sortBy: newSortBy = [],
      selectedButton: buttonId,
      to: newTo,
      from: newFrom,
      period: newPeriod,
    }) => {
      dispatch(
        globalFiltersActions.changeFilter({
          context: newContext,
          [getFieldKey(newContext)]: newField,
          ...(nqlFlow && { nqlFlow }),
          ...(nqlDns && { nqlDns }),
          ...(newTo && { to: newTo }),
          ...(newFrom && { from: newFrom }),
          ...(newPeriod && { period: newPeriod }),
        }),
      );
      setSortBy(newSortBy);
      setSelectedButton(buttonId);
    },
    [dispatch, getFieldKey],
  );

  const setDefaultFilters = useCallback(
    (newContext) => {
      handleFilterChange({
        context: newContext,
        field: defaultFieldValue[newContext],
        nqlFlow: [''],
        nqlDns: [''],
        sortBy: [],
        period: defaultPeriod,
      });
      setExternalFilters([
        {
          label: `${fieldLabel}: ${defaultFieldValue[newContext]}`,
        },
      ]);
      if (newContext === ContextTypes.flow) {
        dispatch(globalFiltersUiActions.userFlippedIpDirection(flipFields.dst));
      }
    },
    [handleFilterChange],
  );

  useEffect(() => {
    if (userChangedContext) {
      setDefaultFilters(userChangedContext);
      setSelectedButton(null);
      dispatch(globalFiltersUiActions.userChangedContext(false));
      if (userChangedContext === ContextTypes.flow) {
        dispatch(globalFiltersUiActions.userFlippedIpDirection(flipFields.dst));
      }
    }
  }, [userChangedContext]);

  const handleInvestigateSubmit = useCallback(
    (modalValues) => {
      setIsInvestigateModalOpen(false);
      handleFilterChange({
        context: ContextTypes.flow,
        field: 'dstport',
        nqlFlow: [
          nqlLang.and(
            nqlLang.equal(modalValues.directionality, modalValues.ip),
            nqlLang.less('dstport', 'srcport'),
            modalValues.internal &&
              nqlLang.and(
                nqlLang.equal('srcinternal', true),
                nqlLang.equal('dstinternal', true),
              ),
          ),
        ],
        sortBy: [{ id: 'agg_count', desc: true }],
        selectedButton: 'investigateHost',
        to: modalValues.dateTime.to,
        from: modalValues.dateTime.from,
        period: modalValues.dateTime.period,
      });
      setExternalFilters([
        { label: `${fieldLabel}: dstport` },
        {
          label: `Investigate Host: ${modalValues.ip}`,
          onRemoveFilter: () => setDefaultFilters(ContextTypes.flow),
        },
      ]);
    },
    [dispatch, setDefaultFilters],
  );

  const externallyExposedHosts = useCallback(() => {
    handleFilterChange({
      context: ContextTypes.flow,
      field: 'srcip',
      nqlFlow: [externallyExposedNql],
      sortBy: [],
      selectedButton: 'externallyExposedHosts',
    });
    setExternalFilters([
      { label: `${fieldLabel}: srcip` },
      {
        label: 'Externally Exposed Hosts',
        onRemoveFilter: () => setDefaultFilters(ContextTypes.flow),
      },
    ]);
    dispatch(globalFiltersUiActions.userFlippedIpDirection(flipFields.src));
  }, [handleFilterChange, setDefaultFilters]);

  const externallyExposedPorts = useCallback(() => {
    handleFilterChange({
      context: ContextTypes.flow,
      field: 'srcport',
      nqlFlow: [externallyExposedNql],
      sortBy: [],
      selectedButton: 'externallyExposedPorts',
    });
    setExternalFilters([
      { label: `${fieldLabel}: srcport` },
      {
        label: 'Externally Exposed Ports',
        onRemoveFilter: () => setDefaultFilters(ContextTypes.flow),
      },
    ]);
  }, [handleFilterChange, setDefaultFilters]);

  const internetPorts = useCallback(() => {
    handleFilterChange({
      context: ContextTypes.flow,
      field: 'dstport',
      nqlFlow: [
        nqlLang.and(
          nqlLang.equal('srcinternal', true),
          nqlLang.equal('dstinternal', false),
          nqlLang.less('dstport', 'srcport'),
        ),
      ],
      sortBy: [{ id: 'agg_count', desc: true }],
      selectedButton: 'internetPorts',
    });
    setExternalFilters([
      { label: `${fieldLabel}: dstport` },
      {
        label: 'Internet Ports',
        onRemoveFilter: () => setDefaultFilters(ContextTypes.flow),
      },
    ]);
  }, [handleFilterChange, setDefaultFilters]);

  const rareDomainNames = useCallback(() => {
    handleFilterChange({
      context: ContextTypes.dns,
      field: 'query.name',
      sortBy: [{ id: 'agg_count', desc: false }],
      selectedButton: 'rareDomainNames',
    });
    setExternalFilters([
      { label: `${fieldLabel}: query.name` },
      {
        label: 'Rare Domain Names',
        onRemoveFilter: () => setDefaultFilters(ContextTypes.dns),
      },
    ]);
  }, [handleFilterChange, setDefaultFilters]);

  const trafficTopButtons = useMemo(
    () => [
      {
        label: 'Investigate Host',
        onClick: investigateHost,
        id: 'investigateHost',
      },
      {
        label: 'Externally Exposed Hosts',
        onClick: externallyExposedHosts,
        id: 'externallyExposedHosts',
      },
      {
        label: 'Externally Exposed Ports',
        onClick: externallyExposedPorts,
        id: 'externallyExposedPorts',
      },
      {
        label: 'Internet Ports',
        onClick: internetPorts,
        id: 'internetPorts',
      },
      {
        label: 'Rare Domain Names',
        onClick: rareDomainNames,
        id: 'rareDomainNames',
      },
    ],
    [
      investigateHost,
      externallyExposedHosts,
      externallyExposedPorts,
      internetPorts,
      rareDomainNames,
    ],
  );

  const onClearExternalFilters = useEvent(() => setDefaultFilters(context));

  const removeFromExternalFilters = useEvent(
    (label, newAggregateValue, newContext, newNql) => {
      setExternalFilters((prevFilters) => [
        { label: `${fieldLabel}: ${newAggregateValue}` },
        ...prevFilters.filter((filter) => filter.label !== label).slice(1),
      ]);

      const currentNql = filters[getNqlFieldName(newContext)];
      const updatedNql = getNewNql(currentNql, newNql);

      if (updatedNql[0] === '') {
        setDefaultFilters(newContext);
      } else {
        dispatch(
          globalFiltersActions.changeFilter({
            [getFieldKey(newContext)]: newAggregateValue,
          }),
        );
      }

      dispatch(
        globalFiltersActions.changeFilter({
          ...(newContext === ContextTypes.flow && { nqlFlow: updatedNql }),
          ...(newContext === ContextTypes.dns && { nqlDns: updatedNql }),
        }),
      );
    },
  );

  const onPivotClick = useCallback(
    (row, dataFieldLabel) => {
      const value = row?.agg;

      if (!value) {
        return;
      }

      const newNql = nqlLang.equal(field, value);

      const existingNql = Array.isArray(filters.nql) && filters.nql[0];

      const updatedNql = [nqlLang.and(existingNql, newNql)];

      const newAggregate = `${fieldLabel}: ${dataFieldLabel}`;
      const pivotLabel = `${dataFieldLabel} pivot: ${newNql}`;

      setExternalFilters((prevFilters) => {
        const nonAggregateFilters = prevFilters.filter(
          (filter) => !filter.label.startsWith(fieldLabel),
        );

        return [
          { label: newAggregate },
          ...nonAggregateFilters,
          {
            label: pivotLabel,
            onRemoveFilter: () =>
              removeFromExternalFilters(pivotLabel, field, context, newNql),
          },
        ];
      });

      handleFilterChange({
        context,
        field: dataFieldLabel,
        ...(context === ContextTypes.dns && { nqlDns: updatedNql }),
        ...(context === ContextTypes.flow && { nqlFlow: updatedNql }),
      });
    },
    [
      context,
      field,
      filters,
      removeFromExternalFilters,
      handleFilterChange,
      fieldLabel,
    ],
  );

  return (
    <Fragment>
      <GlobalFiltersSetting
        nql
        context={context}
        excludeContexts={excludeContextsArr}
        customers
      />

      {!excludeContexts.has(selectedContext) && (
        <GlobalFiltersPortal
          isFiltered={isFiltered}
          initialValues={portalInitialValues}
        >
          <GlobalFiltersFields />
        </GlobalFiltersPortal>
      )}

      <Stack direction="row" gap="10px" alignItems="center">
        <PageHeader title="Traffic Top" marginBottom="16px">
          {trafficTopButtons.map(({ label, onClick, id }) => (
            <StyledButton
              key={id}
              onClick={onClick}
              disabled={selectedButton === id && id !== 'investigateHost'}
              variant={
                selectedButton === 'investigateHost' && id === 'investigateHost'
                  ? ButtonVariants.outlined
                  : ButtonVariants.contained
              }
            >
              {label}
            </StyledButton>
          ))}
        </PageHeader>
      </Stack>
      <TableComponent
        field={field}
        data={data}
        noDataText={data ? undefined : lang.loading}
        sortBy={sortBy}
        externalFilters={externalFilters}
        onPivotClick={onPivotClick}
        onClearExternalFilters={onClearExternalFilters}
      />

      <InvestigateHostModal
        isOpen={isInvestigateModalOpen}
        onClose={() => setIsInvestigateModalOpen(false)}
        onInvestigate={handleInvestigateSubmit}
      />
    </Fragment>
  );
};
