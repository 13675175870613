import PropTypes from '+prop-types';
import { Fragment, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { ColorTypes } from '@/models/ColorTypes';
import { UIProperties } from '@/models/UIProperties';

import {
  actions as customerSecurityActions,
  selectors as customerSecuritySelectors,
} from '@/redux/api/customer/security';

import ConfirmModal from '+components/ConfirmModal';
import {
  Description,
  FieldContainer,
  Group,
  Label,
} from '+components/form/FormField';
import Toggle from '+components/form/Toggle';
import { Col } from '+components/Layout';
import useLoadingIndicator from '+hooks/useLoadingIndicator';
import useUIProperty from '+hooks/useUIProperty';

const ResellerAccessSection = ({ customer, disabled }) => {
  const dispatch = useDispatch();
  const location = useLocation();

  const [masqueradeUrl] = useUIProperty(
    UIProperties.masqueradeUrl,
    `${location.pathname}${location.search || ''}`,
  );
  const [isUnderCovered] = useUIProperty(UIProperties.underCover);

  const isFetching = useSelector(customerSecuritySelectors.isFetching);
  const resellerLoginStatus = useSelector(
    customerSecuritySelectors.getResellerLoginStatus,
  );
  const resellerDataAggStatus = useSelector(
    customerSecuritySelectors.getResellerDataAggStatus,
  );

  const [showResellerLoginModal, setShowResellerLoginModal] = useState(false);
  const [showResellerDataAggModal, setShowResellerDataAggModal] =
    useState(false);

  useLoadingIndicator(isFetching);

  /** * Reseller Login ** */
  const onResellerLogin = useCallback(() => {
    setShowResellerLoginModal(true);
  }, []);

  const onResellerLoginCancel = useCallback(() => {
    setShowResellerLoginModal(false);
  }, []);

  const onResellerLoginConfirm = useCallback(() => {
    setShowResellerLoginModal(false);
    if (resellerLoginStatus) {
      // refresh page if user is in masquerade mode
      // because when reseller login is disabled, user will be logged out to his parent account
      dispatch(
        customerSecurityActions.disableResellerLogin(
          isUnderCovered ? masqueradeUrl : null,
        ),
      );
    } else {
      dispatch(customerSecurityActions.enableResellerLogin());
    }
  }, [resellerLoginStatus, isUnderCovered, masqueradeUrl]);

  /** * Reseller Data Aggregation ** */
  const onResellerDataAgg = useCallback(() => {
    setShowResellerDataAggModal(true);
  }, []);

  const onResellerDataAggCancel = useCallback(() => {
    setShowResellerDataAggModal(false);
  }, []);

  const onResellerDataAggConfirm = useCallback(() => {
    setShowResellerDataAggModal(false);
    if (resellerDataAggStatus) {
      dispatch(customerSecurityActions.disableResellerDataAgg());
    } else {
      dispatch(customerSecurityActions.enableResellerDataAgg());
    }
  }, [resellerDataAggStatus]);

  useEffect(() => {
    dispatch(customerSecurityActions.fetchResellerLoginStatus());
    dispatch(customerSecurityActions.fetchResellerDataAggStatus());
  }, []);

  return (
    <Fragment>
      <Col className="form form--horizontal" item container={false}>
        <Group>
          <Label disabled={isFetching || disabled}>Sub-Account Login</Label>
          <FieldContainer>
            <Toggle
              name="resellerLoginStatus"
              checked={resellerLoginStatus}
              type="checkbox"
              checkedLabel="Enabled"
              disabled={isFetching || disabled}
              onChange={onResellerLogin}
            />
          </FieldContainer>
          <Description>
            Allow {customer?.parent} account to login to this account
          </Description>
        </Group>
      </Col>

      <Col className="form form--horizontal" item container={false}>
        <Group>
          <Label disabled={isFetching || disabled}>Sub-Account Sharing</Label>
          <FieldContainer>
            <Toggle
              name="resellerDataAggStatus"
              checked={resellerDataAggStatus}
              type="checkbox"
              checkedLabel="Enabled"
              disabled={isFetching || disabled}
              onChange={onResellerDataAgg}
            />
          </FieldContainer>
          <Description>
            Allow {customer?.parent} account to view event data from this
            account
          </Description>
        </Group>
      </Col>

      {showResellerLoginModal && (
        <ConfirmModal
          item="sub-account login"
          confirmButtonText={resellerLoginStatus ? 'Disable' : 'Enable'}
          confirmButtonColor={ColorTypes.primary}
          whyAsking={
            resellerLoginStatus
              ? `Disabling sub-account login will prevent ${customer?.parent} account to login to this account.`
              : `Enabling sub-account login will grant ${customer?.parent} account to login to this account.`
          }
          onToggle={onResellerLoginCancel}
          onConfirm={onResellerLoginConfirm}
          isOpen
        />
      )}

      {showResellerDataAggModal && (
        <ConfirmModal
          item="sub-account sharing"
          confirmButtonText={resellerDataAggStatus ? 'Disable' : 'Enable'}
          confirmButtonColor={ColorTypes.primary}
          whyAsking={
            resellerDataAggStatus
              ? `Disabling sub-account sharing will prevent ${customer?.parent} account to view event data from this account.`
              : `Enabling sub-account sharing will grant ${customer?.parent} account to view event data from this account.`
          }
          onToggle={onResellerDataAggCancel}
          onConfirm={onResellerDataAggConfirm}
          isOpen
        />
      )}
    </Fragment>
  );
};

ResellerAccessSection.propTypes = {
  customer: PropTypes.shape().isRequired,
  disabled: PropTypes.bool,
};

ResellerAccessSection.defaultProps = {
  disabled: false,
};

export default ResellerAccessSection;
