import { useCallback } from 'react';
import { ensurePluginOrder } from 'react-table';

import TechnicalColumns from '../TechnicalColumns';

const pluginName = 'useGroupByColumnMethodsExtender';

const useInstance = (instance) => {
  const {
    flatHeaders,
    toggleGroupBy,
    setSortBy,
    plugins,
    state: { groupBy, sortBy },
  } = instance;

  ensurePluginOrder(plugins, ['useGroupBy'], pluginName);

  const toggleGroupByExt = useCallback(
    (columnId) => {
      toggleGroupBy(columnId);

      if (groupBy.includes(columnId) || groupBy.length > 0) {
        // ignore if column will be ungrouped.
        // or if it is not first column in grouping.
        return;
      }

      const found = sortBy?.find(
        (item) => item.id === TechnicalColumns.totalColumn,
      );
      if (!found) {
        setSortBy([
          { id: TechnicalColumns.totalColumn, desc: true },
          ...sortBy,
        ]);
      }
    },
    [toggleGroupBy, groupBy, sortBy, setSortBy],
  );

  flatHeaders.forEach((column) => {
    if (column.canGroupBy) {
      column.toggleGroupBy = () => toggleGroupByExt(column.id);
    }
  });
};

export const useGroupByColumnMethodsExtender = (hooks) => {
  hooks.useInstance.push(useInstance);
};

useGroupByColumnMethodsExtender.pluginName = pluginName;
