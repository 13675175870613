import PropTypes from '+prop-types';
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useToggle } from 'react-use';

import { ContextTypes } from '@/models/ContextTypes';
import RoutePaths from '@/models/RoutePaths';

import {
  actions as deviceActions,
  selectors as deviceSelectors,
} from '@/redux/api/device';
import {
  actions as dnsDeviceActions,
  selectors as dnsDeviceSelectors,
} from '@/redux/api/dnsDevice';
import {
  actions as vpcActions,
  selectors as vpcSelectors,
} from '@/redux/api/vpc';

import ConfirmModal from '+components/ConfirmModal';
import Table from '+components/Table';
import useFocusOnRowId from '+hooks/useFocusOnRowId';
import useGlobalFilters from '+hooks/useGlobalFilters';
import useIpLabels from '+hooks/useIpLabels';
import useLoadingIndicator from '+hooks/useLoadingIndicator';

import { vpcUItoParams } from './VpcForm/utils';
import { Columns, getColumns } from './TableColumns';

const tableId = 'Sources';

const sortBy = [
  {
    id: 'name',
  },
];

const defaultColumns = [
  Columns.rowSelector,
  Columns.source,
  Columns.trafficType,
  Columns.name,
  Columns.site,
  Columns.ips,
  Columns.tags,
  Columns.samplerate,
  Columns.state,
  Columns.bgp,
  Columns.enabled,
  Columns.menu,
];

const SourcesTable = (props) => {
  const { sources, permissions, ...rest } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const additionalColumns = useMemo(
    () => Object.keys(Columns).filter((i) => !defaultColumns.includes(i)),
    [defaultColumns],
  );

  const [filters] = useGlobalFilters();
  const { ipLabelsHash } = useIpLabels();

  const [, setRowId] = useFocusOnRowId(tableId);
  const { isFetching: isDeviceFetching, statuses: deviceStatuses } =
    useSelector(deviceSelectors.getState);
  const { isFetching: isVpcFetching, statuses: vpcStatuses } = useSelector(
    vpcSelectors.getState,
  );
  const isDNSDeviceFetching = useSelector(dnsDeviceSelectors.isFetching);
  const statuses = useMemo(
    () => ({ ...vpcStatuses, ...deviceStatuses }),
    [deviceStatuses, vpcStatuses],
  );

  const [currentSource, setCurrentSource] = useState(false);
  const [showDelete, toggleDelete] = useToggle(false);
  const [showEnableModal, toggleEnableModal] = useToggle(false);

  const isDataLoading =
    isDeviceFetching || isVpcFetching || isDNSDeviceFetching;

  useLoadingIndicator(isDataLoading);

  const tableData = useMemo(
    () =>
      !filters.labelContext.show
        ? sources
        : (sources || []).map((el) => {
            const ipsnames = (el.ips || []).map(
              (ip) => ipLabelsHash[ip]?.[filters.labelContext.ip],
            );

            let state;
            let stateLastUpdate;

            if (statuses[el.id] && statuses[el.id]?.flowresource) {
              // if source is a cloud provider
              state = statuses[el.id].success ? 'active' : 'not polling';
              stateLastUpdate = statuses[el.id].time;
            }

            if (statuses[el.id] && !statuses[el.id]?.flowresource) {
              // if source is a device
              state = statuses[el.id].flow.active ? 'active' : 'inactive';
              stateLastUpdate = statuses[el.id].flow.lastseen * 1000;
            }

            return {
              ...el,
              ipsnames,
              state,
              stateLastUpdate,
            };
          }),
    [sources, ipLabelsHash, filters.labelContext, statuses],
  );

  const onEdit = useCallback(
    (item) => {
      setRowId(item.id);
      if (item.flowtype) {
        // VPC
        navigate(`${RoutePaths.sourcesCloudProviders}/edit/${item.id}`);
        return;
      }
      if (item.traffictype === ContextTypes.flow) {
        // Flow device
        navigate(`${RoutePaths.sourcesDevices}/edit/${item.id}`);
        return;
      }
      // DNS device
      navigate(`${RoutePaths.sourcesDnsDevices}/edit/${item.id}`);
    },
    [setRowId],
  );

  useEffect(() => {
    if (Object.keys(vpcStatuses).length === 0) {
      dispatch(vpcActions.fetchVpcStatuses(null, 'vpcStatuses'));
    }
    return () => {
      dispatch(vpcActions.cancel('vpcStatuses'));
    };
  }, [vpcStatuses]);

  const onBGPConfig = useCallback(
    (item) => {
      setRowId(item.id);
      navigate(
        `${RoutePaths.sourcesDevices}/bgp/${encodeURIComponent(item.id)}`,
      );
    },
    [setRowId],
  );

  const onDetails = useCallback(
    (item) => {
      setRowId(item.id);
      if (item.flowtype) {
        // VPC
        navigate(`${RoutePaths.sourcesCloudProviders}/details/${item.id}`);
        return;
      }
      if (item.traffictype === ContextTypes.flow) {
        // Flow device
        navigate(`${RoutePaths.sourcesDevices}/details/${item.id}`);
        return;
      }
      // DNS device
      navigate(`${RoutePaths.sourcesDnsDevices}/details/${item.id}`);
    },
    [setRowId],
  );

  const onDeleteModalToggle = useCallback(
    (item) => () => {
      if (showDelete) {
        setCurrentSource(false);
        toggleDelete(false);
      } else {
        setCurrentSource(item);
        toggleDelete(true);
      }
    },
    [showDelete],
  );

  const onDeleteModalConfirm = useCallback(
    (item) => () => {
      if (!item?.id) {
        return;
      }
      if (item.flowtype) {
        // VPC
        dispatch(vpcActions.vpcDelete(item.id));
        return;
      }
      if (item.traffictype === ContextTypes.flow) {
        // Flow device
        dispatch(deviceActions.deviceDelete(item.id));
        return;
      }
      // DNS device
      dispatch(dnsDeviceActions.removeDNSDevice(item.id));
    },
    [],
  );

  const onEnableToggle = useCallback(
    (item) => {
      if (showEnableModal) {
        setCurrentSource(false);
        toggleEnableModal(false);
      } else {
        setCurrentSource(item);
        toggleEnableModal(true);
      }
    },
    [showEnableModal],
  );

  const onEnableConfirm = useCallback((item) => {
    if (!item?.id) {
      return;
    }

    if (item.type === 'infoblox') {
      // DNS device
      dispatch(
        item.enabled
          ? dnsDeviceActions.disableDNSDevice(item)
          : dnsDeviceActions.enableDNSDevice(item),
      );
      return;
    }

    // Cloud provider
    const values = vpcUItoParams(item);
    dispatch(
      item.enabled
        ? vpcActions.vpcDisable(values)
        : vpcActions.vpcEnable(values),
    );
  }, []);

  const columns = useMemo(
    () =>
      getColumns(defaultColumns, {
        labelContext: filters.labelContext,
        onEdit,
        onDelete: onDeleteModalToggle,
        onDetails,
        onBGPConfig,
        permissions,
        onEnableToggle,
      }),
    [
      onEdit,
      onDeleteModalToggle,
      onDetails,
      onBGPConfig,
      permissions,
      filters.labelContext,
    ],
  );

  const availableColumns = useMemo(
    () =>
      getColumns(additionalColumns, {
        labelContext: filters.labelContext,
        onEdit,
        onDelete: onDeleteModalToggle,
        onDetails,
        onBGPConfig,
        permissions,
        onEnableToggle,
      }),
    [
      onEdit,
      onDeleteModalToggle,
      onDetails,
      onBGPConfig,
      permissions,
      filters.labelContext,
    ],
  );

  return (
    <Fragment>
      <div style={{ marginTop: '8px' }}>
        <Table
          {...rest}
          id={tableId}
          columns={columns}
          data={tableData}
          availableColumns={availableColumns}
          sortBy={sortBy}
        />
      </div>

      {showDelete && (
        <ConfirmModal
          item={currentSource?.name}
          onConfirm={onDeleteModalConfirm(currentSource)}
          onToggle={onDeleteModalToggle(currentSource)}
          isOpen
        />
      )}
      {showEnableModal && (
        <ConfirmModal
          confirmButtonText={currentSource?.enabled ? 'Disable' : 'Enable'}
          whyAsking=""
          confirmButtonColor="primary"
          item={currentSource?.name}
          onConfirm={() => onEnableConfirm(currentSource)}
          onToggle={() => onEnableToggle(currentSource)}
          isOpen
        />
      )}
    </Fragment>
  );
};

SourcesTable.propTypes = {
  filter: PropTypes.shape({}),
  sources: PropTypes.arrayOf(PropTypes.shape({})),
  permissions: PropTypes.shape({}),
};

SourcesTable.defaultProps = {
  filter: {},
  sources: [],
  permissions: null,
};

export default SourcesTable;
