import PropTypes from '+prop-types';
import {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import styled from 'styled-components';

import PermissionModel from '@/models/Permission';
import RoutePaths from '@/models/RoutePaths';

import {
  actions as responseIntegrationsActions,
  selectors as responseIntegrationsSelectors,
} from '@/redux/api/integrations/response';
import {
  actions as ruleActions,
  actions as rulesActions,
  selectors as rulesSelectors,
} from '@/redux/api/rules';

import { MitreChart } from '+components/charts/MitreChart';
import ConfirmModal from '+components/ConfirmModal';
import ButtonGroupField from '+components/form/ButtonGroupField';
import CheckBox from '+components/form/CheckBox';
import FieldsSection from '+components/form/FieldsSection';
import { Field, FormSpy, useForm } from '+components/form/FinalForm';
import FieldContainer from '+components/form/FormField/components/FieldContainer';
import Group from '+components/form/FormField/components/Group';
import Label from '+components/form/FormField/components/Label';
import MultiSelectField from '+components/form/MultiSelectField';
import { normalizeMultiSelectValue } from '+components/form/Normalizers';
import TextField from '+components/form/TextField';
import { ToggleField } from '+components/form/Toggle';
import { validateRequired } from '+components/form/Validators';
import FormWizard, { Step } from '+components/FormWizard';
import { LayoutSizes } from '+components/Layout';
import { PageHeader } from '+components/Layout/PageHeader';
import { inUseMitreTechniques, useMitreTactics } from '+hooks/mitreHooks';
import usePermissions from '+hooks/usePermissions';
import { ScrollBarMixin } from '+theme/mixins/scrollBarMixin';
import sortBySystemAndValue from '+utils/sortBySystemAndValue';

import { matchByOptions, rulesUItoParams } from './Rules';

const sortByAdapterAndName = (a, b) => {
  if (a?.adapter < b?.adapter) {
    return -1;
  }

  if (a?.adapter !== b?.adapter) {
    return 1;
  }

  return (a?.name || '')
    .toLowerCase()
    .localeCompare((b?.name || '').toLowerCase());
};

const FieldContainerStyled = styled(FieldContainer)`
  align-items: center;
  gap: 5rem;
  label {
    margin-bottom: 0;
  }
`;

const StyledWizard = styled(FormWizard)`
  .wizard__left-panel {
    width: 100%;
    max-width: 100%;
  }

  .fields-section-line {
    width: 60%;
  }
`;

const StyledFieldsSection = styled(FieldsSection)`
  .fields-section-header-container {
    min-width: 630px;
    width: 60%;
  }
`;

const TextFieldsSection = styled(StyledFieldsSection)`
  min-width: 630px;
  width: 60%;
  margin-top: 0;

  .fields-section-line {
    display: none;
  }
`;

const FormGroupNoMargin = styled(Group)`
  margin-bottom: 0;
`;

const StyledField = styled(Field)`
  min-width: 491px;
  width: 54%;
`;

const MitreGroup = styled(Group)`
  width: 100%;
  flex-wrap: nowrap !important;

  .form__form-group-field {
    background-color: ${({ theme }) => theme.colorFieldBackground};
    padding: 2px 10px 10px 10px !important;
    margin: 10px 0 0 20px !important;

    ${ScrollBarMixin};
    overflow-x: auto;
  }
`;

const MitreGroupLabelContainer = styled.div`
  min-width: 120px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-top: 10px;
  font-size: 13px;
  font-weight: 600;
`;

const MitreGroupSubtitle = styled.span`
  color: ${({ theme }) => theme.colorTextSecondary} !important;
  font-size: 11px;
  font-weight: 600;
  margin-top: ${({ $marginTop }) => $marginTop}px;
`;

const MitreGroupLabel = () => (
  <MitreGroupLabelContainer>
    <span>MITRE Categories</span>
    <MitreGroupSubtitle $marginTop={10}>Tactic</MitreGroupSubtitle>
    <MitreGroupSubtitle $marginTop={23}>Techniques</MitreGroupSubtitle>
  </MitreGroupLabelContainer>
);

const detectionCategoriesHelperText =
  'Multiple Detection Categories match with an OR condition';
const detectionModelsHelperText =
  'Multiple Detection Models match with an OR condition';

const FormBody = (props) => {
  const { canManage, formValues } = props;

  const form = useForm();

  const [, tactics, , filteredTactics] = useMitreTactics(undefined, true);

  const [selectedTechniques, setSelectedTechniques] = useState([]);

  const rules = useSelector(rulesSelectors.getState);
  const { plugins } = useSelector(responseIntegrationsSelectors.getState);

  const onTechniqueClick = useCallback(
    (technique) => {
      const id = technique.technique_id;
      setSelectedTechniques((prev) =>
        prev?.includes(id) ? prev.filter((item) => item !== id) : [...prev, id],
      );
      const newCategories = !formValues?.UIcategories?.includes(id)
        ? [...formValues.UIcategories, id]
        : formValues.UIcategories.filter((cat) => cat !== id);
      form.change('UIcategories', newCategories);
    },
    [formValues, selectedTechniques, setSelectedTechniques],
  );

  const onTacticClick = useCallback(
    (tactic) => {
      const childTechniqueIds = tactic.techniques.map(
        (tech) => tech.technique_id,
      );

      const someChildTechniquesNotSelected = childTechniqueIds.some(
        (id) => !selectedTechniques.includes(id),
      );

      const newTechnqiues = someChildTechniquesNotSelected
        ? [...new Set([...selectedTechniques, ...childTechniqueIds])]
        : selectedTechniques.filter(
            (selectedId) => !childTechniqueIds.includes(selectedId),
          );

      const newCategories = someChildTechniquesNotSelected
        ? [...new Set([...formValues.UIcategories, ...childTechniqueIds])]
        : [
            ...formValues.UIcategories.filter(
              (cat) =>
                !inUseMitreTechniques.has(cat) || newTechnqiues.includes(cat),
            ),
          ];

      setSelectedTechniques(newTechnqiues);
      form.change('UIcategories', newCategories);
    },
    [selectedTechniques],
  );

  useEffect(() => {
    if (formValues?.UIcategories) {
      setSelectedTechniques(
        formValues.UIcategories.filter((cat) => inUseMitreTechniques.has(cat)),
      );
    }
  }, [formValues.UIcategories]);

  const categories = useMemo(
    () =>
      Object.values(rules.categories || {})
        .map((el) => ({
          ...el,
          value: el.name,
          label: el.name,
          description: el.description,
        }))
        .sort(sortBySystemAndValue),
    [rules.categories],
  );

  const algorithms = useMemo(
    () =>
      Object.values(rules.algorithms || {})
        .map((el) => ({
          ...el,
          value: el.name,
          label: el.name,
        }))
        .sort(sortBySystemAndValue),
    [rules.algorithms],
  );

  const integrations = useMemo(
    () =>
      Object.values(plugins || {})
        .map((el) => ({ ...el, value: el.id, label: el.name }))
        .sort(sortByAdapterAndName),
    [plugins],
  );

  return (
    <Fragment>
      <TextFieldsSection>
        <Field
          name="name"
          label="Name"
          component={TextField}
          type="text"
          autoComplete="new-password"
          validate={validateRequired}
          disabled={!canManage}
          required
        />

        <Field
          name="description"
          label="Description"
          component={TextField}
          type="text"
          maxLength={255}
          autoComplete="new-password"
          disabled={!canManage}
        />

        <Field
          name="UIplugins"
          label="Integrations"
          component={MultiSelectField}
          options={integrations}
          groupBy={(item) => item.adapter}
          parse={normalizeMultiSelectValue}
          disabled={!canManage}
        />
      </TextFieldsSection>

      <FormGroupNoMargin>
        <Label>Disable/Enable</Label>
        <FieldContainer>
          <Field
            name="enabled"
            type="checkbox"
            checkedLabel="Enabled"
            uncheckedLabel="Disabled"
            component={ToggleField}
            disabled={!canManage}
          />
        </FieldContainer>
      </FormGroupNoMargin>

      <StyledFieldsSection label="Detection Options">
        <Group>
          <Label>Severities</Label>
          <FieldContainerStyled row>
            <Field
              name="UIseverity-high"
              component={CheckBox}
              type="checkbox"
              label="High"
              disabled={!canManage}
            />

            <Field
              name="UIseverity-medium"
              label="Medium"
              component={CheckBox}
              type="checkbox"
              disabled={!canManage}
            />

            <Field
              name="UIseverity-low"
              label="Low"
              component={CheckBox}
              type="checkbox"
              disabled={!canManage}
            />
          </FieldContainerStyled>
        </Group>

        <FormGroupNoMargin>
          <Label>Alert Types</Label>
          <FieldContainerStyled row>
            <Field
              name="UIalerttype-start"
              label="Start"
              component={CheckBox}
              type="checkbox"
              disabled={!canManage}
            />

            <Field
              name="UIalerttype-ongoing"
              label="Ongoing"
              component={CheckBox}
              type="checkbox"
              disabled={!canManage}
            />

            <Field
              name="UIalerttype-end"
              label="End"
              component={CheckBox}
              type="checkbox"
              disabled={!canManage}
            />
          </FieldContainerStyled>
        </FormGroupNoMargin>
      </StyledFieldsSection>

      <StyledFieldsSection label="Detection Matches">
        <Field
          name="UImatchBy"
          label="Match By"
          component={ButtonGroupField}
          options={Object.values(matchByOptions)}
          defaultValue={matchByOptions.models.value}
        />

        {formValues.UImatchBy === matchByOptions.models.value ? (
          <StyledField
            name="UIalgorithms"
            label="Detection Models"
            component={MultiSelectField}
            options={algorithms}
            groupBy={(item) =>
              item.system
                ? 'System Detection Models'
                : 'Custom Detection Models'
            }
            helperText={detectionModelsHelperText}
            parse={normalizeMultiSelectValue}
            disabled={!canManage}
          />
        ) : (
          <FormGroupNoMargin>
            <StyledField
              name="UIcategories"
              label="Detection Categories"
              component={MultiSelectField}
              options={categories}
              groupBy={(item) =>
                item.system ? 'System Categories' : 'Custom Categories'
              }
              helperText={detectionCategoriesHelperText}
              parse={normalizeMultiSelectValue}
              disabled={!canManage}
            />
            <MitreGroup>
              <MitreGroupLabel />
              <FieldContainer>
                <MitreChart
                  selectorMode
                  tactics={tactics}
                  tacticsAndEvents={filteredTactics}
                  onTechniqueClick={onTechniqueClick}
                  onTacticClick={onTacticClick}
                  selectedTechniques={selectedTechniques}
                />
              </FieldContainer>
            </MitreGroup>
          </FormGroupNoMargin>
        )}
      </StyledFieldsSection>
    </Fragment>
  );
};

FormBody.propTypes = {
  canManage: PropTypes.bool.isRequired,
  formValues: PropTypes.shape({
    UIcategories: PropTypes.arrayOf(PropTypes.string),
    UImatchBy: PropTypes.string,
  }).isRequired,
};

const RuleForm = (props) => {
  const {
    mode,
    ruleId,
    initialValues,
    loading,
    updateCallback,
    createCallback,
  } = props;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const permissions = usePermissions(
    PermissionModel.Resources.response_policy.value,
  );

  const [submitMode, setSubmitMode] = useState(mode);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [formValues, setFormValues] = useState({});

  const canManage =
    submitMode === 'create' ? permissions?.create : permissions?.update;
  const canRemove = submitMode !== 'create' && permissions?.delete;

  const timer = useRef();

  useEffect(
    () => () => {
      if (timer.current) {
        clearTimeout(timer.current);
      }
    },
    [],
  );

  const onChange = useCallback(({ values }) => {
    timer.current = setTimeout(() => {
      setFormValues(values);
    }, 10);
  }, []);

  const onSubmit = useCallback(
    (UIvalues) => {
      // converted flattened UI object to nested API object
      const values = rulesUItoParams(UIvalues);
      if ('update' === submitMode) {
        updateCallback(values);
      } else {
        createCallback(values);
      }
    },
    [submitMode, updateCallback, createCallback],
  );

  const onCancel = useCallback(() => {
    navigate(`${RoutePaths.responsePolicies}`);
  }, []);

  const onCopy = useCallback(() => {
    setSubmitMode(submitMode === 'create' ? 'update' : 'create');
  }, [submitMode]);

  const onDeleteModalToggle = useCallback(() => {
    setShowDeleteModal((prevValue) => !prevValue);
  }, []);

  const onDelete = useCallback(() => {
    dispatch(rulesActions.deleteRule(ruleId));
    onCancel();
  }, [ruleId, onCancel]);

  const additionalActions = useMemo(
    () =>
      permissions?.create
        ? [
            {
              text: `Switch to ${
                submitMode === 'create' ? 'Update' : 'Create'
              }`,
              onClick: updateCallback ? onCopy : () => {},
            },
          ]
        : undefined,
    [permissions, submitMode, updateCallback, onCopy],
  );

  // load dependencies for the dropdown fields (algorithms, categories & plugins)
  useEffect(() => {
    dispatch(ruleActions.fetchCategories());
    dispatch(ruleActions.fetchAlgorithms());
    dispatch(responseIntegrationsActions.fetchPlugins());
  }, []);

  return (
    <Fragment>
      <PageHeader
        title={`${submitMode === 'create' ? 'Add' : 'Edit'} Response Policy`}
        showBackButton
        backUrl={`${RoutePaths.responsePolicies}`}
        marginBottom={LayoutSizes.groupGap}
      />
      <StyledWizard
        initialValues={initialValues}
        onSubmit={onSubmit}
        onCancel={onCancel}
        additionalActions={props.updateCallback ? additionalActions : undefined}
        confirmButtonText={submitMode === 'create' ? 'Create' : 'Update'}
        loading={loading}
        disabled={!canManage}
        deleteButtonText="Delete Response Policy"
        onDelete={onDeleteModalToggle}
        deleteButtonHidden={submitMode === 'create'}
        deleteButtonDisabled={!canRemove}
      >
        <Step
          title={`${submitMode === 'create' ? 'Add' : 'Edit'} Response Policy`}
        >
          <FormSpy subscription={{ values: true }} onChange={onChange} />
          <FormBody canManage={canManage} formValues={formValues} />
        </Step>
      </StyledWizard>

      {showDeleteModal && (
        <ConfirmModal
          item={initialValues?.name}
          onToggle={onDeleteModalToggle}
          onConfirm={onDelete}
          isOpen
        />
      )}
    </Fragment>
  );
};

RuleForm.propTypes = {
  createCallback: PropTypes.func,
  updateCallback: PropTypes.func,
  mode: PropTypes.oneOf(['create', 'update']),
  initialValues: PropTypes.shape({
    name: PropTypes.string,
    UIplugins: PropTypes.arrayOf(PropTypes.string),
    'UIseverity-high': PropTypes.bool,
    'UIseverity-medium': PropTypes.bool,
    'UIseverity-low': PropTypes.bool,
    'UIalerttype-start': PropTypes.bool,
    'UIalerttype-ongoing': PropTypes.bool,
    'UIalerttype-end': PropTypes.bool,
    enabled: PropTypes.bool,
  }),
  loading: PropTypes.bool,
  ruleId: PropTypes.string,
};

RuleForm.defaultProps = {
  mode: 'create',
  createCallback: () => {},
  updateCallback: null, // null so we can switch the UI accordingly
  initialValues: {},
  loading: false,
  ruleId: null,
};

export default RuleForm;
