import PropTypes from '+prop-types';
import { useEffect, useRef, useState } from 'react';

import { useFlag } from '@unleash/proxy-client-react';

import FeatureFlags from '@/models/FeatureFlags';
import { UIProperties } from '@/models/UIProperties';

import useUIProperty from '+hooks/useUIProperty';

import Container from './components/Container';
import GrabArea from './components/Resizer';

const resizeEventDispatcher = () => window.dispatchEvent(new Event('resize'));

const SidebarRight = ({
  className,
  children,
  $width,
  $separatorWidth,
  isOpen,
  onResizeStart,
  onResizeReset,
}) => {
  const isResizableTrayEnabled = useFlag(FeatureFlags.resizableTray);
  const [isShown, setIsShown] = useState(isOpen);

  const [isSideBarRightResizing] = useUIProperty(
    UIProperties.isSideBarRightResizing,
    false,
  );

  const containerRef = useRef(null);

  useEffect(() => {
    if (isResizableTrayEnabled || !containerRef.current) {
      return undefined;
    }
    let timer;
    containerRef.current.ontransitionend = () => {
      timer = setTimeout(resizeEventDispatcher, 100);
    };
    return () => {
      clearTimeout(timer);
    };
  }, [isResizableTrayEnabled]);

  useEffect(() => {
    if (isOpen) {
      setIsShown(isOpen);
      return undefined;
    }

    const handle = () => {
      setIsShown(false);
    };
    if (containerRef.current) {
      containerRef.current.addEventListener('transitionend', handle);
    } else {
      handle();
    }

    return () => {
      containerRef.current?.removeEventListener('transitionend', handle);
    };
  }, [isOpen]);

  return (
    <Container
      ref={containerRef}
      className={className}
      $isOpen={isOpen}
      $width={$width}
      $separatorWidth={$separatorWidth}
      $resizing={isSideBarRightResizing}
    >
      {isResizableTrayEnabled && (
        <GrabArea
          $separatorWidth={$separatorWidth}
          onMouseDown={onResizeStart}
          onDoubleClick={onResizeReset}
        />
      )}
      {isShown && children}
    </Container>
  );
};

SidebarRight.propTypes = {
  className: PropTypes.string,
  children: PropTypes.children,
  $width: PropTypes.number.isRequired,
  $separatorWidth: PropTypes.number.isRequired,
  isOpen: PropTypes.bool,
  onResizeStart: PropTypes.func,
  onResizeReset: PropTypes.func,
};

SidebarRight.defaultProps = {
  className: null,
  children: null,
  isOpen: false,
  onResizeStart: null,
  onResizeReset: null,
};

export default SidebarRight;
