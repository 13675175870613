import PropTypes from '+prop-types';
import {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useToggle } from 'react-use';

import capitalize from 'lodash.capitalize';
import styled from 'styled-components';

import { ContextTypes } from '@/models/ContextTypes';
import RoutePaths from '@/models/RoutePaths';
import { UIProperties } from '@/models/UIProperties';

import {
  actions as dashboardsActions,
  selectors as dashboardsSelectors,
} from '@/redux/api/dashboards';

import { Breadcrumb } from '+components/Breadcrumb';
// import Button, { ButtonVariants } from '+components/Button';
import ConfirmModal from '+components/ConfirmModal';
import FieldsSectionOrigin from '+components/form/FieldsSection';
import { FieldArray, FORM_ERROR, FormSpy } from '+components/form/FinalForm';
import FormWizard, { Step } from '+components/FormWizard';
import GlobalFiltersSetting from '+components/GlobalFilters/Setting';
import useUIProperty from '+hooks/useUIProperty';

import errorDetails from '../shared/errorDetails';
import { dashboardPropTypes, widgetPropTypes } from '../shared/propTypes';
import {
  getWidgetTitle,
  widgetParamsToUi,
  widgetUiToParams,
} from '../shared/utils';
import { widgets } from '../shared/widgets';
import PageContainer from './components/PageContainer';
import WidgetPreview from './components/WidgetPreview';
import SeriesFields from './fields/series/SeriesFields';
import WidgetDateTimeFields from './fields/WidgetDateTimeFields';
import WidgetDisplayFields from './fields/WidgetDisplayFields';
import WidgetTitleFields from './fields/WidgetTitleFields';

import './fields/widgetsFields';

const FieldsSection = styled(FieldsSectionOrigin)`
  gap: 15px;

  .form__form-group {
    margin-bottom: unset;
  }

  &:not(:first-of-type) {
    margin-top: 25px;
  }

  .form__form-group + .form__form-group {
    margin-left: unset;
    margin-bottom: unset;
  }

  .form__form-group-field {
    align-self: unset !important;
  }
`;

const excludeMetrics = ['card', 'counts'];

const FormBody = ({ dashboard }) => (
  <Fragment>
    <FieldsSection label="Widget Title">
      <WidgetTitleFields />
    </FieldsSection>

    <FieldsSection label="Details">
      <FieldArray
        name="series"
        component={SeriesFields}
        dashboard={dashboard}
      />
    </FieldsSection>

    <FieldsSection label="Settings">
      <WidgetDateTimeFields />

      <WidgetDisplayFields />
    </FieldsSection>
  </Fragment>
);

FormBody.propTypes = {
  dashboard: PropTypes.shape(dashboardPropTypes).isRequired,
};

const AllowedContexts = {
  [ContextTypes.traffic]: ContextTypes.traffic,
  [ContextTypes.flow]: ContextTypes.flow,
  [ContextTypes.dns]: ContextTypes.dns,
  [ContextTypes.blocks]: ContextTypes.blocks,
  [ContextTypes.alerts]: ContextTypes.alerts,
};

const WidgetForm = (props) => {
  const { dashboard, widget } = props;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [formErrors, setFormErrors] = useState({});
  const [formValues, setFormValues] = useState({});
  const [formValidating, setFormValidating] = useState(false);
  const [showDeleteModal, toggleDeleteModal] = useToggle(false);

  const error = useSelector(dashboardsSelectors.getError);
  const isFetching = useSelector(dashboardsSelectors.isFetching);
  const [isProcessing, setIsProcessing] = useState(null);

  const initialValues = useMemo(() => widgetParamsToUi(widget), [widget]);

  const pageTitle = useMemo(
    () =>
      initialValues?.id
        ? `Edit ${getWidgetTitle(initialValues).replace(/\w+/g, capitalize)}`
        : 'Add Widget',
    [initialValues],
  );

  const onBackToDashboard = useCallback(() => {
    navigate(`${RoutePaths.dashboards}/${dashboard.id}?mode=edit`);
  }, [dashboard.id]);

  const onWidgetCreate = useCallback(
    (data) => {
      const { defaultLayout } = widgets[data.series[0].display.type] || {};

      dispatch(
        dashboardsActions.createWidget({
          id: dashboard.id,
          version: dashboard.version,
          widget: data,
          layout: defaultLayout,
          errorDetails: errorDetails(dashboard.id),
        }),
      );

      return new Promise((resolve) => {
        setIsProcessing({ resolve });
      });
    },
    [dashboard.id, dashboard.version],
  );

  const onWidgetUpdate = useCallback(
    (data) => {
      dispatch(
        dashboardsActions.updateWidget({
          ...data,
          dashboardId: dashboard.id,
          widgetId: widget.id,
          version: dashboard.version,
          errorDetails: errorDetails(dashboard.id),
        }),
      );

      return new Promise((resolve) => {
        setIsProcessing({ resolve });
      });
    },
    [dashboard.id, dashboard.version, widget.id],
  );

  const onWidgetDelete = useCallback(() => {
    toggleDeleteModal(false);

    dispatch(
      dashboardsActions.removeWidget({
        dashboardId: dashboard.id,
        widgetId: widget.id,
        version: dashboard.version,
        errorDetails: errorDetails(dashboard.id),
      }),
    );

    onBackToDashboard();
  }, [dashboard.id, dashboard.version, widget.id, onBackToDashboard]);

  const onSubmit = useCallback(
    (values) => {
      const normalizedValue = widgetUiToParams(values);
      return normalizedValue.id
        ? onWidgetUpdate(normalizedValue)
        : onWidgetCreate(normalizedValue);
    },
    [onWidgetCreate, onWidgetUpdate],
  );

  const doSubmit = useCallback(
    async (values) => {
      const errors = await onSubmit(values);
      return errors ? { [FORM_ERROR]: errors } : null;
    },
    [onSubmit],
  );

  useEffect(() => {
    if (isFetching || !isProcessing) {
      return;
    }

    const { resolve } = isProcessing;

    if (error) {
      resolve({ [FORM_ERROR]: error });
      return;
    }

    setIsProcessing(null);
    onBackToDashboard();

    resolve();
  }, [isFetching, isProcessing, error, onBackToDashboard]);

  const timer = useRef();
  const onChange = useCallback(({ values, errors, validating }) => {
    timer.current = setTimeout(() => {
      setFormValues(values);
      setFormErrors(errors);
      setFormValidating(validating);
    }, 10);
  }, []);
  useEffect(
    () => () => {
      if (timer.current) {
        clearTimeout(timer.current);
      }
    },
    [],
  );

  const [, setMasqueradeUrl] = useUIProperty(UIProperties.masqueradeUrl);
  useEffect(() => {
    setMasqueradeUrl(`${RoutePaths.dashboards}`);
    return () => {
      setMasqueradeUrl(null);
    };
  }, []);

  const series0 = formValues?.series?.[0] || widget?.series?.[0];

  return !widget ? null : (
    <PageContainer>
      <GlobalFiltersSetting
        metric
        nql
        context={AllowedContexts[series0.context] || ContextTypes.flow}
        excludeMetrics={excludeMetrics}
      />

      <Breadcrumb title={pageTitle} />

      <FormWizard
        mode={widget?.id ? 'edit' : 'add'}
        initialValues={initialValues}
        deleteButtonText="Delete Widget"
        deleteButtonHidden={!widget?.id}
        loading={isFetching}
        disabled={isFetching}
        onSubmit={doSubmit}
        onCancel={onBackToDashboard}
        onDelete={toggleDeleteModal}
        validateOnBlur
      >
        <Step>
          <FormSpy
            subscription={{ values: true, errors: true, validating: true }}
            onChange={onChange}
          />

          <FormBody dashboard={dashboard} />
        </Step>
      </FormWizard>

      <WidgetPreview
        dashboard={dashboard}
        formValues={formValues}
        formErrors={formErrors}
        formValidating={formValidating}
      />

      {showDeleteModal && (
        <ConfirmModal
          item={getWidgetTitle(widget)}
          onToggle={toggleDeleteModal}
          onConfirm={onWidgetDelete}
          toggleOnConfirm={false}
          isOpen
        />
      )}
    </PageContainer>
  );
};

WidgetForm.propTypes = {
  dashboard: PropTypes.shape(dashboardPropTypes).isRequired,
  widget: PropTypes.shape(widgetPropTypes).isRequired,
};

export default WidgetForm;
