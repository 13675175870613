import PropTypes from '+prop-types';
import { useEffect, useMemo, useRef } from 'react';
import { useFormState } from 'react-final-form';

import capitalize from 'lodash.capitalize';
import get from 'lodash.get';
import styled from 'styled-components';

import InformationIcon from 'mdi-react/InformationIcon';

import PermissionModel from '@/models/Permission';

import CheckBoxField from '+components/form/CheckBox';
import { Field, useForm } from '+components/form/FinalForm';
import { Group, Label } from '+components/form/FormField';
import { Col, Row } from '+components/Layout';
import Tooltip from '+components/Tooltip';

const StyledGroup = styled(Group)`
  margin-bottom: 10px;
`;

const TooltipContainer = styled.div`
  display: flex;
  flex-direction: column;
  cursor: help;

  .mdi-icon {
    color: ${({ theme }) => theme.primary};
  }
`;

const StyledTooltip = styled(Tooltip)`
  max-width: 250px !important;
  padding: 15px;
`;

const manageActions = Object.values(PermissionModel.Actions);

const isCUDButNotRead = (permissions) =>
  !permissions?.fetch &&
  (permissions?.create || permissions?.update || permissions?.delete);

const PermissionsRow = (props) => {
  const { resourceGroup, name, disabled, label, resourceMeta, helperText } =
    props;

  const { values } = useFormState({
    subscription: { values: true },
  });

  const permissions = get(values, name);

  const showTooltip = isCUDButNotRead(permissions);

  const form = useForm();

  const excludeActions = useMemo(
    () => new Set(resourceMeta?.excludeActions || []),
    [resourceMeta?.excludeActions],
  );

  const prevValueRef = useRef(permissions);

  useEffect(() => {
    const diff = Object.fromEntries(
      Object.entries(permissions).filter(
        ([key, val]) =>
          key in prevValueRef.current && prevValueRef.current[key] !== val,
      ),
    );

    prevValueRef.current = permissions;

    if (diff[PermissionModel.Actions.fetch] != null) {
      return;
    }

    const anyTrue = Object.values(diff).some(Boolean);

    if (anyTrue) {
      form.change(name, { ...permissions, fetch: true });
    }
  }, [permissions]);

  return (
    <StyledGroup key={`${resourceGroup}${name}`}>
      <Row wrap="nowrap" gap="20px" alignItems="center">
        {helperText ? (
          <StyledTooltip title={helperText}>
            <TooltipContainer>
              <Label disabled={disabled}>{label}</Label>
            </TooltipContainer>
          </StyledTooltip>
        ) : (
          <Label disabled={disabled}>{label}</Label>
        )}

        <Col container={false} xs item>
          <Row wrap="nowrap" gap="42px" alignItems="center">
            {manageActions.map((action) => (
              <Field
                key={action}
                // we need to use className to hide actions but reserve place for them in grid
                className={excludeActions.has(action) ? 'hidden' : ''}
                name={`${name}.${action}`}
                type="checkbox"
                component={CheckBoxField}
                label={capitalize(
                  action === PermissionModel.Actions.fetch ? 'read' : action,
                )}
              />
            ))}
            {showTooltip && (
              <StyledTooltip
                title="Roles without read access may have limited portal functionality rendering them unable to
                  utilize other permissions settings."
              >
                <TooltipContainer>
                  <InformationIcon size={14} />
                </TooltipContainer>
              </StyledTooltip>
            )}
          </Row>
        </Col>
      </Row>
    </StyledGroup>
  );
};

PermissionsRow.propTypes = {
  resourceGroup: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  resourceMeta: PropTypes.shape().isRequired,
  disabled: PropTypes.bool,
  helperText: PropTypes.string,
};

PermissionsRow.defaultProps = {
  disabled: false,
  helperText: undefined,
};

export default PermissionsRow;
