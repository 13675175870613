import PropTypes from '+prop-types';
import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import PermissionModel from '@/models/Permission';

import {
  actions as customerActions,
  selectors as customerSelectors,
} from '@/redux/api/customer';

import { Col } from '+components/Layout';
import usePermissions from '+hooks/usePermissions';

import SamlConfigurationForm from './SamlConfigurationForm';

export const SsoForm = (props) => {
  const { onCancel } = props;
  const dispatch = useDispatch();

  const samlProvider = useSelector(customerSelectors.getSamlProvider);
  const samlProviderSettings = useSelector(
    customerSelectors.getSamlProviderSettings,
  );
  const samlProviderMetadataXml = useSelector(
    customerSelectors.getSamlProviderMetadataXml,
  );
  const isFetching = useSelector(customerSelectors.isFetching);
  const permissions = usePermissions(PermissionModel.Resources.account.value);

  const initialValues = useMemo(
    () => (!samlProvider?.alias ? {} : samlProvider),
    [samlProvider],
  );

  const onSamlSubmit = useCallback((values) => {
    const data = {
      file: values.file,
      resellerSso: values.resellerSso,
      config: {
        wantAssertionsEncrypted: values.config?.wantAssertionsEncrypted,
      },
      userAttributeMappers: values.userAttributeMappers,
      userRoleMappers: values.userRoleMappers,
      defaultUserRole: values.defaultUserRole,
    };
    if (values.alias) {
      dispatch(customerActions.updateSamlProvider(data));
    } else {
      dispatch(customerActions.createSamlProvider(data));
    }
  }, []);

  useEffect(() => {
    dispatch(customerActions.requestSamlProviderSettings());
  }, []);

  return (
    <Col xs={8} item>
      <SamlConfigurationForm
        samlProvider={initialValues}
        samlProviderSettings={samlProviderSettings}
        samlProviderMetadataXml={samlProviderMetadataXml}
        disabled={isFetching || !permissions?.update}
        onSubmit={onSamlSubmit}
        onCancel={onCancel}
      />
    </Col>
  );
};

SsoForm.propTypes = {
  onCancel: PropTypes.func,
};

SsoForm.defaultProps = {
  onCancel: null,
};
