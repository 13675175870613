/* eslint-disable react/prop-types */
import PropTypes from '+prop-types';
import { Fragment, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { UIProperties } from '@/models/UIProperties';

import {
  actions as customerActions,
  selectors as customerSelectors,
} from '@/redux/api/customer';
import { selectors as profileSelectors } from '@/redux/api/user/profile';

import authClient from '@/middleware/authClient';

import {
  MessageAction,
  MessageContainer,
  MessageItem,
} from '+components/ConfirmModal';
import { Field } from '+components/form/FinalForm';
import { normalizeSelectValue } from '+components/form/Normalizers';
import SelectField from '+components/form/SelectField';
import { validateRequired } from '+components/form/Validators';
import FormModal from '+components/FormModal';
import useRoles from '+hooks/useRoles';
import useUIProperty from '+hooks/useUIProperty';

const customDashboardsStr = 'Custom Dashboards';

const FormBody = (props) => {
  const {
    dashboard,
    customer,
    customers,
    profile,
    canUserMasquerade,
    originalShortname,
  } = props;

  const [isUnderCovered] = useUIProperty(UIProperties.underCover);
  const [isImpersonating] = useUIProperty(UIProperties.impersonating);

  const customersOptions = useMemo(() => {
    if (!canUserMasquerade) {
      return [];
    }
    return Object.values(customers || {})
      .filter((item) => item.shortname !== customer.shortname)
      .map((item) => ({
        value: item.shortname,
        label: `${item.organization} (${item.shortname})`,
        disabled: !(
          item.meta?.reseller_login_enabled ||
          originalShortname === 'netography'
        ),
      }))
      .sort((a, b) => a.label.localeCompare(b.label));
  }, [canUserMasquerade, customer, customers, originalShortname]);

  const copyToOptions = useMemo(() => {
    const isMasquerading = isUnderCovered || isImpersonating;
    const options = [
      {
        value: customer.shortname,
        label: `${customDashboardsStr} (${customer.shortname})`,
      },
    ];

    if (isMasquerading) {
      const shortnames = [customer.shortname];
      const realUser = authClient.getUser();
      const realUserShortname = realUser.app_metadata.shortname;
      const parentResellerShortname = customer.parent;

      if (!shortnames.includes(originalShortname)) {
        shortnames.push(originalShortname);
        options.push({
          value: originalShortname,
          label: `${customDashboardsStr} (${originalShortname})`,
        });
      }

      if (!shortnames.includes(realUserShortname)) {
        shortnames.push(realUserShortname);
        options.push({
          value: realUserShortname,
          label: `${customDashboardsStr} (${realUserShortname})`,
        });
      }

      if (!shortnames.includes(parentResellerShortname)) {
        shortnames.push(parentResellerShortname);
        options.push({
          value: parentResellerShortname,
          label: `Parent Reseller (${parentResellerShortname})`,
        });
      }
    }

    return [...options, ...customersOptions];
  }, [
    isUnderCovered,
    isImpersonating,
    customersOptions,
    customer,
    profile,
    originalShortname,
  ]);

  const showCopyToList = copyToOptions.length > 1;

  return (
    <Fragment>
      <MessageContainer>
        <span>Do you really want to</span>
        <MessageAction>copy</MessageAction>
        <MessageItem>{dashboard.title}</MessageItem>
        {showCopyToList ? (
          <span>?</span>
        ) : (
          <Fragment>
            <span>&nbsp;</span>
            <span>to {customDashboardsStr}?</span>
          </Fragment>
        )}
      </MessageContainer>

      {showCopyToList && (
        <Field
          name="copyTo"
          label="Copy to"
          component={SelectField}
          options={copyToOptions}
          parse={normalizeSelectValue}
          validate={validateRequired}
          required
        />
      )}
    </Fragment>
  );
};

const DashboardCloneForm = (props) => {
  const { dashboard, isOpen, onConfirm, onToggle } = props;

  const dispatch = useDispatch();

  const customer = useSelector(customerSelectors.getCurrentCustomer);
  const customers = useSelector(customerSelectors.getCustomersForTopBarMenu);
  const areAllForTopBarMenuFetched = useSelector(
    customerSelectors.areAllForTopBarMenuFetched,
  );

  const { roles } = useRoles();
  const profile = useSelector(profileSelectors.getProfile);
  const canUserMasquerade = !!roles?.[profile?.roles?.[0]]?.canMasquerade;
  const originalShortname =
    profile?.app_metadata?.original || profile?.app_metadata?.shortname;

  useEffect(() => {
    const skip =
      areAllForTopBarMenuFetched ||
      !originalShortname ||
      (!customer?.multi_account && customer?.shortname === originalShortname);
    if (skip) {
      return undefined;
    }
    const namespace = 'fetch_customers_for_dashboard_clone_form';
    dispatch(customerActions.fetchForTopBarMenu(originalShortname, namespace));
    return () => {
      dispatch(customerActions.cancel(namespace));
    };
  }, [
    areAllForTopBarMenuFetched,
    customer?.multi_account,
    customer?.shortname,
    originalShortname,
  ]);

  return (
    <FormModal
      mode="copy"
      confirmButtonText="Copy"
      item={dashboard.title}
      initialValues={{
        id: dashboard.id,
        copyTo: customer.shortname,
      }}
      isOpen={isOpen}
      onSubmit={onConfirm}
      onToggle={onToggle}
      focusOnFields={false}
      labelOnTop
    >
      <FormBody
        dashboard={dashboard}
        customer={customer}
        customers={customers}
        profile={profile}
        canUserMasquerade={canUserMasquerade}
        originalShortname={originalShortname}
      />
    </FormModal>
  );
};

DashboardCloneForm.propTypes = {
  dashboard: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    shortname: PropTypes.string,
  }).isRequired,
  isOpen: PropTypes.bool.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onToggle: PropTypes.func.isRequired,
};

export default DashboardCloneForm;
