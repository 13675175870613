/* eslint-disable max-len */
import PropTypes from '+prop-types';

import styled from 'styled-components';

import { PluginIcon } from '@/shared/img/icon';

const Container = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  overflow: hidden;
  gap: 5px;

  span {
    flex: 1 1 auto;
    overflow: hidden;
    text-overflow: ellipsis;
    //text-transform: capitalize;
  }
`;

export const PluginLabel = ({ adapter, name, title, size, logo, ...tail }) => {
  return (
    <Container {...tail}>
      <PluginIcon name={adapter} size={size} logo={logo} />
      <span>{title || adapter}</span>
      {name && <span>integration {name}</span>}
    </Container>
  );
};

PluginLabel.propTypes = {
  adapter: PropTypes.string,
  name: PropTypes.string,
  title: PropTypes.string,
  size: PropTypes.number,
  logo: PropTypes.shape(),
};

PluginLabel.defaultProps = {
  adapter: '',
  name: '',
  title: '',
  size: 14,
  logo: null,
};
