/* eslint-disable react/no-array-index-key */
import PropTypes from '+prop-types';
import { memo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useToggle } from 'react-use';

import MDEditor from '@uiw/react-md-editor';
import rehypeExternalLinks from 'rehype-external-links';
import styled, { css } from 'styled-components';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { alpha } from '@mui/material/styles';

import ChevronDownIcon from 'mdi-react/ChevronDownIcon';
import ChevronUpIcon from 'mdi-react/ChevronUpIcon';

import { ContextTypes } from '@/models/ContextTypes';
import RoutePaths from '@/models/RoutePaths';

import { selectors as docsSelectors } from '@/redux/api/docs';
import { selectors as rulesSelectors } from '@/redux/api/rules';

import Button, { ButtonVariants } from '+components/Button';
import CodeBlock from '+components/form/NqlTextField/components/CodeBlock';
import SeverityLabel from '+components/Labels/SeverityLabel';
import { Col, LayoutTypes, Row } from '+components/Layout';
import { usePageTabs } from '+components/PageTabs';
import { CellNqlSearch } from '+components/Table/Cells';
import UniversalField from '+components/UniversalField';
import { ScrollBarMixin } from '+theme/mixins/scrollBarMixin';

const MarkdownStyling = {
  fontSize: '14px',
  backgroundColor: 'transparent',
};

const plugins = [
  [rehypeExternalLinks, { target: '_blank', rel: 'noreferrer' }],
];

const CanBeEmptyRow = styled(Row)`
  &:empty::before {
    content: '—';
  }
`;

const Scrolled = styled(Stack).attrs({
  maxWidth: {
    xs: '80vw',
    md: '50vw',
    lg: '40vw',
  },
})`
  overflow-x: hidden;
  overflow-y: auto;
  ${ScrollBarMixin};
  height: 100%;
  position: relative;
`;

const ButtonContainer = styled(Stack)`
  background-color: ${({ theme }) => theme.drawerBackgroundColor};
  position: sticky;
  bottom: 0;
  z-index: 2;
`;

const InfoBody = styled(Box)`
  overflow: hidden;
  max-height: ${({ $expanded }) => ($expanded ? 'max-content' : '10ch')};
  position: relative;

  &:after {
    @property --stopColor1 {
      syntax: '<color>';
      initial-value: ${({ theme }) => theme.drawerBackgroundColor};
      inherits: false;
    }

    @property --stopColor2 {
      syntax: '<color>';
      initial-value: ${({ theme }) => alpha(theme.drawerBackgroundColor, 0.3)};
      inherits: false;
    }

    --stopColor1: ${({ theme }) => theme.drawerBackgroundColor};
    --stopColor2: ${({ theme }) => alpha(theme.drawerBackgroundColor, 0.3)};

    ${({ $expanded }) =>
      $expanded &&
      css`
        --stopColor1: rgba(0, 0, 0, 0);
        --stopColor2: rgba(0, 0, 0, 0);
      `}

    content: '';
    display: block;
    position: absolute;
    pointer-events: none;

    bottom: 0;
    top: 0;
    left: 0;
    right: 0;

    background: linear-gradient(
      0,
      var(--stopColor1) 10%,
      var(--stopColor2) 100%
    );

    transition:
      --stopColor1 0.3s ease-in-out,
      --stopColor2 0.3s ease-in-out;
  }

  a[target='_blank']::after {
    content: '' / 'opens in new tab/window';
    mask: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxNiAxNiIgd2lkdGg9IjE2IiBoZWlnaHQ9IjE2Ij48cGF0aCBkPSJNOSAyTDkgMyAxMi4zIDMgNiA5LjMgNi43IDEwIDEzIDMuNyAxMyA3IDE0IDcgMTQgMlpNNCA0QzIuOSA0IDIgNC45IDIgNkwyIDEyQzIgMTMuMSAyLjkgMTQgNCAxNEwxMCAxNEMxMS4xIDE0IDEyIDEzLjEgMTIgMTJMMTIgNyAxMSA4IDExIDEyQzExIDEyLjYgMTAuNiAxMyAxMCAxM0w0IDEzQzMuNCAxMyAzIDEyLjYgMyAxMkwzIDZDMyA1LjQgMy40IDUgNCA1TDggNSA5IDRaIi8+PC9zdmc+)
      no-repeat;
    background: currentColor;
    display: inline-block;
    width: 1em;
    height: 1em;
    margin-left: 0.2em;
  }
`;

const infoSx = {
  cursor: 'pointer',
};

export const DrawerBody = memo((props) => {
  const { id, onClose } = props;

  const [infoExpanded, setInfoExpanded] = useToggle(true);
  const [, , pageTabMethods] = usePageTabs();

  const algorithm = useSelector(rulesSelectors.getAlgorithm(id));

  const doc = useSelector(
    docsSelectors.getDetectionModelDoc(algorithm.name || ''),
  );

  const context = algorithm?.algo_record_type || ContextTypes.flow;

  const onCloseClick = useCallback(() => {
    onClose?.('closeButton');
  }, [onClose]);

  const onEditClick = useCallback(() => {
    pageTabMethods.add(
      `${algorithm?.algo_type === 'CDM' ? RoutePaths.modelsContext : RoutePaths.modelsDetection}/${id}`,
    );
    onClose?.('editButton');
  }, [algorithm?.algo_type, id]);

  const onInfoClick = useCallback(() => {
    setInfoExpanded();
  }, []);

  return (
    <Scrolled px="10px" pt="20px" position="relative">
      <Col spacing={2}>
        <Col item>
          <Row item $type={LayoutTypes.title} py={0}>
            {algorithm?.algo_type === 'CDM' ? 'Context Creation' : 'Detection'}{' '}
            Model
          </Row>
          <Row item $type={LayoutTypes.cardContent} py={0}>
            <UniversalField field="tdm" value={algorithm} />
          </Row>
        </Col>

        {!!doc?.body && (
          <Col item>
            <Row
              item
              $type={LayoutTypes.title}
              py={0}
              onClick={onInfoClick}
              sx={infoSx}
              alignItems="center"
              data-tracking="detection-model-info"
            >
              {infoExpanded && <ChevronUpIcon size={20} />}
              {!infoExpanded && <ChevronDownIcon size={20} />}
              Info
            </Row>
            <Row item $type={LayoutTypes.cardContent} py={0}>
              <InfoBody $expanded={infoExpanded}>
                <MDEditor.Markdown
                  source={doc?.body}
                  fullscreen
                  style={MarkdownStyling}
                  rehypePlugins={plugins}
                />
              </InfoBody>
            </Row>
          </Col>
        )}

        <Col item>
          <Row item $type={LayoutTypes.title} py={0}>
            Categories / MITRE Techniques
          </Row>
          {(algorithm?.categories || []).map?.((category, index) => (
            <Row item $type={LayoutTypes.cardContent} py={0} key={index}>
              <UniversalField field="categories" value={category} />
            </Row>
          ))}
        </Col>

        <Col item>
          <Row item $type={LayoutTypes.title} py={0}>
            Traffic
          </Row>
          <Row item $type={LayoutTypes.cardContent} py={0}>
            <UniversalField field="algo_record_type" value={context} disabled />
          </Row>
        </Col>

        <Col item>
          <Row item $type={LayoutTypes.title} py={0}>
            NQL Search
          </Row>

          {(algorithm?.search_by || []).map?.((by) => (
            <Row key={by.type} item $type={LayoutTypes.cardContent} py={0}>
              {(by.search || []).map((item, index) => (
                <CellNqlSearch
                  key={index}
                  value={item}
                  type={index ? null : by.type}
                  context={context}
                />
              ))}
            </Row>
          ))}
        </Col>

        <Col item>
          <Row item $type={LayoutTypes.title} py={0}>
            Thresholds
          </Row>
          {algorithm?.thresholds?.map?.((item, index) => (
            <Row key={index} item $type={LayoutTypes.cardContent} py={0}>
              <Stack direction="row" gap="10px" alignItems="baseline">
                <SeverityLabel severity={item.severity} />
                <CodeBlock $interactive $transparent $inline>
                  {item.threshold}
                </CodeBlock>
              </Stack>
            </Row>
          ))}
        </Col>

        <Col item>
          <Row item $type={LayoutTypes.title} py={0}>
            Auto Thresholding
          </Row>
          <Row item $type={LayoutTypes.cardContent} py={0}>
            {algorithm?.autothreshold ? 'Enabled' : 'Disabled'}
          </Row>
        </Col>

        <Col item>
          <Row item $type={LayoutTypes.title} py={0}>
            Response Policies
          </Row>
          <CanBeEmptyRow
            item
            $type={LayoutTypes.cardContent}
            py={0}
            container={false}
          >
            <UniversalField field="rules" value={algorithm?.rules} />
          </CanBeEmptyRow>
        </Col>

        <Col item>
          <Row item $type={LayoutTypes.title} py={0}>
            Integrations
          </Row>
          <CanBeEmptyRow
            item
            $type={LayoutTypes.cardContent}
            py={0}
            container={false}
          >
            <UniversalField
              field="rules.plugins"
              value={(algorithm?.rules || []).flatMap((rule) => rule.plugins)}
            />
          </CanBeEmptyRow>
        </Col>
      </Col>

      <ButtonContainer mt="20px">
        <Col>
          <Row item $type={LayoutTypes.cardContent}>
            <Stack direction="row" gap="10px">
              <Button onClick={onCloseClick}>Close</Button>
              <Button variant={ButtonVariants.outlined} onClick={onEditClick}>
                Edit
              </Button>
            </Stack>
          </Row>
        </Col>
      </ButtonContainer>
    </Scrolled>
  );
});

DrawerBody.displayName = 'DrawerBody';

DrawerBody.propTypes = {
  id: PropTypes.string.isRequired,
  onClose: PropTypes.func,
};

DrawerBody.defaultProps = {
  onClose: undefined,
};
