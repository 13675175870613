import PropTypes from '+prop-types';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import LeadPencilIcon from 'mdi-react/LeadPencilIcon';

import RoutePaths from '@/models/RoutePaths';
import { UIProperties } from '@/models/UIProperties';

import { selectors as rulesSelectors } from '@/redux/api/rules';

import { usePageTabs } from '+components/PageTabs';
import { HeaderSubheader } from '+components/Table/Cells/HeaderSubheaderCell';
import useEvent from '+hooks/useEvent';
import useUIProperty from '+hooks/useUIProperty';

import CellWrapper from './CellWrapper';

const CellAlgorithmName = (props) => {
  const { name, description, original, disabled, isNew } = props;
  const [, , pageTabMethods] = usePageTabs();

  const algorithm = useSelector(rulesSelectors.getAlgorithmByName(name));
  const [, setDMDrawerId] = useUIProperty(UIProperties.dmDrawerId);

  const item = useMemo(() => {
    if (!algorithm) {
      return { name };
    }

    return {
      id: algorithm.id,
      name: algorithm.name,
      description: algorithm.description,
      type: 'algorithm',
      model:
        algorithm.algo_type === 'CDM'
          ? RoutePaths.modelsContext.pageName
          : RoutePaths.modelsDetection.pageName,
    };
  }, [name, algorithm]);

  const additionalMenuOptions = useMemo(
    () =>
      item.id && item.type === 'algorithm'
        ? [
            {
              icon: <LeadPencilIcon />,
              text: 'Edit',
              onClick: () => {
                pageTabMethods.add(
                  `${RoutePaths.models}/${item.model}/${item.id}`,
                );
              },
            },
          ]
        : [],
    [item.id, item.type, item.model, pageTabMethods],
  );

  const onMoreInfoClick = useEvent(() => {
    if (item.id) {
      setDMDrawerId(item.id);
    }
  });

  return (
    <HeaderSubheader
      header={
        <CellWrapper
          customer={original?.customer}
          field="algorithm"
          value={name}
          additionalMenuOptions={additionalMenuOptions}
          disabled={disabled}
          onMoreInfoClick={item.id ? onMoreInfoClick : null}
        >
          <span>{item.name}</span>
        </CellWrapper>
      }
      subHeader={description}
      isNew={isNew}
    />
  );
};

CellAlgorithmName.propTypes = {
  name: PropTypes.string.isRequired,
  description: PropTypes.string,
  original: PropTypes.shape(),
  disabled: PropTypes.bool,
  isNew: PropTypes.bool,
};

CellAlgorithmName.defaultProps = {
  description: null,
  original: null,
  disabled: false,
  isNew: false,
};

export default CellAlgorithmName;
