import PropTypes from '+prop-types';
import {
  forwardRef,
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import uniqBy from 'lodash.uniqby';

import { Divider } from '@mui/material';
import { createFilterOptions } from '@mui/material/Autocomplete';

import ChevronDownIcon from 'mdi-react/ChevronDownIcon';

import InputText, { ClearIcon } from '+components/form/InputText';
import { combineRefs, makeId } from '+utils';

import Autocomplete from './components/Autocomplete';
import Option from './components/Option';
import Paper from './components/Paper';
import Popper from './components/Popper';
import Tag from './components/Tag';
import VirtualizedListbox from './components/VirtualizedListbox';

const convertToArray = (value) => {
  if (value == null || value === '') {
    return [];
  }
  return Array.isArray(value) ? value : [value];
};
const convertToSelectObject = (value) =>
  typeof value === 'object' ? value : { value, label: value };
const hash = (arr) => {
  const _hash = {};
  arr.forEach((item, i) => {
    _hash[item.value] = { ...item, order: i };
  });
  return _hash;
};

const renderOption = (renderProps, option) => {
  const key = renderProps.id || renderProps.key;
  return option.separator ? (
    <Divider key={key} />
  ) : (
    <Option {...renderProps} key={key} option={option} />
  );
};

const renderOptionVirtual = (renderProps, option, state) => {
  renderProps.key = renderProps.id || renderProps.key;

  if (option.separator) {
    renderProps.separator = true;
    return renderProps;
  }

  renderProps.option = option;
  renderProps.selected = state.selected;
  return renderProps;
};
const renderGroupVirtual = (params) => params;

/**
 * Select is a type of input that is used in forms, where a user is submitting data and chooses one option from a list.
 */
const Select = forwardRef((props, ref) => {
  const {
    id,
    name,
    placeholder,
    options,
    value,
    invalid,
    multiple,
    readOnly,
    limit,
    allowCreate,
    freeSolo,
    popperProps,
    popperStyle,
    onOpen,
    onClose,
    onChange,
    optionsLimit,
    virtualized,
    showClearButton,
    ...tail
  } = props;

  const componentRef = useRef(null);
  const combinedRef = useMemo(() => combineRefs(componentRef, ref), [ref]);

  const [listId] = useState(makeId());
  const [open, setOpen] = useState(props.open ?? false);

  const filter = useMemo(
    () => createFilterOptions({ limit: optionsLimit }),
    [optionsLimit],
  );

  const normalizedValueArr = useMemo(
    () => convertToArray(value).map(convertToSelectObject),
    [value],
  );

  const normalizedValue = useMemo(
    () => (multiple ? normalizedValueArr : normalizedValueArr[0] || null),
    [multiple, normalizedValueArr],
  );

  const normalizedOptionsHash = useMemo(() => {
    const _normalizedOptionsHash = hash(
      convertToArray(options).map(convertToSelectObject),
    );
    normalizedValueArr.forEach((el) => {
      const isExistsInOptions = _normalizedOptionsHash[el.value];
      if (!isExistsInOptions) {
        _normalizedOptionsHash[el.value] = el;
      }
    });
    return _normalizedOptionsHash;
  }, [options, normalizedValueArr]);

  const normalizedOptions = useMemo(
    () =>
      Object.values(normalizedOptionsHash)
        .sort((a, b) => a.order - b.order)
        .map(({ order, ...el }) => el),
    [normalizedOptionsHash],
  );

  const isValueLimitReached = useMemo(
    () => multiple && limit && normalizedValueArr.length >= limit,
    [multiple, limit, normalizedValueArr.length],
  );

  const getOptionLabel = useCallback(
    (option) => {
      const _option = normalizedOptionsHash[option.value] || option;
      // Label can be empty string that's why we can't use ?? with it
      return `${
        (_option.inputValue ?? _option.label) || (_option.value ?? _option)
      }`;
    },
    [normalizedOptionsHash],
  );

  const getOptionDisabled = useCallback(
    (option) => {
      if (isValueLimitReached) {
        // If value limit reached then disable all options that are not selected
        return !normalizedValueArr.map((el) => el.value).includes(option.value);
      }
      return option.disabled;
    },
    [isValueLimitReached, normalizedValueArr],
  );

  const isOptionEqualToValue = useCallback(
    (option, val) => option.value === val.value,
    [],
  );

  // Suggest the creation of a new value
  const filterOptions = useCallback(
    (inputOptions, params) => {
      const filtered = filter(inputOptions, params);

      if (params.inputValue !== '' && allowCreate) {
        // we need to show `create` option if "no items found at all OR not found exact item"
        // @see: https://netography.atlassian.net/browse/PORTAL-1714
        const showCreateOption =
          !filtered.length ||
          !filtered.filter((item) => item.value === params.inputValue).length;
        if (showCreateOption) {
          filtered.push({
            inputValue: params.inputValue,
            value: params.inputValue,
            label: `Create "${params.inputValue}"`,
          });
        }
      }
      return filtered;
    },
    [allowCreate],
  );

  const onTagMove = useCallback(
    ({ oldIndex, newIndex }) => {
      if (!onChange) {
        return;
      }
      const copy = [...normalizedValue];
      const [removed] = copy.splice(oldIndex, 1);
      copy.splice(newIndex, 0, removed);
      onChange(copy);
    },
    [normalizedValue, onChange],
  );

  const doChange = useCallback(
    (_, val) => {
      onChange?.(val);
    },
    [onChange],
  );

  const wasChanged = useRef(false);

  const renderInput = useCallback(
    ({ inputProps, ...tailRenderProps }) => {
      // this is workaround to prevent blinking on first load (mostly inside table's select filter)
      let inputValue = inputProps.value;

      const needToUseValue =
        !wasChanged.current && !inputValue && normalizedValue && !multiple;

      if (needToUseValue) {
        inputValue = getOptionLabel(normalizedValue);
      }

      // eslint-disable-next-line no-nested-ternary
      const localPlaceholder = tailRenderProps.disabled
        ? !normalizedValue || !normalizedValue?.length
          ? '—'
          : ''
        : placeholder;

      return (
        <InputText
          {...tailRenderProps}
          id={id}
          name={name}
          placeholder={localPlaceholder}
          variant="outlined"
          inputProps={{
            ...inputProps,
            value: inputValue,
            readOnly,
            onChange: isValueLimitReached ? () => {} : inputProps.onChange,
            onBlur: (event) => {
              if (freeSolo && !multiple && !isValueLimitReached) {
                doChange(null, { value: event.target.value });
              }
              inputProps.onBlur?.(event);
            },
          }}
          showClearButton={showClearButton}
          $invalid={inputProps.error || invalid}
        />
      );
    },
    [
      invalid,
      name,
      id,
      placeholder,
      isValueLimitReached,
      readOnly,
      freeSolo,
      doChange,
      normalizedValue,
      getOptionLabel,
      multiple,
      showClearButton,
    ],
  );

  const renderTags = useCallback(
    (items, getTagProps, ownerState) => (
      <Fragment>
        {items.map((item, index) => (
          <Tag
            {...getTagProps({ index })}
            id={item?.value ?? item}
            index={index}
            label={ownerState.getOptionLabel(item)}
            draggable={items.length > 1}
            onTagMove={onTagMove}
          />
        ))}
      </Fragment>
    ),
    [onTagMove],
  );

  const PopperComponent = useCallback(
    (componentProps) => (
      <Popper {...componentProps} {...popperProps} popperStyle={popperStyle} />
    ),
    [popperProps, popperStyle],
  );

  const doOpen = useCallback(
    (event) => {
      setOpen(props.open ?? true);
      if (onOpen) {
        onOpen(event);
      }
    },
    [props.open, onOpen],
  );

  const doClose = useCallback(
    (event) => {
      setOpen(props.open ?? false);
      if (onClose) {
        onClose(event);
      }
    },
    [props.open, onClose],
  );

  const onKeyDown = useCallback(() => {
    wasChanged.current = true;
  }, []);

  const onKeyPress = useCallback(
    (event) => {
      // Workaround: Hitting enter while creating list items causes the form to submit
      // @see: https://netography.atlassian.net/browse/PORTAL-1632
      const needToStopEvent = event.key === 'Enter'; // && multiple;
      if (needToStopEvent) {
        event.preventDefault();
        const needToDoChange =
          allowCreate && !isValueLimitReached && event.target.value;
        if (needToDoChange) {
          const newValue = uniqBy(
            [...(normalizedValue || []), { value: event.target.value }],
            'value',
          );
          doChange(null, newValue);
        }
      }
    },
    [multiple, allowCreate, isValueLimitReached, normalizedValue, doChange],
  );

  useEffect(() => {
    if (!listId) {
      return undefined;
    }
    const resize = (event) => doClose(event);
    const scroll = (event) => {
      const targetIsInsideAutocomplete =
        componentRef.current?.contains(event.target) ||
        event.target.id === listId;
      if (!targetIsInsideAutocomplete) {
        doClose(event);
      }
    };
    window.addEventListener('resize', resize, true);
    document.addEventListener('scroll', scroll, true);
    return () => {
      window.removeEventListener('resize', resize, true);
      document.removeEventListener('scroll', scroll, true);
    };
  }, [listId, doClose]);

  const isVirtualized = normalizedOptions?.length > 50 || virtualized;

  return (
    <Autocomplete
      {...tail}
      ref={combinedRef}
      options={normalizedOptions}
      value={normalizedValue}
      getOptionLabel={getOptionLabel}
      getOptionDisabled={getOptionDisabled}
      filterOptions={filterOptions}
      isOptionEqualToValue={isOptionEqualToValue}
      renderInput={renderInput}
      popupIcon={<ChevronDownIcon size={16} />}
      clearIcon={<ClearIcon size={14} />}
      renderOption={isVirtualized ? renderOptionVirtual : renderOption}
      renderGroup={isVirtualized ? renderGroupVirtual : undefined}
      ListboxProps={{ id: listId, 'data-testid': props.name }}
      ListboxComponent={isVirtualized ? VirtualizedListbox : undefined}
      PopperComponent={PopperComponent}
      PaperComponent={Paper}
      onOpen={doOpen}
      onClose={doClose}
      onChange={doChange}
      onKeyDown={onKeyDown}
      onKeyPress={onKeyPress}
      open={open}
      multiple={multiple}
      freeSolo={freeSolo}
      $readOnly={readOnly}
      $limitReached={isValueLimitReached}
      $showClear={showClearButton}
      disableClearable={!showClearButton}
      renderTags={renderTags}
    />
  );
});

const propTypes = {
  /**
   * Override component ID.
   */
  id: PropTypes.string,
  /**
   * Component name for form.
   */
  name: PropTypes.string,
  /**
   * Short hint that describes the search.
   */
  placeholder: PropTypes.string,
  /**
   * Options (suggestions) is an array of objects or strings.
   * If array of objects, each object must have property __value__,
   * also can be set __label__, __description__ and __icon__
   *
   * @example
   * [{ value: 1 }, { value: 2, label: 'Name' }]
   *
   * // or
   *
   * ['value 1', 'value 2']
   */
  options: PropTypes.arrayOf(PropTypes.any), // eslint-disable-line react/forbid-prop-types
  /**
   * Sets the value of the selected option.
   */
  value: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.any), // eslint-disable-line react/forbid-prop-types
    PropTypes.any, // eslint-disable-line react/forbid-prop-types
  ]),
  /**
   * If true, the component will be opened.
   */
  open: PropTypes.bool,
  /**
   * If true, the component will be invalid.
   */
  invalid: PropTypes.bool,
  /**
   * If true, the component will be in multi select mode.
   */
  multiple: PropTypes.bool,
  /**
   * If true, the component will be in readOnly mode.
   */
  readOnly: PropTypes.bool,
  /**
   * For multi select mode: Max number of selected items.
   */
  limit: PropTypes.number,
  /**
   * If true, user can create new items.
   */
  allowCreate: PropTypes.bool,
  /**
   * If true, the Select is free solo, meaning that the user input is not bound to provided options.
   */
  freeSolo: PropTypes.bool,
  /**
   * Additional props for Popper instance.
   */
  popperProps: PropTypes.shape({}),
  /**
   * Additional style for Popper instance.
   */
  popperStyle: PropTypes.shape({}),
  /**
   * Callback fired when component is open.
   */
  onOpen: PropTypes.func,
  /**
   * Callback fired when component is close.
   */
  onClose: PropTypes.func,
  /**
   * Callback fired when component is change.
   */
  onChange: PropTypes.func,
  /**
   * limits the number of options shown in the selection dropdown
   */
  optionsLimit: PropTypes.number,
  /**
   * If true, the component will be virtualized.
   */
  virtualized: PropTypes.bool,
  /**
   * If true, the component will be show clear button.
   */
  showClearButton: PropTypes.bool,
};

const defaultProps = {
  id: undefined,
  name: undefined,
  placeholder: '',
  options: [],
  value: null,
  open: undefined,
  invalid: undefined,
  multiple: false,
  readOnly: false,
  limit: undefined,
  allowCreate: false,
  freeSolo: false,
  popperProps: undefined,
  popperStyle: undefined,
  onOpen: undefined,
  onClose: undefined,
  onChange: undefined,
  optionsLimit: undefined,
  virtualized: true,
  showClearButton: false,
};

Select.displayName = 'Select';
Select.propTypes = propTypes;
Select.defaultProps = defaultProps;

export { propTypes, defaultProps };

export default Select;
