import PropTypes from '+prop-types';

import IpLabel from '+components/Labels/IpLabel';
import MultipleItemsWrapper from '+components/Table/Cells/UniversalCell/components/MultipleItemsWrapper';

const CellIpLabels = (props) => {
  const { ips, labels, context } = props;

  return !ips?.length ? null : (
    <MultipleItemsWrapper>
      {ips.map((ip, i) => {
        return (
          <IpLabel key={ip} ip={ip} labels={labels?.[i]} context={context} />
        );
      })}
    </MultipleItemsWrapper>
  );
};

CellIpLabels.propTypes = {
  ips: PropTypes.arrayOf(PropTypes.string),
  labels: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
  context: PropTypes.string,
};

CellIpLabels.defaultProps = {
  ips: null,
  labels: null,
  context: 'name',
};

export default CellIpLabels;
