import PropTypes from '+prop-types';
import { memo } from 'react';

import ArrowDownBoldIcon from 'mdi-react/ArrowDownBoldIcon';
import ArrowUpBoldIcon from 'mdi-react/ArrowUpBoldIcon';
import OpenInNewIcon from 'mdi-react/OpenInNewIcon';
import PinOffIcon from 'mdi-react/PinOffIcon';

import { Menu, Item as MenuItem } from '+components/Menu';
import useEvent from '+hooks/useEvent';

export const ItemMenu = memo((props) => {
  const { route, onOpenInNewTab, id, onMoveUp, onMoveDown, onUnpin } = props;

  const doOpenInNewTab = useEvent(() => {
    onOpenInNewTab?.(route);
  });

  const doMoveUp = useEvent(() => {
    onMoveUp?.(id);
  });

  const doMoveDown = useEvent(() => {
    onMoveDown?.(id);
  });

  const doUnpin = useEvent(
    (e) => {
      // prevent navigation to the route
      e.preventDefault();
      onUnpin?.(id);
    },
    [onUnpin, id],
  );

  return (
    <Menu>
      {onOpenInNewTab && (
        <MenuItem onClick={doOpenInNewTab}>
          <OpenInNewIcon size={16} />
          <span>Open in new tab</span>
        </MenuItem>
      )}
      {onMoveUp && (
        <MenuItem onClick={doMoveUp}>
          <ArrowUpBoldIcon size={16} />
          <span>Move up</span>
        </MenuItem>
      )}
      {onMoveDown && (
        <MenuItem onClick={doMoveDown}>
          <ArrowDownBoldIcon size={16} />
          <span>Move down</span>
        </MenuItem>
      )}
      {onUnpin && (
        <MenuItem onClick={doUnpin}>
          <PinOffIcon size={16} />
          <span>Unpin</span>
        </MenuItem>
      )}
    </Menu>
  );
});

ItemMenu.displayName = 'ItemMenu';

ItemMenu.propTypes = {
  route: PropTypes.string,
  onOpenInNewTab: PropTypes.func,
  id: PropTypes.string,
  onMoveUp: PropTypes.func,
  onMoveDown: PropTypes.func,
  onUnpin: PropTypes.func,
};

ItemMenu.defaultProps = {
  route: null,
  onOpenInNewTab: null,
  id: null,
  onMoveUp: null,
  onMoveDown: null,
  onUnpin: null,
};
