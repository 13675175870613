import { isIpField } from '@/models/FieldTypes';

import { UniversalCell } from '+components/Table/Cells';
import { NumberColumnFactory } from '+components/Table/Columns';
import { getRowOriginal } from '+components/Table/Columns/utils';

import { commonColumns } from './commonColumns';
import { PivotClickable } from './PivotClickable';

const Columns = {
  agg: 'agg',
  agg_count: 'agg_count',
  srcip: 'srcip',
  srcport: 'srcport',
  protocol: 'protocol',
  type: 'type',
  domain: 'domain',
  tld: 'tld',
  publicsuffix: 'publicsuffix',
  rcode: 'rcode',
};

const Fields = {
  ...Columns,
  [Columns.type]: 'query.type',
  [Columns.domain]: 'query.domain',
  [Columns.tld]: 'query.tld',
  [Columns.publicsuffix]: 'query.publicsuffix',
};

const cells = {
  [Columns.protocol]: UniversalCell('protocol'),
  [Columns.type]: UniversalCell('type'),
  [Columns.domain]: UniversalCell('domain'),
  [Columns.tld]: UniversalCell('tld'),
  [Columns.publicsuffix]: UniversalCell('publicsuffix'),
  [Columns.rcode]: UniversalCell('rcode'),
};

/**
 * Creates a wrapped pivot column configuration object.
 *
 * @param {Object} params - Parameters for configuring the wrapped pivot column.
 * @param {string} params.column - The name or identifier of the column to be wrapped.
 * @param {Function} params.onPivotClick - Callback function invoked when a pivotable column is clicked.
 * @param {...Object} [params.rest] - Additional properties to be included in the configuration object.
 * @returns {Object} A configuration object for the wrapped pivot column, including custom accessor and cell render logic.
 */
const getWrappedPivotColumn = ({ column, onPivotClick, ...rest }) => ({
  ...rest,
  accessor: column,
  Cell: (options) => {
    const original = getRowOriginal(options.row);
    const Component = cells[column];

    return (
      <PivotClickable
        onPivotClick={onPivotClick}
        row={original}
        field={Fields[column]}
      >
        <Component {...options} />
      </PivotClickable>
    );
  },
});

export const getColumns = ({ field, labelContext, onPivotClick }) => [
  {
    accessor: Columns.agg,
    Header: 'Aggregation',
    Cell: UniversalCell(field),
    sortType: isIpField(field) ? 'ip' : 'alphanumeric',
  },
  NumberColumnFactory({
    accessor: Columns.agg_count,
    Header: 'Count (of 1000)',
    textAlign: 'center',
  }),
  ...commonColumns({ labelContext, onPivotClick }),
  getWrappedPivotColumn({
    column: Columns.protocol,
    Header: 'Protocol',
    onPivotClick,
  }),
  getWrappedPivotColumn({
    column: Columns.type,
    Header: 'Query Type',
    onPivotClick,
  }),
  getWrappedPivotColumn({
    column: Columns.domain,
    Header: 'Query Domain',
    onPivotClick,
  }),
  getWrappedPivotColumn({
    column: Columns.tld,
    Header: 'Query TLD',
    onPivotClick,
  }),
  getWrappedPivotColumn({
    column: Columns.publicsuffix,
    Header: 'Query Public Suffix',
    onPivotClick,
  }),
  getWrappedPivotColumn({
    column: Columns.rcode,
    Header: 'Query RCode',
    onPivotClick,
  }),
];
