export const UIProperties = {
  masqueradeUrl: 'masqueradeUrl',
  propertiesTray: 'propertiesTray',
  underCover: 'underCover',
  impersonating: 'impersonating',
  theme: 'theme',
  guest: 'guest',
  hideNav: 'hideNav',
  isLayoutExportMode: 'isLayoutExportMode',
  dmDrawerId: 'dmDrawerId',
  globalContextMenu: 'globalContextMenu',
  globalNqlPresetChange: 'globalNqlPresetChange',
  globalNqlPresetRemove: 'globalNqlPresetRemove',
  profileMenuOpen: 'profileMenuOpen',
  demo: 'demo',
  isSideBarRightResizing: 'isSideBarRightResizing',
  version: 'version',
  expectedVersion: 'expectedVersion',
  showNewReleaseBanner: 'showNewReleaseBanner',
  showNoDNSSourcesAlert: 'showNoDNSSourcesAlert',
  sideBarLeftCollapse: 'sideBarLeftCollapse',
  sideBarRightWidth: 'sideBarRightWidth',
  windowFocused: 'windowFocused',
};
