import { Navigate, Route, Routes, useLocation } from 'react-router-dom';

import RoutePaths from '@/models/RoutePaths';

import Events from '@/pages/Events';
import DetectionModels from '@/pages/Models';
import ContextDetectionModelsAdd from '@/pages/Models/Context/Add';
import ContextDetectionModelsEdit from '@/pages/Models/Context/Edit';
import DetectionDetectionModelsAdd from '@/pages/Models/Detection/Add';
import DetectionDetectionModelsEdit from '@/pages/Models/Detection/Edit';

import { CrumbRoute } from '+components/Breadcrumb';

const ReplaceNetwork = () => {
  const { pathname, search } = useLocation();

  const newPath = pathname.replace(
    RoutePaths.modelsNetwork,
    RoutePaths.modelsDetection,
  );

  return <Navigate to={`${newPath}${search}`} replace />;
};

const DetectionModelsRoutes = () => {
  return (
    <Routes>
      <Route index element={<DetectionModels />} />

      <Route
        path={`${RoutePaths.modelsDetection.pageName}/add`}
        element={<DetectionDetectionModelsAdd />}
      />
      <Route
        path={`${RoutePaths.modelsDetection.pageName}/:algorithmId/*`}
        element={<DetectionDetectionModelsEdit />}
      />

      <Route
        path={`${RoutePaths.modelsNetwork.pageName}/*`}
        element={<ReplaceNetwork />}
      />

      <Route
        path={`${RoutePaths.modelsContext.pageName}/add`}
        element={<ContextDetectionModelsAdd />}
      />
      <Route
        path={`${RoutePaths.modelsContext.pageName}/:algorithmId/*`}
        element={<ContextDetectionModelsEdit />}
      />

      <Route
        path="*"
        element={<Navigate to={`${RoutePaths.models}`} replace />}
      />
    </Routes>
  );
};

const NdrRoutes = () => (
  <Routes>
    <Route path="events/*" element={<Events />} />

    <Route
      path="models/*"
      element={
        <CrumbRoute
          title="Detection Models"
          component={DetectionModelsRoutes}
        />
      }
    />
  </Routes>
);

export default NdrRoutes;
