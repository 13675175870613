import PropTypes from '+prop-types';
import { useMemo } from 'react';

import RealtimeManager from '+components/RealtimeManager';
import Table from '+components/Table';
import useGlobalFilters from '+hooks/useGlobalFilters';

import { getColumns } from './dnsTableColumns';

const TrafficTopDNSTable = (props) => {
  const { field, data, noDataText, sortBy, onPivotClick, ...rest } = props;
  const [filters] = useGlobalFilters();

  const tableColumns = useMemo(
    () =>
      !field
        ? []
        : getColumns({
            field,
            labelContext: filters.labelContext,
            onPivotClick,
          }),
    [field, filters.labelContext, onPivotClick],
  );

  return (
    <Table
      id="TrafficTopDns_Table"
      minRows={20}
      data={data || []}
      noDataText={noDataText}
      sortBy={sortBy}
      columns={tableColumns}
      fillWithEmptyRows
      {...rest}
    />
  );
};

TrafficTopDNSTable.propTypes = {
  field: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.shape({})),
  noDataText: PropTypes.string,
  sortBy: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      desc: PropTypes.bool,
    }),
  ),
  onPivotClick: PropTypes.func,
};

TrafficTopDNSTable.defaultProps = {
  field: null,
  data: [],
  noDataText: 'No DNS received',
  sortBy: [{ id: 'agg_count', desc: true }],
  onPivotClick: null,
};

export default RealtimeManager(TrafficTopDNSTable, {
  throttle: {
    wait: 300,
    props: [
      'field',
      'data',
      'noDataText',
      'sortBy',
      'externalFilters',
      'onClearExternalFilters',
      'onPivotClick',
    ],
  },
});
