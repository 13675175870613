import PropTypes from '+prop-types';
import { Fragment } from 'react';

import FieldsSection from '+components/form/FieldsSection';
import Field from '+components/form/FinalForm/Field';
import TextField from '+components/form/TextField';
import { validateRequired, validateUrl } from '+components/form/Validators';

const Sentinel = ({ disabled }) => (
  <Fragment>
    <Field
      name="name"
      label="Name"
      component={TextField}
      type="text"
      maxLength={255}
      autoComplete="new-password"
      validate={validateRequired}
      style={{ width: '50%' }}
      disabled={disabled}
      required
    />

    <Field
      name="description"
      label="Description"
      component={TextField}
      type="text"
      maxLength={255}
      autoComplete="new-password"
      disabled={disabled}
    />

    <Field
      name="config.endpoint"
      label="Endpoint"
      component={TextField}
      type="text"
      autoComplete="new-password"
      validate={[validateRequired, validateUrl]}
      disabled={disabled}
      required
    />

    <Field
      name="config.rule_id"
      label="Rule ID"
      component={TextField}
      type="text"
      validate={validateRequired}
      disabled={disabled}
      required
    />

    <Field
      name="config.stream_name"
      label="Stream Name"
      component={TextField}
      type="text"
      validate={validateRequired}
      disabled={disabled}
      required
    />

    <FieldsSection label="Authentication">
      <Field
        name="config.azure_tenant_id"
        label="Tenant ID"
        component={TextField}
        type="text"
        autoComplete="new-password"
        disabled={disabled}
        validate={validateRequired}
        required
      />
      <Field
        name="config.azure_client_id"
        label="Client ID"
        component={TextField}
        type="text"
        autoComplete="new-password"
        disabled={disabled}
        validate={validateRequired}
        required
      />

      <Field
        name="config.azure_client_secret"
        label="Client Secret"
        component={TextField}
        type="password"
        autoComplete="new-password"
        disabled={disabled}
        validate={validateRequired}
        required
      />
    </FieldsSection>
  </Fragment>
);

Sentinel.propTypes = {
  disabled: PropTypes.bool,
};

Sentinel.defaultProps = {
  disabled: false,
};

export default Sentinel;
