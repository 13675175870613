import PropTypes from '+prop-types';
import { forwardRef, Fragment, useCallback, useContext } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Link } from 'react-router-dom';

import ArrowLeftIcon from 'mdi-react/ArrowLeftIcon';

import SeverityLabel from '+components/Labels/SeverityLabel';
import { Col, Row } from '+components/Layout';
import * as toast from '+components/toast';

import { Context } from '../../../MenuWrapper';
import Separator from '../Separator';
import Title from '../Title';
import { getMainTitle, padding } from '../utils';
import BackIconButton from './components/BackIconButton';
import MenuContainer from './components/MenuContainer';
import MenuItem from './components/MenuItem';
import NoCveData from './components/NoCveData';

const MenuRenderer = forwardRef((props, ref) => {
  const { $separatorWidth } = props;

  const {
    isActionsMenuHidden,
    menuData,
    menuOptions,
    cve,
    isCveMenuMode,
    isCveFetching,
    isLabelMenuMode,
    setActiveLabelData,
  } = useContext(Context);

  const onCopy = useCallback(() => {
    toast.success('Copied!');
  }, []);

  return (
    <MenuContainer ref={ref} $isOpen={!isActionsMenuHidden}>
      {!isActionsMenuHidden && (
        <Fragment>
          <Separator $width={$separatorWidth} />

          <Col gap={padding} padding={padding}>
            <Row>
              <Title title={getMainTitle(menuData)}>
                {isLabelMenuMode && (
                  <BackIconButton
                    title="Back to field menu"
                    onClick={() => setActiveLabelData(null)}
                  >
                    <ArrowLeftIcon size={16} />
                  </BackIconButton>
                )}
                {getMainTitle(menuData)}
              </Title>
            </Row>

            {isCveMenuMode && !cve?.id && (
              <Row gap="6px">
                <NoCveData>
                  {isCveFetching
                    ? 'Loading CVE data...'
                    : 'No CVE data to display'}
                </NoCveData>
              </Row>
            )}

            {isCveMenuMode && !!cve?.id && (
              <Row gap="6px">
                <Col>
                  {cve.descriptions.find((item) => item.lang === 'en')?.value ||
                    ''}
                </Col>
                {cve?.metrics?.cvssMetricV31?.[0] && (
                  <Fragment>
                    <Col fontWeight="bold">CVSS 3.1 Severity Base Score:</Col>
                    <Col>
                      <SeverityLabel
                        severity={cve.metrics.cvssMetricV31[0].cvssData?.baseSeverity?.toLowerCase()}
                        label={`${cve.metrics.cvssMetricV31[0].cvssData?.baseScore} ${cve.metrics.cvssMetricV31[0].cvssData?.baseSeverity}`}
                      />
                    </Col>
                  </Fragment>
                )}
                {cve?.metrics?.cvssMetricV2?.[0] && (
                  <Fragment>
                    <Col fontWeight="bold">CVSS 2.0 Severity Base Score:</Col>
                    <Col>
                      <SeverityLabel
                        severity={cve.metrics.cvssMetricV2[0].baseSeverity?.toLowerCase()}
                        label={`${cve.metrics.cvssMetricV2[0].cvssData?.baseScore} ${cve.metrics.cvssMetricV2[0].baseSeverity}`}
                      />
                    </Col>
                  </Fragment>
                )}
              </Row>
            )}

            <Row gap="6px">
              {menuOptions.map((item, index) => {
                const key = `action-menu-${index}`;

                if (item.disabled) {
                  return (
                    <MenuItem
                      key={key}
                      disabled
                      data-tracking={`props-tray-${item.tracking}`}
                    >
                      {item.icon}
                      {item.text}
                    </MenuItem>
                  );
                }

                let itemContent = (
                  <MenuItem
                    onClick={item.url ? null : item.onClick}
                    data-tracking={`props-tray-${item.tracking}`}
                  >
                    {item.icon}
                    {item.text}
                  </MenuItem>
                );

                if (item.url) {
                  itemContent = (
                    <Link to={item.url} onClick={item.onClick}>
                      {itemContent}
                    </Link>
                  );
                }

                if (item.copyToClipboard) {
                  itemContent = (
                    <CopyToClipboard
                      text={item.copyToClipboard}
                      onCopy={onCopy}
                    >
                      {itemContent}
                    </CopyToClipboard>
                  );
                }

                return <Fragment key={key}>{itemContent}</Fragment>;
              })}
            </Row>
          </Col>
        </Fragment>
      )}
    </MenuContainer>
  );
});

MenuRenderer.propTypes = {
  $separatorWidth: PropTypes.number,
};

MenuRenderer.defaultProps = {
  $separatorWidth: null,
};

export default MenuRenderer;
