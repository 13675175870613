import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';

import Stack from '@mui/material/Stack';

import RadarIcon from 'mdi-react/RadarIcon';

import { ContextTypes } from '@/models/ContextTypes';
import { CustomType } from '@/models/CustomType';
import { UIProperties } from '@/models/UIProperties';

import { selectors as customerSelectors } from '@/redux/api/customer';
import {
  actions as deviceActions,
  selectors as deviceSelectors,
} from '@/redux/api/device';
import { selectors as labelsSelectors } from '@/redux/api/labels/ips';
import { actions as actionsFilters } from '@/redux/globalFilters';
import { actions as toastActions } from '@/redux/toast';

import { Breadcrumb } from '+components/Breadcrumb';
import Button from '+components/Button';
import GlobalFiltersSetting from '+components/GlobalFilters/Setting';
import { IpSummary } from '+components/IpSummary';
import {
  ActionsContainer,
  Col,
  LayoutSizes,
  LayoutTypes,
  Row,
} from '+components/Layout';
import * as Menu from '+components/Menu';
import { withMenu } from '+components/Menu';
import { BackToButton } from '+components/PageTabs';
import RecordModal from '+components/RecordModal';
import {
  useFetchContextRecord,
  useGetContextRecord,
  useIsFetchingContextRecord,
} from '+hooks';
import useGlobalFilters from '+hooks/useGlobalFilters';
import useLastAllowedContext from '+hooks/useLastAllowedContext';
import useLoadingIndicator from '+hooks/useLoadingIndicator';
import useUIProperty from '+hooks/useUIProperty';
import { getFlowDataFromRecord } from '+utils/getFlowDataFromRecord';
import getNqlFieldName from '+utils/getNqlFieldName';
import makeArr from '+utils/makeArr';

import { IpExplorer } from './components/IpExplorer';
import { OverridesButton } from './components/OverridesButton';
import { Overview } from './components/Overview';
import { RelatedEvents } from './components/RelatedEvents';
import { Thresholds } from './components/Thresholds';
import { Title } from './components/Title';
import { TrafficTable } from './components/TrafficTable';

const excludeContexts = new Set([
  ContextTypes.blocks,
  ContextTypes.traffic,
  ContextTypes.dns,
  ContextTypes.flow,
]);

const empty = {};

const titleIcon = <RadarIcon size={24} />;

const EventDetails = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const { id } = useParams();

  const [thresholdsCollapsed, setThresholdsCollapsed] = useState(true);
  const onShowAllClick = useCallback(
    () => setThresholdsCollapsed((prev) => !prev),
    [],
  );

  const context = useLastAllowedContext({
    excludeContexts,
    defaultContext: ContextTypes.alerts,
  });

  const [filters] = useGlobalFilters(ContextTypes.alerts);
  const customer = useSelector(customerSelectors.getCurrentCustomer);
  const devices = useSelector(deviceSelectors.getDevices);
  const isDevicesFetching = useSelector(deviceSelectors.isFetching);

  const [searchParams] = useSearchParams();
  const customerParam = searchParams.get('customer');
  const timestampParam = searchParams.get('timestamp');

  const event = useGetContextRecord(ContextTypes.alerts, id) || empty;
  const isEventsFetching = useIsFetchingContextRecord(ContextTypes.alerts, id);

  useFetchContextRecord(
    ContextTypes.alerts,
    {
      id,
      customer: customerParam,
      timestamp: timestampParam,
    },
    'pageDetails',
    [id, customerParam, timestampParam],
  );

  const [recordModalOpened, setRecordModalOpened] = useState(false);

  useLoadingIndicator(isEventsFetching || isDevicesFetching);

  const isSubAccountRecord =
    event?.customer && event?.customer !== customer?.shortname;

  const eventUrl = useMemo(() => {
    const url = document.location.origin;
    return `${url}${location.pathname}?timestamp=${event.timestamp}&customer=${event.customer}`;
  }, [event, location.pathname]);
  const onCopy = useCallback(() => {
    dispatch(toastActions.info('Copied'));
  }, []);

  useEffect(() => {
    if (isSubAccountRecord || Object.keys(devices || {}).length) {
      return;
    }
    dispatch(deviceActions.devicesFetch());
  }, [isSubAccountRecord, devices]);

  const [, setMasqueradeUrl] = useUIProperty(UIProperties.masqueradeUrl);
  useEffect(() => {
    const rootPath = location.pathname.slice(
      0,
      location.pathname.lastIndexOf('/'),
    );
    setMasqueradeUrl(rootPath);
    return () => {
      setMasqueradeUrl(null);
    };
  }, [location.pathname]);

  const excludeContextsArr = useMemo(
    () => Array.from(excludeContexts),
    [excludeContexts],
  );

  // We set global filters time period based on event,
  // for Flow context nql to use it in TrafficTable and IPExplorer
  useEffect(() => {
    if (!event?.start) {
      return;
    }

    const { nql, from, to } = getFlowDataFromRecord({
      record: event,
      type: ContextTypes.alerts,
    });

    const eventContext = event?.traffictype || ContextTypes.flow;

    const nqlAsArr = makeArr(nql);

    const state = {
      period: {
        type: CustomType,
      },
      from,
      to,
      autoRefresh: false,
      [getNqlFieldName(eventContext)]:
        eventContext === ContextTypes.dns ? nqlAsArr[0] : nqlAsArr,
      ...(isSubAccountRecord && { customers: [event?.customer] }),
    };
    dispatch(actionsFilters.changeFilter(state));
  }, [event]);

  const ipLabelsHash = useSelector(labelsSelectors.getIpLabelsHash);

  const ipIntellData = useMemo(
    () =>
      (event?.ipinfo || []).map((item) => {
        const loaded =
          filters.labelContext.show &&
          filters.labelContext.ip === 'name' &&
          item.ipname;

        return {
          ...item,
          ipname:
            ipLabelsHash?.[item.ip]?.[filters.labelContext.ip] || loaded || [],
          customer: event?.customer,
          algorithm: event.algorithm,
        };
      }),
    [event, ipLabelsHash],
  );

  if (!event?.id) {
    return (
      <Fragment>
        <Breadcrumb title="Event Details" />

        <GlobalFiltersSetting
          context={context}
          excludeContexts={excludeContextsArr}
          range={false}
          from={false}
          to={false}
          nql={false}
          customers={false}
          metric={false}
          socketControl={false}
        />

        <Col
          $type={LayoutTypes.card}
          alignItems="center"
          justifyContent="center"
          height="80vh"
        >
          {isEventsFetching ? 'Loading...' : 'Event not found'}
        </Col>
      </Fragment>
    );
  }

  const showThresholds = (event?.search?.length || 0) === 1;

  return (
    <Fragment>
      <Menu.TriggerMenu />

      <Breadcrumb title="Event Details" />

      <GlobalFiltersSetting
        context={context}
        excludeContexts={excludeContextsArr}
        range={false}
        from={false}
        to={false}
        nql={false}
        customers={false}
        metric={false}
        socketControl={false}
      />

      <Col spacing={LayoutSizes.groupGap}>
        <Row item alignItems="center" spacing={LayoutSizes.groupGap}>
          <Col item sm={12} md={12} lg={8}>
            <Stack
              direction="row"
              gap={LayoutSizes.groupGap}
              alignItems="center"
            >
              <BackToButton />
              <Title severity={event.severity} icon={titleIcon}>
                Event Details
              </Title>
            </Stack>
          </Col>
          <Col item sm={12} md={12} lg={4}>
            <ActionsContainer mb={0} item>
              <CopyToClipboard text={eventUrl} onCopy={onCopy}>
                <Button>Share</Button>
              </CopyToClipboard>

              <Button onClick={() => setRecordModalOpened(true)}>
                Raw Record
              </Button>

              <OverridesButton
                event={event}
                isSubAccountRecord={isSubAccountRecord}
              />
            </ActionsContainer>
          </Col>
        </Row>

        <Row item spacing={LayoutSizes.groupGap}>
          <Col
            item
            sm={12}
            md={12}
            lg={showThresholds && thresholdsCollapsed ? 8 : 12}
          >
            <Overview event={event} isSubAccountRecord={isSubAccountRecord} />
          </Col>
          {showThresholds && thresholdsCollapsed && (
            <Col item sm={12} md={12} lg={4}>
              <Thresholds
                event={event}
                collapsed
                onShowAllClick={onShowAllClick}
              />
            </Col>
          )}
        </Row>

        {showThresholds && !thresholdsCollapsed && (
          <Thresholds
            event={event}
            collapsed={false}
            onShowAllClick={onShowAllClick}
          />
        )}

        <Row item>
          <Col item>
            <IpSummary
              ipScorePeriodStart={event?.start || event?.timestamp}
              ipScorePeriodEnd={event?.end}
              isSubAccountRecord={isSubAccountRecord}
              data={ipIntellData}
            />
          </Col>
        </Row>

        <Row item>
          <Col item>
            <TrafficTable
              event={event}
              isSubAccountRecord={isSubAccountRecord}
            />
          </Col>
        </Row>

        <Row item spacing={LayoutSizes.groupGap}>
          <Col
            item
            xs={12}
            lg={event?.traffictype === ContextTypes.flow ? 6 : 12}
          >
            <Row item $type={LayoutTypes.card}>
              <RelatedEvents
                event={event}
                isSubAccountRecord={isSubAccountRecord}
              />
            </Row>
          </Col>
          {event?.traffictype === ContextTypes.flow && (
            <Col item xs={12} lg={6}>
              <IpExplorer
                event={event}
                isSubAccountRecord={isSubAccountRecord}
              />
            </Col>
          )}
        </Row>
      </Col>

      {recordModalOpened && (
        <RecordModal
          title={`Event Record — ${event.id}`}
          data={event}
          onToggle={() => setRecordModalOpened(false)}
          isOpen
        />
      )}
    </Fragment>
  );
};

export default withMenu(EventDetails);
