import { useContext } from 'react';

import { PageTabsContext } from './PageTabsContext';

export const usePageTabs = () => {
  const context = useContext(PageTabsContext);

  if (!context) {
    throw new Error('usePageTabs must be used within a PageTabsProvider');
  }

  return context;
};
