import styled from 'styled-components';

import NqlTextFieldOrigin from '+components/form/NqlTextField';

const NQLFieldLabel = styled.div`
  position: absolute;
  top: 0;
  left: calc(80px + 8px + 2px);
  align-items: center;
  font-size: 11px;
  font-weight: 600;
  white-space: nowrap;
  user-select: none;
  max-height: 12px;
  color: ${({ theme }) => theme.colorTextSecondary} !important;
`;

const NqlButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-shrink: 0;
  gap: 2px;
  align-self: stretch;

  &:empty {
    display: none;
  }
`;

const NqlFieldError = styled.div`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  height: 32px;

  .mdi-icon {
    color: ${({ theme }) => theme.Palette.danger};
  }
`;

const NQLFieldContext = styled.div`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const NQLFieldClear = styled.div`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  .MuiButtonBase-root {
    background: transparent;
  }

  .MuiButtonBase-root:hover {
    background: ${({ theme }) =>
      theme.colorTool.lighten(theme.buttonTertiaryBackground, 0.2)} !important;
  }

  .mdi-icon {
    color: ${({ theme }) => theme.colorTextSecondary} !important;
  }
`;

const NQLFieldSave = styled.div`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const MainNqlFilter = styled(NqlTextFieldOrigin)`
  --height: 26px;

  .nql-main-container {
    flex-direction: unset;
    border: none;
    border-radius: 0;
    background: transparent !important;
    min-height: calc(var(--height) + 4px) !important;
    max-height: var(--height);
  }

  .nql-dropdown-btn-container {
    width: 0;
    height: 0;
    overflow: hidden;
    visibility: hidden;
    padding: 0 !important;
    margin: 0 !important;
  }

  .nql-code-block {
    padding-top: 11px !important;
    padding-left: 0 !important;
    padding-right: 18px !important;
    background: transparent !important;
    min-height: var(--height) !important;
    line-height: unset !important;
    * {
      background: transparent !important;
    }
  }

  .nql-textarea {
    --buttons-width: 0px;
    padding-top: 11px !important;
    padding-left: 0 !important;
    padding-right: 18px !important;
    background: transparent !important;
    min-height: var(--height) !important;
    line-height: unset !important;
  }

  .form__form-group-error {
    display: none;
  }
`;

export {
  NQLFieldLabel,
  NqlFieldError,
  NQLFieldContext,
  NQLFieldClear,
  NQLFieldSave,
  NqlButtonsContainer,
};
export default MainNqlFilter;
