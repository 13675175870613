import { BooleanColumnFactory } from './BooleanColumnFactory';

/**
 * @param {Object} props
 * @param {object} [props.optionsLabels] - options labels for BooleanColumnFilter
 * @return {Object} - column with filter=booleanFilter and Filter=BooleanColumnFilter
 */
export const EnabledDisabledColumnFactory = (props) =>
  BooleanColumnFactory({
    width: 120,
    optionsLabels: {
      false: 'Disabled',
      true: 'Enabled',
    },
    // eslint-disable-next-line react/prop-types
    Cell: ({ value }) => (value ? 'Enabled' : 'Disabled'),
    ...props,
  });
