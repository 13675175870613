import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  actions as rulesActions,
  selectors as rulesSelectors,
} from '@/redux/api/rules';

/**
 * Hook for track by fields.
 *
 * @param {Object} params
 * @param {boolean} params.fetch - if true, fields will be fetched
 * @param {string} params.context
 *
 * @return {{Object}}
 */
export const useTrackByFields = (params) => {
  const { fetch: fetchFields, context } = params;

  const dispatch = useDispatch();

  const fields = useSelector(rulesSelectors.getTrackByFields(context));

  const fieldsLength = fields?.length;
  useEffect(() => {
    if (fetchFields && !fieldsLength) {
      dispatch(rulesActions.fetchTrackByFields(context));
    }
  }, [fetchFields, fieldsLength, context]);

  return fields;
};
