import styled from 'styled-components';

import GroupBody from './GroupBody';
import GroupName from './GroupName';

export default styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 ${(props) => props.$width || '100%'};
  border-radius: 4px;
  background-color: ${({ theme }) => theme.chartBackground};
  padding: ${(props) => (props.$noPadding ? undefined : '8px')};
  overflow: hidden;
  height: ${(props) => (props.$fullHeight ? '100%' : undefined)};

  ${GroupName} + ${GroupBody} {
    margin-top: 8px;
  }
`;
