import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import isEqual from 'lodash.isequal';

import { CustomType } from '@/models/CustomType';
import SettingCategories from '@/models/SettingCategories';
import { UIProperties } from '@/models/UIProperties';

import { selectors as customerSelectors } from '@/redux/api/customer';
import { actions as globalFiltersActions } from '@/redux/globalFilters';

import { FinalForm } from '+components/form/FinalForm';
import PortalContext from '+components/GlobalFilters/Context';
import { usePageTabs } from '+components/PageTabs';
import useDateTimePeriodStack from '+hooks/useDateTimePeriodStack';
import useGlobalFilters from '+hooks/useGlobalFilters';
import usePortalSettingsValue from '+hooks/usePortalSettingsValue';
import useSessionStorage from '+hooks/useSessionStorage';
import useUIProperty from '+hooks/useUIProperty';
import { getNewRecentFiltersList } from '+utils/getNewRecentFilters';
import getNqlFieldName from '+utils/getNqlFieldName';

import FormBody from './components/FormBody';
import { paramsToUi, uiToParams } from './components/utils';
import withRealTimeController from './components/withRealTimeController';

const GlobalFiltersPanel = (props) => {
  const dispatch = useDispatch();

  const [, activePageTab] = usePageTabs();
  const [filters] = useGlobalFilters();
  const [guest] = useUIProperty(UIProperties.guest);
  const [, { clear }] = useDateTimePeriodStack();
  const { portalInitialValues } = useContext(PortalContext);

  const customer = useSelector(customerSelectors.getCurrentCustomer);

  const [recents, changeRecents] = usePortalSettingsValue(
    SettingCategories.ui,
    'globalFiltersRecents',
    [],
  );
  const [sessionRecents, setSessionRecents] = useSessionStorage(
    'globalFiltersRecents',
  );

  const [initialValues, setInitialValues] = useState({});

  const recentsRef = useRef();
  recentsRef.current = guest ? sessionRecents || recents : recents;

  const applyFilters = useCallback(
    (_values) => {
      const values = uiToParams(_values);

      // TODO: Is it a bug or feature? We are saving to recent nql only for current context
      // but user could set nql for another context and it will be saved to recent filters
      const nextRecent = getNewRecentFiltersList(
        values[`${getNqlFieldName(values.context)}`]?.[0],
        recentsRef,
        values.context,
        customer.shortname,
      );

      if (guest) {
        setSessionRecents(nextRecent);
      } else {
        changeRecents(nextRecent);
      }

      const state = {
        tabId: activePageTab?.id,
        ...values,
      };

      if (values.period.type !== CustomType) {
        state.from = null;
        state.to = null;
        state.startIsMin = false;
        state.endIsNow = false;
      }

      clear();
      dispatch(globalFiltersActions.changeFilter(state));
    },
    [customer?.shortname, activePageTab?.id, guest],
  );

  useEffect(() => {
    setInitialValues((prev) => {
      const next = paramsToUi({
        ...filters,
        ...portalInitialValues,
      });
      return isEqual(prev, next) ? prev : next;
    });
  }, [filters, portalInitialValues]);

  return (
    <FinalForm
      {...props}
      initialValues={initialValues}
      onSubmit={applyFilters}
      component={FormBody}
      validateOnBlur
      keepDirtyOnReinitialize
      focusOnFields={false}
    />
  );
};

export default withRealTimeController(GlobalFiltersPanel);
