import { isIpField } from '@/models/FieldTypes';

import { UniversalCell } from '+components/Table/Cells';
import {
  LabelOrPortColumnFactory,
  NumberColumnFactory,
} from '+components/Table/Columns';
import { getRowOriginal } from '+components/Table/Columns/utils';

import { commonColumns, getWrappedLabelOrIpColumn } from './commonColumns';
import { PivotClickable } from './PivotClickable';

const Columns = {
  agg: 'agg',
  agg_count: 'agg_count',
  packets: 'packets',
  bits: 'bits',
  srcip: 'srcip',
  srcport: 'srcport',
  dstip: 'dstip',
  dstport: 'dstport',
  protocol: 'protocol',
};

const CellProtocol = UniversalCell(Columns.protocol);

export const getColumns = ({ field, labelContext, onPivotClick }) => [
  {
    accessor: Columns.agg,
    Header: 'Aggregation',
    Cell: UniversalCell(field),
    sortType: isIpField(field) ? 'ip' : 'alphanumeric',
  },
  NumberColumnFactory({
    accessor: Columns.agg_count,
    Header: 'Count (of 1000)',
    textAlign: 'right',
  }),
  NumberColumnFactory({
    accessor: Columns.packets,
    Header: 'Packets',
    textAlign: 'right',
  }),
  NumberColumnFactory({
    accessor: Columns.bits,
    Header: 'Bits',
    textAlign: 'right',
  }),
  ...commonColumns({ labelContext, onPivotClick }),
  getWrappedLabelOrIpColumn({
    accessor: Columns.dstip,
    Header: 'Destination',
    labelFieldName: `label.ip.${labelContext.ip}.dst`,
    showLabel: labelContext.show,
    onPivotClick,
  }),
  getWrappedLabelOrIpColumn({
    accessor: Columns.dstport,
    Header: 'DST Port',
    labelFieldName: `label.port.${labelContext.port}.dst`,
    showLabel: labelContext.show,
    onPivotClick,
    factory: LabelOrPortColumnFactory,
  }),
  {
    accessor: Columns.protocol,
    Header: 'Protocol',
    Cell: (options) => {
      const row = getRowOriginal(options.row);

      return (
        <PivotClickable
          onPivotClick={onPivotClick}
          row={row}
          field={Columns.protocol}
        >
          <CellProtocol {...options} />
        </PivotClickable>
      );
    },
  },
];
