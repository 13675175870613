import { call, put } from 'redux-saga/effects';

import { actions as toastActions } from '@/redux/toast';
import {
  createSelector,
  createSlice,
  defaultReducers,
  startFetching,
  stopFetching,
  takeLeading,
} from '@/redux/util';

import backendClient from '@/middleware/backendClient';

export const initialState = {
  isFetching: false,
  error: '',
  dnsDevices: null, // {},
  createdId: null,
};

const apiPath = '/dns-devices';

let api;

const initApi = () => {
  if (!api) {
    api = backendClient();
  }
};

const slice = createSlice({
  name: 'dnsDevices',
  initialState,

  reducers: {
    ...defaultReducers,
    fetchDNSDevices: startFetching,
    fetchDNSDevicesSuccess(state, { payload: data }) {
      stopFetching(state);
      state.dnsDevices = (data || []).reduce((acc, item) => {
        acc[item.id] = item;
        return acc;
      }, {});
    },

    fetchDNSDevice: startFetching,
    fetchDNSDeviceSuccess(state, { payload: data }) {
      stopFetching(state);
      if (!state.dnsDevices) {
        state.dnsDevices = {};
      }
      state.dnsDevices[data.id] = data;
    },

    createDNSDevice: startFetching,
    createDNSDeviceSuccess(state, { payload: data }) {
      stopFetching(state);
      if (!state.dnsDevices) {
        state.dnsDevices = {};
      }
      state.dnsDevices[data.id] = data;
      state.createdId = data.id;
    },
    clearCreatedId(state) {
      state.createdId = null;
    },

    updateDNSDevice: startFetching,
    updateDNSDeviceSuccess(state, { payload: data }) {
      stopFetching(state);
      if (!state.dnsDevices) {
        state.dnsDevices = {};
      }
      state.dnsDevices[data.id] = data;
    },

    removeDNSDevice: startFetching,
    removeDNSDeviceSuccess(state, { payload: id }) {
      stopFetching(state);
      delete state.dnsDevices?.[id];
    },

    bulkDeleteDNSDevices: startFetching,
    bulkDeleteDNSDeviceSuccess(state, { payload: devices }) {
      stopFetching(state);
      devices.forEach((device) => {
        delete state.dnsDevices?.[device.id];
      });
    },

    enableDNSDevice: startFetching,
    enableDNSDeviceSuccess(state, { payload: data }) {
      stopFetching(state);
      if (!state.dnsDevices) {
        state.dnsDevices = {};
      }
      state.dnsDevices[data.id] = data;
    },

    disableDNSDevice: startFetching,
    disableDNSDeviceSuccess(state, { payload: data }) {
      stopFetching(state);
      if (!state.dnsDevices) {
        state.dnsDevices = {};
      }
      state.dnsDevices[data.id] = data;
    },

    skip: stopFetching,
  },

  sagas: (actions) => ({
    [actions.fetchDNSDevices]: {
      taker: takeLeading(actions.skip),
      *saga() {
        initApi();

        try {
          const response = yield call(api.get, apiPath);
          yield put(actions.fetchDNSDevicesSuccess(response.data.data));
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: 'Error fetching devices',
              details: error.message,
            }),
          );
        }
      },
    },

    [actions.fetchDNSDevice]: {
      *saga({ payload: id }) {
        initApi();

        try {
          const response = yield call(api.get, `${apiPath}/${id}`);
          yield put(actions.fetchDNSDeviceSuccess(response.data.data));
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: 'Error fetching device',
              details: error.message,
            }),
          );
        }
      },
    },

    [actions.createDNSDevice]: {
      *saga({ payload: device }) {
        initApi();

        try {
          const response = yield call(api.post, apiPath, device);
          yield put(actions.createDNSDeviceSuccess(response.data.data));
          yield put(
            toastActions.successWithAuditLogVerification({
              message: 'Device has been created',
              response,
            }),
          );
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: 'Error creating device',
              details: error.message,
            }),
          );
        }
      },
    },

    [actions.updateDNSDevice]: {
      *saga({ payload: device }) {
        initApi();

        try {
          const response = yield call(
            api.put,
            `${apiPath}/${device.id}`,
            device,
          );
          yield put(actions.updateDNSDeviceSuccess(response.data.data));
          yield put(
            toastActions.successWithAuditLogVerification({
              message: 'Device has been updated',
              response,
            }),
          );
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: 'Error updating device',
              details: error.message,
            }),
          );
        }
      },
    },

    [actions.removeDNSDevice]: {
      *saga({ payload: id }) {
        initApi();

        try {
          const response = yield call(api.delete, `${apiPath}/${id}`);
          yield put(actions.removeDNSDeviceSuccess(id));
          yield put(
            toastActions.successWithAuditLogVerification({
              message: 'Device has been deleted',
              response,
            }),
          );
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: 'Error deleting device',
              details: error.message,
            }),
          );
        }
      },
    },

    [actions.bulkDeleteDNSDevices]: {
      *saga({ payload: devices }) {
        initApi();

        try {
          const response = yield call(api.put, `${apiPath}/bulkdelete`, {
            devices,
          });
          yield put(actions.bulkDeleteDNSDeviceSuccess(devices));
          yield put(
            toastActions.successWithAuditLogVerification({
              message: 'Devices have been removed',
              response,
            }),
          );
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: 'Error deleting devices',
              details: error.message,
            }),
          );
        }
      },
    },

    [actions.enableDNSDevice]: {
      *saga({ payload: { id } }) {
        initApi();

        try {
          const response = yield call(api.put, `${apiPath}/${id}/enable`);
          yield put(actions.enableDNSDeviceSuccess(response.data.data));
          yield put(
            toastActions.successWithAuditLogVerification({
              message: 'Device has been enabled',
              response,
            }),
          );
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: 'Error enabling device',
              details: error.message,
            }),
          );
        }
      },
    },

    [actions.disableDNSDevice]: {
      *saga({ payload: { id } }) {
        initApi();

        try {
          const response = yield call(api.put, `${apiPath}/${id}/disable`);
          yield put(actions.disableDNSDeviceSuccess(response.data.data));
          yield put(
            toastActions.successWithAuditLogVerification({
              message: 'Device has been disabled',
              response,
            }),
          );
        } catch (error) {
          yield put(actions.fail(error));
          yield put(
            toastActions.error({
              message: 'Error disabling device',
              details: error.message,
            }),
          );
        }
      },
    },
  }),

  selectors: (getState) => ({
    isFetching: createSelector([getState], (state) => state.isFetching),

    getError: createSelector([getState], (state) => state.error),

    getDNSDevices: createSelector([getState], (state) => state.dnsDevices),

    getDNSDevice: (id) =>
      createSelector([getState], (state) => state.dnsDevices?.[id]),

    getCreatedId: createSelector([getState], (state) => state.createdId),
  }),
});

export const { actions, selectors } = slice;

export default slice;
