import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useLocation, useMeasure, useToggle } from 'react-use';

import { useFlag } from '@unleash/proxy-client-react';

import { ContextTypes } from '@/models/ContextTypes';
import FeatureFlags from '@/models/FeatureFlags';
import PermissionModel from '@/models/Permission';
import RoutePaths from '@/models/RoutePaths';

import {
  actions as bgpActions,
  selectors as bgpSelectors,
} from '@/redux/api/bgp';
import {
  actions as deviceActions,
  selectors as deviceSelectors,
} from '@/redux/api/device';
import {
  actions as dnsDeviceActions,
  selectors as dnsDeviceSelectors,
} from '@/redux/api/dnsDevice';
import {
  actions as vpcActions,
  selectors as vpcSelectors,
} from '@/redux/api/vpc';

import Button, { ButtonVariants } from '+components/Button';
import ConfirmModal from '+components/ConfirmModal';
import { Dropdown, DropdownItem } from '+components/Dropdown';
import GlobalFiltersSetting from '+components/GlobalFilters/Setting';
import { ActionsContainer, Col, Row } from '+components/Layout';
import NoDataPage from '+components/NoDataPage';
import { getRowOriginal } from '+components/Table/Columns/utils';
import Tag from '+components/Tag';
import * as toast from '+components/toast';
import useGlobalFilters from '+hooks/useGlobalFilters';
import { useHasFlow } from '+hooks/useHasFlow';
import useLastAllowedContext from '+hooks/useLastAllowedContext';
import useLoadingIndicator from '+hooks/useLoadingIndicator';
import useMaxSources from '+hooks/useMaxSources';
import usePermissions from '+hooks/usePermissions';
import { pluralize } from '+utils';
import getSearchParams from '+utils/getSearchParams';

import SourcesColChart from './components/SourcesColChart';
import SourcesLineChart from './components/SourcesLineChart';
import SourcesTable from './components/Table';
import { excludeMetrics } from './utils';

const excludeContexts = new Set([
  ContextTypes.alerts,
  ContextTypes.blocks,
  ContextTypes.traffic,
]);

const getSelected = (selected, data) => {
  if (!selected?.length) {
    return [];
  }
  if (!Object.keys(data || {}).length) {
    return [];
  }
  return selected.map((id) => data[id]).filter(Boolean);
};

const FlowSources = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const hasFlow = useHasFlow();

  const { search = '' } = useLocation();
  const context = useLastAllowedContext({
    excludeContexts,
    defaultContext: ContextTypes.flow,
  });
  const [filters] = useGlobalFilters(context);
  const [lineChartMeasureRef, { width: lineChartWidth }] = useMeasure();
  const [topChartMeasureRef, { width: topChartWidth }] = useMeasure();

  const isDNSDevicesEnabled = useFlag(FeatureFlags.dnsDevices);
  const { data: deviceData, isFetching: isDeviceFetching } = useSelector(
    deviceSelectors.getState,
  );
  const { isFetching: isBgpFetching } = useSelector(bgpSelectors.getState);
  const { isFetching: isVpcFetching } = useSelector(vpcSelectors.getState);
  const vpcData = useSelector(vpcSelectors.getVpcs);
  const bgpEnabledDevices = useSelector(bgpSelectors.devicesHaveNeighbors);
  const isDNSDeviceFetching = useSelector(dnsDeviceSelectors.isFetching);
  const dnsDeviceData = useSelector(dnsDeviceSelectors.getDNSDevices);
  const devicePermissions = usePermissions(
    PermissionModel.Resources.device.value,
  );
  const cloudPermissions = usePermissions(
    PermissionModel.Resources.cloud_provider.value,
  );
  const { enforceMaxSources, sourcesRemaining } = useMaxSources();
  const [selected, setSelected] = useState([]);
  const [showBulkEnableModal, toggleBulkEnableModal] = useToggle(false);
  const [showBulkDisableModal, toggleBulkDisableModal] = useToggle(false);
  const [showBulkDeleteModal, toggleBulkDeleteModal] = useToggle(false);

  const isDataLoading =
    isDeviceFetching || isBgpFetching || isVpcFetching || isDNSDeviceFetching;

  useLoadingIndicator(isDataLoading);

  const params = useMemo(() => getSearchParams(search), [search]);

  const permissions = useMemo(
    () => ({
      devicePermissions,
      cloudPermissions,
    }),
    [devicePermissions, cloudPermissions],
  );

  const sources = useMemo(() => {
    const flowDevices = Object.values(deviceData || {}).map((item) => ({
      ...item,
      bgpenabled: bgpEnabledDevices[item.id],
      traffictype: ContextTypes.flow,
    }));
    const dnsDevices = Object.values(dnsDeviceData || {}).map((item) => ({
      ...item,
      traffictype: ContextTypes.dns,
    }));
    const vpc = Object.values(vpcData || {});
    return [...flowDevices, ...dnsDevices, ...vpc];
  }, [deviceData, vpcData, bgpEnabledDevices, dnsDeviceData]);

  const [selectedDevices, selectedVpcs, selectedDNSDevices] = useMemo(
    () => [
      getSelected(selected, deviceData),
      getSelected(selected, vpcData),
      getSelected(selected, dnsDeviceData),
    ],
    [selected, deviceData, vpcData, dnsDeviceData],
  );

  const onConfirmBulkEnable = useCallback(() => {
    toggleBulkEnableModal();
    const disabledVpcs = selectedVpcs.filter((vpc) => !vpc.enabled);

    if (!disabledVpcs.length) {
      toast.info('All selected cloud traffic sources are already enabled');
    } else {
      dispatch(vpcActions.bulkEnableVpcs({ vpcs: disabledVpcs }));
    }
  }, [selectedVpcs]);

  const onConfirmBulkDisable = useCallback(() => {
    toggleBulkDisableModal();
    const enabledVpcs = selectedVpcs.filter((vpc) => vpc.enabled);

    if (!enabledVpcs.length) {
      toast.info('All selected cloud traffic sources are already disabled');
    } else {
      dispatch(vpcActions.bulkDisableVpcs({ vpcs: enabledVpcs }));
    }
  }, [selectedVpcs]);

  const onConfirmBulkDelete = useCallback(() => {
    toggleBulkDeleteModal();

    if (selectedVpcs.length > 0) {
      dispatch(vpcActions.bulkDeleteVpcs(selectedVpcs));
    }

    if (selectedDevices.length > 0) {
      dispatch(deviceActions.bulkDeleteDevices(selectedDevices));
    }

    if (selectedDNSDevices.length > 0) {
      dispatch(dnsDeviceActions.bulkDeleteDevices(selectedDNSDevices));
    }
  }, [selectedDevices, selectedVpcs, selectedDNSDevices]);

  const onAddClick = useCallback(
    () => navigate(`${RoutePaths.sources}/add`),
    [],
  );

  const onSelectedRowsChange = useCallback((selectedRowIds) => {
    setSelected((prev) => {
      const next = Object.entries(selectedRowIds || {})
        .map(([key, value]) => (value ? key : null))
        .filter(Boolean);

      if (!prev.length && !next.length) {
        return prev;
      }

      return next;
    });
  }, []);

  const getIsRowSelectorDisabled = useCallback(
    (row) => {
      const original = getRowOriginal(row);
      if (!original?.flowtype) {
        return !devicePermissions?.delete;
      }

      return !cloudPermissions?.update && !cloudPermissions?.delete;
    },
    [devicePermissions, cloudPermissions],
  );

  useEffect(() => {
    dispatch(deviceActions.devicesStatus());
    dispatch(deviceActions.devicesFetch());
    dispatch(bgpActions.fetchNeighbors());
    dispatch(vpcActions.fetchVpcs());
    if (isDNSDevicesEnabled) {
      dispatch(dnsDeviceActions.fetchDNSDevices());
    }
  }, [filters.refresher, isDNSDevicesEnabled]);

  const excludeContextsArr = useMemo(
    () => Array.from(excludeContexts),
    [excludeContexts],
  );

  return (
    <Fragment>
      <GlobalFiltersSetting
        context={context}
        excludeMetrics={excludeMetrics}
        excludeContexts={excludeContextsArr}
        nql
        metric
        socketControl={false}
      />

      <ActionsContainer>
        <Button
          onClick={onAddClick}
          disabled={
            (!devicePermissions?.create && !cloudPermissions?.create) ||
            (enforceMaxSources && sourcesRemaining <= 0)
          }
        >
          Add Traffic Source
        </Button>

        {sources.length > 0 && (
          <Dropdown
            caption="Update Selected"
            variant={ButtonVariants.outlined}
            disabled={!selected.length}
          >
            <DropdownItem header>Cloud Providers</DropdownItem>
            <DropdownItem
              onClick={toggleBulkEnableModal}
              disabled={!cloudPermissions?.update || !selectedVpcs.length}
              short
            >
              Enable
            </DropdownItem>
            <DropdownItem
              onClick={toggleBulkDisableModal}
              disabled={!cloudPermissions?.update || !selectedVpcs.length}
              short
            >
              Disable
            </DropdownItem>
            <DropdownItem header>Traffic Sources</DropdownItem>
            <DropdownItem
              onClick={toggleBulkDeleteModal}
              disabled={!devicePermissions?.delete || !cloudPermissions?.delete}
              short
            >
              Delete
            </DropdownItem>
          </Dropdown>
        )}

        {enforceMaxSources && (
          <Tag color="secondary" outlined={false}>
            {sourcesRemaining} Remaining
          </Tag>
        )}
      </ActionsContainer>

      {sources.length === 0 && (
        <NoDataPage
          image="/assets/graphic-devices-bw.png"
          imageOffsetY={20}
          noDataText="No Traffic Sources set"
          actionButtonText="Add Traffic Source"
          loading={isDataLoading}
        />
      )}

      {sources.length > 0 && (
        <Fragment>
          {hasFlow && (
            <Row columnSpacing={1} style={{ height: 300, overflow: 'hidden' }}>
              <Col ref={lineChartMeasureRef} xs={8} item container={false}>
                <SourcesLineChart
                  {...params}
                  sources={sources}
                  excludeMetrics={excludeMetrics}
                  width={lineChartWidth}
                  height={300}
                  context={context}
                />
              </Col>

              <Col ref={topChartMeasureRef} xs={4} item container={false}>
                <SourcesColChart
                  {...params}
                  sources={sources}
                  excludeMetrics={excludeMetrics}
                  width={topChartWidth}
                  height={300}
                  context={context}
                />
              </Col>
            </Row>
          )}

          <SourcesTable
            {...params}
            permissions={permissions}
            sources={sources}
            getIsRowSelectorDisabled={getIsRowSelectorDisabled}
            onSelectedRowsChange={onSelectedRowsChange}
          />
        </Fragment>
      )}
      {showBulkEnableModal && (
        <ConfirmModal
          item={`${selectedVpcs.length} Cloud Traffic ${pluralize(selectedVpcs.length, 'Source')}`}
          confirmButtonText="enable"
          confirmButtonColor="primary"
          whyAsking=""
          onToggle={toggleBulkEnableModal}
          onConfirm={onConfirmBulkEnable}
          toggleOnConfirm={false}
          isOpen
        />
      )}

      {showBulkDisableModal && (
        <ConfirmModal
          item={`${selectedVpcs.length} Cloud Traffic ${pluralize(selectedVpcs.length, 'Source')}`}
          confirmButtonText="disable"
          confirmButtonColor="primary"
          whyAsking=""
          onToggle={toggleBulkDisableModal}
          onConfirm={onConfirmBulkDisable}
          toggleOnConfirm={false}
          isOpen
        />
      )}
      {showBulkDeleteModal && (
        <ConfirmModal
          item={`${selected.length} Traffic ${pluralize(selected.length, 'Source')}`}
          confirmButtonText="delete"
          whyAsking=""
          onToggle={toggleBulkDeleteModal}
          onConfirm={onConfirmBulkDelete}
          toggleOnConfirm={false}
          isOpen
        />
      )}
    </Fragment>
  );
};

export default FlowSources;
