import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import styled from 'styled-components';

import { ContextTypes } from '@/models/ContextTypes';
import PermissionModel from '@/models/Permission';
import RoutePaths from '@/models/RoutePaths';

import {
  actions as rulesActions,
  selectors as rulesSelectors,
} from '@/redux/api/rules';

import { Breadcrumb } from '+components/Breadcrumb';
import ConfirmModal from '+components/ConfirmModal';
import FieldsSection from '+components/form/FieldsSection';
import { Field, FieldArray } from '+components/form/FinalForm';
import FormField, { Group, Label } from '+components/form/FormField';
import { normalizeNumber } from '+components/form/Normalizers';
import Plaintext from '+components/form/Plaintext';
import TextField from '+components/form/TextField';
import { validateRequired } from '+components/form/Validators';
import FormWizard, { Step } from '+components/FormWizard';
import SeverityLabel from '+components/Labels/SeverityLabel';
import { Col, LayoutTypes, Row } from '+components/Layout';
import { usePageTabs } from '+components/PageTabs';
import UniversalField from '+components/UniversalField';
import usePermissions from '+hooks/usePermissions';

import RenderThresholds from '../../shared/RenderThresholds';
import { useTrackByOptions } from '../../shared/useTrackByOptions';
import { Config } from '../../shared/utils';
import { paramsToUi, uiToParams } from '../utils';
import RenderTrackBy from './RenderTrackBy';

const InfoSpan = styled.span`
  color: ${(props) => props.theme.colorTextSecondary} !important;
`;

export const UpdateForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { algorithmId } = useParams();

  const [, activePageTab] = usePageTabs();

  const permissions = usePermissions(
    PermissionModel.Resources.threat_model.value,
  );

  const algorithm = useSelector(rulesSelectors.getAlgorithm(algorithmId));
  const isFetching = useSelector(rulesSelectors.isFetching);
  const error = useSelector(rulesSelectors.getError);
  const configuredTriggers = useSelector(
    rulesSelectors.getConfiguredTriggersByAlgorithm(algorithm?.name),
  );
  const context = algorithm?.algo_record_type || ContextTypes.flow;

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [searchParams] = useSearchParams();

  const editMode = searchParams.get('mode') === 'edit';
  const trackByOptions = useTrackByOptions(context, {
    onlyFields: true,
    addGlobal: algorithm?.algo_type === 'TDM',
  });

  const trackBySearchParams = useMemo(() => {
    const trackBy = Object.fromEntries(searchParams);

    const trackBySet = new Set(trackByOptions);

    Object.keys(trackBy).forEach((paramKey) => {
      if (!trackBySet.has(paramKey)) {
        delete trackBy[paramKey];
      }
    });

    return Object.keys(trackBy).length > 0 ? trackBy : null;
  }, [searchParams, trackByOptions]);

  const currentTrigger = useMemo(() => {
    if (!trackBySearchParams) {
      return null;
    }

    const values = Object.values(trackBySearchParams);
    return configuredTriggers?.find((trigger) => {
      const track = trigger.track.split(' ');

      return values.every((trackValue) => track.includes(trackValue));
    });
  }, [editMode, trackBySearchParams, configuredTriggers]);

  const defaultValues = useMemo(
    () =>
      paramsToUi({
        rollupperiod: currentTrigger?.rollupperiod ?? 3600,
        updateinterval: currentTrigger?.updateinterval ?? 3600,
        thresholds: currentTrigger?.thresholds ?? [Config.defaultThresholds],
        trackby: algorithm?.track_by?.[0]?.map((trackby) => ({
          trackby,
          value: trackBySearchParams?.[trackby] || undefined,
        })),
      }),
    [Config, algorithm, currentTrigger, trackBySearchParams],
  );

  useEffect(() => {
    if (!algorithm && !isFetching && !error) {
      dispatch(rulesActions.fetchAlgorithm(algorithmId));
    }
  }, [algorithm, isFetching, error, algorithmId]);

  // Need to make sure we fetch triggers, otherwise refreshing the page then updating could result in incorrect state
  useEffect(() => {
    if (algorithm && !configuredTriggers && !isFetching) {
      dispatch(rulesActions.fetchConfiguredTriggersByAlgorithm(algorithm.name));
    }
  }, [algorithm, configuredTriggers, isFetching]);

  const onCancel = useCallback(() => {
    const path = RoutePaths.modelsDetection;
    navigate(`${path}/${algorithmId}/${RoutePaths.modelOverrides}`);
  }, [algorithmId]);

  const onDelete = useCallback(() => {
    setShowDeleteModal(true);
  }, []);

  const onDeleteCancel = useCallback(() => {
    setShowDeleteModal(false);
  }, []);

  const onDeleteConfirm = useCallback(() => {
    dispatch(
      rulesActions.deleteTriggersByAlgorithmAndObject({
        algorithm: algorithm.name,
        object: currentTrigger?.track,
      }),
    );
    onCancel();
  }, [algorithm?.name, currentTrigger?.track, onCancel]);

  const handleSubmit = useCallback(
    (values) => {
      let track = values.trackby.map((item) => `${item.value}`).join(' ');

      if (editMode && currentTrigger?.track) {
        track = currentTrigger.track;
      }

      const data = uiToParams({
        algorithm: algorithm.name,
        rollupperiod: values.rollupperiod,
        updateinterval: values.updateinterval,
        thresholds: values.thresholds,
        track,
      });

      setIsSubmitting(activePageTab?.id);
      dispatch(
        rulesActions.updateTrigger({
          algorithm: algorithm.name,
          data,
          configuredOnly: true,
        }),
      );
    },
    [algorithm?.name, activePageTab?.id, editMode, currentTrigger],
  );

  useEffect(() => {
    if (!isSubmitting || isFetching) {
      return;
    }

    if (isSubmitting !== activePageTab?.id) {
      return;
    }

    if (!error) {
      onCancel();
    } else {
      setIsSubmitting(false);
    }
  }, [isSubmitting, isFetching, error, activePageTab?.id, onCancel]);

  const onCreateAsNew = useCallback(() => {
    let params = new URLSearchParams(trackBySearchParams || {}).toString();
    params = params ? `?${params}` : '';

    const path = RoutePaths.modelsDetection;
    navigate({
      pathname: `${path}/${algorithm.id}/${RoutePaths.modelOverrides}/update`,
      search: params,
    });
  }, [algorithm?.id, trackBySearchParams]);

  const additionalActions = useMemo(
    () =>
      permissions?.create
        ? [{ text: 'Create As New', onClick: onCreateAsNew }]
        : undefined,
    [permissions, onCreateAsNew],
  );

  const disableForm =
    !algorithm || !permissions?.update || !!isSubmitting || isFetching;

  const title = `${editMode ? 'Edit' : 'Add'} Threshold Overrides`;

  return (
    <Fragment>
      <Breadcrumb title={title} />

      <FormWizard
        onCancel={onCancel}
        onSubmit={handleSubmit}
        loading={isFetching}
        initialValues={defaultValues}
        deleteButtonHidden={false}
        deleteButtonDisabled={!editMode || !currentTrigger}
        deleteButtonText="Delete Override"
        onDelete={onDelete}
        disabled={disableForm}
        additionalActions={editMode ? additionalActions : undefined}
        confirmButtonText={editMode ? 'Update' : 'Add'}
      >
        <Step>
          <FieldsSection label="TRACK BY">
            {!editMode ? (
              <FieldArray
                name="trackby"
                component={RenderTrackBy}
                context={context}
              />
            ) : (
              (defaultValues?.trackby || []).map((trackBy) => (
                <Group key={trackBy.trackby}>
                  <Label>{trackBy.trackby}</Label>
                  <Plaintext>{trackBy.value}</Plaintext>
                </Group>
              ))
            )}
            <FieldsSection label="OVERRIDES">
              <FormField label="Current Settings">
                <Col spacing="2px" mt={0.3} mb={1}>
                  <Row item spacing="4px">
                    <Col xs={2.1} item>
                      <InfoSpan>Rollup Period:</InfoSpan>
                    </Col>
                    <Col item $type={LayoutTypes.fieldValue}>
                      <InfoSpan>{algorithm?.rollupperiod}</InfoSpan>
                    </Col>
                  </Row>

                  <Row item spacing="4px">
                    <Col xs={2.1} item>
                      <InfoSpan>Update Interval:</InfoSpan>
                    </Col>
                    <Col item $type={LayoutTypes.fieldValue}>
                      <InfoSpan>{algorithm?.updateinterval}</InfoSpan>
                    </Col>
                  </Row>

                  {!!algorithm?.thresholds?.length && (
                    <Row item spacing="2px">
                      <Row item>
                        <InfoSpan>Thresholds:</InfoSpan>
                      </Row>

                      {algorithm.thresholds.map((threshold, index) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <Row item key={index} spacing={1}>
                          <Col item container={false}>
                            <SeverityLabel severity={threshold.severity} />
                          </Col>
                          <Col item container={false}>
                            <UniversalField
                              field="search"
                              value={threshold.threshold}
                              options={{
                                underline: false,
                                context: ContextTypes.thresholdFlow,
                              }}
                            />
                          </Col>
                        </Row>
                      ))}
                    </Row>
                  )}
                </Col>
              </FormField>
              <Field
                name="rollupperiod"
                label="Rollup Period"
                component={TextField}
                type="number"
                placeholder="3600"
                min={15}
                max={3600}
                validate={validateRequired}
                style={{ width: '30%' }}
                helperText="The lookback period for the detection model. Min 15 seconds. Max 1 hour (3600)."
                parse={normalizeNumber}
                required
                // disabled={!canManage}
              />
              <FieldArray
                name="thresholds"
                label="Thresholds"
                helperText="Expression used to calculate the threshold"
                component={RenderThresholds}
                context={
                  context === ContextTypes.dns
                    ? ContextTypes.thresholdDns
                    : ContextTypes.thresholdFlow
                }
                maxLength={5}
                required
                // disabled={!canManage}
              />
              <Field
                name="updateinterval"
                label="Update Interval"
                component={TextField}
                type="number"
                placeholder="3600"
                min={15}
                max={3600}
                style={{ width: '30%' }}
                helperText="The lookback period for the algorithm. Min 15 seconds. Max 1 hour (3600)."
                parse={normalizeNumber}
                required
                // disabled={!canManage}
              />
            </FieldsSection>
          </FieldsSection>
        </Step>
      </FormWizard>

      {showDeleteModal && (
        <ConfirmModal
          item={currentTrigger?.track || null}
          onConfirm={onDeleteConfirm}
          toggleOnConfirm
          isDisabled={isFetching}
          cancelButtonDisabled={isFetching}
          onToggle={onDeleteCancel}
          isOpen
        />
      )}
    </Fragment>
  );
};
