import PropTypes from '+prop-types';

import styled from 'styled-components';

import Stack from '@mui/material/Stack';

import useEvent from '+hooks/useEvent';

const PivotLink = styled.span`
  background-color: ${({ theme }) => theme.pivotClickableBackground};
  color: ${({ theme }) => theme.pivotClickableText} !important;
  border-radius: 2px 0 0 2px;
  border-right: 1px solid ${({ theme }) => theme.pivotClickableBorder};
  padding: 0 5px;
  cursor: pointer;
  font-weight: 400;
  font-size: 13px;
  line-height: 21px;
  margin-left: 5px;
  position: absolute;
  right: 0;

  transition:
    background-color 0.2s ease-in-out,
    padding-right 0.1s ease-in-out;

  &:hover {
    padding-right: 8px;
    background-color: ${({ theme }) =>
      theme.colorTool.lighten(theme.pivotClickableBackground, 0.2)};
  }
`;

export const PivotClickable = ({ children, onPivotClick, field, row }) => {
  const processedField = field.split('.').pop();
  const pivotValue = row[`${processedField}_total`];
  const showPivot = pivotValue && pivotValue !== row.agg_count;

  const onClick = useEvent(() => {
    onPivotClick?.(row, field);
  });

  return (
    <Stack justifyContent="space-between" direction="row" alignItems="center">
      {children}
      {showPivot && <PivotLink onClick={onClick}>+{pivotValue}</PivotLink>}
    </Stack>
  );
};

PivotClickable.propTypes = {
  children: PropTypes.node.isRequired,
  onPivotClick: PropTypes.func,
  row: PropTypes.object.isRequired,
  field: PropTypes.string.isRequired,
};

PivotClickable.defaultProps = {
  onPivotClick: null,
};
