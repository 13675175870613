import { NumberColumnFilter } from '+components/Table/Filters';

import { BaseColumnFactory } from './BaseColumnFactory';

/**
 * @param {Object} props
 * @param {string} [props.description=''] - field description
 * @param {string} [props.Header] - header text value
 * @param {string} [props.filter] - filter type
 * @param {function} [props.Filter] - filter function override
 * @param {function} [props.Cell] - cell data formatter
 * @param {function} [props.textAlign] - text alignment
 * @param {boolean} [props.genericCell] - use generic cell instead of UniversalCell in BaseColumnFactory, helpful to override formatting (i.e. thousand separators)
 * @return {Object}
 */
export const NumberColumnFactory = (props) => {
  const { genericCell, ...rest } = props || {};

  return BaseColumnFactory({
    Filter: NumberColumnFilter,
    filter: 'numberFilter',
    Cell: genericCell ? ({ value }) => value : undefined,
    getCellProps: () => ({
      style: { justifyContent: props.textAlign ?? 'flex-end' },
    }),
    sortType: 'number',
    ...rest,
  });
};
