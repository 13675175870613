import styled from 'styled-components';

import { Row as RowOrigin } from './Row';

export const ActionsContainer = styled(RowOrigin).attrs((props) => ({
  width: 'unset',
  minHeight: 32,
  gap: '10px',
  mb: '15px',
  zIndex: 9999,
  alignItems: 'center',
  ...props,
}))`
  &:empty {
    display: none;
  }

  & > .MuiButton-root {
    height: 30px;
  }
`;
