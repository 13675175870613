import PropTypes from '+prop-types';
import { Fragment, useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import AlphaECircleIcon from 'mdi-react/AlphaECircleIcon';
import AlphaECircleOutlineIcon from 'mdi-react/AlphaECircleOutlineIcon';
import ArrowRightIcon from 'mdi-react/ArrowRightIcon';
import ContentCopyIcon from 'mdi-react/ContentCopyIcon';
import FolderIcon from 'mdi-react/FolderIcon';
import GearIcon from 'mdi-react/GearIcon';
import HomeGroupIcon from 'mdi-react/HomeGroupIcon';
import HomeGroupRemoveIcon from 'mdi-react/HomeGroupRemoveIcon';
import HomeIcon from 'mdi-react/HomeIcon';
import HomeRemoveIcon from 'mdi-react/HomeRemoveIcon';
import LeadPencilIcon from 'mdi-react/LeadPencilIcon';
import PinIcon from 'mdi-react/PinIcon';
import PinOffIcon from 'mdi-react/PinOffIcon';
import StarIcon from 'mdi-react/StarIcon';
import StarRemoveIcon from 'mdi-react/StarRemoveIcon';
import TrashCanOutlineIcon from 'mdi-react/TrashCanOutlineIcon';

import PermissionModel from '@/models/Permission';
import RoutePaths from '@/models/RoutePaths';
import SettingCategories from '@/models/SettingCategories';
import { UIProperties } from '@/models/UIProperties';

import { selectors as customerSelectors } from '@/redux/api/customer';
import {
  actions as dashboardsActions,
  selectors as dashboardsSelectors,
  MAX_PINNED_DASHBOARDS,
} from '@/redux/api/dashboards';
import { selectors as portalSettingsSelectors } from '@/redux/api/portalSettings';
import { selectors as profileSelectors } from '@/redux/api/user/profile';

import Button, { ButtonVariants } from '+components/Button';
import { lang } from '+components/charts/common/utils';
import ConfirmModal from '+components/ConfirmModal';
import { Dropdown, DropdownItem } from '+components/Dropdown';
import { ActionsContainer } from '+components/Layout';
import Table from '+components/Table';
import { MenuColumnContextMenu } from '+components/Table/Columns';
import * as toast from '+components/toast';
import usePermissions from '+hooks/usePermissions';
import usePortalSettingsValue from '+hooks/usePortalSettingsValue';
import useUIProperty from '+hooks/useUIProperty';
import { pluralize } from '+utils';

import DashboardModeTypes from '../../../shared/DashboardModeTypes';
import { Columns, getColumns } from './components/Columns';
import GroupBulkManageForm from './components/GroupBulkManageForm';
import RenameGroupForm from './components/RenameGroupForm';

const defaultColumns = [
  Columns.rowSelector,
  Columns.title,
  Columns.description,
  Columns.author,
  Columns.groups,
  Columns.menu,
];

const additionalColumns = [
  Columns.useGlobalDateTime,
  Columns.nqlMode,
  Columns.lastseen,
];

const getCellProps = () => ({ style: { textAlign: 'center' } });

const getRowMeta = (row, options) => {
  const isUserHomepage = row?.id === options?.userHomepageDashboard;
  const isCompanyHomepage = row?.id === options?.companyHomepageDashboard;
  const isEventTemplate = row?.id === options?.eventTemplateDashboard;

  const isEditable = !row?.system || options?.isDefaultCustomer;
  const isDeletable =
    isEditable && !isUserHomepage && !isCompanyHomepage && !isEventTemplate;

  return {
    isUserHomepage,
    isCompanyHomepage,
    isEventTemplate,
    isEditable,
    isDeletable,
  };
};

const DashboardsTable = (props) => {
  const {
    id,
    dashboards,
    sortBy,
    groupBy,
    onAdd,
    onEdit,
    onDelete,
    onCopy,
    onManageGroups,
  } = props;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const permissions = usePermissions(PermissionModel.Resources.dashboard.value);
  const permissionsAccount = usePermissions(
    PermissionModel.Resources.account.value,
  );

  const [guest] = useUIProperty(UIProperties.guest);
  const customer = useSelector(customerSelectors.getCurrentCustomer);
  const profile = useSelector(profileSelectors.getProfile);
  const isDefaultCustomer = useSelector(profileSelectors.isDefaultCustomer);
  const isAllMetaFetched = useSelector(dashboardsSelectors.isAllMetaFetched);

  const [dashboardFavorites, setDashboardFavorites] = usePortalSettingsValue(
    SettingCategories.dashboard,
    'favorites',
    [],
  );
  const [userHomepageDashboard, setUserHomepageDashboard] =
    usePortalSettingsValue(
      SettingCategories.dashboard,
      'userHomepageDashboardId',
    );
  const [companyHomepageDashboard, setCompanyHomepageDashboard] =
    usePortalSettingsValue(
      SettingCategories.dashboard,
      'companyHomepageDashboardId',
    );
  const [eventTemplateDashboard, setEventTemplateDashboard] =
    usePortalSettingsValue(
      SettingCategories.dashboard,
      'eventTemplateDashboardId',
    );
  const [pinnedDashboards, setPinnedDashboards] = usePortalSettingsValue(
    SettingCategories.dashboard,
    'pinnedDashboards',
    [],
  );

  const companyHomepageDashboardSettingsMeta = useSelector(
    portalSettingsSelectors.getCategoryPropertySelector(
      SettingCategories.dashboard,
      'companyHomepageDashboardId',
    ),
  );
  const eventTemplateDashboardSettingsMeta = useSelector(
    portalSettingsSelectors.getCategoryPropertySelector(
      SettingCategories.dashboard,
      'eventTemplateDashboardId',
    ),
  );

  const [selected, setSelected] = useState([]);
  const [showRenameGroup, setShowRenameGroup] = useState(null);
  const [showBulkAddToGroup, setShowBulkAddToGroup] = useState(false);
  const [showBulkRemoveFromGroup, setShowBulkRemoveFromGroup] = useState(false);
  const [showBulkAddToFavorite, setShowBulkAddToFavorite] = useState(false);
  const [showBulkRemoveFromFavorite, setShowBulkRemoveFromFavorite] =
    useState(false);
  const [showBulkSetAsPrivate, setShowBulkSetAsPrivate] = useState(false);
  const [showBulkSetAsPublic, setShowBulkSetAsPublic] = useState(false);

  // As we have different bulk actions we can't define is row disabled for exact action or not.
  // We will check permissions inside action handler instead.
  // const getIsRowSelectorDisabled = useCallback(
  //   ({ original }) => !permissions?.update,
  //   [permissions],
  // );

  const onSelectedRowsChange = useCallback((selectedRowIds) => {
    setSelected((prev) => {
      const next = Object.entries(selectedRowIds || {})
        .map(([key, value]) => (value ? key : null))
        .filter(Boolean);

      if (!prev.length && !next.length) {
        return prev;
      }

      return next;
    });
  }, []);

  const onRenameGroupToggle = useCallback((original) => {
    const [groupByVal] = JSON.parse(original?.groupByVal || '[]');
    setShowRenameGroup((prevValue) =>
      !original || prevValue
        ? null
        : {
            rows: original.subRows.map((item) => item.original),
            prevGroupName: groupByVal,
            nextGroupName: groupByVal,
          },
    );
  }, []);

  const onRenameGroupConfirm = useCallback(
    ({ rows, prevGroupName, nextGroupName }) => {
      onRenameGroupToggle(null);
      if (!permissions?.update) {
        return;
      }
      if (prevGroupName === nextGroupName) {
        return;
      }
      const updateData = rows.reduce((acc, item) => {
        const rowMeta = getRowMeta(item, {
          userHomepageDashboard,
          companyHomepageDashboard,
          eventTemplateDashboard,
          isDefaultCustomer,
        });
        if (!rowMeta.isEditable) {
          return acc;
        }
        const groupsSet = new Set(item.groups || []);
        groupsSet.delete(prevGroupName);
        groupsSet.add(nextGroupName);
        acc.push({
          ...item,
          groups: [...groupsSet],
        });
        return acc;
      }, []);

      if (updateData.length) {
        dispatch(
          dashboardsActions.bulkUpdateDashboards({
            dashboards: updateData,
            successMessage: 'Dashboard collection renamed',
          }),
        );
      }
    },
    [
      permissions,
      userHomepageDashboard,
      companyHomepageDashboard,
      eventTemplateDashboard,
      isDefaultCustomer,
      onRenameGroupToggle,
    ],
  );

  const onBulkAddToGroup = useCallback(
    ({ group }) => {
      setShowBulkAddToGroup(false);
      if (!permissions?.update) {
        return;
      }
      const selectedSet = new Set(selected);
      const updateData = dashboards.reduce((acc, item) => {
        if (!selectedSet.has(item.id)) {
          return acc;
        }
        const groupsSet = new Set(item.groups || []);
        if (groupsSet.has(group)) {
          return acc;
        }
        const rowMeta = getRowMeta(item, {
          userHomepageDashboard,
          companyHomepageDashboard,
          eventTemplateDashboard,
          isDefaultCustomer,
        });
        if (!rowMeta.isEditable) {
          return acc;
        }
        groupsSet.add(group);
        acc.push({
          ...item,
          groups: [...groupsSet],
        });
        return acc;
      }, []);

      if (updateData.length) {
        dispatch(
          dashboardsActions.bulkUpdateDashboards({
            dashboards: updateData,
            successMessage: 'Dashboards added to collection',
          }),
        );
      }
    },
    [
      permissions,
      selected,
      dashboards,
      userHomepageDashboard,
      companyHomepageDashboard,
      eventTemplateDashboard,
      isDefaultCustomer,
    ],
  );

  const onBulkRemoveFromGroup = useCallback(
    ({ group }) => {
      setShowBulkRemoveFromGroup(false);
      if (!permissions?.update) {
        return;
      }
      const selectedSet = new Set(selected);
      const updateData = dashboards.reduce((acc, item) => {
        if (!selectedSet.has(item.id)) {
          return acc;
        }
        const groupsSet = new Set(item.groups || []);
        if (!groupsSet.has(group)) {
          return acc;
        }
        const rowMeta = getRowMeta(item, {
          userHomepageDashboard,
          companyHomepageDashboard,
          eventTemplateDashboard,
          isDefaultCustomer,
        });
        if (!rowMeta.isEditable) {
          return acc;
        }
        groupsSet.delete(group);
        acc.push({
          ...item,
          groups: [...groupsSet],
        });
        return acc;
      }, []);

      if (updateData.length) {
        dispatch(
          dashboardsActions.bulkUpdateDashboards({
            dashboards: updateData,
            successMessage: 'Dashboards removed from collection',
          }),
        );
      }
    },
    [
      permissions,
      selected,
      dashboards,
      userHomepageDashboard,
      companyHomepageDashboard,
      eventTemplateDashboard,
      isDefaultCustomer,
    ],
  );

  const onBulkAddToFavorite = useCallback(() => {
    setShowBulkAddToFavorite(false);
    const nextFavoritesSet = new Set([...dashboardFavorites, ...selected]);
    setDashboardFavorites([...nextFavoritesSet]);
    toast.success('Dashboards added to favorites');
  }, [dashboardFavorites, setDashboardFavorites, selected]);

  const onBulkRemoveFromFavorite = useCallback(() => {
    setShowBulkRemoveFromFavorite(false);
    const nextFavoritesSet = new Set(dashboardFavorites);
    selected.forEach((_id) => nextFavoritesSet.delete(_id));
    setDashboardFavorites([...nextFavoritesSet]);
    toast.success('Dashboards removed from favorites');
  }, [dashboardFavorites, setDashboardFavorites, selected]);

  const onBulkSetAsPrivate = useCallback(() => {
    setShowBulkSetAsPrivate(false);
    if (!permissions?.update) {
      return;
    }
    const selectedSet = new Set(selected);
    dashboards.forEach((item) => {
      if (!selectedSet.has(item.id)) {
        return;
      }
      if (!item.public) {
        return;
      }
      const rowMeta = getRowMeta(item, {
        userHomepageDashboard,
        companyHomepageDashboard,
        eventTemplateDashboard,
        isDefaultCustomer,
      });
      if (!rowMeta.isEditable) {
        return;
      }
      dispatch(
        dashboardsActions.updateDashboard({
          ...item,
          public: false,
          silent: true,
        }),
      );
    });
    toast.success('Dashboards set as private');
  }, [
    permissions,
    selected,
    dashboards,
    userHomepageDashboard,
    companyHomepageDashboard,
    eventTemplateDashboard,
    isDefaultCustomer,
  ]);

  const onBulkSetAsPublic = useCallback(() => {
    setShowBulkSetAsPublic(false);
    if (!permissions?.update) {
      return;
    }
    const selectedSet = new Set(selected);
    dashboards.forEach((item) => {
      if (!selectedSet.has(item.id)) {
        return;
      }
      if (item.public) {
        return;
      }
      const rowMeta = getRowMeta(item, {
        userHomepageDashboard,
        companyHomepageDashboard,
        eventTemplateDashboard,
        isDefaultCustomer,
      });
      if (!rowMeta.isEditable) {
        return;
      }
      dispatch(
        dashboardsActions.updateDashboard({
          ...item,
          public: true,
          silent: true,
        }),
      );
    });
    toast.success('Dashboards set as public');
  }, [
    permissions,
    selected,
    dashboards,
    userHomepageDashboard,
    companyHomepageDashboard,
    eventTemplateDashboard,
    isDefaultCustomer,
  ]);

  const onTogglePinned = useCallback(
    (_id) => {
      const nextPinnedDashboards = [...pinnedDashboards];
      const index = nextPinnedDashboards.findIndex(
        (item) => item.id === _id && item.customer === customer?.shortname,
      );
      if (index === -1) {
        nextPinnedDashboards.push({ id: _id, customer: customer?.shortname });
      } else {
        nextPinnedDashboards.splice(index, 1);
      }
      setPinnedDashboards(nextPinnedDashboards);
    },
    [pinnedDashboards, customer?.shortname],
  );

  const onToggleFavorite = useCallback(
    (_id) => {
      const nextFavoritesSet = new Set(dashboardFavorites);
      if (nextFavoritesSet.has(_id)) {
        nextFavoritesSet.delete(_id);
      } else {
        nextFavoritesSet.add(_id);
      }
      toast.success(
        nextFavoritesSet.has(_id)
          ? 'Dashboard added to favorites'
          : 'Dashboard removed from favorites',
      );
      setDashboardFavorites([...nextFavoritesSet]);
    },
    [dashboardFavorites, setDashboardFavorites],
  );

  const onToggleCompanyHomepage = useCallback(
    (_id) => {
      if (_id === companyHomepageDashboard) {
        toast.success('Dashboard unset as Company Homepage');
        setCompanyHomepageDashboard(null, true);
        return;
      }
      toast.success('Dashboard set as Company Homepage');
      setCompanyHomepageDashboard(_id, true);
    },
    [companyHomepageDashboard, setCompanyHomepageDashboard],
  );

  const onToggleUserHomepage = useCallback(
    (_id) => {
      if (_id === userHomepageDashboard) {
        toast.success('Dashboard unset as Your Homepage');
        setUserHomepageDashboard(null);
        return;
      }
      toast.success('Dashboard set as Your Homepage');
      setUserHomepageDashboard(_id);
    },
    [userHomepageDashboard, setUserHomepageDashboard],
  );

  const onToggleEventTemplate = useCallback(
    (_id) => {
      if (_id === eventTemplateDashboard) {
        toast.success('Dashboard unset as Event Template');
        setEventTemplateDashboard(null, true);
        return;
      }
      toast.success('Dashboard set as Event Template');
      setEventTemplateDashboard(_id, true);
    },
    [eventTemplateDashboard, setEventTemplateDashboard],
  );

  const isPinnedLimitReached = useMemo(() => {
    const localPinnedDashboards = dashboards.filter((item) => item.pinned);
    return localPinnedDashboards.length >= MAX_PINNED_DASHBOARDS;
  }, [dashboards]);

  const onTitleClick = useCallback((original) => {
    navigate(`${RoutePaths.dashboards}/${original.id}`);
  }, []);

  const cxActionMenu = useCallback(
    (_, original = {}) => {
      const rowMeta = getRowMeta(original, {
        userHomepageDashboard,
        companyHomepageDashboard,
        eventTemplateDashboard,
        isDefaultCustomer,
      });

      const items = [
        {
          icon: <ArrowRightIcon />,
          text: 'Open',
          onClick: () => navigate(`${RoutePaths.dashboards}/${original.id}`),
        },
        {
          icon: <LeadPencilIcon />,
          text: 'Edit',
          disabled: !permissions?.update || !rowMeta.isEditable,
          onClick: () =>
            navigate(
              `${RoutePaths.dashboards}/${original.id}?mode=${DashboardModeTypes.edit}`,
            ),
        },
        {
          icon: <GearIcon />,
          text: 'Settings',
          disabled: !permissions?.update || !rowMeta.isEditable,
          onClick: () => onEdit(original.id),
        },
        {
          icon: <ContentCopyIcon />,
          text: 'Copy',
          disabled: !permissions?.create,
          onClick: () => onCopy(original.id),
        },
        {
          icon: <TrashCanOutlineIcon />,
          text: 'Delete',
          disabled: !permissions?.delete || !rowMeta.isDeletable,
          onClick: () => onDelete(original.id),
        },
        { separator: true },
        {
          icon: <FolderIcon />,
          text: 'Manage Collections',
          disabled: !permissions?.update || !rowMeta.isEditable,
          onClick: () => onManageGroups(original),
        },
        { separator: true },
        {
          icon: original.pinned ? <PinOffIcon /> : <PinIcon />,
          text: original.pinned
            ? 'Unpin'
            : `Pin${isPinnedLimitReached ? ' (limit reached)' : ''}`,
          onClick: () => onTogglePinned(original.id),
          disabled: !original.pinned && isPinnedLimitReached,
        },
        {
          icon: original.favorite ? <StarRemoveIcon /> : <StarIcon />,
          text: original.favorite
            ? 'Remove from Favorites'
            : 'Add to Favorites',
          disabled: guest,
          onClick: () => onToggleFavorite(original.id),
        },
        {
          icon: rowMeta.isUserHomepage ? <HomeRemoveIcon /> : <HomeIcon />,
          text: rowMeta.isUserHomepage
            ? 'Unset as Your Homepage'
            : 'Set as Your Homepage',
          disabled: guest,
          onClick: () => onToggleUserHomepage(original.id),
        },
        {
          icon: rowMeta.isCompanyHomepage ? (
            <HomeGroupRemoveIcon />
          ) : (
            <HomeGroupIcon />
          ),
          text: rowMeta.isCompanyHomepage
            ? 'Unset as Company Homepage'
            : 'Set as Company Homepage',
          disabled:
            !permissionsAccount?.update ||
            !original.public ||
            (rowMeta.isCompanyHomepage &&
              companyHomepageDashboardSettingsMeta?.system &&
              !isDefaultCustomer),
          onClick: () => onToggleCompanyHomepage(original.id),
        },
        {
          icon: rowMeta.isEventTemplate ? (
            <AlphaECircleOutlineIcon />
          ) : (
            <AlphaECircleIcon />
          ),
          text: rowMeta.isEventTemplate
            ? 'Unset as Event Template'
            : 'Set as Event Template',
          disabled:
            !permissionsAccount?.update ||
            !original.public ||
            (rowMeta.isEventTemplate &&
              eventTemplateDashboardSettingsMeta?.system &&
              !isDefaultCustomer),
          onClick: () => onToggleEventTemplate(original.id),
        },
      ];

      return (
        <MenuColumnContextMenu
          title={original.title}
          items={items}
          dataTracking="dashboard"
        />
      );
    },
    [
      permissionsAccount,
      userHomepageDashboard,
      companyHomepageDashboard,
      eventTemplateDashboard,
      isDefaultCustomer,
      permissions,
      onEdit,
      onCopy,
      onDelete,
      onManageGroups,
      onToggleFavorite,
      onToggleUserHomepage,
      onToggleCompanyHomepage,
      onToggleEventTemplate,
      companyHomepageDashboardSettingsMeta,
      eventTemplateDashboardSettingsMeta,
      guest,
      isPinnedLimitReached,
      onTogglePinned,
    ],
  );

  const cxGroupActionMenu = useCallback(
    (original) => {
      if (original.groupByID !== Columns.groups) {
        return null;
      }

      const items = [
        {
          icon: <LeadPencilIcon />,
          text: 'Edit Collection',
          disabled: !permissions?.update,
          onClick: () => onRenameGroupToggle(original),
        },
      ];

      const [groupByVal] = JSON.parse(original?.groupByVal || '[]');
      return (
        <MenuColumnContextMenu
          title={groupByVal}
          items={items}
          dataTracking="dashboard"
        />
      );
    },
    [permissions, onRenameGroupToggle],
  );

  const columns = useMemo(
    () =>
      getColumns(defaultColumns, {
        profile,
        companyHomepageDashboard,
        userHomepageDashboard,
        eventTemplateDashboard,
        cxActionMenu,
        cxGroupActionMenu,
        onTitleClick,
      }),
    [
      profile,
      companyHomepageDashboard,
      userHomepageDashboard,
      eventTemplateDashboard,
      cxActionMenu,
      cxGroupActionMenu,
      onTitleClick,
    ],
  );

  const availableColumns = useMemo(
    () =>
      getColumns(additionalColumns, {
        profile,
        companyHomepageDashboard,
        userHomepageDashboard,
        eventTemplateDashboard,
        cxActionMenu,
        cxGroupActionMenu,
        onTitleClick,
      }),
    [
      profile,
      companyHomepageDashboard,
      userHomepageDashboard,
      eventTemplateDashboard,
      cxActionMenu,
      cxGroupActionMenu,
      onTitleClick,
    ],
  );

  return (
    <Fragment>
      <ActionsContainer>
        <Button onClick={onAdd} disabled={!permissions?.create}>
          Add Dashboard
        </Button>

        <Dropdown
          caption="Update Selected"
          variant={ButtonVariants.outlined}
          disabled={!selected.length}
        >
          <DropdownItem header>Collection</DropdownItem>
          <DropdownItem
            disabled={!permissions?.update}
            onClick={() => setShowBulkAddToGroup(true)}
            short
          >
            Add to Collection
          </DropdownItem>
          <DropdownItem
            disabled={!permissions?.update}
            onClick={() => setShowBulkRemoveFromGroup(true)}
            short
          >
            Remove from Collection
          </DropdownItem>

          <DropdownItem header>Favorites</DropdownItem>
          <DropdownItem
            disabled={guest}
            onClick={() => setShowBulkAddToFavorite(true)}
            short
          >
            Add to Favorites
          </DropdownItem>
          <DropdownItem
            disabled={guest}
            onClick={() => setShowBulkRemoveFromFavorite(true)}
            short
          >
            Remove from Favorites
          </DropdownItem>

          <DropdownItem header>Visibility</DropdownItem>
          <DropdownItem
            disabled={!permissions?.update}
            onClick={() => setShowBulkSetAsPrivate(true)}
            short
          >
            Set as Private
          </DropdownItem>
          <DropdownItem
            disabled={!permissions?.update}
            onClick={() => setShowBulkSetAsPublic(true)}
            short
          >
            Set as Public
          </DropdownItem>
        </Dropdown>
      </ActionsContainer>

      <Table
        id={id}
        columns={columns}
        availableColumns={availableColumns}
        data={dashboards}
        sortBy={sortBy}
        groupBy={groupBy}
        getCellProps={getCellProps}
        noDataText={isAllMetaFetched ? undefined : lang.loading}
        onSelectedRowsChange={onSelectedRowsChange}
      />

      {!!showRenameGroup && (
        <RenameGroupForm
          initialValues={showRenameGroup}
          onConfirm={onRenameGroupConfirm}
          onToggle={() => setShowRenameGroup(null)}
          isOpen={!!showRenameGroup}
        />
      )}

      {showBulkAddToGroup && (
        <GroupBulkManageForm
          confirmButtonText="add"
          onConfirm={onBulkAddToGroup}
          onToggle={() => setShowBulkAddToGroup(false)}
          isOpen={showBulkAddToGroup}
        />
      )}

      {showBulkRemoveFromGroup && (
        <GroupBulkManageForm
          confirmButtonText="remove"
          onConfirm={onBulkRemoveFromGroup}
          onToggle={() => setShowBulkRemoveFromGroup(false)}
          isOpen={showBulkRemoveFromGroup}
        />
      )}

      {showBulkAddToFavorite && (
        <ConfirmModal
          item={`${selected.length} ${pluralize(
            selected.length,
            'dashboard',
          )} to favorites`}
          titleTemplate="Add Dashboards to Favorites"
          confirmButtonText="add"
          confirmButtonColor="primary"
          whyAsking=""
          onConfirm={onBulkAddToFavorite}
          onToggle={() => setShowBulkAddToFavorite(false)}
          isOpen={showBulkAddToFavorite}
        />
      )}

      {showBulkRemoveFromFavorite && (
        <ConfirmModal
          item={`${selected.length} ${pluralize(
            selected.length,
            'dashboard',
          )} from favorites`}
          titleTemplate="Remove Dashboards from Favorites"
          confirmButtonText="remove"
          confirmButtonColor="primary"
          whyAsking=""
          onConfirm={onBulkRemoveFromFavorite}
          onToggle={() => setShowBulkRemoveFromFavorite(false)}
          isOpen={showBulkRemoveFromFavorite}
        />
      )}

      {showBulkSetAsPrivate && (
        <ConfirmModal
          item={`${selected.length} ${pluralize(
            selected.length,
            'dashboard',
          )} as private`}
          titleTemplate="Set Dashboards as Private"
          confirmButtonText="set as private"
          confirmButtonColor="primary"
          whyAsking=""
          onConfirm={onBulkSetAsPrivate}
          onToggle={() => setShowBulkSetAsPrivate(false)}
          isOpen={showBulkSetAsPrivate}
        />
      )}

      {showBulkSetAsPublic && (
        <ConfirmModal
          item={`${selected.length} ${pluralize(
            selected.length,
            'dashboard',
          )} as public`}
          titleTemplate="Set Dashboards as Public"
          confirmButtonText="set as public"
          confirmButtonColor="primary"
          whyAsking=""
          onConfirm={onBulkSetAsPublic}
          onToggle={() => setShowBulkSetAsPublic(false)}
          isOpen={showBulkSetAsPublic}
        />
      )}
    </Fragment>
  );
};

DashboardsTable.propTypes = {
  id: PropTypes.string.isRequired,
  dashboards: PropTypes.arrayOf(PropTypes.shape({})),
  sortBy: PropTypes.arrayOf(PropTypes.shape({})),
  groupBy: PropTypes.arrayOf(PropTypes.string),
  onAdd: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onCopy: PropTypes.func.isRequired,
  onManageGroups: PropTypes.func.isRequired,
};

DashboardsTable.defaultProps = {
  dashboards: [],
  sortBy: undefined,
  groupBy: undefined,
};

export default DashboardsTable;
