import { Fragment, useCallback, useMemo, useState } from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { MitreChart } from '+components/charts/MitreChart';
import { mirteHeatmapColors, useMitreTactics } from '+hooks/mitreHooks';

import MitreTable from './components/MitreTable';

const Title = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-weight: 600;
  padding-bottom: 8px;
`;

const HeatmapLegend = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const HeatmapScale = styled.div`
  background: linear-gradient(
    90deg,
    ${mirteHeatmapColors[0]} 0%,
    ${mirteHeatmapColors[4]} 100%
  );
  border-radius: 6px;
  height: 8px;
  width: 115px;
  margin: 0 10px;
`;

const EventsMITRE = (props) => {
  const { userFilters } = props;
  const [, tactics, tacticsAndEvents] = useMitreTactics(userFilters, true);
  const [selectedTechnique, setSelectedTechnique] = useState();
  const [selectedTechniqueLabel, setSelectedTechniqueLabel] = useState();

  const onTechniqueClick = useCallback((technique) => {
    setSelectedTechnique((prev) =>
      prev && prev === technique.technique_id ? '' : technique.technique_id,
    );
    setSelectedTechniqueLabel(technique.technique_name);
  }, []);

  const filteredTacticsAndEvents = useMemo(() => {
    return tacticsAndEvents.flatMap((tactic) => {
      if (
        selectedTechnique === 'non-mitre' &&
        tactic.tactic_name === 'Non MITRE'
      ) {
        if (tactic.tactic_name === 'Non MITRE') {
          return tactic.techniques[0].events;
        }
      }

      return (
        tactic.techniques.find(
          (technique) => technique.technique_id === selectedTechnique,
        )?.events || []
      );
    });
  }, [tacticsAndEvents, selectedTechnique]);

  const [minEventCount, maxEventCount] = useMemo(() => {
    const [minCount, maxCount] = tacticsAndEvents.reduce(
      ([min, max], tactic) => {
        const tacticEventCount = tactic.techniques.reduce(
          (sum, technique) => sum + technique.events.length,
          0,
        );
        return tacticEventCount > 0
          ? [Math.min(min, tacticEventCount), Math.max(max, tacticEventCount)]
          : [min, max];
      },
      [Infinity, 0],
    );
    return minCount === maxCount ? [0, maxCount] : [minCount, maxCount];
  }, [tacticsAndEvents]);

  return (
    <Fragment>
      <Title>
        <div>Events by MITRE ATT&CK®</div>
        {minEventCount !== Infinity && (
          <HeatmapLegend>
            {minEventCount}
            <HeatmapScale />
            {maxEventCount}
          </HeatmapLegend>
        )}
      </Title>
      <MitreChart
        tactics={tactics}
        tacticsAndEvents={tacticsAndEvents}
        onTechniqueClick={onTechniqueClick}
        selectedTechniques={selectedTechnique ? [selectedTechnique] : []}
        minEventCount={minEventCount}
        maxEventCount={maxEventCount}
      />
      <MitreTable
        tacticsAndEvents={
          selectedTechnique ? filteredTacticsAndEvents : tacticsAndEvents
        }
        selectedTechnique={selectedTechnique}
        selectedTechniqueLabel={selectedTechniqueLabel}
        setSelectedTechnique={setSelectedTechnique}
      />
    </Fragment>
  );
};

EventsMITRE.propTypes = {
  userFilters: PropTypes.shape({
    start: PropTypes.number,
    end: PropTypes.number,
    algorithm: PropTypes.string,
    ip: PropTypes.string,
  }),
};

EventsMITRE.defaultProps = {
  userFilters: {},
};

export default EventsMITRE;
