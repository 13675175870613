import PropTypes from '+prop-types';
import { memo, useCallback, useRef, useState } from 'react';

import { ContextTypes } from '@/models/ContextTypes';
import { CustomType } from '@/models/CustomType';
import RoutePaths from '@/models/RoutePaths';

import Button, { ButtonVariants } from '+components/Button';
import IpExplorerOrigin from '+components/IpExplorer';
import { Col, LayoutTypes, Row } from '+components/Layout';
import { usePageTabs } from '+components/PageTabs';
import { useOnScreen } from '+hooks';
import useIpExplorerResolver from '+hooks/useIpExplorerResolver';
import { getFlowDataFromRecord } from '+utils/getFlowDataFromRecord';
import { getSearchUrl } from '+utils/getSearchUrl';
import makeArr from '+utils/makeArr';

export const IpExplorer = memo((props) => {
  const { nodesFunction, particlesFunction } = useIpExplorerResolver();

  const { event, isSubAccountRecord } = props;
  const containerRef = useRef(null);
  const [ref, setRef] = useState(null);
  containerRef.current = ref;

  const isOnScreen = useOnScreen(containerRef);

  const [, , pageTabMethods] = usePageTabs();

  const eventContext = event?.traffictype || ContextTypes.flow;

  const onOpen = useCallback(() => {
    const { nql, from, to } = getFlowDataFromRecord({
      record: event,
      type: ContextTypes.alerts,
    });

    const nqlAsArr = makeArr(nql);

    let url = getSearchUrl({
      context: eventContext,
      period: {
        type: CustomType,
      },
      from,
      to,
      autoRefresh: false,
      nql: eventContext === ContextTypes.dns ? nqlAsArr[0] : nqlAsArr,
      customer: isSubAccountRecord ? event?.customer : undefined,
    });

    const [, search] = url.split('?');

    url = `${RoutePaths.ipExplorer}?${search}`;

    pageTabMethods.add(url);
  }, [event, eventContext, isSubAccountRecord]);

  return (
    <Row item $type={LayoutTypes.card} ref={setRef}>
      <Col item>
        <Row
          $type={LayoutTypes.titleLarge}
          justifyContent="space-between"
          alignItems="center"
        >
          IP Explorer
          <Button
            variant={ButtonVariants.outlined}
            onClick={onOpen}
            size="medium"
          >
            Full View
          </Button>
        </Row>

        <Row item $type={LayoutTypes.cardContent} height={350} pt="8px">
          {isOnScreen && (
            <IpExplorerOrigin
              nodesFunction={nodesFunction}
              particlesFunction={particlesFunction}
              onlyInternal={false}
              transparent
            />
          )}
        </Row>
      </Col>
    </Row>
  );
});

IpExplorer.displayName = 'IpExplorer';

IpExplorer.propTypes = {
  event: PropTypes.object,
  isSubAccountRecord: PropTypes.bool,
};

IpExplorer.defaultProps = {
  event: undefined,
  isSubAccountRecord: false,
};
