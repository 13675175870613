import { put, select } from 'redux-saga/effects';

import { createSelector, createSlice } from '@/redux/util';

import {
  actions as filtersActions,
  selectors as filtersSelectors,
} from './index';

const initialState = {
  stack: [],
};

const slice = createSlice({
  name: 'dateTimeStack',
  initialState,

  reducers: {
    push() {},
    pushApply(state, { payload: { item, keepOnlyFirst } }) {
      if (!keepOnlyFirst || !state.stack.length) {
        state.stack.push(item);
      }
    },

    pop() {},
    popApply(state) {
      state.stack.pop();
    },

    clear(state) {
      state.stack = [];
    },
  },

  sagas: (actions, { getState }) => ({
    [actions.push]: {
      *saga({ payload: { item, keepOnlyFirst } }) {
        const filters = yield select(filtersSelectors.getFilters);

        const prev = {
          period: {
            ...filters.period,
          },
          from: filters.from,
          to: filters.to,
          startIsMin: filters.startIsMin,
          endIsNow: filters.endIsNow,
        };

        yield put(actions.pushApply({ item: prev, keepOnlyFirst }));
        yield put(
          filtersActions.changeFilter({
            ...item,
            startIsMin: false,
            endIsNow: false,
          }),
        );
      },
    },

    [actions.pop]: {
      *saga() {
        const { stack } = yield select(getState);

        const [item] = stack.slice(-1);
        if (item) {
          yield put(filtersActions.changeFilter(item));
        }
        yield put(actions.popApply());
      },
    },
  }),

  selectors: (getState) => ({
    getStack: createSelector([getState], (state) => state.stack),
  }),
});

export const { selectors, actions } = slice;

export default slice;
