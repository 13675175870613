/* eslint-disable prefer-destructuring */
import PropTypes from '+prop-types';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import { useSelector } from 'react-redux';

import throttle from 'lodash.throttle';
import { LRUMap } from 'lru_map';

import * as PropertiesTray from '@/models/PropertiesTray';
import { UIProperties } from '@/models/UIProperties';

import { selectors as socketControlSelectors } from '@/redux/ui/socketControl';

import { labelWithDataRenderer } from '+components/charts/common/formatters';
import TreeGraphChart, {
  nodeFormatter,
} from '+components/charts/TreeGraphChart';
import { usePageTabs } from '+components/PageTabs';
import useGlobalFilters from '+hooks/useGlobalFilters';
import useUIProperty from '+hooks/useUIProperty';
import AccumulatorTree from '+utils/AccumulatorTree';
import {
  formatBits,
  formatPackets,
  getPortCategory,
  getPortCategoryName,
  PortCategory,
} from '+utils/format';
import { makeId } from '+utils/general';
import nqlLang from '+utils/nqlLang';

import Tooltip from './Tooltip';
import { getColorIndex, getSecurity, SECURITY, securityMax } from './util';

const allowedProtocols = ['tcp', 'udp'];
const pathTypes = ['root', 'dstip', 'protocol', 'category', 'dstport'];
const throttleTime = 2e3;

// prettier-ignore
const recordPath = ({ dstip, protocol, dstport }) => [
  'rootNode',
  dstip,
  protocol,
  getPortCategoryName(dstport),
  dstport,
].map(String);

export const includeFields = [
  'id',
  'dstip',
  'dstport',
  'protocol',
  'dstas.org',
  'dstas.number',
  'bits',
  'packets',
  'tags',
  'label',
  'timestamp',
];

export const ResolveOptions = [
  { value: 'dstip', label: 'IP' },
  { value: 'as', label: 'AS' },
  // { value: 'asn', label: 'ASN' },
  // { value: 'asorg', label: 'AS Org' },
  { value: 'tags', label: 'Tags' },
];

const srtInTrue = nqlLang.equal('srcinternal', true);
const srtInFalse = nqlLang.equal('srcinternal', false);
const dstInTrue = nqlLang.equal('dstinternal', true);
const dstInFalse = nqlLang.equal('dstinternal', false);

export const directionOptions = [
  {
    label: 'External → Internal',
    value: 'right',
    nql: nqlLang.and(srtInFalse, dstInTrue),
  },
  {
    label: 'Internal → External',
    value: 'left',
    nql: nqlLang.and(srtInTrue, dstInFalse),
  },
];

const mergeRecord = (node, item, depth) => {
  const sec = getSecurity(`${item.dstport}:${item.protocol}`);

  if (!node.id) {
    node.id = makeId();
    node.value = 0;
    node.bits = 0;
    node.packets = 0;
    node.insecure = sec;
    node.portCategory = getPortCategory(item.dstport);
    node.colorIndex = getColorIndex(sec);
    node.name = String(node.relPath);
    node.depth = depth;
    node.type = pathTypes[depth];
    node.customer = item.customer;
  }

  const dstIpLabels = {};

  Object.entries(item.label?.ip || {}).forEach(([context, directionsObj]) => {
    Object.entries(directionsObj).forEach(([direction, labels]) => {
      if (direction === 'dst') {
        dstIpLabels[context] = labels;
      }
    });
  });

  const dstPortLabels = {};

  Object.entries(item.label?.port || {}).forEach(([context, directionsObj]) => {
    Object.entries(directionsObj).forEach(([direction, labels]) => {
      if (direction === 'dst') {
        dstPortLabels[context] = labels;
      }
    });
  });

  if (node.type !== 'root') {
    node.dstip = item.dstip;
    node.dstport = item.dstport;
    node.ipLabels = dstIpLabels;
    node.portLabels = dstPortLabels;
  }

  if (node.type === 'dstport' && !node.protocol) {
    node.protocol = item.protocol;
  }

  if (node.type === 'dstip') {
    node.asorg = item.dstas.org;
    node.asn = item.dstas.number;
    node.tags = (item.tags || []).slice(0, 1).join(', ');
  }

  node.value += 1;
  node.bits += item.bits;
  node.packets += item.packets;

  const newSec = securityMax(node.insecure, sec);
  node.insecure = newSec;
  node.colorIndex = getColorIndex(newSec);
};

const unmergeRecord = (node, item) => {
  node.value -= 1;
  node.bits -= item.bits;
  node.packets -= item.packets;

  // Determine new security of parent in case all children change security
  const newSec = node
    .children()
    .reduce((prev, child) => securityMax(prev, child.insecure), SECURITY.ok);

  node.insecure = newSec;
  node.colorIndex = getColorIndex(newSec);
};

const childrenGetter = (node) => {
  if (!node?.children) {
    return null;
  }

  return node.children();
};

const collapsedCheck = (node) => {
  if (node.depth !== 3) {
    return false;
  }

  const children = childrenGetter(node);
  const hasSecureChildren = children?.every(
    (child) => child.insecure === SECURITY.ok,
  );
  return (
    (hasSecureChildren && children?.length > 1) ||
    node.portCategory === PortCategory.ephemeral
  );
};

const makeInfo = (data, showName, labelContext) => ({
  value: String(data.value),
  packets: formatPackets(data.packets, 0),
  bandwidth: formatBits(data.bits),
  dstip: data.dstip,
  ipLabels: data.ipLabels[labelContext.ip],
  asn: data.asn,
  asorg: data.asorg,
  name: showName ? data.name : null,
  customer: data.customer,
});

const AttackSurface = (props) => {
  const {
    ipResolveBy,
    maxLeaves,
    loading,
    direction,
    data: records,
    reinit,
    width,
    height,
    className,
    ...tail
  } = props;

  const [, setPropertiesTray] = useUIProperty(
    UIProperties.propertiesTray,
    null,
  );

  const [filters] = useGlobalFilters();

  const [, activePageTab] = usePageTabs();
  const isSocketPaused = useSelector(
    socketControlSelectors.isPaused(activePageTab?.id),
  );

  const lastRecord = useRef(new LRUMap(2000));
  const handleRecord = useRef();

  const treeData = useRef();
  const [root, setRoot] = useState();
  const lru = useRef();
  const insecLRU = useRef();

  const throttleSetRoot = useMemo(() => throttle(setRoot, throttleTime), []);

  const refreshRoot = useCallback(() => {
    if (treeData.current?.root) {
      treeData.current.root = { ...treeData.current.root };
    }
    throttleSetRoot(treeData.current?.root);
  }, []);

  const merger = useCallback((acc, r, depth) => {
    mergeRecord(acc, r, depth);
  }, []);

  const startFetch = useCallback(() => {
    treeData.current = new AccumulatorTree();
    treeData.current.unmerger = unmergeRecord;
    treeData.current.merger = merger;

    lru.current = new LRUMap(51);
    insecLRU.current = new LRUMap(51);
    lastRecord.current.clear();
  }, []);

  const labelContext = useCallback(
    (data) => {
      if (data.type === 'dstip') {
        if (ipResolveBy === 'dstip') {
          return filters.labelContext.show ? filters.labelContext.ip : null;
        }

        if (['as', 'asn', 'asorg'].includes(ipResolveBy)) {
          return data.asn;
        }
      }

      if (data.type === 'dstport') {
        return filters.labelContext.show ? data.dstport : null;
      }

      return null;
    },
    [filters.labelContext, ipResolveBy],
  );

  const label = useCallback(
    (data) => {
      if (data.type === 'dstip') {
        if (ipResolveBy === 'dstip') {
          if (!filters.labelContext.show) {
            return null;
          }

          const _labelContext = filters.labelContext.ip;
          return data.ipLabels?.[_labelContext];
        }

        if (['as', 'asn', 'asorg'].includes(ipResolveBy)) {
          return [data.asorg].filter(Boolean);
        }
      }

      if (data.type === 'dstport') {
        if (!filters.labelContext.show) {
          return null;
        }

        const _labelContext = filters.labelContext.port;
        return data.portLabels?.[_labelContext];
      }

      return null;
    },
    [filters.labelContext, ipResolveBy],
  );

  const dataLabelFormatter = useCallback(
    (data, toHtml = true) => {
      let _name = data.name;

      if (_name === 'rootNode') {
        _name = directionOptions[0].label.split(' ')[0];
        if (direction === 'left') {
          _name = directionOptions[1].label.split(' ')[0];
        }

        return labelWithDataRenderer({
          data: _name,
        });
      }

      if (data.type === 'dstip') {
        if (ipResolveBy === 'dstip') {
          _name = data.dstip;
        }
        if (['as', 'asn', 'asorg'].includes(ipResolveBy)) {
          _name = data.asorg;
        }
      }

      if (data.type === 'dstport') {
        _name = data.dstport;
      }

      const html = labelWithDataRenderer({
        data: _name,
        labelsContext: labelContext(data),
        labels: label(data),
        renderAsGroup: toHtml,
      });

      return `
        <span style="cursor: pointer">
          ${html}
        </span>
      `;
    },
    [direction, ipResolveBy, labelContext],
  );

  const tooltipFormatter = useMemo(
    () =>
      function () {
        const { point } = this;

        if (point.isLink) {
          return `
          <div style="display: flex; flex-wrap: nowrap; align-items: center; gap: 5px">
            ${nodeFormatter(point.fromNode, dataLabelFormatter)}
            <span style="font-size: 14px; margin-top: -3px;">→</span>
            ${nodeFormatter(point.toNode, dataLabelFormatter)}
          </div>
        `;
        }

        if (point.name === 'rootNode') {
          return `
          <div style="display: flex; flex-wrap: nowrap; align-items: center; gap: 5px">
            ${nodeFormatter(point, dataLabelFormatter)}
          </div>
        `;
        }

        const tooltipProps = makeInfo(point, true, filters.labelContext);

        tooltipProps.name = (
          <div
            style={{ display: 'flex', alignItems: 'center', gap: 5 }}
            dangerouslySetInnerHTML={{
              __html: nodeFormatter(point, dataLabelFormatter),
            }}
          />
        );

        return renderToStaticMarkup(<Tooltip {...tooltipProps} />);
      },
    [dataLabelFormatter, filters.labelContext],
  );

  const onClickPoint = useMemo(
    () =>
      function () {
        const data = this;

        if (data.name === 'rootNode') {
          return;
        }

        const field = data.type === 'category' ? null : data.type;
        const value = data.relPath;
        const tooltipProps = makeInfo(data, false, filters.labelContext);
        const info = <Tooltip {...tooltipProps} />;

        setPropertiesTray({
          data: [
            {
              dataType: PropertiesTray.DataTypes.field,
              field,
              value,
              info,
              customer: data.customer,
            },
          ],
          isOpen: true,
        });
      },
    [filters.labelContext],
  );

  useEffect(() => {
    if (treeData.current) {
      treeData.current.merger = merger;
    }
  }, [treeData.current, merger]);

  useEffect(() => {
    if (!treeData.current?.root) {
      return;
    }

    treeData.current.root.children().forEach((child) => {
      child.name = child[ipResolveBy] || child.relPath;
      child.showLabel = ipResolveBy === 'dstip';
    });

    refreshRoot();
  }, [ipResolveBy]);

  useEffect(() => {
    handleRecord.current = (record) => {
      if (!allowedProtocols.includes(record.protocol)) {
        return;
      }

      const tree = treeData.current;
      const path = recordPath(record);

      tree.insert(path, record);

      const pathStr = path.join('|');
      const security = getSecurity(`${record.dstport}:${record.protocol}`);

      const insecLimit = Math.round(maxLeaves);
      // const okLimit = Math.round(this.props.maxLeaves * 0.2);
      if (security === SECURITY.critical || security === SECURITY.warning) {
        insecLRU.current.set(pathStr, pathStr);
      } else {
        lru.current.set(pathStr, pathStr);
      }

      const overFilled = lru.current.size + insecLRU.current.size > maxLeaves;
      if (overFilled) {
        // if insec over limit then take from insec
        let removePath;

        if (insecLRU.current.size > insecLimit) {
          removePath = insecLRU.current.shift()[0];
        } else {
          removePath = lru.current.shift()[0];
        }

        tree.remove(removePath.split('|'));
      }
    };

    if (!treeData.current) {
      return;
    }

    if (maxLeaves < lru.current.size + insecLRU.current.size) {
      const insecLimit = Math.round(maxLeaves * 1);

      // Trim LRU and tree if maxLeaves smaller than before
      while (maxLeaves < lru.current.size + insecLRU.current.size) {
        let removePath;

        if (insecLRU.current.size > insecLimit) {
          removePath = insecLRU.current.shift()[0];
        } else {
          removePath = lru.current.shift()[0];
        }

        treeData.current.remove(removePath.split('|'));
      }

      refreshRoot();
    }
  }, [maxLeaves]);

  const lastState = useRef();
  const wasPaused = useRef(isSocketPaused);
  useEffect(() => {
    if (lastState.current !== reinit && wasPaused.current === isSocketPaused) {
      lastState.current = reinit;
      startFetch();
    }
    refreshRoot();
    wasPaused.current = isSocketPaused;
  }, [reinit]);

  useEffect(() => {
    if (!records?.length) {
      return;
    }

    const items = (records || []).filter((item) => {
      if (!item || lastRecord.current.has(item.id)) {
        return false;
      }

      lastRecord.current.set(item.id, true);

      return true;
    });

    if (!items.length) {
      return;
    }

    items.forEach(handleRecord.current);
    refreshRoot();
  }, [records]);

  useEffect(() => {
    if (isSocketPaused && treeData.current?.root) {
      treeData.current.root = { ...treeData.current.root };
      setRoot(treeData.current.root);
    }
  }, [isSocketPaused]);

  useEffect(
    () => () => {
      throttleSetRoot.cancel();
    },
    [],
  );

  const data = useMemo(() => {
    const queue = [root];
    let parent = root ? { ...root } : null;

    const result = [];

    if (parent) {
      delete parent.children;
      delete parent.childrenMap;
      result.push(parent);
    }

    parent = queue.pop();
    let children;
    while (parent) {
      children = parent.children();
      if (children.length) {
        queue.push(...children);
        // eslint-disable-next-line no-loop-func
        children.forEach((item) => {
          const row = {
            ...item,
            parent: parent?.id,
            collapsedState: collapsedCheck(item),
          };

          delete row.children;
          delete row.childrenMap;

          result.push(row);
        });
      }
      parent = queue.pop();
    }

    return result;
  }, [root]);

  return (
    <TreeGraphChart
      {...tail}
      className={className}
      height={height}
      width={width}
      data={data}
      loading={loading}
      onClickPoint={onClickPoint}
      tooltipFormatter={tooltipFormatter}
      dataLabelFormatter={dataLabelFormatter}
    />
  );
};

AttackSurface.propTypes = {
  maxLeaves: PropTypes.number,
  ipResolveBy: PropTypes.oneOf(ResolveOptions.map((item) => item.value)),
  loading: PropTypes.bool,
  direction: PropTypes.oneOf(['left', 'right']),
  data: PropTypes.arrayOf(PropTypes.shape()),
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  reinit: PropTypes.number,
  className: PropTypes.string,
};

AttackSurface.defaultProps = {
  maxLeaves: 25,
  ipResolveBy: ResolveOptions[0].value,
  loading: false,
  direction: 'right',
  data: null,
  reinit: 0,
  className: null,
};

export default AttackSurface;
