import PropTypes from '+prop-types';
import { useCallback, useContext, useEffect, useMemo } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import { getFieldType } from '@/models/FieldTypes';
import { DataTypes } from '@/models/PropertiesTray';
import { UIProperties } from '@/models/UIProperties';

import {
  Menu,
  Item as MenuItem,
  useMenuActions,
  withMenu,
} from '+components/Menu';
import * as toast from '+components/toast';
import useUIProperty from '+hooks/useUIProperty';
import getNqlByFieldName from '+utils/getNqlByFieldName';
import { getStreamNql } from '+utils/getStreamNql';

import MenuWrapper, { Context } from '../MenuWrapper';

const byDisabled = (option) => !option.disabled;

const Item = (props) => {
  const { option } = props;

  const onCopy = useCallback(() => {
    toast.success('Copied!');
  }, []);

  const element = (
    <MenuItem onClick={option.onClick} data-tracking={option.tracking}>
      {option.icon}
      {option.text}
    </MenuItem>
  );

  if (!option.copyToClipboard) {
    return element;
  }

  return (
    <CopyToClipboard text={option.copyToClipboard} onCopy={onCopy}>
      {element}
    </CopyToClipboard>
  );
};

Item.propTypes = {
  option: PropTypes.object.isRequired,
};

const MenuRenderer = () => {
  const { menuOptions } = useContext(Context);

  return (
    <Menu>
      {menuOptions.filter(byDisabled).map((option, index) => (
        <Item
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          option={option}
        />
      ))}
    </Menu>
  );
};

const GlobalContextMenu = () => {
  const [globalContextMenu] = useUIProperty(
    UIProperties.globalContextMenu,
    null,
  );
  const menuActions = useMenuActions();

  const activeData = useMemo(() => {
    const data = globalContextMenu?.data?.[0];
    if (!data) {
      return null;
    }

    if (data.dataType === DataTypes.field) {
      return {
        streamNql: getStreamNql(data?.stream),
        ...getNqlByFieldName({
          context: data?.context,
          field: data?.field,
          value: data?.value,
        }),
        fieldType: getFieldType(data.field),
        ...data,
      };
    }

    return data;
  }, [globalContextMenu?.data]);

  useEffect(() => {
    if (globalContextMenu?.event) {
      menuActions?.showMenu?.(globalContextMenu?.event);
    }
  }, [globalContextMenu?.event]);

  return (
    <MenuWrapper data={activeData}>
      <MenuRenderer />
    </MenuWrapper>
  );
};

export default withMenu(GlobalContextMenu);
