import PropTypes from '+prop-types';
import { useMemo } from 'react';

import RealtimeManager from '+components/RealtimeManager';
import Table from '+components/Table';
import useGlobalFilters from '+hooks/useGlobalFilters';

import { getColumns } from './trafficTableColumns';

const TrafficTopTrafficTable = (props) => {
  const { field, data, noDataText, sortBy, ...rest } = props;
  const [filters] = useGlobalFilters();

  const tableColumns = useMemo(
    () =>
      !field
        ? []
        : getColumns({
            field,
            labelContext: filters.labelContext,
          }),
    [field, filters.labelContext],
  );

  return (
    <Table
      id="TrafficTopTraffic_Table"
      minRows={20}
      data={data || []}
      noDataText={noDataText}
      columns={tableColumns}
      sortBy={sortBy}
      fillWithEmptyRows
      {...rest}
    />
  );
};

TrafficTopTrafficTable.propTypes = {
  field: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.shape({})),
  noDataText: PropTypes.string,
  sortBy: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      desc: PropTypes.bool,
    }),
  ),
  externalFilters: PropTypes.arrayOf(PropTypes.shape()),
};

TrafficTopTrafficTable.defaultProps = {
  field: null,
  data: [],
  noDataText: 'No Traffic received',
  sortBy: [{ id: 'agg_count', desc: true }],
  externalFilters: null,
};

export default RealtimeManager(TrafficTopTrafficTable, {
  throttle: {
    wait: 300,
    props: [
      'field',
      'data',
      'noDataText',
      'sortBy',
      'externalFilters',
      'onClearExternalFilters',
    ],
  },
});
