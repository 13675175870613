import { useMemo } from 'react';

import { useTrackByFields } from '+hooks/useTrackByFields';

export const useTrackByOptions = (context, { addGlobal, onlyFields }) => {
  const fields = useTrackByFields({ context, fetch: true });

  return useMemo(() => {
    const result = (fields || [])
      .filter(({ type }) => type !== 'Object')
      .map(({ field, description }) =>
        onlyFields
          ? field
          : {
              value: field,
              label: field,
              description,
            },
      );

    if (addGlobal) {
      result.unshift(
        onlyFields
          ? 'global'
          : {
              value: 'global',
              label: 'global',
              description: 'No unique track by aggregation',
            },
      );
    }

    return result;
  }, [fields]);
};
