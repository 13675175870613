import PropTypes from '+prop-types';

import styled, { css } from 'styled-components';

import { ButtonVariants } from '+components/Button';
import childrenMap from '+utils/childrenMap';

const text = css`
  .MuiButtonBase-root {
    border-bottom: unset;
    border-top: unset;
    border-left: unset !important;
    border-width: var(--borderWidth) !important;

    &:last-of-type {
      border-right: unset !important;
    }

    &:not(:first-of-type) {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }

    &:not(:last-of-type) {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
`;

const contained = css`
  .MuiButtonBase-root {
    border-width: var(--borderWidth) !important;

    &:not(:first-of-type) {
      border-left: 0;
    }

    &:not(:first-of-type) {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }

    &:not(:last-of-type) {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
`;

const outlined = css`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;

  border-width: var(--borderWidth);
  border-style: solid;
  border-color: ${({ theme }) => theme.buttonPrimaryBorder};
  border-radius: 18px;

  .MuiButtonBase-root {
    border: unset;
    &:first-of-type {
      margin-left: -2px;
    }
    &:last-of-type {
      margin-right: -2px;
    }
  }
`;

const small = css`
  --borderWidth: 1px;
  height: 16px;
  max-height: 16px;
`;

const medium = css`
  --borderWidth: 2px;
  height: 32px;
  max-height: 32px;
`;

const Container = styled.div`
  display: inline-flex;
  width: fit-content !important;

  ${({ size }) => size === 'small' && small}
  ${({ size }) => size === 'medium' && medium}
  
  ${({ variant }) => variant === ButtonVariants.text && text}
  ${({ variant }) => variant === ButtonVariants.contained && contained}
  ${({ variant }) => variant === ButtonVariants.outlined && outlined}

  &[disabled] {
    border-color: ${({ theme }) => theme.buttonPrimaryBorderDisabled};
  }
`;

const ButtonGroup = (props) => {
  const { size, variant, children, disabled, ...tail } = props;

  return (
    <Container {...tail} size={size} variant={variant} disabled={disabled}>
      {childrenMap(children, (childProps, order) => ({
        key: order,
        ...childProps,
        disabled: childProps.disabled || disabled,
        size,
      }))}
    </Container>
  );
};

ButtonGroup.propTypes = {
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  variant: PropTypes.oneOf([
    ButtonVariants.text,
    ButtonVariants.contained,
    ButtonVariants.outlined,
  ]),
  children: PropTypes.children,
  disabled: PropTypes.bool,
};

ButtonGroup.defaultProps = {
  size: 'large',
  variant: ButtonVariants.outlined,
  children: null,
  disabled: false,
};

export default ButtonGroup;
