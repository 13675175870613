/* eslint-disable react/prop-types */
import { forwardRef } from 'react';

import styled, { css } from 'styled-components';

import GridOrigin from '@mui/material/Grid';

import { CardMixin } from './CardMixin';
import { LayoutSizes } from './LayoutSizes';
import { LayoutTypes } from './LayoutTypes';

const titles = new Set([LayoutTypes.title, LayoutTypes.titleLarge]);

export const Grid = styled(
  forwardRef((props, ref) => {
    const { type, role, ...rest } = props;

    let extraProps = {};

    switch (true) {
      case type === LayoutTypes.cardContent:
        extraProps = {
          padding: '8px 10px',
          pt: '4px',
        };
        break;
      case type === LayoutTypes.field && role === 'row':
        extraProps = {
          gap: LayoutSizes.fieldGap,
          lineHeight: '20px',
          wrap: 'nowrap',
        };
        break;
      case titles.has(type):
        extraProps = {
          padding: `${type === LayoutTypes.title ? 8 : 12}px 10px`,
          fontWeight: 700,
          width: '100%',
          alignItems: 'center',
          gap: '10px',
        };
        break;
      case type === LayoutTypes.cardLegacy && role === 'column':
      case type === LayoutTypes.card && role === 'column':
        extraProps = {
          gap: LayoutSizes.cardGap,
          lg: true,
          item: true,
        };
        break;
      case type === LayoutTypes.fieldName && role === 'column':
        extraProps = {
          xs: LayoutSizes.fieldNameXs,
          item: true,
          container: false,
          wrap: 'nowrap',
        };
        break;
      case type === LayoutTypes.fieldValue && role === 'column':
        extraProps = {
          xs: LayoutSizes.fieldValueXs,
          item: true,
          container: false,
        };
        break;

      case type === LayoutTypes.fieldDescription && role === 'column':
        extraProps = {
          item: true,
          container: false,
        };
        break;
      default:
        break;
    }

    return (
      <GridOrigin
        ref={ref}
        {...extraProps}
        {...rest}
        data-layout={role}
        data-layout-role={type}
      />
    );
  }),
).attrs((props) => ({
  ...props,
  type: props.$type,
}))`
  ${(props) => props.$type === LayoutTypes.card && CardMixin}
  ${(props) =>
    props.$type === LayoutTypes.cardLegacy &&
    css`
      ${CardMixin};
      padding: 8px 10px;
    `}

  ${(props) =>
    props.$type === LayoutTypes.fieldName &&
    css`
      overflow: hidden;
      font-weight: 700;
      * {
        font-weight: 700;
      }
    `}

  ${(props) =>
    props.$type === LayoutTypes.fieldValue &&
    css`
      &:empty::before {
        content: '—';
      }
    `}
  
  ${(props) =>
    props.$type === LayoutTypes.fieldDescription &&
    css`
      color: ${props.theme.colorTextSecondary} !important;
    `}
`;

export default Grid;
