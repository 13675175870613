/* eslint-disable max-len */
import PropTypes from '+prop-types';
import { memo } from 'react';

const InfobloxIcon = memo(({ size }) => (
  <svg
    width={size}
    height={size}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 1000 1000"
  >
    <g>
      <path
        d="M234.4,437L83.3,323.7c-4.2-4.2-6.3-8.4-6.3-10.5c-0.1-4.4,2.4-8.5,6.3-10.5l151.1-113.4
		c10.5-8.4,31.5-8.4,44.1,0l151.1,113.4c4.2,4.2,6.3,8.4,6.3,10.5c0.1,4.4-2.4,8.5-6.3,10.5L278.5,437
		C265.9,445.4,245,445.4,234.4,437"
        fill="#95C840"
      />
      <path
        d="M234.4,802.3L83.3,688.9c-4.2-4.2-6.3-8.4-6.3-10.5c-0.1-4.4,2.4-8.5,6.3-10.5l151.1-113.4
		c10.5-8.4,31.5-8.4,44.1,0l151.1,113.4c4.2,4.2,6.3,8.4,6.3,10.5c0.1,4.4-2.4,8.5-6.3,10.5L278.5,802.3
		C265.9,812.8,244.9,812.8,234.4,802.3"
        fill="#59C9E6"
      />
      <g>
        <path
          d="M478,256.5L326.8,143.1c-4.2-4.2-6.3-8.4-6.3-12.6c-0.1-4.4,2.4-8.5,6.3-10.5L478,6.7
			c12.6-8.4,31.5-8.4,44.1,0L673.2,120c4.2,4.2,6.3,8.4,6.3,10.5c0,4.2-2.1,8.4-6.3,12.6L522.1,256.5
			C509.5,264.9,490.6,264.9,478,256.5"
          fill="#EBE719"
        />
      </g>
      <path
        d="M478,621.7L326.8,508.4c-4.2-4.2-6.3-8.4-6.3-10.5c-0.1-4.4,2.4-8.5,6.3-10.5L478,374.1
		c12.6-8.4,31.5-8.4,44.1,0l151.1,113.4c4.2,4.2,6.3,8.4,6.3,10.5c0.1,4.4-2.4,8.5-6.3,10.5L522.1,621.7
		C509.5,630.1,490.6,630.1,478,621.7"
        fill="#50C0AF"
      />
      <g>
        <path
          d="M478,993.3L326.8,880c-4.2-4.2-6.3-8.4-6.3-12.6c-0.1-4.4,2.4-8.5,6.3-10.5L478,743.5
			c12.6-8.4,31.5-8.4,44.1,0l151.1,113.4c4.2,4.2,6.3,8.4,6.3,10.5c0,4.2-2.1,8.4-6.3,12.6L522.1,993.3
			C509.5,1001.7,490.6,1001.7,478,993.3"
          fill="#0377BB"
        />
      </g>
      <path
        d="M721.5,437L570.3,323.7c-4.2-4.2-6.3-8.4-6.3-10.5c-0.1-4.4,2.4-8.5,6.3-10.5l151.1-113.4
		c10.5-8.4,31.5-8.4,44.1,0l151.1,113.4c4.2,4.2,6.3,8.4,6.3,10.5c0.1,4.4-2.4,8.5-6.3,10.5L765.6,437
		C755.1,445.4,734.1,445.4,721.5,437"
        fill="#78BC43"
      />
      <path
        d="M721.5,802.3L570.3,688.9c-4.2-4.2-6.3-8.4-6.3-10.5c-0.1-4.4,2.4-8.5,6.3-10.5l151.1-113.4
		c10.5-8.4,31.5-8.4,44.1,0l151.1,113.4c4.2,4.2,6.3,8.4,6.3,10.5c0.1,4.4-2.4,8.5-6.3,10.5L765.6,802.3
		C755.1,812.8,734.1,812.8,721.5,802.3"
        fill="#109FDA"
      />
    </g>
  </svg>
));

InfobloxIcon.propTypes = {
  size: PropTypes.number,
};

InfobloxIcon.defaultProps = {
  size: 24,
};

export { InfobloxIcon };
