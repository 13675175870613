import PropTypes from '+prop-types';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import styled from 'styled-components';

import { UIProperties } from '@/models/UIProperties';

import { actions } from '@/redux/api/labels/ips';

import { labelWithDataRenderer } from '+components/charts/common/formatters';
import useEvent from '+hooks/useEvent';
import useUIProperty from '+hooks/useUIProperty';

const LabelButton = styled.button`
  font-size: 0.9em;
  display: flex;
  align-items: center;

  .label-series__counter {
    margin-left: 5px;
  }
`;

const buttonStyle = {
  backgroundColor: 'transparent',
  border: 'none',
  padding: 0,
};

const IpLabel = (props) => {
  const { ip, labels, context } = props;
  const dispatch = useDispatch();
  const [, setPropertiesTray] = useUIProperty(
    UIProperties.propertiesTray,
    null,
  );

  useEffect(() => {
    if (!labels) {
      dispatch(actions.fetchIpLabelsByIp({ ip }, `CellIp - ${ip}`));
    }
  }, [labels]);

  const onClick = useEvent(() => {
    setPropertiesTray({
      data: [
        {
          dataType: 'field',
          field: 'ip',
          value: ip,
        },
      ],
      isOpen: true,
    });
  });

  return !ip ? null : (
    <LabelButton
      type="button"
      onClick={onClick}
      style={buttonStyle}
      data-ip={ip}
    >
      {labelWithDataRenderer({
        data: ip,
        labelsContext: context,
        labels,
        renderAsGroup: false,
      })}
    </LabelButton>
  );
};

IpLabel.propTypes = {
  ip: PropTypes.string,
  labels: PropTypes.arrayOf(PropTypes.string),
  context: PropTypes.string,
};

IpLabel.defaultProps = {
  ip: null,
  labels: null,
  context: 'name',
};

export default IpLabel;
