/* eslint-disable react/no-array-index-key */
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';

import get from 'lodash.get';

import Stack from '@mui/material/Stack';

import AccountMultipleIcon from 'mdi-react/AccountMultipleIcon';

import { ContextTypes } from '@/models/ContextTypes';
import { CustomType } from '@/models/CustomType';
import { UIProperties } from '@/models/UIProperties';

import { selectors as customerSelectors } from '@/redux/api/customer';
import { actions as actionsFilters } from '@/redux/globalFilters';

import { Breadcrumb } from '+components/Breadcrumb';
import Button, { ButtonVariants } from '+components/Button';
import GlobalFiltersSetting from '+components/GlobalFilters/Setting';
import { IpSummary } from '+components/IpSummary';
import TCPFlagLabel from '+components/Labels/TCPFlagLabel';
import { Col, LayoutSizes, LayoutTypes, Row } from '+components/Layout';
import { PageHeader } from '+components/Layout/PageHeader';
import * as Menu from '+components/Menu';
import { withMenu } from '+components/Menu';
import { usePageTabs } from '+components/PageTabs';
import RecordModal from '+components/RecordModal';
import { CellTag } from '+components/Table/Cells';
import SubAccountTag from '+components/Tag/SubAccountTag';
import UniversalField from '+components/UniversalField';
import {
  useFetchContextRecord,
  useGetContextRecord,
  useIsFetchingContextRecord,
} from '+hooks';
import useGlobalFilters from '+hooks/useGlobalFilters';
import useLoadingIndicator from '+hooks/useLoadingIndicator';
import useUIProperty from '+hooks/useUIProperty';
import { getFlowDataFromRecord } from '+utils/getFlowDataFromRecord';
import { getSearchUrl } from '+utils/getSearchUrl';

const empty = {};

const durationOptions = {
  unit: 'milliseconds',
};

const labelOptions = { useDataValueInPropertiesTray: true };

const FlowDetails = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { id } = useParams();

  const [, , pageTabMethods] = usePageTabs();

  const [filters] = useGlobalFilters();
  const customer = useSelector(customerSelectors.getCurrentCustomer);

  const [searchParams] = useSearchParams();
  const customerParam = searchParams.get('customer');
  const timestampParam = searchParams.get('timestamp');

  const flow = useGetContextRecord(ContextTypes.flow, id) || empty;
  const isFetching = useIsFetchingContextRecord(ContextTypes.flow, id);

  useFetchContextRecord(
    ContextTypes.flow,
    {
      id,
      customer: customerParam,
      timestamp: timestampParam,
    },
    'pageDetails',
    [id, customerParam, timestampParam],
  );

  const [recordModalOpened, setRecordModalOpened] = useState(false);

  useLoadingIndicator(isFetching);

  const isSubAccountRecord =
    flow?.customer && flow?.customer !== customer?.shortname;

  const ipIntellData = useMemo(() => {
    const data = [];
    if (flow?.dstip) {
      data.push({
        dstip: true,
        ip: flow.dstip,
        ipname: !filters.labelContext.show
          ? []
          : flow.label?.ip?.[filters.labelContext.ip]?.dst,
        bogon: flow.bogondst,
        geo: flow.dstgeo,
        as: flow.dstowneras,
        iprep: flow.dstiprep,
        customer: flow.customer,
      });
    }
    if (flow?.srcip) {
      data.push({
        srcip: true,
        ip: flow.srcip,
        ipname: !filters.labelContext.show
          ? []
          : flow.label?.ip?.[filters.labelContext.ip]?.src,
        bogon: flow.bogonsrc,
        geo: flow.srcgeo,
        as: flow.srcowneras,
        iprep: flow.srciprep,
        customer: flow.customer,
      });
    }
    return data;
  }, [flow, filters.labelContext]);

  const { nql } = useMemo(
    () => getFlowDataFromRecord({ record: flow, type: ContextTypes.flow }),
    [flow],
  );

  const onSearch = useCallback(() => {
    const url = getSearchUrl({
      context: ContextTypes.flow,
      nql,
      customer: isSubAccountRecord ? flow?.customer : undefined,
    });
    pageTabMethods.add(url);
  }, [nql, isSubAccountRecord, flow]);

  const [, setMasqueradeUrl] = useUIProperty(UIProperties.masqueradeUrl);
  useEffect(() => {
    const rootPath = location.pathname.slice(
      0,
      location.pathname.lastIndexOf('/'),
    );
    setMasqueradeUrl(rootPath);
    return () => {
      setMasqueradeUrl(null);
    };
  }, [location.pathname]);

  // we set global filter time period to flow start,end or timestamp +- 5 minutes
  useEffect(() => {
    if (!flow?.timestamp) {
      return;
    }

    const { from, to } = getFlowDataFromRecord({
      record: {
        start: flow.start || flow.timestamp,
        end: flow.end || flow.timestamp,
      },
      type: ContextTypes.alerts,
    });

    const state = {
      period: {
        type: CustomType,
      },
      from,
      to,
      autoRefresh: false,
      ...(isSubAccountRecord && { customers: [flow?.customer] }),
    };
    dispatch(actionsFilters.changeFilter(state));
  }, [flow]);

  const srcIpLabelFieldName = `label.ip.${filters.labelContext.ip}.src`;
  const srcPortLabelFieldName = `label.port.${filters.labelContext.port}.src`;
  const dstIpLabelFieldName = `label.ip.${filters.labelContext.ip}.dst`;
  const dstPortLabelFieldName = `label.port.${filters.labelContext.port}.dst`;

  if (!flow?.id) {
    return (
      <Fragment>
        <Breadcrumb title="Flow Details" />

        <GlobalFiltersSetting
          context={ContextTypes.flow}
          range={false}
          from={false}
          to={false}
          nql={false}
          customers={false}
          metric={false}
          socketControl={false}
        />

        <Col
          $type={LayoutTypes.cardLegacy}
          alignItems="center"
          justifyContent="center"
          height="80vh"
        >
          {isFetching ? 'Loading...' : 'Flow not found'}
        </Col>
      </Fragment>
    );
  }

  return (
    <Fragment>
      <Menu.TriggerMenu />

      <Breadcrumb title="Flow Details" />

      <GlobalFiltersSetting
        context={ContextTypes.flow}
        range={false}
        from={false}
        to={false}
        nql={false}
        customers={false}
        metric={false}
        socketControl={false}
      />

      <Col spacing={LayoutSizes.groupGap}>
        <PageHeader title="Flow Details" showBackButton>
          <Button
            variant={ButtonVariants.contained}
            onClick={() => setRecordModalOpened(true)}
          >
            View Raw Record
          </Button>

          <Button
            variant={ButtonVariants.contained}
            onClick={onSearch}
            disabled={!nql}
          >
            Search Flows
          </Button>
        </PageHeader>

        <Row item spacing={LayoutSizes.groupGap}>
          <Col item md={12} lg={3}>
            <Row item $type={LayoutTypes.card} minHeight="100%">
              <Col item>
                <Row item $type={LayoutTypes.titleLarge}>
                  Overview
                </Row>

                <Col item flex={1} $type={LayoutTypes.cardContent}>
                  {isSubAccountRecord && (
                    <Row $type={LayoutTypes.field}>
                      <Col $type={LayoutTypes.fieldName}>
                        <Stack direction="row" gap={0.5} alignItems="center">
                          <SubAccountTag
                            icon={<AccountMultipleIcon size={16} />}
                          />{' '}
                          Sub account:
                        </Stack>
                      </Col>
                      <Col $type={LayoutTypes.fieldValue}>{flow.customer}</Col>
                    </Row>
                  )}

                  <Row $type={LayoutTypes.field}>
                    <Col $type={LayoutTypes.fieldName}>Flow Type:</Col>
                    <Col $type={LayoutTypes.fieldValue}>
                      <UniversalField field="flowtype" value={flow.flowtype} />
                    </Col>
                  </Row>

                  <Row $type={LayoutTypes.field}>
                    <Col $type={LayoutTypes.fieldName}>Protocol:</Col>
                    <Col $type={LayoutTypes.fieldValue}>
                      <UniversalField field="protocol" value={flow.protocol} />
                    </Col>
                  </Row>

                  <Row $type={LayoutTypes.field}>
                    <Col $type={LayoutTypes.fieldName}>Flow Version:</Col>
                    <Col $type={LayoutTypes.fieldValue}>
                      <UniversalField
                        field="flowversion"
                        value={flow.flowversion}
                      />
                    </Col>
                  </Row>

                  <Row $type={LayoutTypes.field}>
                    <Col $type={LayoutTypes.fieldName}>IP Version:</Col>
                    <Col $type={LayoutTypes.fieldValue}>
                      <UniversalField
                        field="ipversion"
                        value={flow.ipversion}
                        original={flow}
                      />
                    </Col>
                  </Row>

                  <Row $type={LayoutTypes.field}>
                    <Col $type={LayoutTypes.fieldName}>Packets:</Col>
                    <Col $type={LayoutTypes.fieldValue}>
                      <UniversalField
                        field="packets"
                        value={flow.packets}
                        original={flow}
                      />
                    </Col>
                  </Row>

                  <Row $type={LayoutTypes.field}>
                    <Col $type={LayoutTypes.fieldName}>Sample Rate:</Col>
                    <Col $type={LayoutTypes.fieldValue}>
                      <UniversalField
                        field="samplerate"
                        value={flow.samplerate}
                        original={flow}
                      />
                    </Col>
                  </Row>
                </Col>
              </Col>
            </Row>
          </Col>

          <Col item md={12} lg={3}>
            <Row item $type={LayoutTypes.card} minHeight="100%">
              <Col item>
                <Row item $type={LayoutTypes.titleLarge}>
                  Time
                </Row>

                <Col item flex={1} $type={LayoutTypes.cardContent}>
                  <Row $type={LayoutTypes.field}>
                    <Col $type={LayoutTypes.fieldName}>Start:</Col>
                    <Col $type={LayoutTypes.fieldValue}>
                      <UniversalField
                        field="start"
                        value={flow.start}
                        original={flow}
                      />
                    </Col>
                  </Row>

                  <Row $type={LayoutTypes.field}>
                    <Col $type={LayoutTypes.fieldName}>End:</Col>
                    <Col $type={LayoutTypes.fieldValue}>
                      <UniversalField
                        field="end"
                        value={flow.end}
                        original={flow}
                      />
                    </Col>
                  </Row>

                  <Row $type={LayoutTypes.field}>
                    <Col $type={LayoutTypes.fieldName}>Duration:</Col>
                    <Col $type={LayoutTypes.fieldValue}>
                      <UniversalField
                        field="duration"
                        value={flow.duration}
                        original={flow}
                        options={durationOptions}
                      />
                    </Col>
                  </Row>

                  <Row $type={LayoutTypes.field}>
                    <Col $type={LayoutTypes.fieldName}>Flowtime:</Col>
                    <Col $type={LayoutTypes.fieldValue}>
                      <UniversalField
                        field="flowrtime"
                        value={flow.flowrtime}
                        options={flow}
                      />
                    </Col>
                  </Row>

                  <Row $type={LayoutTypes.field}>
                    <Col $type={LayoutTypes.fieldName}>Timestamp:</Col>
                    <Col $type={LayoutTypes.fieldValue}>
                      <UniversalField
                        field="timestamp"
                        value={flow.timestamp}
                        original={flow}
                      />
                    </Col>
                  </Row>
                </Col>
              </Col>
            </Row>
          </Col>

          <Col item md={12} lg={3}>
            <Row item $type={LayoutTypes.card} minHeight="100%">
              <Col item>
                <Row item $type={LayoutTypes.titleLarge}>
                  Traffic
                </Row>

                <Col item flex={1} $type={LayoutTypes.cardContent} gap="2px">
                  <Row $type={LayoutTypes.field}>
                    <Col $type={LayoutTypes.fieldName}>Source:</Col>
                    <Col $type={LayoutTypes.fieldValue}>
                      {!filters.labelContext.show ||
                      !get(flow, srcIpLabelFieldName)?.length ? (
                        <UniversalField
                          field="srcip"
                          value={flow.srcip}
                          original={flow}
                        />
                      ) : (
                        <UniversalField
                          field={srcIpLabelFieldName}
                          value={get(flow, srcIpLabelFieldName)}
                          original={flow}
                          options={labelOptions}
                        />
                      )}
                    </Col>
                  </Row>

                  <Row $type={LayoutTypes.field}>
                    <Col $type={LayoutTypes.fieldName}>SRC Port:</Col>
                    <Col $type={LayoutTypes.fieldValue}>
                      {!filters.labelContext.show ||
                      !get(flow, srcPortLabelFieldName)?.length ? (
                        <UniversalField
                          field="srcport"
                          value={flow.srcport}
                          original={flow}
                        />
                      ) : (
                        <UniversalField
                          field={srcPortLabelFieldName}
                          value={get(flow, srcPortLabelFieldName)}
                          original={flow}
                          options={labelOptions}
                        />
                      )}
                    </Col>
                  </Row>

                  <Row $type={LayoutTypes.field}>
                    <Col $type={LayoutTypes.fieldName}>Destination:</Col>
                    <Col $type={LayoutTypes.fieldValue}>
                      {!filters.labelContext.show ||
                      !get(flow, dstIpLabelFieldName)?.length ? (
                        <UniversalField
                          field="dstip"
                          value={flow.dstip}
                          original={flow}
                        />
                      ) : (
                        <UniversalField
                          field={dstIpLabelFieldName}
                          value={get(flow, dstIpLabelFieldName)}
                          original={flow}
                          options={labelOptions}
                        />
                      )}
                    </Col>
                  </Row>

                  <Row $type={LayoutTypes.field}>
                    <Col $type={LayoutTypes.fieldName}>DST Port:</Col>
                    <Col $type={LayoutTypes.fieldValue}>
                      {!filters.labelContext.show ||
                      !get(flow, dstPortLabelFieldName)?.length ? (
                        <UniversalField
                          field="dstport"
                          value={flow.dstport}
                          original={flow}
                        />
                      ) : (
                        <UniversalField
                          field={dstPortLabelFieldName}
                          value={get(flow, dstPortLabelFieldName)}
                          original={flow}
                          options={labelOptions}
                        />
                      )}
                    </Col>
                  </Row>

                  <Row $type={LayoutTypes.field}>
                    <Col $type={LayoutTypes.fieldName}>Site:</Col>
                    <Col $type={LayoutTypes.fieldValue}>
                      <UniversalField
                        field="site"
                        value={flow.site}
                        original={flow}
                      />
                    </Col>
                  </Row>
                </Col>
              </Col>
            </Row>
          </Col>

          <Col item md={12} lg={3}>
            <Row item $type={LayoutTypes.card} minHeight="100%">
              <Col item>
                <Row item $type={LayoutTypes.titleLarge}>
                  Metrics
                </Row>

                <Col item flex={1} $type={LayoutTypes.cardContent}>
                  <Row $type={LayoutTypes.field}>
                    <Col $type={LayoutTypes.fieldName}>Bits:</Col>
                    <Col $type={LayoutTypes.fieldValue}>
                      <UniversalField
                        field="bits"
                        value={flow.bits}
                        original={flow}
                      />
                    </Col>
                  </Row>

                  <Row $type={LayoutTypes.field}>
                    <Col $type={LayoutTypes.fieldName}>Bits x Rate:</Col>
                    <Col $type={LayoutTypes.fieldValue}>
                      <UniversalField
                        field="bitsxrate"
                        value={flow.bitsxrate}
                        original={flow}
                      />
                    </Col>
                  </Row>

                  <Row $type={LayoutTypes.field}>
                    <Col $type={LayoutTypes.fieldName}>Flow Bit Rate:</Col>
                    <Col $type={LayoutTypes.fieldValue}>
                      <UniversalField
                        field="flowbrate"
                        value={flow.flowbrate}
                        original={flow}
                      />
                    </Col>
                  </Row>

                  <Row $type={LayoutTypes.field}>
                    <Col $type={LayoutTypes.fieldName}>Flow Packet Rate:</Col>
                    <Col $type={LayoutTypes.fieldValue}>
                      <UniversalField
                        field="flowprate"
                        value={flow.flowprate}
                        original={flow}
                      />
                    </Col>
                  </Row>

                  <Row $type={LayoutTypes.field}>
                    <Col $type={LayoutTypes.fieldName}>Packets x Rate:</Col>
                    <Col $type={LayoutTypes.fieldValue}>
                      <UniversalField
                        field="packetsxrate"
                        value={flow.packetsxrate}
                        original={flow}
                      />
                    </Col>
                  </Row>
                </Col>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row item spacing={LayoutSizes.groupGap}>
          <Col item md={12} lg={6}>
            <Row item $type={LayoutTypes.card} pb="12px" minHeight="100%">
              <Col item>
                <Row $type={LayoutTypes.titleLarge}>Tags</Row>

                <Row gap="5px" $type={LayoutTypes.cardContent}>
                  {(flow.tags || []).map((tag) => (
                    <CellTag
                      key={tag}
                      field="tags"
                      value={tag}
                      original={flow}
                    />
                  ))}
                </Row>
              </Col>
            </Row>
          </Col>
          <Col item md={12} lg={6}>
            <Row item $type={LayoutTypes.card} pb="12px" minHeight="100%">
              <Col item>
                <Row $type={LayoutTypes.titleLarge}>TCP Flags</Row>

                <Row gap="5px" $type={LayoutTypes.cardContent}>
                  {Object.entries(flow.tcpflags || {}).map(
                    ([flag, flagValue]) => (
                      <TCPFlagLabel
                        key={flag}
                        $flag={flagValue && flag.toLowerCase()}
                      >
                        {flag.toUpperCase()}
                      </TCPFlagLabel>
                    ),
                  )}
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row item>
          <Col item>
            <IpSummary
              ipScorePeriodStart={flow.start || flow.timestamp}
              ipScorePeriodEnd={flow.end}
              isSubAccountRecord={isSubAccountRecord}
              data={ipIntellData}
            />
          </Col>
        </Row>
      </Col>

      {recordModalOpened && (
        <RecordModal
          title={`Flow Record — ${flow.id}`}
          data={flow}
          onToggle={() => setRecordModalOpened(false)}
          isOpen
        />
      )}
    </Fragment>
  );
};

export default withMenu(FlowDetails);
