import { useMemo } from 'react';

import Stack from '@mui/material/Stack';

import { HeaderSubheaderCell } from '+components/Table/Cells';
import {
  AuthorColumnFactory,
  BaseColumnFactory,
  MenuColumnFactory,
  TrafficColumnFactory,
} from '+components/Table/Columns';
import { someOfFieldsFilter } from '+components/Table/FilterTypeFactories';
import UniversalField from '+components/UniversalField';
import { getColumnsHelper } from '+utils';

import { Columns } from './Columns';

const columnsCollection = ({ profileEmail, cxActionMenu }) => ({
  [Columns.title]: BaseColumnFactory({
    Header: 'Name / Desc',
    width: 220,
    Cell: HeaderSubheaderCell({
      propHeader: 'title',
      propSubheader: 'description',
    }),
    realAccessor: ['title', 'description'],
    filter: someOfFieldsFilter(['title', 'description']),
  }),
  [Columns.nql]: BaseColumnFactory({
    Header: 'NQL',
    // eslint-disable-next-line react/prop-types
    Cell: ({
      value,
      row: {
        original: { context },
      },
    }) =>
      useMemo(
        () => (
          <Stack p={1}>
            <UniversalField
              field="search"
              value={value}
              options={{ context, underline: true }}
            />
          </Stack>
        ),
        [value, context],
      ),
  }),
  [Columns.context]: TrafficColumnFactory({
    Header: 'Context',
    options: {
      disabled: true,
    },
    maxWidth: 120,
    width: 120,
    disableResizing: false,
  }),
  [Columns.author]: AuthorColumnFactory({
    profileEmail,
  }),
  [Columns.menu]: MenuColumnFactory({ cxActionMenu }),
});

export const getColumns = getColumnsHelper(columnsCollection);
