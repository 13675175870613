import PropTypes from '+prop-types';
import { useToggle } from 'react-use';

import styled from 'styled-components';

import MenuRightIcon from 'mdi-react/MenuRightIcon';
import TriangleSmallDownIcon from 'mdi-react/TriangleSmallDownIcon';

import { Row } from '+components/Layout';

const Container = styled(Row)`
  width: 100%;
  flex-wrap: nowrap;
  align-items: center;
  background: ${({ theme }) => theme.colorBackgroundBodySecondary};
`;

const ToggleIcon = styled.div`
  color: ${({ theme }) => theme.colorTextSecondary} !important;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 10px;
`;

const NameDescription = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 5px;
  line-height: 1.4;
  min-width: 0;
  //.name {
  //  font-size: 16px;
  //}
  .description {
    font-size: 11px;
    color: ${({ theme }) => theme.colorTextSecondary};
  }
  a {
    display: contents;
    color: white;
  }
`;

const NameContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const NameDetails = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  width: 100%;
`;

const Additional = styled(Row)`
  margin-top: 15px;

  *:not(.excludeStyle) {
    font-size: 11px !important;
    //color: ${({ theme }) => theme.colorTextSecondary} !important;
  }

  > div > div:nth-child(1) {
    flex-basis: 20%;
    max-width: 20%;
    word-wrap: break-word;
  }

  > div > div:nth-child(2) {
    flex-basis: 80%;
    max-width: 80%;
  }
`;

const Collapsible = (props) => {
  const {
    id,
    name,
    description,
    icon,
    expandable,
    formatter,
    children,
    initialExpanded,
    original,
    disabled,
  } = props;

  const [expanded, toggleExpanded] = useToggle(initialExpanded);

  return (
    <Container>
      {icon}

      <NameDescription>
        <NameContainer>
          <NameDetails>
            <span className="name">
              {formatter ? formatter({ name, id, original, disabled }) : name}
            </span>
            {description && <span className="description">{description}</span>}
          </NameDetails>
          {expandable && (
            <ToggleIcon onClick={expandable ? toggleExpanded : undefined}>
              {expanded ? (
                <TriangleSmallDownIcon size={28} />
              ) : (
                <MenuRightIcon size={28} />
              )}
            </ToggleIcon>
          )}
        </NameContainer>
        {expanded && children && <Additional gap="8px">{children}</Additional>}
      </NameDescription>
    </Container>
  );
};

Collapsible.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  description: PropTypes.string,
  icon: PropTypes.node,
  expandable: PropTypes.bool,
  children: PropTypes.node,
  formatter: PropTypes.func,
  initialExpanded: PropTypes.bool,
  original: PropTypes.object,
  disabled: PropTypes.bool,
};

Collapsible.defaultProps = {
  id: null,
  name: null,
  icon: null,
  description: null,
  expandable: false,
  children: null,
  formatter: null,
  initialExpanded: false,
  original: null,
  disabled: false,
};

export default Collapsible;
