import styled from 'styled-components';

import { withMenu } from '+components/Menu';

import { Body } from './Body';

const Item = styled(Body).attrs(({ theme }) => ({
  key: theme.name,
}))`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  cursor: pointer;
  height: 24px;
  padding: 0 0 0 32px;
  overflow: hidden;
  border-radius: 12px;
  gap: 4px;
  transition: background-color 0.3s;
  background-color: transparent;

  &.isContextMenuOpen,
  &:hover {
    background-color: ${({ theme }) => theme.sideBarItemHoverBackground};
    .sidebar__link-unpin {
      display: flex !important;
    }
  }

  .sidebar__link-unpin {
    display: none;
    color: ${({ theme }) => theme.colorText};
    background-color: transparent;
    margin-left: auto;
    .unpin-icon {
      display: none;
    }

    &:hover {
      .unpin-icon {
        display: block;
      }
      .pin-icon {
        display: none;
      }
    }
  }

  .sidebar__link-icon .mdi-icon {
    width: 16px;
  }

  .sidebar__link-title {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: ${({ theme }) => theme.colorText};
  }

  &.sidebar__link--active {
    font-weight: bold;
    background-color: ${({ theme }) => theme.sideBarItemActiveBackground};
    .sidebar__link-icon .mdi-icon,
    .sidebar__link-title {
      color: ${({ theme }) => theme.sideBarItemActiveColor};
    }
  }

  &.sidebar__link--collapse {
    justify-content: center;
    padding: unset;
    width: 40px;
    height: 40px;
    border-radius: 20px;

    .sidebar__link-icon .mdi-icon {
      width: 26px;
      height: 26px;
      color: ${({ theme }) => theme.colorText};
    }
  }

  &.sidebar__link--disabled {
    background-color: transparent;
    cursor: default;
    .sidebar__link-title {
      color: ${({ theme }) => theme.colorMenuDisabledText};
    }
  }

  &.isItemDragging:not(.sidebar__link--active) {
    background-color: ${({ theme }) => theme.sideBarItemHoverBackground};
  }

  &.isNavDragging:not(.isItemDragging):not(.sidebar__link--active):hover {
    background-color: unset;
  }
`;

Item.propTypes = Body.propTypes;

Item.defaultProps = Body.defaultProps;

export default withMenu(Item);
