import PropTypes from '+prop-types';
import { Fragment, useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useFlag } from '@unleash/proxy-client-react';
import styled from 'styled-components';

import CalendarClockIcon from 'mdi-react/CalendarClockIcon';
import FilterIcon from 'mdi-react/FilterIcon';
import LinkPlusIcon from 'mdi-react/LinkPlusIcon';
import LockOpenVariantOutlineIcon from 'mdi-react/LockOpenVariantOutlineIcon';
import LockOutlineIcon from 'mdi-react/LockOutlineIcon';
import PrinterIcon from 'mdi-react/PrinterIcon';

import { DateTimeModes } from '@/models/DateTimeModes';
import FeatureFlags from '@/models/FeatureFlags';
import PermissionModel from '@/models/Permission';
import SettingCategories from '@/models/SettingCategories';
import { UIProperties } from '@/models/UIProperties';

import {
  actions as dashboardsActions,
  selectors as dashboardsSelectors,
} from '@/redux/api/dashboards';
import { selectors as profileSelectors } from '@/redux/api/user/profile';

import Button, { ButtonVariants } from '+components/Button';
import SelectOrigin from '+components/form/Select';
import Toggle from '+components/form/Toggle';
import IconButton from '+components/IconButton';
import { ActionsContainer } from '+components/Layout';
import Tooltip from '+components/Tooltip';
import useGlobalFilters from '+hooks/useGlobalFilters';
import usePermissions from '+hooks/usePermissions';
import usePortalSettingsValue from '+hooks/usePortalSettingsValue';
import useUIProperty from '+hooks/useUIProperty';
import dayjs from '+utils/dayjs';

import DashboardModeTypes from '../../shared/DashboardModeTypes';
import NqlModeTypes from '../../shared/NqlModeTypes';
import { dashboardPropTypes } from '../../shared/propTypes';
import { getDashboardNql } from '../../shared/requests';
import { formatter, groups, makeOptions } from '../utils/dashboardOptions';
import getFromAndToDate from '../utils/getFromAndToDate';

const Select = styled(SelectOrigin)`
  width: 270px;
`;

const TooltipContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`;

const FilterIconContainer = styled.div`
  border: 2px solid ${({ theme }) => theme.colorText};
  height: 26px;
  width: 26px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const getFrom = (value, period) => {
  const SubtractBy = {
    60000: 'minutes',
    3600000: 'hours',
    86400000: 'days',
    604800000: 'weeks',
    2678400000: 'months',
  };

  const now = dayjs().tz(dayjs.tz.guess());
  return (
    value && period ? now.subtract(value, SubtractBy[period]) : now
  ).toISOString();
};

const getNow = () => dayjs().tz(dayjs.tz.guess()).toISOString();

const dashboardGroupBy = (option) => option.group;

const ControlPanel = (props) => {
  const {
    dashboard,
    mode,
    isEditable,
    hideNav: hideNavProp,
    syncMouseHover,
    showSyncMouseHover,
    additionalActionItems,
    onWidgetAdd,
    onDashboardEditToggle,
    onDashboardSettings,
    onGoToDashboard,
    onDashboardPrint,
    onDashboardShare,
    onDashboardSchedule,
    onSyncMouseHoverToggle,
    ...tail
  } = props;

  const dispatch = useDispatch();

  const isGuestAccessEnabled = useFlag(FeatureFlags.guestAccess);
  const [guest] = useUIProperty(UIProperties.guest);
  const [hideNav] = useUIProperty(UIProperties.hideNav);
  const permissionsUsers = usePermissions(PermissionModel.Resources.user.value);
  const permissionsDashboards = usePermissions(
    PermissionModel.Resources.dashboard.value,
  );
  const isDefaultCustomer = useSelector(profileSelectors.isDefaultCustomer);

  const [filters] = useGlobalFilters(dashboard?.context);

  const dashboardsMeta = useSelector(dashboardsSelectors.getDashboardsMeta);

  const [favIds] = usePortalSettingsValue(
    SettingCategories.dashboard,
    'favorites',
    [],
  );
  const [recent] = usePortalSettingsValue(
    SettingCategories.dashboard,
    'recents',
    [],
  );

  const from =
    dashboard?.dateTime?.periodType === 'custom'
      ? dashboard?.dateTime?.from
      : getFrom(
          dashboard?.dateTime?.periodValue,
          dashboard?.dateTime?.periodType,
        );
  const to =
    dashboard?.dateTime?.periodType === 'custom'
      ? dashboard?.dateTime?.to
      : getNow();
  const formattedFrom = +new Date(from);
  const formattedTo = +new Date(to);
  const customDateTimeMode = dashboard?.dateTime?.periodType === 'custom';

  const showNavControls = !hideNav && !hideNavProp;
  const showShareControls =
    isGuestAccessEnabled &&
    mode !== DashboardModeTypes.page &&
    permissionsUsers?.create;
  const showScheduleControls =
    mode !== DashboardModeTypes.page && permissionsDashboards?.update;
  const showAddWidgetControls =
    mode === DashboardModeTypes.edit && permissionsDashboards?.update;
  const showSettingsControls =
    mode === DashboardModeTypes.edit && permissionsDashboards?.update;
  const showEditControls =
    isEditable &&
    permissionsDashboards?.update &&
    mode !== DashboardModeTypes.page;

  const fullNqlString = useMemo(
    () => (dashboard ? getDashboardNql(dashboard, filters.nql).join(', ') : ''),
    [dashboard, filters.nql],
  );

  const {
    recentDashboards,
    favoriteDashboards,
    customDashboards,
    systemDashboards,
  } = useMemo(() => {
    const favoriteSet = new Set(favIds);
    const recentSet = new Set(recent.map(({ id }) => id));
    return Object.values(dashboardsMeta || {}).reduce(
      (acc, el) => {
        if (!isDefaultCustomer && el.hidden) {
          return acc;
        }
        if (recentSet.has(el.id)) {
          const lastseen = recent.find(({ id }) => id === el.id)?.timestamp;
          acc.recentDashboards.push({ ...el, lastseen });
        }

        if (favoriteSet.has(el.id)) {
          acc.favoriteDashboards.push(el);
        }

        if (el.system) {
          acc.systemDashboards.push(el);
        } else {
          acc.customDashboards.push(el);
        }

        return acc;
      },
      {
        recentDashboards: [],
        favoriteDashboards: [],
        systemDashboards: [],
        customDashboards: [],
      },
    );
  }, [dashboardsMeta, favIds, recent, isDefaultCustomer]);

  const selectOptions = useMemo(
    () => [
      ...makeOptions(recentDashboards, groups.recent),
      ...makeOptions(favoriteDashboards, groups.favorites),
      ...makeOptions(systemDashboards, groups.system),
      ...makeOptions(customDashboards, groups.custom),
    ],
    [recentDashboards, favoriteDashboards, systemDashboards, customDashboards],
  );

  const selectValue = useMemo(() => {
    if (mode === DashboardModeTypes.page) {
      return undefined;
    }

    if (!dashboard?.id) {
      return selectOptions?.[0];
    }

    return formatter(dashboard.id, selectOptions);
  }, [mode, dashboard?.id, selectOptions]);

  const recentsRef = useRef();
  recentsRef.current = recent;
  useEffect(() => {
    if (!dashboard?.id || mode === DashboardModeTypes.page || guest) {
      return;
    }
    dispatch(dashboardsActions.addDashboardToRecent(dashboard.id));
  }, [dashboard?.id, mode, guest]);

  const dashboardsMetaLength = Object.keys(dashboardsMeta || {}).length;
  useEffect(() => {
    if (!dashboardsMetaLength) {
      dispatch(dashboardsActions.fetchDashboardsMeta());
    }
  }, [dashboardsMetaLength]);

  return (
    <ActionsContainer {...tail}>
      {showNavControls && (
        <Select
          placeholder="Select dashboard..."
          options={selectOptions}
          value={selectValue}
          groupBy={dashboardGroupBy}
          onChange={onGoToDashboard}
          data-tracking="select-dashboard"
        />
      )}

      {dashboard?.id && (
        <Fragment>
          <IconButton
            size="medium"
            title="Print"
            onClick={onDashboardPrint}
            data-tracking="print-dashboard-button"
          >
            <PrinterIcon size={16} />
          </IconButton>

          {showShareControls && (
            <IconButton
              size="medium"
              title="Generate Sharable Link"
              onClick={onDashboardShare}
              data-tracking="share-dashboard-button"
              disabled={!dashboard?.public}
            >
              <LinkPlusIcon size={16} />
            </IconButton>
          )}

          {showScheduleControls && (
            <IconButton
              size="medium"
              title="Schedule"
              onClick={onDashboardSchedule}
              data-tracking="schedule-dashboard-button"
              disabled={!dashboard?.public}
            >
              <CalendarClockIcon size={16} />
            </IconButton>
          )}

          {(!dashboard?.useGlobalDateTime ||
            (dashboard?.nqlMode !== NqlModeTypes.global && fullNqlString)) && (
            <Tooltip
              title={
                <TooltipContent>
                  {dashboard?.nqlMode !== NqlModeTypes.global &&
                    fullNqlString && <span>Custom NQL: {fullNqlString}</span>}

                  {!dashboard?.useGlobalDateTime && (
                    <span>
                      Custom Date & Time:{' '}
                      {getFromAndToDate({
                        from: formattedFrom,
                        to: formattedTo,
                        dateTimeMode: customDateTimeMode
                          ? DateTimeModes.range
                          : DateTimeModes.now,
                        period: {
                          type: dashboard?.dateTime?.periodType,
                          value: dashboard?.dateTime?.periodValue,
                        },
                      })}
                    </span>
                  )}
                </TooltipContent>
              }
              arrow={false}
            >
              <FilterIconContainer>
                <FilterIcon size={14} />
              </FilterIconContainer>
            </Tooltip>
          )}

          {showSyncMouseHover && (
            <Toggle
              type="checkbox"
              checked={syncMouseHover}
              uncheckedLabel="Sync Hover"
              onChange={onSyncMouseHoverToggle}
              data-tracking="sync-mouse-hover-dashboard-toggle"
            />
          )}

          {showEditControls && (
            <IconButton
              size="medium"
              title={
                mode === DashboardModeTypes.edit
                  ? 'Exit Edit Mode'
                  : 'Edit Mode'
              }
              onClick={onDashboardEditToggle}
              data-tracking="edit-dashboard-button"
            >
              {mode === DashboardModeTypes.edit ? (
                <LockOpenVariantOutlineIcon size={16} />
              ) : (
                <LockOutlineIcon size={16} />
              )}
            </IconButton>
          )}

          {showAddWidgetControls && (
            <Button
              variant={ButtonVariants.contained}
              onClick={onWidgetAdd}
              data-tracking="add-widget-button"
            >
              Add Widget
            </Button>
          )}

          {showSettingsControls && (
            <Button
              variant={ButtonVariants.outlined}
              onClick={onDashboardSettings}
              data-tracking="settings-dashboard-button"
            >
              Settings
            </Button>
          )}

          {additionalActionItems}
        </Fragment>
      )}
    </ActionsContainer>
  );
};

ControlPanel.propTypes = {
  dashboard: PropTypes.shape(dashboardPropTypes),
  mode: PropTypes.oneOf(Object.values(DashboardModeTypes)),
  isEditable: PropTypes.bool,
  hideNav: PropTypes.bool,
  syncMouseHover: PropTypes.bool,
  showSyncMouseHover: PropTypes.bool,
  additionalActionItems: PropTypes.children,
  onWidgetAdd: PropTypes.func.isRequired,
  onDashboardEditToggle: PropTypes.func.isRequired,
  onDashboardSettings: PropTypes.func.isRequired,
  onGoToDashboard: PropTypes.func.isRequired,
  onDashboardPrint: PropTypes.func.isRequired,
  onDashboardShare: PropTypes.func.isRequired,
  onDashboardSchedule: PropTypes.func.isRequired,
  onSyncMouseHoverToggle: PropTypes.func.isRequired,
};

ControlPanel.defaultProps = {
  dashboard: undefined,
  mode: undefined,
  isEditable: false,
  hideNav: false,
  syncMouseHover: true,
  showSyncMouseHover: false,
  additionalActionItems: undefined,
};

export default ControlPanel;
