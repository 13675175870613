/* eslint-disable react/no-array-index-key, react/prop-types */
import capitalize from 'lodash.capitalize';

import { MultipleItemsWrapper, UniversalCell } from '+components/Table/Cells';
import {
  BaseColumnFactory,
  MenuColumnFactory,
  RowSelectorColumnFactory,
} from '+components/Table/Columns';
import { SelectColumnFilter } from '+components/Table/Filters';
import Tag from '+components/Tag';

import CONDITIONS from './conditions';

const testConditions = (value) =>
  (typeof value === 'string' && value !== '') ||
  typeof value === 'number' ||
  (typeof value === 'object' && Object.keys(value ?? {}).length > 0);

const getConditions = (_, { original }) =>
  Object.keys(CONDITIONS).reduce((acc, key) => {
    if (!original[key]) {
      return acc;
    }

    const val = original[key];
    if (testConditions(val)) {
      acc.push(key);
    }

    return acc;
  }, []);

export const getColumns = (props) => [
  RowSelectorColumnFactory(),
  BaseColumnFactory({
    Header: 'Rule Name',
    accessor: 'name',
  }),
  BaseColumnFactory({
    Header: 'Tags',
    accessor: 'tags',
    Cell: UniversalCell('tags'),
  }),
  BaseColumnFactory({
    accessor: (original) => getConditions(null, { original }),
    Header: 'Conditions',
    Cell: ({ value }) => {
      return !value?.length ? null : (
        <MultipleItemsWrapper gap={5}>
          {value.map((condition, index) => (
            <Tag
              key={index}
              color={CONDITIONS[condition].style}
              outlined={false}
            >
              {CONDITIONS[condition].name}
            </Tag>
          ))}
        </MultipleItemsWrapper>
      );
    },
    Filter: SelectColumnFilter({
      fixedOptions: ['all', ...Object.keys(CONDITIONS)],
      optionLabel: (key) => CONDITIONS[key]?.name ?? capitalize(key),
    }),
    filter: 'selectFilter',
  }),
  MenuColumnFactory(props),
];
